<template>
  <k-container class="view-dealer-order-container">
    <template #header>
      <h2>經銷訂單</h2>
      <k-button
        theme="info"
        @click="showEdit"
      >新增訂單</k-button>
    </template>

    <k-card>
      <k-table
        v-if="orderList.length"
        :items="orderList"
        :fields="TableFields"
        hover
      >
        <template #cell(dealer_order_create_at)="data">
          {{ timestampParse(data.value).format('YYYY/MM/DD') }}
        </template>

        <template #cell(dealer_order_port_at)="data">
          {{ timestampParse(data.value).format('YYYY/MM/DD') }}
        </template>

        <template #cell(dealer_order_income)="{ value }">
          ${{ value }}
        </template>

        <template #cell(dealer_order_has_paid)="{ value }">
          <k-tag :theme="value ? 'success' : 'danger'">{{ value ? '已付款' : '未付款' }}</k-tag>
        </template>

        <template #cell(dealer_order_has_port)="{ value }">
          <k-tag :theme="value ? 'success' : 'danger'">{{ value ? '已出貨' : '未出貨' }}</k-tag>
        </template>

        <template #cell(action)="{ item }">
          <k-button
            varient="ghost"
            theme="info"
            @click="changeFocusOrder(item.dealer_order_id)"
          >詳情</k-button>
        </template>
      </k-table>
    </k-card>

    <k-modal
      class="order-modal"
      title="訂單詳細資料"
      :show.sync="showDetail"
      @cancel="handleDetailCancel"
      :width="600"
    >
      <h3>訂單狀態：
        <k-tag
          v-if="focusOrderState"
          :theme="focusOrderState.theme"
        >{{ focusOrderState.text }}</k-tag>
      </h3>
      <k-divider>訂單資訊</k-divider>
      <k-descriptions
        v-if="focusOrderInfo"
        layout="vertical"
        bordered
      >
        <k-descriptions-item label="工作室名稱">{{ focusOrderInfo.dealer_name }}
          <k-tag :theme="DEALER_TYPE_STATE_DICT[focusOrderInfo.dealer_type].theme">{{
              DEALER_TYPE_STATE_DICT[focusOrderInfo.dealer_type].text
          }}</k-tag>
        </k-descriptions-item>
        <k-descriptions-item label="訂單總額">${{ focusOrderInfo.dealer_order_income }}</k-descriptions-item>
        <k-descriptions-item label="是否已付款">{{ focusOrderInfo.dealer_order_has_paid ? '是' : '否' }}</k-descriptions-item>
        <k-descriptions-item label="訂單建立日期">{{
            timestampParse(focusOrderInfo.dealer_order_create_at).format('YYYY/MM/DD')
        }}</k-descriptions-item>
        <k-descriptions-item label="最晚出貨日期">{{ timestampParse(focusOrderInfo.dealer_order_port_at).format('YYYY/MM/DD')
        }}</k-descriptions-item>
        <k-descriptions-item label="是否已出貨">{{ focusOrderInfo.dealer_order_has_port ? '是' : '否' }}</k-descriptions-item>
        <k-descriptions-item label="出貨方式">
          {{ focusOrderInfo.dealer_address }}
        </k-descriptions-item>
        <k-descriptions-item label="出貨方式">
          {{ focusOrderInfo.dealer_order_port_type ? PORT_TYPE_DICT[focusOrderInfo.dealer_order_port_type] : '未設定'
          }}
        </k-descriptions-item>
        <k-descriptions-item
          class="dealer-order-content"
          label="訂貨內容"
          span="3"
        >
          <div v-if="focusOrderInfo.dealer_order_products">
            <p
              v-for="product in focusOrderInfo.dealer_order_products"
              :key="`${focusOrderInfo.dealer_order_id}_${product.product_id}`"
            >{{ productDict[product.product_id].product_name_zh }} ＊ {{ product.product_quantity }}</p>
          </div>
          <k-divider></k-divider>
          <div v-if="focusOrderInfo.dealer_order_giveaways">
            <p
              v-for="giveaway in focusOrderInfo.dealer_order_giveaways"
              :key="`${focusOrderInfo.dealer_order_id}_${giveaway.giveaway_id}`"
            >{{ giveawayDict[giveaway.giveaway_id].giveaway_name_zh }} ＊ {{ giveaway.giveaway_quantity }}</p>
          </div>
        </k-descriptions-item>
      </k-descriptions>

      <template #footer>
        <k-button
          class="delete-order"
          theme="danger"
          v-if="focusOrderState.text === '未收款' && focusOrderInfo.dealer_type !== 'PURCHASING'"
          @click="handleDeleteOrder(focusOrderInfo.dealer_order_id)"
        >刪除訂單</k-button>
        <section>
          <k-button
            theme="info"
            :loading="genPortLoading"
            @click="genOrderPortDoc"
          >
            {{ focusOrderInfo.dealer_order_port_doc ? '查看出貨單' : '生成出貨單' }}
          </k-button>
          <k-button
            :theme="focusOrderState.text === '未收款' ? 'warning' : 'secondary'"
            v-if="focusOrderState.text === '未收款'"
            @click="incomeRecieve(focusOrderInfo.dealer_order_id)"
          >確認收款</k-button>
          <k-button
            :theme="focusOrderState.text === '已收款待出貨' ? 'warning' : 'secondary'"
            v-if="focusOrderState.text === '已收款待出貨'"
            @click="orderPort(focusOrderInfo.dealer_order_id)"
          >確認出貨</k-button>
        </section>
      </template>
    </k-modal>

    <k-modal
      class="edit-modal"
      :show.sync="showEditModal"
      :hideWithOk="false"
      title="建立訂單"
      :okButtonProps="{ theme: 'primary', loading: editLoading, label: '建立', disabled: !editDealerInfo }"
      @ok="$refs.form.submit()"
      @cancel="handleCancel"
    >
      <k-select
        label="工作室或採購商名稱"
        placeholder="請輸入或選擇經銷商"
        :options="dealerOptions"
        @change="matchDealer"
        block
        searchable
      >
      </k-select>
      <k-divider>{{ editDealerInfo ? '訂單資訊' : '請先輸入工作室或採購商名稱' }}</k-divider>

      <k-form
        v-show="editDealerInfo"
        ref="form"
        :questionConfig="QuestionFeilds"
        @submit="handleEditSubmit"
      >
        <template #after(dealer_order_port_type)>
          <k-divider>訂貨內容</k-divider>
          <k-button
            theme="info"
            block
            @click="showAddModal"
          >選擇產品</k-button>
          <k-card
            class="dealer-order-products"
            noShadow
          >
            <ul v-if="order_products.length || order_giveaways.length">
              <li
                v-for="product in order_products"
                :key="`edit_product_${product.key}`"
              >
                <k-input
                  :value="product.product_name"
                  readonly
                />
                <k-input
                  v-model="product.product_price"
                  addonAfter="元"
                  type="number"
                />
                <k-input
                  v-model="product.product_quantity"
                  addonAfter="組"
                  type="number"
                />
                <k-button
                  varient="ghost"
                  theme="danger"
                  icon="dash-circle-fill"
                  @click="removeProductOrGiveaway('products', product.key)"
                ></k-button>
              </li>
              <k-divider v-if="order_giveaways.length">贈品</k-divider>
              <li
                v-for="giveaway in order_giveaways"
                :key="`edit_giveaway_${giveaway.key}`"
              >
                <k-input
                  :value="giveaway.giveaway_name"
                  readonly
                />
                <k-input
                  v-model="giveaway.giveaway_quantity"
                  addonAfter="組"
                  type="number"
                />
                <k-button
                  varient="ghost"
                  theme="danger"
                  icon="dash-circle-fill"
                  @click="removeProductOrGiveaway('giveaways', giveaway.key)"
                ></k-button>
              </li>
            </ul>

            <k-empty
              v-else
              description="請選擇產品"
            ></k-empty>
          </k-card>
        </template>

        <template #after(dealer_order_quantity_5)>
          <k-divider></k-divider>
          <span
            class="total-income"
            v-if="editDealerInfo"
          >
            訂單總計：
            ${{ totalIncome }}
          </span>
        </template>
      </k-form>
    </k-modal>

    <k-modal
      class="choose-add-product-modal"
      :show.sync="showAddProductModal"
      title="選擇產品"
      :okButtonProps="{ theme: 'info', label: '新增' }"
      @ok="handleAddModalOk"
    >
      <k-divider>產品區</k-divider>
      <div>
        <k-button
          v-for="product in productList"
          :key="`add-product-${product.product_id}`"
          :theme="order_products_cache.includes(product.product_id) ? 'success' : 'secondary'"
          @click="toggleProductOrGiveaway('products', product.product_id)"
        >
          {{ product.product_name_zh }}
        </k-button>
      </div>
      <k-divider>贈品區</k-divider>
      <div>
        <k-button
          v-for="giveaway in giveawayList"
          :key="`add-giveaway-${giveaway.giveaway_id}`"
          :theme="order_giveaways_cache.includes(giveaway.giveaway_id) ? 'warning' : 'secondary'"
          @click="toggleProductOrGiveaway('giveaways', giveaway.giveaway_id)"
        >
          {{ giveaway.giveaway_name_zh }}
        </k-button>
      </div>
    </k-modal>
  </k-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { KDescriptions, KDescriptionsItem } from '@/components/Descriptions'
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import KDivider from '@/components/Divider'
import KEmpty from '@/components/Empty'
import { KForm, KInput, KSelect } from '@/components/Form'
import KTable from '@/components/Table'
import KTag from '@/components/Tag'
import KModal from '@/components/Modal'
import { timestampParse, gen8ID } from '@/utils'
import { defaultDealerProduct } from './utils'

function compareArrays(a, b) {
  let intersect = new Set([...a].filter(x => !new Set([...b]).has(x)));
  return intersect
}

export default {
  mounted() {
    if (!this.$store.state.dealer.dealerList.length) this.$store.dispatch('dealer/query')
    if (!this.$store.state.product.productList.length) this.$store.dispatch('product/query')
    if (!this.$store.state.giveaway.giveawayList.length) this.$store.dispatch('giveaway/query')
    if (!this.$store.state.dealer.orderList.length) this.$store.dispatch('dealer/queryOrderList')
  },
  data() {
    return {
      DEALER_TYPE_STATE_DICT,
      PORT_TYPE_DICT,
      QuestionFeilds: JSON.parse(JSON.stringify(QuestionFeilds)),
      TableFields,
      showDetail: false,
      showEditModal: false,
      showAddProductModal: false,
      editLoading: false,
      focusOrderInfo: undefined,
      editDealerInfo: null,
      genPortLoading: false,
      order_products: [],
      order_giveaways: [],
      order_products_cache: [],
      order_giveaways_cache: []
    }
  },
  methods: {
    ...mapActions('dealer',
      ['orderIncomeRecieve', 'orderOutPort', 'createOrder', 'buildOrderPortDoc', 'deleteOrder', 'genPortSheet']),
    timestampParse,
    changeFocusOrder(id) {
      this.focusOrderInfo = { ...this.orderList.filter(i => i.dealer_order_id === id)[0] }
      this.showDetail = true
    },
    handleDetailCancel() {
      this.focusOrderInfo = undefined
    },
    async incomeRecieve(id) {
      this.$confirm.info({
        title: '確認已收到此訂單的所有款項',
        callback: async ensure => {
          if (ensure) {
            const res = await this.orderIncomeRecieve(id)
            if (res.status === 200) {
              this.$message.success('已收款')
              this.showDetail = false
            }
          }
        }
      })
    },
    async orderPort(id) {
      this.$confirm.info({
        title: '請確認是否已將此訂單出貨',
        callback: async ensure => {
          if (ensure) {
            const res = await this.orderOutPort(id)
            if (res.status === 200) {
              this.$message.success('已出貨')
              this.showDetail = false
            }
          }
        }
      })
    },
    async handleDeleteOrder(id) {
      this.$confirm.warning({
        title: '確定要刪除此張訂單？',
        callback: async ensure => {
          if (ensure) {
            await this.deleteOrder(id)
            this.$message.info('已刪除訂單')
            this.showDetail = false
            this.focusOrderInfo = undefined
          }
        }
      })
    },
    async genOrderPortDoc() {
      if (!this.focusOrderInfo.dealer_order_port_doc) {
        this.genPortLoading = true
        const res = await this.genPortSheet(this.focusOrderInfo)
        this.focusOrderInfo.dealer_order_port_doc = res.docURL
        window.open(res.docURL)
        this.genPortLoading = false
      } else {
        window.open(this.focusOrderInfo.dealer_order_port_doc)
      }
    },
    showEdit() {
      this.showEditModal = true
    },
    handleCancel() {
      this.QuestionFeilds.forEach((e, index) => delete this.QuestionFeilds[index].value)
      this.editDealerInfo = null
    },
    showAddModal() {
      this.showAddProductModal = true
      this.order_products_cache = this.order_products.map(p => p.product_id)
      this.order_giveaways_cache = this.order_giveaways.map(g => g.giveaway_id)
    },
    toggleProductOrGiveaway(type, id) {
      const index = this[`order_${type}_cache`].indexOf(id)
      if (index === -1) this[`order_${type}_cache`].push(id)
      else this[`order_${type}_cache`].splice(index, 1)
    },
    handleAddModalOk() {
      const productInters = compareArrays(this.order_products.map(p => p.product_id), this.order_products_cache)
      this.order_products = this.order_products.filter(p => ![...productInters].includes(p.product_id))
      for (const product_id of this.order_products_cache) {
        if (!this.order_products.map(p => p.product_id).includes(product_id)) this.order_products.push({
          key: gen8ID(), product_id,
          product_name: this.productDict[product_id].product_name_zh, product_quantity: '0', product_price: '0'
        })
      }
      const giveawayInters = compareArrays(this.order_giveaways.map(p => p.giveaway_id), this.order_giveaways_cache)
      this.order_giveaways = this.order_giveaways.filter(p => ![...giveawayInters].includes(p.giveaway_id))
      for (const giveaway_id of this.order_giveaways_cache) {
        if (!this.order_giveaways.map(p => p.giveaway_id).includes(giveaway_id)) this.order_giveaways.push({
          key: gen8ID(), giveaway_id,
          giveaway_name: this.giveawayDict[giveaway_id].giveaway_name_zh, giveaway_quantity: '0', giveaway_price: '0'
        })
      }
      this.showAddProductModal = false
    },
    removeProductOrGiveaway(type, key) {
      const index = this[`order_${type}`].map(p => p.key).indexOf(key)
      this[`order_${type}`].splice(index, 1)
    },
    async handleEditSubmit({ values }) {
      const { dealer_id, dealer_name, dealer_type } = this.editDealerInfo
      const { dealer_order_create_at, dealer_order_port_at, dealer_order_quantity_50, dealer_order_quantity_5 } = values
      const res = await this.createOrder({
        dealer_id,
        dealer_name,
        dealer_type,
        dealer_order_quantity_50,
        dealer_order_quantity_5,
        dealer_order_create_at: dealer_order_create_at.unix(),
        dealer_order_port_at: dealer_order_port_at.unix(),
        dealer_order_income: this.totalIncome
      })
      if (res.status === 200) {
        this.$message.success('已建立訂單')
        this.showEditModal = false
      }
    },
    matchDealer(value) {
      const result = this.dealerList.filter(d => d.dealer_name === value)
      if (result.length) {
        this.editDealerInfo = result[0]
        this.QuestionFeilds = JSON.parse(JSON.stringify(QuestionFeilds))
        this.QuestionFeilds[0].value = timestampParse(new Date().getTime())
        this.QuestionFeilds[1].value = timestampParse(new Date().getTime()).add(3, 'days')
        const defaultItems = defaultDealerProduct(this.editDealerInfo, this.productList, this.giveawayList)
        this.order_products = defaultItems.product || []
        this.order_giveaways = defaultItems.giveaway || []
        console.log(defaultItems);
      } else {
        this.editDealerInfo = null
      }
    }
  },
  computed: {
    ...mapState('dealer', ['dealerList', 'orderList']),
    ...mapState('product', ['productList']),
    ...mapState('giveaway', ['giveawayList']),
    ...mapGetters('product', ['productDict']),
    ...mapGetters('giveaway', ['giveawayDict']),
    focusOrderState() {
      if (this.focusOrderInfo) {
        const { dealer_order_has_paid, dealer_order_has_port } = this.focusOrderInfo
        if (dealer_order_has_paid && dealer_order_has_port) return { theme: 'success', text: '訂單完成' }
        if (dealer_order_has_paid) return { theme: 'info', text: '已收款待出貨' }
        else return { theme: 'warning', text: '未收款' }
      }
      return null
    },
    totalIncome() {
      let income = Number(this.QuestionFeilds[3].value) * this.editDealerInfo.dealer_price_50 + Number(this.QuestionFeilds[4].value) * this.editDealerInfo.dealer_price_5
      if (Number(this.QuestionFeilds[3].value) + Number(this.QuestionFeilds[4].value) * 0.5 < 5) income += 120
      return income
    },
    dealerOptions() {
      if (this.dealerList.length) {
        return this.dealerList.map(d => ({ value: d.dealer_name, text: d.dealer_name }))
      }
      return []
    }
  },
  components: {
    KButton, KCard, KDivider, KEmpty, KForm, KTable, KTag, KModal, KDescriptions, KDescriptionsItem, KInput, KSelect
  }
}

const TableFields = [
  { key: 'dealer_order_id', fieldIndex: 'dealer_order_id', label: '編號' },
  { key: 'dealer_name', fieldIndex: 'dealer_name', label: '工作室名稱', searchable: true },
  { key: 'dealer_order_create_at', fieldIndex: 'dealer_order_create_at', label: '訂單建立日期', sorter: (a, b) => b - a },
  { key: 'dealer_order_port_at', fieldIndex: 'dealer_order_port_at', label: '出貨截止日' },
  { key: 'dealer_order_quantity_50', fieldIndex: 'dealer_order_quantity_50', label: '50ml 正貨數量' },
  { key: 'dealer_order_quantity_5', fieldIndex: 'dealer_order_quantity_5', label: '5*5ml 組合數量', },
  { key: 'dealer_order_income', fieldIndex: 'dealer_order_income', label: '訂單總價值' },
  {
    key: 'dealer_order_has_paid', fieldIndex: 'dealer_order_has_paid', label: '付款狀態', filters: [
      { text: '已收款', value: true },
      { text: '未收款', value: false }
    ]
  },
  {
    key: 'dealer_order_has_port', fieldIndex: 'dealer_order_has_port', label: '出貨狀態', filters: [
      { text: '已出貨', value: true },
      { text: '未出貨', value: false }
    ]
  },
  { key: "action", fieldIndex: 'action', label: '' }
]

const QuestionFeilds = [
  {
    label: '訂單建立日期',
    name: 'dealer_order_create_at',
    questionType: 'k-date-picker',
    required: true,
  },
  {
    label: '最晚出貨日期',
    name: 'dealer_order_port_at',
    questionType: 'k-date-picker',
    required: true,
  },
  {
    label: '出貨方式',
    name: 'dealer_order_port_type',
    questionType: 'k-select',
    options: [
      { text: '親送', value: 'IN_PERSON' },
      { text: '郵局寄件', value: 'POSTOFFICE' },
      { text: '黑貓到府', value: 'BLACKCAT' },
      { text: '7-11 店到店', value: 'SEVEN_TO_ELEVEN' }
    ],
    block: true,
    theme: 'secondary'
  }
]

const DEALER_TYPE_STATE_DICT = {
  DEALER_CONSIGNMENT: {
    theme: 'info',
    text: '寄賣制經銷'
  },
  DEALER_BUYOUT: {
    theme: 'success',
    text: '買斷制經銷'
  },
  PURCHASING: {
    theme: 'warning',
    text: '企業採購'
  }
}

const PORT_TYPE_DICT = {
  IN_PERSON: '親送',
}
</script>
