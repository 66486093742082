<template>
  <div :class="classes">
    <span v-if="$slots['default']">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'horizontal'
    },
    dashed: Boolean
  },
  computed: {
    classes() {
      return {
        'k-divider': true,
        [this.type]: true,
        'with-text-center': this.$slots.default,
        'dashed': !!this.dashed
      }
    }
  },
}
</script>
