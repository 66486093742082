<template>
  <k-container class="view-email-container">
    <template #header>
      <div>
        <h2>撰寫信件</h2>
        <k-select
          v-model="mode"
          :options="[{ value: 'SIMPLE', text: '一般信件模式' }, { value: 'ADVANCE', text: 'HTML模式' }]"
        />
      </div>
      <k-button theme="primary" @click="()=>{shoeDrawer=true}">選擇模板</k-button>
    </template>
    <k-card>
      <k-form :questionConfig.sync="questionConfig" :defaultValues="emailDefaultValue" @submit="handleSubmit">
        <k-card
          class="preview"
          v-if="questionConfig[2].questionType === 'k-coder'"
          slot="after(email_sent_content)"
          header="模板內容預覽："
          outline="light"
        >
          <section v-html="questionConfig[2].value"/>
        </k-card>
      </k-form>
    </k-card>

    <k-drawer :show.sync="shoeDrawer" width="400" title="選擇模板">
      <k-card v-for="temp in emailTemplateList" :key="temp.email_template_id" @click="handleChoose(temp.email_template_id)">
        <h2>{{ temp.email_template_name }}</h2>
        <span>{{ temp.email_template_desc }}</span>
      </k-card>

      <k-button theme="primary" @click="$router.push('/email/build')" block>新建模板</k-button>
    </k-drawer>
  </k-container>
</template>

<script>
import { KForm, KSelect } from '@/components/Form'
import KCard from '@/components/Card'
import KButton from '@/components/Button'
import KDrawer from '@/components/Drawer'
import Variable from './components/Variable'
import { mapState, mapActions } from 'vuex'
import Template from '@/utils/template'
import { emailConfig } from './config'
import { fetchApi } from '@/utils'

export default {
  name: 'Email-Compose',
  mounted() {
    const { user_id } = this.$route.query
    this.$store.dispatch('email/queryEmailTemplate')
    if (user_id) {
      this.$store.dispatch('user/query')
      this.targetUserId = user_id
    }
  },
  data() {
    return {
      questionConfig: [],
      mode: 'SIMPLE',
      shoeDrawer: false,
      targetUserId: undefined,
      templateUseId: undefined,
      emailDefaultValue: {}
    }
  },
  methods: {
    ...mapActions('email', ['add']),
    handleChoose(email_template_id) {
      this.$confirm.info({
        title: '確定要選擇此模板？',
        callback: ensure => {
          if(ensure) {
            this.templateUseId = email_template_id
            this.shoeDrawer = false
          }
        }
      })
    },
    async handleSubmit({ values }) {
      const API = '/api/mail/'
      const response = await fetchApi(API, { data: {
        to_address: this.targetUser.user_email,
        subject: values.email_sent_title,
        body: values.email_sent_content
      }})
      console.log(response);
      const res = await this.add({
        ...values,
        email_sent_target: this.targetUser.user_email,
        email_sent_dealer: this.currentUser.admin_id,
        email_sent_account: this.emailUser || 'info@kirstytaiwan.com'
      })
      if (res.status === 200) {
        this.$message.success('已寄出信件')
        this.$router.push('/order')
      }
    }
  },
  computed: {
    ...mapState('user', ['userList']),
    ...mapState('login', ['currentUser']),
    ...mapState('email', ['emailTemplateList', 'emailUser']),
    targetUser() {
      if (this.targetUserId && this.userList.length) return this.userList.filter(u => u.key_id === this.targetUserId)[0]
      return {}
    },
    templateUse() {
      if (this.templateUseId) return this.emailTemplateList.filter(t => t.key_id === this.templateUseId)[0]
      return {}
    }
  },
  watch: {
    templateUse(newVal) {
      const TmpParser = new Template(this.targetUser)
      this.mode = newVal.email_template_type === 'TXT' ? 'SIMPLE' : "ADVANCE"
      this.emailDefaultValue = { 
        ...this.emailDefaultValue,
        email_sent_title: TmpParser.display(newVal.email_template_title),
        email_sent_content: TmpParser.display(newVal.email_template_content)
      }
    },
    targetUser: {
      immediate: true,
      handler(newVal) {
        if (Object.keys(newVal)) this.emailDefaultValue = {
          ...this.emailDefaultValue,
          email_sent_target: newVal.user_email
        }
      }
    },
    mode: {
      immediate: true,
      handler(newVal) {
        this.questionConfig = emailConfig[newVal](this.targetUser.user_email)
      }
    }
  },
  components: {
    KForm, KCard, KButton, KDrawer, KSelect, Variable
  }
}
</script>
