<template>
  <k-card :class="classes"
    @click="e => $emit('click', e)"
    @dblclick="e => $emit('dblclick', e)"
    @mousedown.native="e => $emit('mousedown', e)"
    @contextmenu="e => $emit('contextmenu', e)"
  >
    <div class="widget-icon" v-if="icon"><k-icon :icon="icon" :theme="theme"></k-icon></div>
    <div class="widget-content">
      <h2>{{ header }}</h2>
      <p>{{ text }}</p>
      <slot></slot>
    </div>
  </k-card>
</template>

<script>
import KCard from '../Card';
import KIcon from '../Icon';

export default {
  props: {
    theme: String,
    icon: String,
    header: [String, Number],
    text: String
  },
  computed: {
    classes() {
      return {
        'k-widget': true,
        'k-widget-icon': true
      }
    }
  },
  components: {
    KCard, KIcon
  }
}
</script>
