<template>
  <k-modal class="move-modal" :show.sync="showProxy">
    <template #header>
      <div>
        <!-- <k-icon theme="info" icon="folder-symlink-fill"></k-icon> -->
        <h2>移動 {{ files.length || 0 }} 個項目</h2>
      </div>
      <div>
        <p>當前位置:</p><k-button @click="chooseRoute(route)" varient="outline" theme="white">{{ route }}</k-button>
        <p>目地位置:</p><k-button varient="outline" theme="white">{{ currentRoute || '媒體庫' }}</k-button>
      </div>
      <div class="last-route">
        <span @click="lastRoute" class="action-icon"><k-icon icon="arrow-left" /></span>
        <h3>{{ currentRoute.split('/').slice(-1)[0] || '媒體庫' }}</h3>
      </div>
    </template>

    <template #default>
      <ul>
        <li class="dir" v-for="dir in currentDir.dir" :key="`move-modal-${dir.path}`">
          <div>
            <k-icon icon="folder-fill"></k-icon>
            <p>{{ dir.path.split('/').slice(-1)[0] }}</p>
          </div>
          <div>
            <k-button theme="secondary">移動</k-button>
            <span @click="chooseRoute(dir.path)" class="action-icon"><k-icon icon="arrow-right"/></span>
          </div>
        </li>
        <li class="dir file" v-for="file in currentDir.file" :key="`move-modal-${file.file}`">
          <div>
            <k-icon icon="image-fill"></k-icon>
            <p>{{ file.file }}</p>
          </div>
        </li>
      </ul>
    </template>

    <template #footer>
      <div class="route-nested">
        <p @click="chooseRoute('/')">媒體庫</p>
        <div v-for="route in currentBreadCrumb" :key="`media-route-${route.path}`">
          <k-icon icon="chevron-right"/>
          <p @click="chooseRoute(route.path)">{{ route.name }}</p>
        </div>
      </div>
      <div class="action">
        <span class="action-icon"><k-icon icon="folder-plus" /></span>
        <div class="buttons">
          <k-button theme="secondary" @click="hide">取消</k-button>
          <k-button theme="info" @click="handleMove">移動</k-button>
        </div>
      </div>
    </template>
  </k-modal>
</template>

<script>
import KModal from '@/components/Modal'
import KButton from '@/components/Button'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    show: Boolean,
    route: String,
    files: Array
  },
  mounted() {
    this.showProxy = this.show
    this.currentRoute = this.route
  },
  data() {
    return {
      showProxy: false,
      currentRoute: ''
    }
  },
  computed: {
    ...mapState('media', ['mediaLayers']),
    currentBreadCrumb() {
      if (!this.currentRoute || this.currentRoute === '/') return []
      return [...this.currentRoute.split('/').filter(item => item && item !== '/').reduce((acc, cur) => {
        acc.push({ path: `${acc[acc.length - 1] ? acc[acc.length - 1].path : ''}/${cur}`, name: cur })
        return acc
      }, [])]
    },
    currentDir() {
      if (this.mediaLayers) {
        return {
          ...this.mediaLayers[this.currentRoute]
        }
      }
      return
    }
  },
  methods: {
    ...mapActions('media', ['moveFiles']),
    hide() {
      this.showProxy = false
    },
    lastRoute() {
      const routeList = this.currentRoute.split('/')
      if (routeList.slice(0,-1).length) {
        this.currentRoute = routeList.slice(0, -1).join('/') || '/'
      } else {
        this.currentRoute =  '/'
      }
    },
    chooseRoute(route) {
      this.currentRoute = route
    },
    async handleMove() {
      await this.moveFiles({ files: this.files, route: this.currentRoute })
      this.hide()
    }
  },
  watch: {
    show(val) {
      this.showProxy = val
    },
    showProxy(val) {
      this.$emit('update:show', val)
    }
  },
  components: {
    KModal, KButton
  }
}
</script>
