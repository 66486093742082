var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-container',{staticClass:"view-home-container",scopedSlots:_vm._u([{key:"header",fn:function(){return _vm._l((_vm.headerWedgit),function(item,index){return _c('k-widget-chart',{key:("hwc_" + index),attrs:{"header":"9,823","text":item.label,"type":item.chart,"data":[10, 22, 34, 46, 58, 70, 46, 23, 45, 78, 34, 12],"label":item.label,"chartColor":_vm.COLOR_MAP[item.theme],"fill":item.theme === 'info',"options":{
        elements: {
          point: { radius: 0 }
        }
      }}})})},proxy:true}])},[_c('k-card',{staticClass:"traffic-chart",staticStyle:{"margin-bottom":"16px"},attrs:{"header":"Traffic - November 2020"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{class:{ 'traffic-info': true, 'hide': _vm.hideLine.includes('visit')}},[_c('section',[_c('p',[_vm._v("Total Visit")]),_c('k-checkbox',{attrs:{"value":"visit","uncheckIcon":'eye-fill',"checkedIcon":'eye-slash-fill'},model:{value:(_vm.hideLine),callback:function ($$v) {_vm.hideLine=$$v},expression:"hideLine"}})],1),_c('b',[_vm._v("29,793 Users")]),_c('k-progress',{attrs:{"value":"47","size":"thin","theme":"warning"}})],1),_c('div',{class:{ 'traffic-info': true, 'hide': _vm.hideLine.includes('unique')}},[_c('section',[_c('p',[_vm._v("Unique")]),_c('k-checkbox',{attrs:{"value":"unique","uncheckIcon":'eye-fill',"checkedIcon":'eye-slash-fill'},model:{value:(_vm.hideLine),callback:function ($$v) {_vm.hideLine=$$v},expression:"hideLine"}})],1),_c('b',[_vm._v("19,793 Users")]),_c('k-progress',{attrs:{"value":"23","size":"thin","theme":"success"}})],1),_c('div',{class:{ 'traffic-info': true, 'hide': _vm.hideLine.includes('member')}},[_c('section',[_c('p',[_vm._v("Member")]),_c('k-checkbox',{attrs:{"value":"member","uncheckIcon":'eye-fill',"checkedIcon":'eye-slash-fill'},model:{value:(_vm.hideLine),callback:function ($$v) {_vm.hideLine=$$v},expression:"hideLine"}})],1),_c('b',[_vm._v("81,793 Users")]),_c('k-progress',{attrs:{"value":"92","size":"thin","theme":"primary"}})],1)]},proxy:true}])},[_c('k-chart-line',{attrs:{"height":"300","datasets":[
        {
          data: Array(24).fill(30),
          backgroundColor: _vm.COLOR_MAP.light,
          borderColor: _vm.COLOR_MAP.light,
          borderDash: [10, 5],
          borderWidth: 1,
          label:'month-avarage',
          elements: {
            point: { radius: 0 }
          }
        },
        {
          data: Array(24).fill().map(function (e) { return _vm.getRandom(20,55); }),
          backgroundColor: _vm.COLOR_MAP.primary,
          borderColor: _vm.COLOR_MAP.primary,
          hide: _vm.hideLine.includes('member'),
          borderWidth: 2,
          tension: 0.2,
          label:'week',
        },
        {
          data: Array(24).fill().map(function (e) { return _vm.getRandom(20,55); }),
          backgroundColor: _vm.COLOR_MAP.success,
          borderColor: _vm.COLOR_MAP.success,
          hide: _vm.hideLine.includes('unique'),
          borderWidth: 2,
          tension: 0.2,
          label:'week',
        },
        {
          data: [10, 22, 34, 46, 58, 70, 46, 23, 45, 78, 34, 12, 10, 22, 34, 46, 58, 70, 46, 23, 45, 78, 34, 12],
          backgroundColor: _vm.COLOR_MAP.warning,
          borderColor: _vm.COLOR_MAP.warning,
          hide: _vm.hideLine.includes('visit'),
          borderWidth: 2,
          tension: 0.4,
          label:'week',
          fill: true
        } ],"labels":"week","options":{
        responsive:true,
        maintainAspectRatio: false,
        scales: { x: { grid: { display: false } }},
        plugins: {
          legend: {
            display: false,
            position: 'bottom'
          }
        }
      }}})],1),_c('section',{staticClass:"brand-container"},_vm._l((_vm.traffic),function(item,index){return _c('k-widget-brand',{key:("wb_" + index),attrs:{"data":Array(7).fill().map(function (e) { return _vm.getRandom(20,55); }),"label":"week","icon":item.icon,"color":item.color,"leftHeader":item.like,"leftFooter":"NEW","rightHeader":item.feed,"rightFooter":"MEMBER"}})}),1),_c('k-card',{staticClass:"sales-and-traffic",staticStyle:{"margin-top":"16px"},attrs:{"header":"Sales & Traffic"}},[_c('section',[_c('k-chart-doughnut',{attrs:{"datasets":[
        {
          data: [10, 22, 34, 46, 58, 70, 46],
          borderColor: 'transparent',
          colorPattern: 'rainbow10'
        }
      ],"options":{ plugins: { legend: { display: false } } },"labels":"week"}})],1),_c('section',[_vm._l((_vm.gender),function(item,index){return _c('div',{key:("gender_" + index),staticClass:"traffic-progress gender"},[_c('h4',[_vm._v(_vm._s(item.source))]),_c('span',[_vm._v("("+_vm._s(item.percentage)+"%)")]),_c('k-progress',{attrs:{"value":item.percentage,"theme":"warning","size":"thin"}})],1)}),_vm._l((_vm.traffic),function(item,index){return _c('div',{key:("traffic_" + index),staticClass:"traffic-progress source"},[_c('h4',[_c('k-icon',{attrs:{"icon":item.icon}}),_vm._v(" "+_vm._s(item.source))],1),_c('span',[_vm._v("("+_vm._s(parseInt(item.value * 100 / _vm.trafficSum))+"%)")]),_c('k-progress',{attrs:{"max":_vm.trafficSum,"value":item.value,"theme":"success","size":"thin"}})],1)})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }