<template>
  <nav class="k-pagination-wrapper" aria-label="pageination">
    <ul :class="classes">
      <li class="prev" @click="changeCurrentPage(value-1)">‹</li>
      <li v-if="value >= 5" :class="{ current: value===1 }" @click="changeCurrentPage(1)">1</li>
      <li v-if="value >= 5" class="prev-five" @click="changeCurrentPage(value-5)"></li>
      <li v-for="p in showPage" :key="p" @click="changeCurrentPage(p)" :class="{ current: value===p }">
        {{ p }}
      </li>
      <li v-if="value <= pages-4" class="next-five" @click="changeCurrentPage(value+5)"></li>
      <li v-if="value < pages-3" :class="{ current: value===pages }" @click="changeCurrentPage(pages)">{{ pages }}</li>
      <li class="next"  @click="changeCurrentPage(value+1)">›</li>
    </ul>
  </nav>
</template>

<script>
import { range } from '../utils';

export default {
  props: {
    current: {
      type: [String, Number],
    },
    value: {
      type: Number,
      default: 1
    },
    pages: [String, Number]
  },
  methods: {
    changeCurrentPage(page) {
      var newPage = page
      if (page<1) newPage = 1
      if (page>this.pages) newPage = this.pages
      this.$emit('input', newPage)
    }
  },
  computed: {
    classes() {
      return {
        'k-pagination': true
      }
    },
    showPage() {
      if (this.value<=4 && this.pages>5) return range(1,6);
      if (this.value<=4) return range(1,this.pages+1);
      if (this.value>=this.pages-3) return range(this.pages-4, this.pages+1)
      else return range(this.value-2, this.value+3)
    }
  },
}
</script>
