var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-table',{staticClass:"component-product-comment-table",attrs:{"items":_vm.data,"fields":_vm.CommentFields},scopedSlots:_vm._u([{key:"cell(product_id)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.productDict[value].product_name_zh))])]}},{key:"cell(comment_stars)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return _vm._l((Array(parseInt(value)).fill()),function(i,index){return _c('k-icon',{key:("comment-stars-" + (item.key_id) + "-" + index),attrs:{"icon":"star-fill"}})})}},{key:"cell(comment_content)",fn:function(ref){
var value = ref.value;
return [_c('p',[_vm._v(_vm._s(value))])]}},{key:"cell(comment_images)",fn:function(ref){
var value = ref.value;
return [_c('p',[_vm._v(_vm._s(value ? value.split(',').length : '-'))]),_c('span',[_c('k-icon')],1)]}},{key:"cell(comment_create_at)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.timestampParse(value).format('YYYY-MM-DD'))+" ")]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('k-button',{attrs:{"varient":"ghost","theme":"info"},on:{"click":function($event){return _vm.showDetail(item)}}},[_vm._v("查看詳情")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }