class Template {
  constructor(vars) {
    this.assign(vars)
    this.leftDelimiter = '\{\{'
    this.rightDelimiter = '\}\}'
  }

  templateReplace(content) {
    let pattern = /\{\{\s*([a-zA-Z_\x7f-\xff][a-zA-Z0-9_\x7f-\xff]*)\s*\}\}/g
    let replaceContent = content.replace(pattern, (match, offset, str) => {
      return this.tempVar[offset] || 'NaN'
    })
    return replaceContent
  }

  assign(vars) {
    this.tempVar = { ...this.tempVar, ...vars }
  }

  display(content) {
    let repContent = this.templateReplace(content)
    return repContent
  }
}

export default Template
