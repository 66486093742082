export function parseMarkdown(markdownText) {
  const headerPattern = /^(#{1,6})\s+(.*)/gm;
  const emphasisPattern = /\*\*(.*?)\*\*/g;
  const linkPattern = /\[(.*?)\]\((.*?)\)/g;
  const codeInlinePattern = /`(.+?)`/g;
  const lineBreakPattern = /  \n/g;

  return markdownText
    .replace(headerPattern, (match, hashes, headerText) => `<h${hashes.length}>${headerText}</h${hashes.length}>`) // 解析標題
    .replace(emphasisPattern, (match, innerText) => `<em>${innerText}</em>`) // 解析粗體和斜體
    .replace(/\*(.*?)\*/g, (match, innerText) => `<strong>${innerText}</strong>`) // 解析粗體
    .replace(linkPattern, (match, linkText, url) => `<a href="${url}">${linkText}</a>`) // 解析超連結
    .replace(codeInlinePattern, (match, code) => `<code>${code}</code>`) // 解析內嵌程式碼
    .replace(lineBreakPattern, '<br>'); // 解析斷行
}
