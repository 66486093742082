<template>
  <k-container class="view-home-container">
    <template #header class="home-header">
      <k-widget-chart
        v-for="(item, index) in headerWedgit" :key="`hwc_${index}`"
        header="9,823"
        :text="item.label"
        :type="item.chart"
        :data="[10, 22, 34, 46, 58, 70, 46, 23, 45, 78, 34, 12]"
        :label="item.label"
        :chartColor="COLOR_MAP[item.theme]"
        :fill="item.theme === 'info'"
        :options="{
          elements: {
            point: { radius: 0 }
          }
        }"
      />
    </template>
    <k-card class="traffic-chart" header="Traffic - November 2020" style="margin-bottom: 16px">
      <k-chart-line
        height="300"
        :datasets="[
          {
            data: Array(24).fill(30),
            backgroundColor: COLOR_MAP.light,
            borderColor: COLOR_MAP.light,
            borderDash: [10, 5],
            borderWidth: 1,
            label:'month-avarage',
            elements: {
              point: { radius: 0 }
            }
          },
          {
            data: Array(24).fill().map(e => getRandom(20,55)),
            backgroundColor: COLOR_MAP.primary,
            borderColor: COLOR_MAP.primary,
            hide: hideLine.includes('member'),
            borderWidth: 2,
            tension: 0.2,
            label:'week',
          },
          {
            data: Array(24).fill().map(e => getRandom(20,55)),
            backgroundColor: COLOR_MAP.success,
            borderColor: COLOR_MAP.success,
            hide: hideLine.includes('unique'),
            borderWidth: 2,
            tension: 0.2,
            label:'week',
          },
          {
            data: [10, 22, 34, 46, 58, 70, 46, 23, 45, 78, 34, 12, 10, 22, 34, 46, 58, 70, 46, 23, 45, 78, 34, 12],
            backgroundColor: COLOR_MAP.warning,
            borderColor: COLOR_MAP.warning,
            hide: hideLine.includes('visit'),
            borderWidth: 2,
            tension: 0.4,
            label:'week',
            fill: true
          },
        ]"
        labels="week"
        :options="{
          responsive:true,
          maintainAspectRatio: false,
          scales: { x: { grid: { display: false } }},
          plugins: {
            legend: {
              display: false,
              position: 'bottom'
            }
          }
        }"
      >
      </k-chart-line>

      <template #footer>
        <div :class="{ 'traffic-info': true, 'hide': hideLine.includes('visit')}">
          <section>
            <p>Total Visit</p>
            <k-checkbox
              v-model="hideLine"
              value="visit"
              :uncheckIcon="'eye-fill'"
              :checkedIcon="'eye-slash-fill'"
            />
          </section>
          <b>29,793 Users</b>
          <k-progress value="47" size="thin" theme="warning" />
        </div>
        <div :class="{ 'traffic-info': true, 'hide': hideLine.includes('unique')}">
          <section>
            <p>Unique</p>
            <k-checkbox
              v-model="hideLine"
              value="unique"
              :uncheckIcon="'eye-fill'"
              :checkedIcon="'eye-slash-fill'"
            />
          </section>
          <b>19,793 Users</b>
          <k-progress value="23" size="thin" theme="success" />
        </div>
        <div :class="{ 'traffic-info': true, 'hide': hideLine.includes('member')}">
          <section>
            <p>Member</p>
            <k-checkbox
              v-model="hideLine"
              value="member"
              :uncheckIcon="'eye-fill'"
              :checkedIcon="'eye-slash-fill'"
            />
          </section>
          <b>81,793 Users</b>
          <k-progress value="92" size="thin" theme="primary" />
        </div>
      </template>
    </k-card>

    <section class="brand-container">
      <k-widget-brand
        v-for="item, index in traffic"
        :data="Array(7).fill().map(e => getRandom(20,55))"
        label="week"
        :key="`wb_${index}`"
        :icon="item.icon"
        :color="item.color"
        :leftHeader="item.like"
        leftFooter="NEW"
        :rightHeader="item.feed"
        rightFooter="MEMBER"
      />
    </section>

    <k-card class="sales-and-traffic" header="Sales & Traffic" style="margin-top: 16px">
      <section>
        <k-chart-doughnut
        :datasets="[
          {
            data: [10, 22, 34, 46, 58, 70, 46],
            borderColor: 'transparent',
            colorPattern: 'rainbow10'
          }
        ]"
        :options="{ plugins: { legend: { display: false } } }"
        labels= "week"
      />
      </section>
      <section>
        <div class="traffic-progress gender" v-for="(item, index) in gender" :key="`gender_${index}`">
          <h4>{{ item.source }}</h4>
          <span>({{ item.percentage }}%)</span>
          <k-progress :value="item.percentage" theme="warning" size="thin" />
        </div>
        <div class="traffic-progress source" v-for="(item, index) in traffic" :key="`traffic_${index}`">
          <h4><k-icon :icon="item.icon" /> {{ item.source }}</h4>
          <span>({{ parseInt(item.value * 100 / trafficSum) }}%)</span>
          <k-progress :max="trafficSum" :value="item.value" theme="success" size="thin" />
        </div>
      </section>
    </k-card>
  </k-container >
</template>

<script>
import { KCheckbox } from '@/components/Form'
import { KWidgetChart, KWidgetBrand } from '@/components/Widget'
import { KChartLine, KChartDoughnut } from '@/components/Chart'
import { KProgress, KProgressBar } from '@/components/Progress'
import KCard from '@/components/Card'
import { COLOR_MAP, getRandom } from '@/utils'

const headerWedgit = [
  { theme: 'error', label: 'Product Sale', chart: 'bar' },
  { theme: 'info', label: 'Income', chart: 'line' },
  { theme: 'success', label: 'User Visit', chart: 'bar' },
  { theme: 'warning', label: 'Member Grow', chart: 'line' },
]

const traffic = [
  { source: 'Organic Search', value: 191235, like: 462, feed: 28, icon: 'globe2', color: COLOR_MAP.warning },
  { source: 'Facebook', value: 51223, like: 462, feed: 28, icon: 'facebook', color: '#30497b' },
  { source: 'Twitter', value: 37564, like: 462, feed: 28, icon: 'twitter', color: '#00bcd4' },
  { source: 'LinkedIn', value: 27319, like: 462, feed: 28, icon: 'linkedin', color: '#4876b4' }
]

const gender = [
  { source: 'Male', percentage: 43, icon: '' },
  { source: 'Fele', percentage: 37, icon: '' },
]

export default {
  name: 'Home',
  async mounted() {
    const res = await this.$store.dispatch('analytics/query')
    console.log(res);

    this.mockUserLand = await this.$mock.fetch('user_landList')
    console.log(this.mockUserLand);
  },
  data() {
    return {
      COLOR_MAP,
      traffic,
      gender,
      headerWedgit,
      hideLine: [],

      mockUserLand: []
    }
  },
  computed: {
    userLandInfo() {
      if (this.mockUserLand.length) {
        return this.mockUserLand
      //   console.log('tests');
      //   return this.mockUserLand.reduce((acc, log) => {
      //     const { user_land_cookie_id, user_land_from, user_land_membership } = log
      //     if (!acc.uniqueCookieIds.includes(user_land_cookie_id) && user_land_membership === '0') {
      //       acc.uniqueCookieIds.push(user_land_cookie_id)
      //       acc.newUserCount += 1
      //       acc.from[userFrom(user_land_from)] += 1
      //     }
      //     if (user_land_membership === '1') {
      //       acc.membershipCount += 1
      //       acc.from[userFrom(user_land_from)] += 1
      //     }
      //     return acc
      //   }, { membershipCount: 0, newUserCount:0, uniqueCookieIds: [], from: { 'Direct': 0, 'Google': 0} })
      }
      return {}
    },
    trafficSum() {
      return traffic.reduce((acc, e) => acc + e.value, 0)
    }
  },
  methods: {
    getRandom
  },
  components: {
    KWidgetChart, KWidgetBrand, KCard, KCheckbox,
    KProgress, KProgressBar,  KChartLine, KChartDoughnut
  }
}
</script>
