<template>
  <div id="k-modal-wrapper" :class="classes" @click="bgHide">
    <transition name="modal">
      <div
        role="document"
        class="modal-dialog"
        v-if="syncShow"
        :style="{ maxWidth: `${width}px` }"
      >
        <div class="modal-content">
          <k-card class="modal-body">
            <template v-if="!hideHeader" #header>
              <slot name="header">{{ title }}</slot><k-icon v-if="!closable" @click="hide" icon="x" />
            </template>
            <template #default><slot></slot></template>
            <template v-if="!hideFooter" #footer>
              <slot name="footer">
                <k-button v-bind="mixedCancelButtonProps" @click="hide" class="modal-btn cancel" />
                <k-button v-if="!mixedOkButtonProps.hide" v-bind="mixedOkButtonProps" @click="handleOK" class="modal-btn ok" />
              </slot>
            </template>
          </k-card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import KCard from './Card'
import KButton from './Button'
import KIcon from './Icon'

export default {
  props: {
    title: String,
    theme: String,
    okText: String,
    okTheme: String,
    cancelText: String,
    width: Number,
    closable: Boolean,
    hideHeader: Boolean,
    hideFooter: Boolean,
    hideWithOk: Boolean,
    show: {
      type: Boolean,
      default: false
    },
    okButtonProps: {
      type: Object,
      default: () => ({
        theme: 'warning',
        label: '確認'
      })
    },
    cancelButtonProps: {
      type: Object,
      default: () => ({
        theme: 'secondary',
        label: '取消'
      })
    }
  },
  methods: {
    hide() {
      this.syncShow = false
      this.$emit('cancel')
    },
    bgHide(e) {
      if (e.target.id === 'k-modal-wrapper') this.hide();
    },
    handleOK(e) {
      this.$emit('ok', e);
      if (this.hideWithOk) this.hide();
    }
  },
  computed: {
    classes() {
      return {
        'k-modal': true,
        'show': this.show
      }
    },
    syncShow: {
      get(){
        return this.show
      },
      set(val){
        if (!val) this.$emit('hide')
        this.$emit('update:show',val)
      }
    },
    mixedOkButtonProps() {
      const { label, theme } = this.okButtonProps
      return {
        ...this.okButtonProps,
        label: this.okText || label,
        theme: this.okTheme || theme
      }
    },
    mixedCancelButtonProps() {
      const { label } = this.cancelButtonProps
      return { ...this.cancelButtonProps, label: this.cancelText || label }
    }
  },
  components: {
    KCard, KButton, KIcon
  }
}
</script>
