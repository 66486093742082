<script>
export default {
  name: 'k-skeleton',
  functional: true,
  props: {
    active: Boolean,
    loading: Boolean,
    dense: Boolean,
    hideTitle: {
      type: Boolean,
      default: false
    },
    line: {
      type: Number,
      default: 2
    }
  },
  render(h, context) {
    const { active, loading, dense, line, hideTitle } = context.props
    const slots = context.slots()
    const classes = {
      'k-skeleton': true,
      'active': !!active,
      'dense': !!dense
    }

    return loading ? (
    <div class={classes}>
      <div class="skeleton-content">
        { hideTitle ? null : <h3 class="skeleton-title"></h3>}
        { 
          line !== 0
          ? <ul class="skeleton-paragraph">
              { Array(line ? line-1 : line).fill().map(() => (<li></li>)) }
              <li style={{ width: line === 1 ? '100%' : '60%' }}></li>
            </ul> 
          : null
        }
      </div>
    </div>
  ) : slots.default && slots.default.length === 1 ? slots.default : <span>{slots.default}</span> 
  }
}
</script>
