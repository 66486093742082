var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-container',{staticClass:"view-crm-container",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("經銷商管理")]),_c('k-button',{attrs:{"theme":"info"},on:{"click":_vm.showEdit}},[_vm._v("新增經銷商")])]},proxy:true}])},[_c('k-card',[(_vm.dealerWithAvarageOrder.length)?_c('k-table',{attrs:{"items":_vm.dealerWithAvarageOrder,"fields":_vm.TableFields,"hover":""},scopedSlots:_vm._u([{key:"cell(dealer_type)",fn:function(data){return [_c('k-tag',{attrs:{"theme":_vm.DEALER_TYPE_STATE_DICT[data.value].theme}},[_vm._v(_vm._s(_vm.DEALER_TYPE_STATE_DICT[data.value].text))])]}},{key:"cell(contract_due)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dealer_contract_signed_at ? _vm.timestampParse(item.dealer_contract_signed_at).add(1, 'years').format('YYYY/MM/DD') : '')+" ")]}},{key:"cell(dealer_contract_signed_at)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.timestampParse(value).format('YYYY/MM/DD') : '')+" ")]}},{key:"cell(avarage_order_duration)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || '-')+" ")]}},{key:"cell(dealer_price_50)",fn:function(ref){
var value = ref.value;
return [(value && value.length > 1)?_c('k-tooltip',{attrs:{"placement":"bottom","title":("歷史定價：\n " + (value.join(' | ')))}},[_vm._v(" "+_vm._s(value && value[0])+" ")]):_c('span',[_vm._v(_vm._s(value && value[0]))])]}},{key:"cell(dealer_price_5)",fn:function(ref){
var value = ref.value;
return [(value && value.length > 1)?_c('k-tooltip',{attrs:{"placement":"bottom","title":("歷史定價：\n " + (value.join(' | ')))}},[_vm._v(" "+_vm._s(value && value[0])+" ")]):_c('span',[_vm._v(_vm._s(value && value[0]))])]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [(item.dealer_type !== 'PURCHASING')?_c('k-button',{attrs:{"varient":"ghost","theme":"info"},on:{"click":function($event){return _vm.editDealer(item.dealer_id)}}},[_vm._v("編輯")]):_vm._e()]}}],null,false,2482686496)}):_vm._e()],1),_c('k-modal',{staticClass:"dealer-modal",attrs:{"show":_vm.showDealerModal,"hideWithOk":false,"title":"建立經銷商資訊","okButtonProps":{ theme: 'primary', loading: _vm.editLoading, label: Object.keys(_vm.focusDealer).length ? '更新' : '建立' }},on:{"update:show":function($event){_vm.showDealerModal=$event},"ok":function($event){return _vm.$refs.form.submit()},"cancel":_vm.handleCancel}},[_c('k-form',{ref:"form",attrs:{"questionConfig":_vm.QuestionFeilds,"defaultValues":Object.assign({}, {dealer_price_50: '1000', dealer_price_5: '550'}, _vm.focusDealer)},on:{"update:questionConfig":function($event){_vm.QuestionFeilds=$event},"update:question-config":function($event){_vm.QuestionFeilds=$event},"submit":_vm.handleDealerSubmit}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }