<script>
export default {
  props: {
    value: [Number, String],
    max: [Number, String],
    theme: String,
    showPercentage: Boolean,
    showValue: Boolean,
    size: {
      type: String,
      default: 'normal'
    }
  },
  mounted() {
    if (this.$parent.$el.className.split(' ').includes('k-progress')) this.inGroup = true
  },
  data() {
    return {
      inGroup: false
    }
  },
  computed: {
    classes() {
      return {
        'k-progress': true,
        'thin': this.size === 'thin'
      }
    },
    computedMax() {
      return parseInt(this.max) || 100
    },
    dynamicPercentage() {
      if (this.inGroup) return parseInt(this.value) * 100 / this.$parent.computedMax
      return parseInt(this.value) * 100 / this.computedMax
    }
  },
  render(h) {
    const progressBarRender = (
      <div
        aria-valuenow={this.value}
        class={{ 'progress-bar': true, [`theme--${this.theme}`]: !!this.theme }}
        style={{ width: `${this.dynamicPercentage}%` }}
      >
        {this.showValue && this.size !== 'thin' ? (<span>{this.value}</span>): null}
      </div>
    )

    return !this.inGroup ? (
      <div class={this.classes}>
        { progressBarRender }
      </div>
    ): progressBarRender
  },
}
</script>
