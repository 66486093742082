import { gen8ID, getRandom } from '../utils'

const emptyArr = Array(1000).fill({})

const fromDict = [
  null,
  'https://www.google.com/',
  'https://l.facebook.com/'
]

const mockData = emptyArr.map((e, index) => {
  const membership = getRandom(0,1)
  const randomDate = new Date(2021, getRandom(0,11), getRandom(1, 28)).getTime() / 1000

  return {
    key_id:`${index}`,
    user_land_cookie_id: gen8ID(),
    user_land_create_at:`${randomDate}`,
    user_land_from: fromDict[getRandom(0,2)],
    user_land_id:"2",
    user_land_membership:`${membership}`,
    user_uid: membership ? gen8ID() : null
  }
})

function userFrom(fromUrl) {
  switch (fromUrl) {
    case 'https://www.google.com/': return 'Google'
    case 'https://l.facebook.com/': return 'Facebook'
    default: return 'Direct'
  }
}

const computed = mockData.reduce((acc, log) => {
  const { user_land_cookie_id, user_land_from, user_land_membership } = log
  if (!acc.uniqueCookieIds.includes(user_land_cookie_id) && user_land_membership === '0') {
    acc.uniqueCookieIds.push(user_land_cookie_id)
    acc.newUserCount += 1
    acc.from[userFrom(user_land_from)] += 1
  }
  if (user_land_membership === '1') {
    acc.membershipCount += 1
    acc.from[userFrom(user_land_from)] += 1
  }
  return acc
}, {
  membershipCount: 0,
  newUserCount:0,
  uniqueCookieIds: [],
  from: { 'Direct': 0, 'Google': 0, 'Facebook': 0}
})
console.log(computed);

export default mockData
