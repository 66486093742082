<template>
  <k-container class="view-coupon-list">
    <header class="pageTitle">
      <h1>所有優惠券</h1>
      <k-button theme="info" icon="plus-lg" @click="handleShowCreateModal">建立優惠券</k-button>
    </header>
    <k-card>
      <k-table v-if="!pageLoading" :items="couponGroupBySuper" :fields="TableFields" hover>
        <template #cell(coupon_uid)="{value, item}">
          <k-tag theme="secondary" v-if="!item.coupon_uids"><code>{{ value }}</code></k-tag>
          <template v-else>
            <k-tag theme="secondary"><code>{{ item.coupon_uids[0] }}</code></k-tag> ~
            <k-tag theme="secondary"><code>{{ item.coupon_uids[item.coupon_uids.length-1] }}</code></k-tag>
          </template>
        </template>
        <template #cell(coupon_super)="{value}">
          <k-tag theme="dark"><code>{{ value || '-' }}</code></k-tag>
        </template>
        <template #cell(coupon_type)="{value}">
          <k-tag :theme="value === 'DISCOUNT' ? 'warning' : 'info'">{{ value === 'DISCOUNT' ? '折扣' : '贈品' }}</k-tag>
        </template>
        <template #cell(coupon_period)="{value}">
          {{ value ? `${value / 3600 / 24} 天` : '-' }} 
        </template>
        <template #cell(coupon_exp)="{value}">
          {{ value ? timestampParse(value).format('YYYY-MM-DD') : '-' }}
        </template>
        <template #cell(action)="{item}">
          <k-button varient="ghost" theme="info" @click="handleShowCouponDetail(item)">查看詳情</k-button>
        </template>
      </k-table>
      <k-loader-scale v-else></k-loader-scale>
    </k-card>

    <k-modal :show.sync="showCouponDetail" title="優惠券詳情" @cancel="handleCancel">
      <div class="inner-head">
        <h2 class="inner-title">優惠碼 - {{ focusCoupon.coupon_uid }}</h2>
        <k-tag :theme="focusCoupon.coupon_type === 'DISCOUNT' ? 'warning' : 'info'">
          {{ focusCoupon.coupon_type === 'DISCOUNT' ? '折扣型優惠券' : '贈品型優惠券' }}
        </k-tag>
      </div>
      <k-divider>優惠券內容</k-divider>
      <k-descriptions v-if="Object.keys(focusCoupon).length" layout="vertical" bordered>
        <k-descriptions-item label="優惠券系統碼">{{ focusCoupon.coupon_super || '-' }}</k-descriptions-item>
        <k-descriptions-item label="使用範疇">{{ focusCoupon.coupon_scope }}</k-descriptions-item>
        <k-descriptions-item label="剩餘數量">{{ focusCoupon.coupon_quantity || '未設定' }}</k-descriptions-item>
        <k-descriptions-item label="限定次數">{{ focusCoupon.coupon_max_usage || '未限定' }}</k-descriptions-item>
        <k-descriptions-item label="使用期限">{{ focusCoupon.coupon_exp || '未限定' }}</k-descriptions-item>
        <k-descriptions-item label="有效時長">{{ focusCoupon.coupon_period/86400 || '未限定' }}天</k-descriptions-item>
      </k-descriptions>
      <k-divider>優惠項目</k-divider>
      <k-card v-if="focusCoupon.coupon_type === 'DISCOUNT'" header="折扣算法" outline="secondary" noShadow>
        <math xmlns='http://www.w3.org/1998/Math/MathML'> <mi> f </mi> <mrow> <mo> ( </mo> <mi> 訂單總額 </mi> <mo> ) </mo> </mrow> <mo> = </mo> <mi> 訂單總額 </mi> <mo v-html="SignDict[focusCoupon.coupon_formula[0]]"></mo> <mn>{{ focusCoupon.coupon_formula.slice(1).join(' ').replace('x', '訂單總額') }}</mn> </math>
      </k-card>
      <k-card v-if="focusCoupon.coupon_type !== 'DISCOUNT' && Object.keys(productDict).length" outline="secondary" header="可選贈品" noShadow>
        <div class="coupon-product" v-if="focusCoupon.coupon_product && !['ALL', 'PRODUCT', 'SET'].includes(focusCoupon.coupon_product)">
          <div class="product product-unchoosed">
            <k-button theme="secondary" v-for="product in productList.filter(p => !focusCoupon.coupon_product.includes(p.product_id))" :key="`coupon-product-unchoosed-${focusCoupon.coupon_id}-${product.product_id}`">
              {{ product.product_name_zh }}
            </k-button>
          </div>
          <div class="arrow"><k-icon icon="caret-right-fill" /></div>
          <div class="product product-choosed">
            <k-button theme="secondary" v-for="product in focusCoupon.coupon_product" :key="`coupon-product-choosed-${focusCoupon.coupon_id}-${product}`">
              {{ productDict[product].product_name_zh }}
            </k-button>
          </div>
        </div>
        <k-button theme="secondary" v-if="['ALL', 'PRODUCT', 'SET'].includes(focusCoupon.coupon_product)">{{ focusCoupon.coupon_product }}</k-button>
      </k-card>

      <k-divider>EDM 提醒鏈接</k-divider>
      <k-card class="edm-link" outline="secondary" noShadow>
        <ul v-if="focusCoupon.coupon_notice_templates && email_templateList.length">
          <li v-for="edm in focusCoupon.coupon_notice_templates" :key="`coupon-edm-${focusCoupon.coupon_id}-${edm.key}`">
            <div class="head">
              <k-tooltip :title="edm.template_type === 'EMAIL' ? 'Email電子郵件' : 'SMS 簡訊'">
                <div class="icon"><k-icon :icon="edm.template_type === 'EMAIL' ? 'envelope' : 'chat-right-text'" /></div>
              </k-tooltip>
              <k-select
                theme="secondary"
                placeholder="選擇EDM模板"
                v-model="edm.template_id"
                :options="edmSelectOptions[edm.template_type]"
              />
              <div class="icon close" @click="handleUnlink(edm.key)"><k-icon icon="x-lg"/></div>
            </div>
            <k-input addonBefore="到期提醒天數" addonAfter="天" placeholder="輸入天數" v-model="edm.lastDay"></k-input>
            <div v-if="edm.template_id" class="template-summary">{{ edmSystemIdDict[edm.template_id].email_template_desc }}</div>
          </li>
          <li class="add">
            <k-button theme="secondary" block icon="envelope-plus" @click="handleLinkTemplate('EMAIL')">
              新增 Email <k-icon icon="plus-lg" />
            </k-button>
            <k-button theme="secondary" block icon="chat-right-text" @click="handleLinkTemplate('SMS')">
              新增 SMS <k-icon icon="plus-lg" />
            </k-button>
          </li>
        </ul>
      </k-card>
      <template #footer>
        <k-button theme="info" @click="handleUpdate" :loading="updateLoading">更新優惠券</k-button>
        <k-button theme="secondary" @click="handleCancel">取消</k-button>
      </template>
    </k-modal>

    <k-modal class="create-modal" :show.sync="showCreateModal" title="建立優惠券" @cancel="cleanNewCouponForm" @ok="handleSubmitForm">
      <k-form ref="createForm" :questionConfig.sync="CouponForm" @submit="handleCreate">
        <template #after(coupon_max_usage)>
          <k-divider>優惠內容設定</k-divider>
          <k-radio-group
            label="優惠類型"
            v-model="newCouponType"
            radioType="button"
            varient="primary"
            :required="true"
            @onChange="handleCouponTypeChange"
          >
            <k-radio-button theme="info" value="DISCOUNT">折扣</k-radio-button>
            <k-radio-button theme="info" value="GIVEAWAY">贈品</k-radio-button>
            <k-radio-button theme="info" value="BONUS">附贈(買N送M)</k-radio-button>
          </k-radio-group>
          <div class="discount"  v-if="newCouponType === 'DISCOUNT'">
            <k-input
              v-model="newCouponInfo.DISCOUNT.coupon_formula"
              label="折扣公式"
              extra="請先輸入 - 或 *，再輸入數字，中間用空格分開，例如：『* 0.85』，代表打八五折; 『- 100』，代表折抵100元"
              required
            />
            <k-card header="折扣算法" outline="secondary" noShadow>
              <!-- <math xmlns='http://www.w3.org/1998/Math/MathML'> <mi> f </mi> <mrow> <mo> ( </mo> <mi> 訂單總額 </mi> <mo> ) </mo> </mrow> <mo> = </mo> <mi> 訂單總額 </mi> <mo v-html="showCouponFormula(newCouponInfo.DISCOUNT.coupon_formula)[0]"></mo> <mn>{{ showCouponFormula(newCouponInfo.DISCOUNT.coupon_formula)[1] }}</mn> </math> -->
              <math xmlns='http://www.w3.org/1998/Math/MathML'> <mi> f </mi> <mrow> <mo> ( </mo> <mi> 訂單總額 </mi> <mo> ) </mo> </mrow> <mo> = </mo> <mi> 訂單總額 </mi> <mo v-html="SignDict[showCouponFormula(newCouponInfo.DISCOUNT.coupon_formula)[0]]"></mo> <mn>{{ showCouponFormula(newCouponInfo.DISCOUNT.coupon_formula).slice(1).join(' ').replace('x', '訂單總額') }}</mn> </math>
            </k-card>
          </div>
          <div class="giveaway" v-if="newCouponType === 'GIVEAWAY'">
            <k-input v-model="newCouponInfo.GIVEAWAY.coupon_max_product_chosen" type="Number" label="可選擇贈品數" required />
            <k-card v-if=" Object.keys(productDict).length" outline="secondary" header="可選贈品" noShadow>
              <div class="coupon-product">
                <div class="product product-unchoosed">
                  <k-button
                    icon="plus"
                    theme="secondary"
                    v-for="product in productList.filter(p => !newCouponInfo.GIVEAWAY.coupon_product.includes(p.product_id))" :key="`new-coupon-product-unchoosed-${product.product_id}`"
                    @click="handleNewCouponChooseProduct(product.product_id, 'GIVEAWAY')"
                  >
                    {{ product.product_name_zh }}
                  </k-button>
                </div>
                <div class="arrow"><k-icon icon="caret-right-fill" /></div>
                <div class="product product-choosed">
                  <k-button
                    theme="light" v-for="product in newCouponInfo.GIVEAWAY.coupon_product" 
                    icon="dash"
                    :key="`new-coupon-product-choosed-${product}`"
                    @click="handleNewCouponUnchooseProduct(product, 'GIVEAWAY')"
                  >
                    {{ productDict[product].product_name_zh }}
                  </k-button>
                </div>
              </div>
            </k-card>
          </div>
          <div class="bonus" v-if="newCouponType === 'BONUS'">
            <k-input v-model="newCouponInfo.BONUS.coupon_bonus_min_standard" type="Number" label="附贈門檻" addonBefore="買" required />
            <k-input v-model="newCouponInfo.BONUS.coupon_bouns_quantity" type="Number" label="附贈數量" addonBefore="送" required />
            <k-card v-if=" Object.keys(productDict).length" outline="secondary" header="可選贈品" noShadow>
              <div class="coupon-product">
                <div class="product product-unchoosed">
                  <k-button
                    icon="plus"
                    theme="secondary"
                    v-for="product in productList.filter(p => !newCouponInfo.BONUS.coupon_product.includes(p.product_id))" :key="`new-coupon-product-unchoosed-${product.product_id}`"
                    @click="handleNewCouponChooseProduct(product.product_id, 'BONUS')"
                  >
                    {{ product.product_name_zh }}
                  </k-button>
                </div>
                <div class="arrow"><k-icon icon="caret-right-fill" /></div>
                <div class="product product-choosed">
                  <k-button
                    theme="light" v-for="product in newCouponInfo.BONUS.coupon_product" 
                    icon="dash"
                    :key="`new-coupon-product-choosed-${product}`"
                    @click="handleNewCouponUnchooseProduct(product, 'BONUS')"
                  >
                    {{ productDict[product].product_name_zh }}
                  </k-button>
                </div>
              </div>
            </k-card>
          </div>
        </template>
      </k-form>
    </k-modal>
  </k-container>
</template>

<script>
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import KDivider from '@/components/Divider'
import KTable from '@/components/Table'
import KTag from '@/components/Tag'
import KModal from '@/components/Modal'
import KTooltip from '@/components/Tooltip'
import { KInput, KSelect, KRadioGroup, KRadioButton, KForm } from '@/components/Form'
import { KLoaderScale } from '@/components/Loader'
import { KDescriptions, KDescriptionsItem } from '@/components/Descriptions'
import { mapGetters, mapState, mapActions } from 'vuex'
import { timestampParse, gen8ID } from '@/utils'
export default {
  mounted() {
    if (this.$store.state.coupon.couponList.length) this.pageLoading = false
  },
  data() {
    return {
      TableFields,
      SignDict,
      CouponForm: JSON.parse(JSON.stringify(CouponForm)),
      showCouponDetail: false,
      showCreateModal: false,
      updateLoading: false,
      pageLoading: true,
      focusCoupon: {},
      newCouponType: '',
      newCouponInfo: {
        DISCOUNT: {
          coupon_formula: ''
        },
        GIVEAWAY: {
          coupon_product: []
        },
        BONUS: {
          coupon_bouns_quantity: '',
          coupon_bonus_min_standard: '',
          coupon_product: []
        }
      }
    }
  },
  computed: {
    ...mapState('coupon', ['couponList']),
    ...mapState('product', ['productList']),
    ...mapState('edm', ['email_templateList']),
    ...mapGetters('coupon', ['couponGroupBySuper']),
    ...mapGetters('product', ['productDict']),
    ...mapGetters('edm', ['edmSystemIdDict']),
    edmSelectOptions() {
      return this.email_templateList.reduce((acc, edm) => ({
        ...acc,
        [edm.email_template_carrier]: [...(acc[edm.email_template_carrier] || []), { text: edm.email_template_name, value: edm.email_template_system_id }]
      }), {})
    }
  },
  methods: {
    ...mapActions('coupon', ['updateCoupon', 'createCoupon']),
    timestampParse,
    handleShowCreateModal() {
      this.showCreateModal = true
    },
    handleShowCouponDetail(coupon) {
      this.showCouponDetail = true
      this.focusCoupon = {
        ...coupon,
        coupon_formula: coupon.coupon_formula && coupon.coupon_formula.split(' '),
        coupon_exp: coupon.coupon_exp && timestampParse(coupon.coupon_exp).format('YYYY-MM-DD'),
        coupon_notice_templates: coupon.coupon_notice_templates.map(edm => ({
            ...edm,
            key: gen8ID(),
            template_type: this.edmSystemIdDict[edm.template_id].email_template_carrier,
            lastDay: edm.lastTime ? `${parseInt(edm.lastTime) / 86400 }`: '0'
          }))
      }
    },
    handleLinkTemplate(type) {
      this.focusCoupon.coupon_notice_templates = [...this.focusCoupon.coupon_notice_templates, {
        key: gen8ID(),
        template_type: type,
        template_id: '',
        lastDay: '0'
      }]
    },
    handleUnlink(key) {
      this.focusCoupon.coupon_notice_templates = this.focusCoupon.coupon_notice_templates.filter(edm => edm.key !== key)
    },
    handleCancel() {
      this.focusCoupon = {}
      this.showCouponDetail = false
    },
    handleCouponTypeChange(value) {
      this.newCouponType = value
    },
    handleNewCouponChooseProduct(product_id, type) {
      this.newCouponInfo[type].coupon_product = [...this.newCouponInfo[type].coupon_product, product_id]
    },
    handleNewCouponUnchooseProduct(product_id, type) {
      this.newCouponInfo[type].coupon_product = this.newCouponInfo[type].coupon_product.filter(p => p !== product_id)
    },
    handleSubmitForm() {
      if (this.newCouponType) {
        if (Object.values(this.newCouponInfo[this.newCouponType]).some(v => !v || !v.length)) return this.$message.warning('優惠內容設定有遺漏，請檢查！')
        return this.$refs.createForm.submit()
      }
      return this.$message.warning('未設定優惠券類型')
    },
    async handleCreate(values) {
      this.updateLoading = true
      await this.createCoupon({
        ...values.values, ...this.newCouponInfo[this.newCouponType], coupon_type: this.newCouponType
      })
      this.$message.success('已新增優惠券')
      this.updateLoading = false
      this.cleanNewCouponForm()
    },
    async handleUpdate() {
      this.updateLoading = true
      const { coupon_notice_templates } = this.focusCoupon
      const payload = {
        ...this.focusCoupon,
        coupon_notice_templates: coupon_notice_templates.map(edm => ({
          template_id: edm.template_id,
          lastTime: parseInt(edm.lastDay) * 86400
        }))
      }
      const res = await this.updateCoupon(payload)
      this.$message.success('已更新優惠券')
      this.updateLoading = false
    },
    cleanNewCouponForm() {
      this.CouponForm = JSON.parse(JSON.stringify(CouponForm))
      this.newCouponType= ''
      this.newCouponInfo = {
        DISCOUNT: {
          coupon_formula: ''
        },
        GIVEAWAY: {
          coupon_product: []
        },
        BONUS: {
          coupon_bouns_quantity: '',
          coupon_bonus_min_standard: '',
          coupon_product: []
        }
      }
    },
    showCouponFormula(formula) {
      if (formula) {
        const exploded = formula.split(' ')
        return exploded
      }
      return ['', '']
    }
  },
  watch: {
    couponList(newVal) {
      if (newVal.length) this.pageLoading = false
    }
  },
  components: {
    KLoaderScale, KTable, KCard, KButton, KTag, KModal, KDescriptions, KDescriptionsItem, KDivider, KSelect, KInput, KTooltip,
    KForm, KRadioButton, KRadioGroup
  }
}

const TableFields = [
  { key: 'key_id', fieldIndex: 'key_id', label: 'NO.' },
  { key: 'coupon_uid', fieldIndex: 'coupon_uid', label: '優惠碼', searchable: true },
  { key: 'coupon_name', fieldIndex: 'coupon_name', label: '優惠券名稱' }, 
  { key: 'coupon_type', fieldIndex: 'coupon_type', label: '優惠類型', sorter: (a, b) => a === 'DISCOUNT' ? 0 : -1 },
  { key: 'coupon_scope', fieldIndex: 'coupon_scope', label: '使用範疇', filters: true },
  { key: 'coupon_period', fieldIndex: 'coupon_period', label: '有效時長', sorter: (a, b) => b - a },
  { key: 'coupon_exp', fieldIndex: 'coupon_exp', label: '到期日', sorter: (a, b) => b - a },
  { key: 'action', fieldIndex: 'action', label: '' }
]
const SignDict = {
  '-': '-',
  '*': '&#x00D7;'
}

const updateForm = (oldForm, couponType) => genCouponForm(couponType).map((f,i) => ({ ...oldForm[i], show: f.show }))

const CouponForm = [
  {
    questionType: 'k-input',
    name: 'coupon_uid',
    label: "優惠碼",
    theme: "light",
    required: true,
    title: '請輸入優惠碼',
  },
  {
    questionType: 'k-input',
    name: 'coupon_name',
    label: "優惠券名稱",
    theme: "light",
    required: true,
    title: '請輸入優惠碼',
    extra: '在消費者端會顯示的優惠券名稱',
  },
  {
    questionType: 'k-textarea',
    name: 'coupon_desc',
    label: '優惠券描述',
    required: true,
    maxlength: 140,
    placeholder: '描述優惠券內容（如果是贈品優惠券可描述贈品內容物）',
    extra: '此描述會顯示於消費者選擇優會券的介面中',
    theme: 'light',
  },
  {
    questionType: 'k-select',
    name: 'coupon_scope',
    label:"優惠券使用範疇",
    options: [
      { text: '公開 [只要輸入優惠碼即可使用]', value: 'ALL' },
      { text: '會員 [只能透過後台或系統發放給會員，並從『選擇優惠券』載入]', value: 'MEMBER' },
      { text: '系統 [正常情況下無法直接使用，為網站依照設定條件自動載入]', value: 'SYSTEM' }
    ],
    block: true,
    required: true,
    theme: 'light',
    placeholder: '請選擇優惠券使用範疇',
  },
  {
    questionType: 'k-input',
    name: 'coupon_period',
    type: "Number",
    addonAfter: '天',
    label: "有效天數",
    theme: "light",
    title: '請輸入優惠券有效天數',
  },
  {
    questionType: 'k-date-picker',
    name: 'coupon_exp',
    label: "有效期限",
    theme: 'light',
  },
  {
    questionType: 'k-input',
    name: 'coupon_quantity',
    label: '優惠券總量',
    type: 'Number',
    theme: 'light',
  },
  {
    questionType: 'k-input',
    name: 'coupon_max_usage',
    label: '每人限制使用次數',
    placeholder: '請輸入折扣公式',
    addonAfter: '次',
    type: 'Number',
    theme: 'light',
  }
]
</script>
