import { COLOR_MAP } from './index'

export const default8 = [
  ...Object.values(COLOR_MAP),
]

export const earthTones6 = [
  "#cb997e",
  "#ddbea9",
  "#ffe8d6",
  "#b7b7a4",
  "#a5a58d",
  "#6b705c"
]

export const rainbow10= [
  "#001219",
  "#005f73",
  "#0a9396",
  "#94d2bd",
  "#e9d8a6",
  "#ee9b00",
  "#ca6702",
  "#bb3e03",
  "#ae2012",
  "#9b2226"
]

export default {
  default8,
  earthTones6,
  rainbow10
} 
