import store from '@/store'

const StarFill = () => {
  const star = document.createElement('img')
  star.setAttribute('src', 'https://www.chris-farrell.com.tw/img/_system/star-fill.svg')
  return star
}
const Star = () => document.createElement('img').setAttribute('src', 'https://www.chris-farrell.com.tw/img/_system/star.svg')
const genStarOfProduct = (productScore) => {
  return `<div class="score-light" style='width: ${productScore*20}%'>
    <img src="https://www.chris-farrell.com.tw/img/_system/star-fill.svg">
    <img src="https://www.chris-farrell.com.tw/img/_system/star-fill.svg">
    <img src="https://www.chris-farrell.com.tw/img/_system/star-fill.svg">
    <img src="https://www.chris-farrell.com.tw/img/_system/star-fill.svg">
    <img src="https://www.chris-farrell.com.tw/img/_system/star-fill.svg">
  </div>`
}

const ProductLinkPlugin = (editor, editorComponent, postEditComponent) => {
  const ProductLink = {
    type: 'menuitem',
    text: '插入產品廣告',
    icon: 'BadgeAd',
    tooltip: '插入產品廣告',
    onAction: () => {
      postEditComponent.$refs.ProductLinkModal.execute({
        callback: products => {
          const newFragment = document.createDocumentFragment()
          products.forEach(product => {
            console.log(postEditComponent);
            let score = postEditComponent.productScore[product.product_id]
            let newAd = document.createElement('div')
            let productAvatar = product.product_type === 'PRODUCT'
              ? `https://www.chris-farrell.com.tw/img/product/${product.product_uid}/avatar.webp`
              :`https://www.chris-farrell.com.tw/img/product/${product.product_images[0]}`
            newAd.id = 'product-ad-chunk'
            newAd.innerHTML = `
              <div class="img-wrapper">
                <img src="${productAvatar}" alt="Chris Farrell ${product.product_name_zh}">
              </div>
              <div class="product-info">
                <p>${product.product_sub_title || ''}</p>
                <h3 class="product-name">${product.product_name_en}</h3>
                <h3 class="product-name">${product.product_name_zh}</h3>
                <div class="product-price"><span class="original">$${product.product_price.ORIGIN}</span> $${product.product_price.MEMBER}</div>
                <div class="score">
                  <div class="wrapper">
                    ${genStarOfProduct(score)}
                    <div class="score-empty">
                      <img src="https://www.chris-farrell.com.tw/img/_system/star.svg">
                      <img src="https://www.chris-farrell.com.tw/img/_system/star.svg">
                      <img src="https://www.chris-farrell.com.tw/img/_system/star.svg">
                      <img src="https://www.chris-farrell.com.tw/img/_system/star.svg">
                      <img src="https://www.chris-farrell.com.tw/img/_system/star.svg">
                    </div>
                  </div>
                  <p class='score-number'>${score}/5</p>
                </div>
                <a href="https://www.chris-farrell.com.tw/product/${product.product_name_en.toLowerCase().split(' ').join('-')}?utm_source=blog&utm_medium=cf_blog&utm_campaign=${product.product_name_en.toLowerCase().split(' ').join('')}_regular">瞭解更多</a>
              </div>
            `
            newFragment.appendChild(newAd)
          })
          editor.selection.setNode(newFragment)
        }
      })
    }
  }

  editor.ui.registry.addButton('ProductLinkMenu', {
    text: '插入產品廣告',
    icon: 'BadgeAd',
    onAction: ProductLink.onAction
  })

  editor.ui.registry.addContextMenu('ProductLinkMenu', {
    update: element => {
      return [ProductLink];
    }
  })
}

export default ProductLinkPlugin
export const ProductLinkStyle = `
  #product-ad-chunk {
    display: flex;
    width: fit-content;
    margin: 16px 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #656565;
    border-radius: 8px;
    box-shadow: 0 0 5px #ebebeb;
    gap: 16px;
    padding: 16px 40px;
  }
  #product-ad-chunk .img-wrapper {
    background-color: #d9d7d4;
    border-radius: 8px;
    overflow: hidden;
  }
  #product-ad-chunk img {
    display: block;
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
  #product-ad-chunk .product-info h3{
    font-weight: 300;
    font-size: 28px;
    margin: 0;
  }
  #product-ad-chunk .product-info .product-price {
    font-weight: 400;
    font-size: 20px;
    margin-top: 8px;
  }
  #product-ad-chunk .product-info .product-price .original {
    text-decoration-line: line-through;
    font-size: 20px;
    color: #b9b9b9;
  }
  #product-ad-chunk .product-info .score {
    display: flex;
  }
  #product-ad-chunk .product-info .score .wrapper {
    display: flex;
    position: relative;
    width: 100px
  }
  #product-ad-chunk .product-info .score-light {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    overflow-x: hidden;
    height: 100%;
  }
  #product-ad-chunk .product-info .score img {
    width: 20px;
    height: auto;
  }
  #product-ad-chunk .product-info .score-number {
    margin-left: 8px;
  }
  #product-ad-chunk .product-info .score-empty {
    display: flex;
  }

  #product-ad-chunk .product-info a {
    display: block;
    width: fit-content;
    padding: 8px 20px;
    background-color: #fff;
    margin-top: 16px;
    text-decoration-line: none;
    border-radius: 4px;
    color: #333;
    cursor: pointer;
  }
`
