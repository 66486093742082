import database from '@/utils/database'

export const query = (MODEL_NAME, config={}) => async () => {
  const { order=MODEL_NAME + '_id', callback=res=>res, asc=true } = config
  if (order) var res = await database.table(MODEL_NAME).orderBy(order, asc ? 'ASC' : 'DESC').get()
  else var res = await database.table(MODEL_NAME).get()
  console.log(res);
  res.data = res.data.map(callback)
  return res
}

export const create = (MODEL_NAME, logCreateTime=false, config={}) => async payload => {
  const { callback=res=>res } = config
  const res =  await database.table(MODEL_NAME).set({
    ...payload,
    [`${MODEL_NAME}_create_at`]: logCreateTime ? database.FieldValue.serverTimestamp() : undefined
  })
  res.data = [res.data].map(callback)[0]
  return res
}

export const update = (MODEL_NAME, columnName=`${MODEL_NAME}_id`, rule='=') => async payload => {
  const { id, ...data } = payload
  return await database.table(MODEL_NAME).where(columnName, rule, id).update(data)
}

export const remove = (MODEL_NAME, columnName=`${MODEL_NAME}_id`, rule='=') => async payload => {
  return await database.table(MODEL_NAME).where(columnName, rule, payload).delete()
}
