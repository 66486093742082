<template>
  <k-container class="view-login-container">
    <k-card class="login-section">
      <k-card class="login-form " noShadow>
        <h1>Login</h1>
        <p>Sign In to your account</p>
        <k-input v-model="email" type="email" label="Email" placeholder="Account">
          <k-icon slot="addonBefore" icon="person-fill"></k-icon>
        </k-input>
        <k-input v-model="password" type="password" label="Password" placeholder="Password" @keyup.native.enter="handleLogin">
          <k-icon slot="addonBefore" icon="lock-fill"></k-icon>
        </k-input>
        <k-button theme="primary" :loading="loading" @click="handleLogin">{{ loading ? '登入中' : '登入' }}</k-button>
      </k-card>
      <k-card class="login-brand" noShadow>
        <img src="/static/g2y-pure-logo.png" alt="">
      </k-card>
    </k-card>
  </k-container>
</template>

<script>
import KCard from '@/components/Card'
import KButton from '@/components/Button'
import { KInput } from '@/components/Form' 
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false
    }
  },
  methods: {
    ...mapActions('login', ['login']),
    async handleLogin() {
      this.loading = true
      if (this.email && this.password) {
        const res = await this.login({ email: this.email, password: this.password })
        console.log(this.email , this.password);
        if (res.state) {
          this.$router.push('/')
        } else {
          this.$message.error('帳號或密碼錯誤')
          this.loading = false
        }
      }
    }
  },
  components: {
    KCard, KButton, KInput
  }
}
</script>
