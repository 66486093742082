<template>
  <div :class="classes">
    <label v-if="label" :for="uid"><span v-if="required && !hideRequiredMark">＊ </span>{{ label }}</label>
    <section>
      <div class="input">
        <textarea 
          ref="input"
          :id="uid"
          :value="value"
          :placeholder="placeholder || `設定${label}`"
          :required="required"
          :readonly="readonly"
          :minlength="minlength"
          :maxlength="maxlength"
          @invalid="handleInvalid"
          @input="$emit('input', $event.target.value); handleValid()"
          :style="{ resize: resize ? 'auto' : 'none', height: `${Number(height)}px` }"
        />
      </div>
    </section>
    <small v-if="extra" class="extra">{{ extra }}</small>
    <span v-if="isInvalid && invalidContext" class="extra invalid">
      <k-icon icon="exclamation-triangle" theme="danger" />
      {{invalidContext}}
    </span>
  </div>
</template>

<script>
import formMixin from '@/mixin/form'

export default {
  mixins: [formMixin],
  mounted() {
    this.uid = `k-textarea-${parseInt(Math.random()*1000)}`
  },
  props: {
    placeholder: String,
    value: String,
    label: String,
    extra: String,
    theme: String,
    required: Boolean,
    hideRequiredMark: Boolean,
    minlength: [Number, String],
    maxlength: [Number, String],
    height: [Number, String],
    resize: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uid: '',
    }
  },
  computed: {
    classes() {
      return {
        'k-textarea': true,
        [`varient--${this.theme}`]: !!this.theme,
        'invalid': this.isInvalid
      }
    },
  }
}
</script>
