<template>
  <k-card class="move-selector">
    <template #header>
      <div class="title">
        <!-- <k-icon theme="info" icon="folder-symlink-fill"></k-icon> -->
        <h2>查詢多媒體</h2>
        <span @click="() => $emit('close')"><k-icon icon="x"></k-icon></span>
      </div>
      <div class="last-route" v-if="currentMode === 'ROUTE'">
        <div>
          <span @click="lastRoute" class="action-icon"><k-icon icon="arrow-left" /></span>
          <h3>{{ currentRoute.split('/').slice(-1)[0] || '媒體庫' }}</h3>
        </div>
        <span class="search-mode" @click="changeMode('SEARCH')"><k-icon icon="search"></k-icon></span>
      </div>
      <div class="search" v-if="currentMode === 'SEARCH'">
        <span class="search-mode" @click="changeMode('ROUTE')"><k-icon icon="arrow-left"></k-icon></span>
        <k-input v-model="searchFile" placeholder="搜尋檔案名稱" theme="secondary" debounce></k-input>
      </div>
    </template>

    <template #default>
      <ul v-if="currentMode === 'ROUTE'">
        <li class="dir" v-for="dir in currentDir.dir" :key="`move-modal-${dir.path}`" @click="chooseRoute(dir.path)">
          <div>
            <k-icon icon="folder-fill"></k-icon>
            <p>{{ dir.path.split('/').slice(-1)[0] }}</p>
          </div>
          <div>
            <span @click="chooseRoute(dir.path)" class="action-icon"><k-icon icon="arrow-right"/></span>
          </div>
        </li>
        <li
          class="dir file" v-for="file in currentDir.file"
          :key="`move-modal-${file.file}`"
          @click="handleClick(file)"
          v-copy="!choosedCallback ? `http://www.chris-farrell.com.tw/img${currentRoute}/${file.file}` : null"
          v-copy:success="()=>{!choosedCallback ? $message.success('已將檔案連結複製到剪貼簿') : void 8}"
        >
          <div>
            <div class="image-wrapper">
              <img :src="`http://www.chris-farrell.com.tw/img${currentRoute}/${file.file}`" alt="">
            </div>
          <p>{{ file.file }}</p>
          </div>
          <div>
            <span class="action-icon"><k-icon icon="link"/></span>
          </div>
        </li>
      </ul>
      <ul v-if="currentMode === 'SEARCH'">
        <li
          class="dir file" v-for="file in mediaSearchResult"
          :key="`media-search-${file.parentRoute}-${file.file}`"
          @click="handleClick(file)"
          v-copy="!choosedCallback ? `http://www.chris-farrell.com.tw/img${file.parentRoute}/${file.file}` : null"
          v-copy:success="()=>{!choosedCallback ? $message.success('已將檔案連結複製到剪貼簿') : void 8}"
        >
          <div>
            <div class="image-wrapper">
              <img :src="`http://www.chris-farrell.com.tw/img${file.parentRoute}/${file.file}`" alt="">
            </div>
            <p>{{ file.file }}</p>
          </div>
          <div>
            <span class="action-icon"><k-icon icon="link"/></span>
          </div>
        </li>
      </ul>
    </template>

    <template #footer>
      <div class="route-nested" v-if="currentMode === 'ROUTE'">
        <p @click="chooseRoute('/')">媒體庫</p>
        <div v-for="route in currentBreadCrumb" :key="`media-route-${route.path}`">
          <k-icon icon="chevron-right"/>
          <p @click="chooseRoute(route.path)">{{ route.name }}</p>
        </div>
      </div>
      <div class="route-nested" v-else>
        <p>搜尋結果</p>
      </div>
    </template>
  </k-card>
</template>

<script>
import KCard from '@/components/Card'
import KButton from '@/components/Button'
import { KInput } from '@/components/Form'
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    route: String,
    mode: String,
    search: String,
    choosedCallback: Function
  },
  mounted() {
    this.currentRoute = this.route
    this.currentMode = this.mode
    this.searchFile = this.search
  },
  data() {
    return {
      currentRoute: '',
      currentMode: 'ROUTE',
      searchFile: '',
    }
  },
  computed: {
    ...mapState('media', ['mediaLayers']),
    ...mapGetters('media', ['mediafiles']),
    currentBreadCrumb() {
      if (!this.currentRoute || this.currentRoute === '/') return []
      return [...this.currentRoute.split('/').filter(item => item && item !== '/').reduce((acc, cur) => {
        acc.push({ path: `${acc[acc.length - 1] ? acc[acc.length - 1].path : ''}/${cur}`, name: cur })
        return acc
      }, [])]
    },
    currentDir() {
      if (this.mediaLayers) {
        return {
          ...this.mediaLayers[this.currentRoute]
        }
      }
      return
    },
    mediaSearchResult() {
      if (this.mediafiles.length && this.mode === 'SEARCH') {
        return this.mediafiles.filter(file => file.name.toLowerCase().includes(this.searchFile.toLowerCase()))
      }
      return []
    }
  },
  methods: {
    lastRoute() {
      const routeList = this.currentRoute.split('/')
      if (routeList.slice(0,-1).filter(r => r).length) {
        this.currentRoute = routeList.slice(0, -1).join('/')
      } else {
        console.log('root');
        this.currentRoute =  '/'
      }
    },
    chooseRoute(route) {
      this.currentRoute = route
    },
    changeMode(mode) {
      this.currentMode = mode
    },
    handleClick(file) {
      if (this.choosedCallback) {
        this.choosedCallback(file)
        this.$emit('close')
      }
    }
  },
  watch: {
    // show(val) {
    //   this.showProxy = val
    // },
    // showProxy(val) {
    //   this.$emit('update:show', val)
    // }
    searchFile(val) {
      this.$emit('update:search', val)
    },
    currentRoute(val) {
      this.$emit('update:route', val)
    },
    currentMode(val) {
      this.$emit('update:mode', val)
    }
  },
  components: {
    KButton, KCard, KInput
  }
}
</script>
