import { genBasicModel } from './utils/basicAction'
import { query, create, update } from './utils/basicService'
import database from '@/utils/database'

const MODEL_NAME = 'giveaway'
const { basicAction, basicMutation } = genBasicModel(
  MODEL_NAME,
  query(MODEL_NAME),
  create(MODEL_NAME, true),
  update(MODEL_NAME))

export default {
  namespaced: true,
  state: {
    giveawayList: []
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction,
  },
  getters: {
    giveawayDict({ giveawayList }) {
      if (giveawayList.length) {
        return giveawayList.reduce((acc, p) => {
          return { ...acc, [p.giveaway_id]: { ...p } }
        }, {})
      }
      return {}
    }
  }
}
