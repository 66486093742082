<template>
  <div class="operation-orders package">
    <h4>包貨確認</h4>
    <ul>
      <li v-for="order in orderCache" :key="`op-package-order-${order.orders_id}`">
        <span>{{ order.orders_id }}</span>
        <k-tag :theme="logisticsDict[order.order_logistics_subtype].theme">
          {{ logisticsDict[order.order_logistics_subtype].name }} | {{ order.order_logistics_info.BookingNote || order.order_logistics_id }}
        </k-tag>
      </li>
    </ul>

    <k-button @click="handleSubmit" theme="info" :disabled="!orderCache.length" block>
      {{ orderCache.length ? '確認完成包貨' : '無可確認包貨訂單' }}
    </k-button>
  </div>
</template>

<script>
import KTag from '@/components/Tag'
import KButton from '@/components/Button'
import { contains } from 'micromatch';

export default {
  props: {
    orders: Array,
    logisticsDict: Object
  },
  data() {
    return {
      orderCache: [],
      orderIncompatible: []
    }
  },
  mounted() {
    this.orderCache = this.orders.filter(o => !o.order_log_operations.includes('PACKAGE'))
    this.orderIncompatible = this.orders.filter(o => o.order_log_operations.includes('PACKAGE'))
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.orderCache)
    }
  },
  components: {
    KButton, KTag
  }
}
</script>
