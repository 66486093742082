<template>
  <div id="k-drawer-wrapper" :class="{'show': show}" @click="bgHide">
    <transition name="drawer-show">
      <div v-if="show" :style="{ width: `${Number(width)}px` }" :class="classes">
        <header><slot name="header">{{ title }}</slot><k-icon v-if="!closable" @click="hide" icon="x" /></header>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import KIcon from '../components/Icon'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    width: [String , Number],
    closable: Boolean,
    title: String
  },
  methods: {
    hide() {
      this.syncShow = false
    },
    bgHide(e) {
      console.log();
      if (e.target.id === 'k-drawer-wrapper') this.hide();
    },
  },
  computed: {
    classes() {
      return {
        'k-drawer': true,
        'show': this.syncShow
      }
    },
    syncShow: {
      get(){
        return this.show
      },
      set(val){
        this.$emit('update:show',val)
      }
    }
  },
  components: {
    KIcon
  }
}
</script>
