import database from '@/utils/database'
import { genBasicModel } from './utils/basicAction'
import firebase from './firebase'

const MODEL_NAME = 'group_project'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME, query)

async function query() {
  const res = await database.table(MODEL_NAME).orderBy('group_project_create_at', 'desc').get()
  res.data = res.data.map(item => ({ ...item, group_project_products: JSON.parse(item.group_project_products) }))
  return res
}

export default {
  namespaced: true,
  state: {
    group_projectList: [],
    group_orderList: [],
    focusProjectAddress: null
  },
  mutations: {
    ...basicMutation,
    changeFocusProject(state, payload) {
      state.focusProjectAddress = payload
    },
    updateOrderState(state, payload) {
      const orderIndex = state.group_orderList.map(item => item.group_order_id).indexOf(payload)
      if (orderIndex !== -1) state.group_orderList.splice(orderIndex, 1, { ...state.group_orderList[orderIndex], group_order_state: true })
    },
    createGroupProject(state, payload) {
      state.group_projectList.push(payload)
    }
  },
  actions: {
    ...basicAction,
    async queryGroupOrder({ commit }) {
      const { data } = await database.table('group_order').orderBy('group_order_create_at', 'desc').get()
      commit('save', {
        name: 'group_orderList', 
        data: data.map(item => ({
          ...item,
          group_order_product: JSON.parse(item.group_order_product),
          group_order_logistics_info: JSON.parse(item.group_order_logistics_info),
          group_order_state: item.group_order_state === '1' ? true : false
        }))
      })
    },
    changeFocusProject({ commit }, payload) {
      commit('changeFocusProject', payload)
    },
    async updateGroupOrderState({ commit }, payload) {
      const res = await database.table('group_order').where('group_order_id', '=', payload).update({ group_order_state: '1' })
      commit('updateOrderState', payload)
    },
    async createGroupProject({ commit }, payload) {
      const res = await database.table('group_project').set({
        ...payload,
        group_project_create_at: database.FieldValue.serverTimestamp()
      })
      console.log(res);
      commit('createGroupProject', {
        ...payload,
        group_project_id: res.id,
        group_project_products: JSON.parse(res.data.group_project_products),
        group_project_create_at: res.data.group_project_create_at
      })
    }
  },
  getters: {
    focusProject: state => {
      if (state.focusProjectAddress && state.group_projectList.length) {
        const projectIndex = state.group_projectList.map(item => item.group_project_address).indexOf(state.focusProjectAddress)
        if (projectIndex !== -1) return state.group_projectList[projectIndex]
        else return false
      }
      return null
    },
    focusProjectOrders: (state, getters) => {
      if (getters.focusProject) {
        return state.group_orderList.filter(item => item.group_project_id === getters.focusProject.group_project_id)
      }
      return []
    },
    focusProjectOrderDict: (state, getters) => {
      if (getters.focusProjectOrders.length) {
        return getters.focusProjectOrders.reduce((acc, item) => {
          acc[item.group_order_id] = item
          return acc
        }, {})
      }
      return {}
    }
  }
}
