import { genBasicModel } from './utils/basicAction'
import { query, create, update } from './utils/basicService'
import database from '@/utils/database'

const MODEL_NAME = 'questionnaire'
const { basicAction, basicMutation } = genBasicModel(
  MODEL_NAME,
  query(MODEL_NAME),
  create(MODEL_NAME, true),
  update(MODEL_NAME))

export default {
  namespaced: true,
  state: {
    questionnaireList: [],
    questionnaireTemplateList: []
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction,
    async queryTemplate({ commit }) {
      const res = await database.table('questionnaire_template').orderBy('questionnaire_template_create_at', 'desc').get()
      res.data = res.data.map(q => ({ questionnaire_template_fields: JSON.parse(q.questionnaire_template_fields) }))
      commit('save', { name: 'questionnaireTemplateList', data: res.data })
    },
    async createTemplate({ commit }, payload) {
      console.log(payload);
      const { questionnaire_template_name, questionnaire_template_route, fields } = payload
      const questionnaire_template_fields = payload.fields.map(f => {
        const { name, component, icon, settingComponent, needSetting, ...fieldInfo } = f
        return fieldInfo
      })

      console.log(questionnaire_template_fields);
      const res = await database.table('questionnaire_template').set({
        questionnaire_template_name, questionnaire_template_route,
        questionnaire_template_fields: JSON.stringify(questionnaire_template_fields),
        questionnaire_template_create_at: database.FieldValue.serverTimestamp()
      })
      console.log(res);
      commit('add', { name: 'questionnaireTemplateList', data: payload })
    }
  },
  getters: {

  }
}
