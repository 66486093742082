<template>
  <div class="setting explain">
    <k-textarea label="說明"></k-textarea>
  </div>
</template>

<script>
import { KTextarea } from '@/components/Form'
import { gen8ID } from '@/utils'

export default {
  props: {
    field: Object
  },
  data() {
    return {
      options: [{ value: gen8ID(), text: '' }]
    }
  },
  methods: {
    addOption() {
      this.options.push({ value: gen8ID(), text: '' })
    },
    deleteOption(value) {
      if (this.options.length === 1) {
        this.$message.warning('請至少保留一個選項，再刪就沒了QQ')
        return
      }
      const index = this.options.map(o => o.value).indexOf(value)
      this.options.splice(index, 1)
    }
  },
  watch: {
    options: {
      handler() {
        this.$emit('update:field', { ...JSON.parse(JSON.stringify(this.field)), options: this.options })
      },
      deep: true
    }
  },
  components: {
    KTextarea
  }
}
</script>
