<template>
  <div
    :class="classes"
    :style="{backgroundColor: bgColor || null}"
    @click="e => $emit('click', e)"
    @dblclick="e => $emit('dblclick', e)"  
    @mousedown="e => $emit('mousedown', e)"
    @contextmenu="e => $emit('contextmenu', e)"
  >
    <header ref="stickyHeader" v-if="header || $slots.header" @click="toggleClose" :class="{collapsible: collapsible || null}" :style="stickyHeaderStyles">
      <slot name="header">{{ header }}</slot>
    </header>

    <main class="card-body" :style="styles">
      <section ref="content" :class="{'content-wrapper': true, 'collapsible': collapsible}">
        <slot name="title"><h2 v-if="title">{{ title }}</h2></slot>
        <slot></slot>
      </section>
    </main>
    
    <footer v-if="footer || $slots.footer" :style="{backgroundColor: bgColor || null}">
      <slot name="footer">{{ footer }}</slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'k-card',
  props: {
    noShadow: Boolean,
    title: {
      type: String
    },
    header: {
      type: String
    },
    footer: {
      type: String
    },
    outline: {
      type: String
    },
    align: {
      type: String
    },
    defaultOpen: Boolean,
    collapsible: Boolean,
    stickyHeader: [Boolean, String],
    bgColor: String
  },
  mounted() {
    if (this.collapsible) {
      this.observer = new MutationObserver(mutations => this.offsetHeight = this.$refs.content.offsetHeight)
      this.observer.observe(
        this.$refs.content,
        { attributes: false, childList: true, characterData: false, subtree: true }
      );
    }
    this.isClose = this.defaultOpen ? false : true
    this.offsetHeight = this.$refs.content.offsetHeight

    if (this.stickyHeader) {
      this.headerOffsetY = this.$refs.stickyHeader.offsetTop
      window.addEventListener('scroll', this.handleScroll)
    }
  },
  beforeDestroy() {
    if (this.collapsible) this.observer.disconnect()
    if (this.stickyHeader) window.removeEventListener('scroll', this.handleScroll)
    
  },
  data() {
    return {
      isClose: false,
      offsetHeight: 'auto'
    }
  },
  computed: {
    classes() {
      return {
        'k-card': true,
        [`outline--${this.outline}`]: !!this.outline,
        'noShadow': !!this.noShadow,
        'collapsible': !!this.collapsible,
        'close': this.isClose && this.collapsible,
        'show': !this.isClose && this.collapsible,
      }
    },
    styles() {
      return {
        backgroundColor: this.bgColor || null,
        'text-align': this.align || 'left',
        'max-height': this.collapsible
          ? this.isClose 
            ? 0
            : `${this.offsetHeight+32}px`
          : null

      }
    },
    stickyHeaderStyles() {
      return {
        backgroundColor: this.bgColor || null,
        'position': this.stickyHeader ? 'sticky' : 'relative',
        'top': !this.stickyHeader
          ? 'auto'
          : this.stickyHeader === true 
            ? '95px'
            : `${this.stickyHeader}px`
      }
    }
  },
  methods: {
    toggleClose() {
      if (this.collapsible) this.isClose = !this.isClose
    },
    handleScroll() {
      const standard =  this.stickyHeader === true ? this.headerOffsetY - 95 : this.headerOffsetY - parseInt(this.stickyHeader)
      if (window.pageYOffset > standard) {
        this.$refs.stickyHeader.classList.add('pinned')
      }
      else {
        this.$refs.stickyHeader.classList.remove('pinned')
      }
    }
  },
}
</script>
