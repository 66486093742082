const CodeMirror = require("codemirror");


const TagNames = ['div', 'p', 'i', 'strong', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 'th', 'td']
const DefaultTag = {
  attrs: {
    style:['background'],
  },
  children: ['div', 'p', 'i', 'strong', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a']
};

function genTags(tagNames) {
  return tagNames.reduce((acc, tag) => {
    acc[tag] = DefaultTag
    return acc
  }, {})
}

export const tags = {
  ...genTags(TagNames),
  table: {
    attrs: ['background'],
    children: [
      'tr', 'td', 'th'
    ]
  },
  tr: {
    attrs: ['background'],
    children: [
      'td', 'th'
    ]
  }
};

export function completeAfter(cm, pred) {
  var cur = cm.getCursor();
  if (!pred || pred()) setTimeout(function() {
    if (!cm.state.completionActive)
      cm.showHint({completeSingle: false});
  }, 100);
  return CodeMirror.Pass;
}

export function completeIfAfterLt(cm) {
  return completeAfter(cm, function() {
    var cur = cm.getCursor();
    return cm.getRange(CodeMirror.Pos(cur.line, cur.ch - 1), cur) == "<";
  });
}

export function completeIfInTag(cm) {
  return completeAfter(cm, function() {
    var tok = cm.getTokenAt(cm.getCursor());
    if (tok.type == "string" && (!/['"]/.test(tok.string.charAt(tok.string.length - 1)) || tok.string.length == 1)) return false;
    var inner = CodeMirror.innerMode(cm.getMode(), tok.state).state;
    return inner.tagName;
  });
}
