import { genBasicModel } from './utils/basicAction'
import { query, create } from './utils/basicService'
import { fetchApi } from '@/utils'
import database from '@/utils/database'

const MODEL_NAME = 'email'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME,
  query,
  create(MODEL_NAME, true)
)

function parseEmailFrom(string) {
  const regex = /"?([\.\s\w\/]+)"?\s<([\W\w]+)>/i
  const found = string.match(regex)
    if (found) return { email_from_name: found[1], email_from_address: found[2] }
    return { email_from: string }
  }

export default {
  namespaced: true,
  state: {
    emailUser: undefined,
    emailList: [],
    emailTagList: [],
    emailTagUseList: [],
    sentList: [],
    emailTemplateList: []
  },
  mutations: {
    ...basicMutation,
    setUser(state, payload) {
      state.emailUser = payload
    },
    saveSent(state, payload) {
      state.sentList = payload.sort((a,b) => b.email_data - a.email_date )
    },
    cleanMails(state) {
      state.emailList = []
      state.sentList = []

    },
    registEmailTagUse(state, payload) {
      payload.forEach(email => {
        let { registerType, ...emailData } = email
        if (registerType === 'SET') state.emailTagUseList.push({ ...email })
        else {
          let updateTagUseEmail = state.emailTagUseList.filter(e => {
            return e.email_id === emailData.email_id && 
            e.email_tag_use_type === emailData.email_tag_use_type && 
            e.email_tag_use_account == emailData.email_tag_use_account
          })[0]
          let index = state.emailTagUseList.map(e => e.email_tag_use_id).indexOf(updateTagUseEmail.email_tag_use_id)
          state.emailTagUseList.splice(index, 1 , { ...updateTagUseEmail, ...emailData })
        }
      })
    }
  },
  actions: {
    ...basicAction,
    async checkLoginState({ commit }) {
      const { status, data } = await fetchApi('/api/session/', { get: ['email_username'] })
      switch (status) {
        case 200:
          commit('setUser', data.email_username)
          return data.email_username
        case 404:
          console.warning('not login')
          return false
        default:
          break;
      }
      
    },
    async querySent({ commit }, payload) {
      const res = await database.table('email_sent')
        .join('admin', 'admin_id', 'email_sent' ,'email_sent_dealer')
        .where('email_sent.email_sent_account', '=', payload)
        .orderBy('email_sent.email_sent_create_at', 'desc')
        .get()
      const data = res.data.map(e => ({ 
        ...e,
        email_id: `SENT-${e.email_sent_id}`,
        email_title: e.email_sent_title,
        email_content: [e.email_sent_content],
        email_target: [e.email_sent_target],
        email_date: e.email_sent_create_at,
        email_type: 'SENT'
      }))
      const webmailSentRes = await fetchApi('/api/inbox/', { ...payload, hostType: 'INBOX.Sent' })
      const webmailSentdata = webmailSentRes.data.map(mail => ({
        ...mail,
        ...parseEmailFrom(mail.email_from),
        email_target: mail.email_to.map(to => `${to.mailbox}@${to.host}`),
        email_type: 'INBOX'
      })).sort((a, b) => b.email_date - a.email_date )
      commit('save', { name: 'sentList', data:[...data, ...webmailSentdata] })
    },
    async queryWebmailInbox({ commit }, payload={ start: 1 }) {
      const res = await fetchApi('/api/inbox/', { ...payload})
      if (res.status === 200) {
        const data = res.data
          .map(mail => ({
            ...mail,
            ...parseEmailFrom(mail.email_from),
            email_type: 'INBOX'
          }))
          .sort((a, b) => b.email_date - a.email_date )
        commit('save', { name: 'emailList' , data })
        commit('setUser', res.username)
      }
      return res
    },
    async queryEmailTags({ commit }, payload) {
      const tagRes = await database.table('email_tag').get()
      const tagUseRes = await database.table('email_tag_use')
        .join('email_tag', 'email_tag_id')
        .join('email_sent', 'email_sent_id', 'email_tag_use', 'email_id')
        .where('email_tag_use.email_tag_use_account', '=' , payload).get()

      if (tagRes.state === 200) commit('save', { name: 'emailTagList', data:tagRes.data })
      if (tagUseRes.state === 200) commit('save', { name: 'emailTagUseList', data: tagUseRes.data })
    },
    async addEmailTag({ commit }, payload) {
      const res = await database.table('email_tag').set({ ...payload })
      if (res.status === 200) commit('add', { name: 'emailTagList', data: { ...payload, key_id: res.id, email_tag_id: res.id } })
      return res
    },
    async batchRegistEmailTagUse({ commit, state, getters }, payload) {
      const { todoEmail, email_tag_id } = payload
      const email_tag_use_account = state.emailUser
      const newTagUseRows = await  Promise.all(todoEmail.map(async email_mix_id => {
        let { key_id, ...tag_data } = getters.emailTagDict[email_tag_id]
        let [email_tag_use_type, email_id] = email_mix_id.split('-')
        if (state.emailTagUseList.some(email => 
            email.email_id === email_id && 
            email.email_tag_use_type === email_tag_use_type && 
            email.email_tag_use_account == email_tag_use_account
        )) {
          await database.table('email_tag_use')
            .where('email_id', '=', email_id)
            .where('email_tag_use_type', '=', email_tag_use_type)
            .where('email_tag_use_account', '=', email_tag_use_account)
            .update({ email_tag_id })
          return {
            email_id,
            email_tag_use_type,
            email_tag_use_account,
            registerType: 'UPDATE',
            ...tag_data
          }
        }
        const res = await database.table('email_tag_use').set({
          email_tag_id,
          email_id,
          email_tag_use_type,
          email_tag_use_account,
        })
        
        const email_tag_use_id = res.id
        return {
          email_tag_use_id,
          email_tag_id,
          email_tag_use_type,
          email_id,
          email_tag_use_account,
          registerType: 'SET',
          ...tag_data
        }
      }))

      commit('registEmailTagUse', newTagUseRows)
      return true
    },
    async webmailLogout({ commit }) {
      const res = await fetchApi('/api/session/', { unset: ['email_username', 'email_password'] })
      if (res.status === 200) {
        commit('cleanMails')
        commit('setUser', undefined)
      }
    },
    async queryEmailTemplate({ commit }) {
      const res = await database.table('email_template').get()
      commit('save', { name: 'emailTemplateList', data: res.data })
    },
    async createEmailTemplate({ commit }, payload) {
      const res = await database.table('email_template').set({ ...payload })
      commit('add', { name: 'emailTemplateList', data: { key_id: res.id, ...res.data } })
      return res
    }
  },
  getters: {
    emailFilterByTag: ({ emailTagList, emailTagUseList }) => {
      if (emailTagList.length && emailTagUseList.length) {
        return emailTagUseList.reduce((acc, email) => {
          return {...acc, [email.email_tag_id]: [{ ...email }, ...acc[email.email_tag_id]]}
        }, emailTagList.reduce((acc, tag) => ({...acc, [tag.email_tag_id]: []}), {}))
      }
      return []
    },
    emailTagDict: ({ emailTagList }) => {
      if (emailTagList) {
        return emailTagList.reduce((acc, tag) => ({
          ...acc,
          [tag.email_tag_id]: { ...tag }
        }), {})
      }
      return {}
    },
    emailWithTag: ({ emailList, emailTagList, emailTagUseList, emailUser }) => {
      if (emailList.length && emailTagList.length && emailTagUseList.length) {
        return emailList.map(email => {
          let [email_type, email_id] = email.email_id.split('-')
          let emailTagUse = emailTagUseList.filter(e => 
            e.email_id === email_id &&
            e.email_tag_use_type === email_type &&
            e.email_tag_use_account === emailUser)[0]
          return { ...emailTagUse, ...email, }
        })
      }
      return []
    },
    sentWithTag: ({ sentList, emailTagList, emailTagUseList, emailUser }) => {
      if (sentList.length && emailTagList.length && emailTagUseList.length) {
        return sentList.map(email => {
          let [email_type, email_id] = email.email_id.split('-')
          let emailTagUse = emailTagUseList.filter(e => 
            e.email_id === email_id &&
            e.email_tag_use_type === email_type &&
            e.email_tag_use_account === emailUser)[0]
          return { ...emailTagUse, ...email, }
        })
      }
      return []
    }
  }
}
