<template>
  <div :class="classes">
    <span v-if="label" class="label"><span v-if="required && !hideRequiredMark">＊ </span>{{ label }}</span>
    <slot></slot>
    <label v-if="options.length && radioType!=='button'">
      <k-radio v-for="(item, index) in options" :key="index" v-bind="item" :staticValue="item.value" v-model="selected" />
    </label>
  </div>
</template>

<script>
import KRadio from './KRadio';

export default {
  mounted() {
    this.uid = `k-input-group-${parseInt(Math.random()*1000)}`;
    this.selected = this.defaultValue || '';
  },
  data() {
    return {
      uid: '',
      selected: ''
    }
  },
  methods: {
    handleSelected(e) {
      console.log(e);
      this.selected = e;
    }
  },
  props: {
    radioType: String,
    defaultValue: String,
    theme: String,
    label: String,
    required: Boolean,
    hideRequiredMark: Boolean,
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    classes() {
      return {
        'k-radio-group': true,
      }
    },
  },
  watch: {
    selected(newValue) {
      this.$emit('onChange', newValue);
    }
  },
  components: {
    KRadio,
  }
}
</script>
