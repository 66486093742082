<template>
  <k-container class="view-edit-container" :title="`${mode === 'CREATE' ? '撰寫' : '修改'}文章`">
    <k-form v-if="!loading" :questionConfig.sync="questionConfig" :defaultValues="defaultValues" @submit="handleSubmit">
      <section class="avatar-choosed" slot="before(post_title)">
        <k-dropdown placement="rightBottom" ref="avatar-dropdown" preventClickAway>
          <k-button theme="secondary">選擇文章首圖</k-button>
          <template #overlay>
            <MediaSelector
              @close="()=>$refs['avatar-dropdown'].close()" 
              :mode.sync="mediaSelctorInfo.mode"
              :search.sync="mediaSelctorInfo.search"
              :route.sync="mediaSelctorInfo.route"
              :choosedCallback="handleAvatarChoose"
            />
          </template>
        </k-dropdown>
        <div v-if="postBanner" class="image-wrapper">
          <img :src="postBanner" alt="">
        </div>
        <div class="hint" v-else>請點選『選擇文章首圖』載入/更換文章首圖</div>
      </section>

      <section class="media-library" slot="after(post_summary)">
        <k-dropdown placement="rightBottom" ref="media-dropdown" preventClickAway>
          <k-button theme="light" varient="outline">從圖片庫選擇</k-button>
          <template #overlay>
            <MediaSelector
              @close="()=>$refs['media-dropdown'].close()" 
              :mode.sync="mediaSelctorInfo.mode"
              :search.sync="mediaSelctorInfo.search"
              :route.sync="mediaSelctorInfo.route"
            />
          </template>
        </k-dropdown>
      </section>

      <!-- <Setting
        slot="after(post_content)"
        ref="seo"
        @mutate="() => seoMutated = true"
        :originalTemplateUse="mode === 'UPDATE' ? defaultValues.seo_template_id : null"
        :originalStructureData="mode === 'UPDATE' 
          ? defaultValues.post_structured_data && JSON.parse(defaultValues.post_structured_data)
          : null"
      /> -->
    </k-form>
    <ImageLinkModal ref="ImgLinkModal"/>
    <ProductLinkModal ref="ProductLinkModal" />
    <LinkModal ref="LinkModal" />
  </k-container>
</template>

<script>
import { KForm, KInput, KSwitch, KDatePicker, KUpload } from '../../components/Form'
import { KMenu, KMenuItem, KMenuDivider } from '@/components/Menu'
import KDropdown from '../../components/Dropdown'
import KCard from '../../components/Card'
import KButton from '../../components/Button'
import KSkeleton from '@/components/Skeleton'
import Setting from '@/views/seo/components/Setting'
import MediaSelector from './components/MediaSelector.vue'
import ImageLinkModal from './components/ImageLinkModal.vue'
import ProductLinkModal from './components/ProductLinkModal.vue'
import LinkModal from './components/LinkModal.vue'
import { timestampParse, fetchApi } from '../../utils'
import { mapState, mapGetters, mapActions } from 'vuex'
import database from '@/utils/database'
import storage from '@/utils/storage'
import { Icons, PostMarkPlugin, ProductLinkPlugin, Link } from './plugins'
import { ProductLinkStyle } from './plugins/ProductLink'


export default {
  created() {
    if (!this.$store.state.media.mediaTree) this.$store.dispatch('media/fetchMediaTree')
    this.questionConfig = genQuestions(this.handleFileChange, this.handleOnPaste, this.categoryOptions, 'CREATE', genCustomInit(this))
  },
  async mounted() {
    this.$store.dispatch('media/query')    
    if (!this.$store.state.posts.categoryList.length) await this.$store.dispatch('posts/queryCategories')
    if (this.$route.params.id) {
      this.updateId = this.$route.params.id
      if (!this.$store.state.posts.postList.length) await this.$store.dispatch('posts/query')
      const response = await database.table('file')
        .join('file_use', 'file_id')
        .where('file_use.post_id', '=', this.updateId).get()
      console.log(response);
      this.fileUseInPost = [ ...response.data ]
      this.insertUpdatePostDataIntoForm(this.updateId)
      this.loading = false
    } else {
      this.loading = false
    }
    
  },
  data() {
    return {
      loading: true,
      defaultValues: {},
      questionConfig: [],
      imgSrc: null,
      postBanner: null,
      updateId: null,
      unuseFileIds: [],
      fileUseInPost: [],
      seoMutated: false,
      mediaSelctorInfo: {
        mode: 'ROUTE',
        search: '',
        route: '/'
      }
    }
  },
  methods: {
    ...mapActions('media', ['insertFileInfo', 'registerFileUse', 'unlinkFileUse']),
    ...mapActions('posts', ['add', 'update', 'createStructureData']),
    onCopy() {
      this.$message.success('已成功複製圖片路徑至剪貼簿！')
      this.$refs['media-dropdown'].hide()
    },
    handleAvatarChoose(file) {
      this.postBanner = `https://www.chris-farrell.com.tw/img${file.parentRoute}/${file.file}`
    },
    handleFileChange(info) {
      if (this.mode === 'UPDATE') {
        const oldBannerId = this.mediaList.filter(m=>m.name === this.defaultValues.bannerName)[0]['id']
        this.unuseFileIds.push(oldBannerId)
      }
      this.questionConfig[0].imgSrc = info.src
    },
    handleOnPaste(editor, e) {
      e.preventDefault();
      let pasteContent = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text/html');
      let DOM = parseAsDom(pasteContent)
      const allTags = Array.from(DOM.getElementsByTagName('*'))
      allTags.forEach(e => e.removeAttribute('style'))
      let imgs = Array.from(DOM.getElementsByTagName('img'))

      if (imgs.length) this.$refs.ImgLinkModal.execute({
        images: imgs,
        callback: (imgLinks) => {
          if (imgLinks) {
            imgs.forEach((img, i) => {
              img.src = imgLinks[i].src || img.src
              img.alt = imgLinks[i].alt || img.alt
            })
          }
          editor.execCommand('mceInsertContent', false, DOM.body.innerHTML)
        }
      })
      else editor.execCommand('mceInsertContent', false, DOM.body.innerHTML)
      
    },
    insertUpdatePostDataIntoForm(id) {
      const postIndex = this.postList.map(p => p.key_id).indexOf(id)
      const post = this.postList[postIndex]
      const { post_bannerPath, post_publishAt } = post
      this.postBanner = this.postList[postIndex].post_bannerPath
      this.defaultValues = { 
        ...post, post_banner: post_bannerPath, post_publishAt, post_publishAt: timestampParse(post_publishAt)
      }
    },
    compareImgUnuse(newHtml) {
      const DOM = new DOMParser().parseFromString(`<body>${newHtml}</body>`, "text/html")
      const newImgNames = Array.from(DOM.getElementsByTagName('img')).map(e => e.getAttribute('src').split('/').slice(-1)[0])
      const unuseFileIds = [
        ...this.unuseFileIds,
        ...this.oldPostImgs.filter(om => !newImgNames.includes(om.file_name)).map(om => om.key_id)
      ]
      const newChooseFileIds = this.postImages
        .filter(img => newImgNames.includes(img.file_name) && !this.oldPostImgs.map(e => e.key_id).includes(img.key_id))
        .map(e => e.key_id)
      return { unuseFileIds, newChooseFileIds }
    },
    async logFileUse(postId, fileIdList) {
      const API = '/api/file_use/'
      const json = await fetchApi(API, { postId, fileIdList, useType: 'post' })
      return json
    },
    async uploadFileList(fileList) {
      const filePathList = await Object.entries(fileList).reduce(async (acc, [column, files]) => {
        var accThen = await acc
        if (Array.isArray(files)) {
          accThen[column] = await files.reduce(async (accumulator, f) => {
            const storageRes = await storage.path('img/post').put(f)
            const file_id = await this.insertFileInfo(storageRes)
            const accumulatorThen = await accumulator
            accumulatorThen.push({ file_id, ...storageRes })
            return accumulatorThen
          },[])
          return accThen
        }
        const storageRes = await storage.path('img/post').put(f)
        const file_id = await this.insertFileInfo(storageRes)
        accThen[column] = { file_id, ...storageRes }
        return accThen
      }, {})

      return filePathList
    },
    async handleSubmit(formData) {
      if (!this.postBanner) return this.$message.warning('請選擇文章封面圖片！')
      if (formData) {
        const { values } = formData
        values.post_bannerPath = this.postBanner
        values.post_banner = `${values.post_title}.webp`
        values.post_publishAt = values.post_publishAt.unix()
        let stateInfo;
        if (this.mode === 'CREATE') stateInfo = await this.add(values)
        if (this.mode === 'UPDATE') stateInfo = await this.update({ id: this.updateId, ...values })
        console.log(stateInfo);
        this.$message.success('已上傳文章')
        this.$router.push('/blog')
      }
    }
  },
  computed: {
    ...mapState('posts', ['postList', 'categoryList']),
    ...mapState('media', ['mediaList']),
    ...mapGetters('posts', ['currentPost']),
    ...mapGetters('media', ['filterByMediaType']),
    ...mapGetters('comment', ['productScore']),
    mode: {
      get() {
        const mode = this.$route.params.id ? 'UPDATE' : 'CREATE'
        this.mode = mode
        return mode
      },
      set(newVal) {
        this.questionConfig = genQuestions(this.handleFileChange, this.handleOnPaste, this.categoryOptions, newVal, genCustomInit(this))
      }
    },
    categoryOptions() {
      if (this.categoryList.length) return this.categoryList.map(e => ({ value: e.key_id, text: e.category_name }))
    },
    postImages() {
      if (this.filterByMediaType.image) {
        return this.filterByMediaType.image
      }
      return []
    },
    oldPostImgs() {
      if (this.defaultValues.post_content) {
        const DOM = new DOMParser().parseFromString(`<body>${this.defaultValues.post_content}</body>`, "text/html")
        const imgs = Array.from(DOM.getElementsByTagName('img')).map(e => {
          let imgPath = e.getAttribute('src')
          console.log(imgPath.split('/').slice(-1)[0]);
          return this.mediaList.filter(m => m.file_name === imgPath.split('/').slice(-1)[0])[0]
        })
        return imgs
      }
      return []
    }
  },
  components: {
    KForm, KInput, KSwitch, KDatePicker, KUpload, KButton, KDropdown, KCard, KMenu, KMenuItem, KMenuDivider, KSkeleton,
    Setting, MediaSelector, ImageLinkModal, ProductLinkModal, LinkModal
  }
}

//編輯器客製化插件設定
const genCustomInit = (componentSelf) => ({
  setup: (editor, editorComponent) => {
    window.tinymce.PluginManager.add('PostMark', () => PostMarkPlugin(editor, editorComponent))
    window.tinymce.PluginManager.add('ProductLink', () => ProductLinkPlugin(editor, editorComponent, componentSelf))
    window.tinymce.PluginManager.add('Link', () => Link(editor, editorComponent, componentSelf))
    window.tinymce.PluginManager.add('Icons', Icons)
  },
  plugins: ['PostMark', 'ProductLink', 'Link'],
  toolbar: ['PostMarkMenu | LinkMenu | ProductLinkMenu'],
  contextmenu: ['PostMarkMenu', 'LinkMenu', 'ProductLinkMenu'],
  content_style: [ProductLinkStyle]
})

const genQuestions = (handleFileChange, handleOnPaste, categoryOptions ,mode, customInit) => {
  console.log(customInit);
  return[
  {
    questionType: 'k-input',
    name: 'post_title',
    label: "文章標題",
    theme: "light",
    required: true,
    title: '請輸入文章標題'
  },
  {
    questionType: 'k-input',
    name: 'post_title_en',
    label: "英文標題（網址顯示與SEO使用）",
    theme: "light",
    required: true,
    title: '請輸入英文標題',
    extra: '輸入一英文標題時請勿使用空格，並以 "-" 或 "_" 取代'
  },
  {
    questionType: 'k-date-picker',
    name: 'post_publishAt',
    label: "上架時間",
    theme: 'light',
    required: true,
  },
  {
    questionType: 'k-switch',
    name: 'post_isPublished',
    label: "預設為發佈狀態",
    theme: "warning",
    required: true,
  },
  {
    questionType: 'k-select',
    name: 'category_id',
    label:"文章類別",
    options: categoryOptions,
    required: true,
    theme: 'light',
    placeholder: '請選擇文章類別',
    block: true
  },
  {
    questionType: 'k-textarea',
    name: 'post_summary',
    label: '總結描述',
    required: true,
    maxlength: 140,
    placeholder: '請以最多140字描述本篇文章的重點',
    extra: '此總結會利用於SEO搜尋使用',
    theme: 'light'
  },
  {
    questionType: 'k-editor',
    name: 'post_content',
    label: "內文",
    required: true,
    filePath: './img/post',
    height: 600,
    customInit,
    onPaste: (editor, e) => {
      handleOnPaste(editor, e)
    }
  },
  {
    questionType: 'k-btn',
    label: mode === 'CREATE' ? "完成建立" : "完成修改",
    theme: "primary",
    type: "submit"
  }
]}

function parseAsDom(htmlString) {
  const DOM = new DOMParser().parseFromString(htmlString, "text/html")
  return DOM
}
</script>
