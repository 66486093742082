import { genBasicModel } from './utils/basicAction'
import database from '@/utils/database'
import firebase from './firebase'
import { timestampParse } from '@/utils'
import moment from 'moment'

const initSecondaryApp = () => {
  var config = {
    apiKey: process.env.VUE_APP_CF_API_KEY,
    authDomain: process.env.VUE_APP_CF_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_CF_DATABASE_URL,
    projectId: process.env.VUE_APP_CF_PROJECT_ID,
    storageBucket: process.env.VUE_APP_CF_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_CF_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_CF_APP_ID,
    measurementId: process.env.VUE_APP_CF_MEASUREMENT_ID,
  };

  let secondaryApp = firebase.initializeApp(config, "Secondary");
  // if (!state.secondaryApp) commit("saveSecondaryApp", secondaryApp);
  return secondaryApp
} 

const MODEL_NAME = 'user'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME, query)

async function query() {
  const res = await database.table(MODEL_NAME)
  .where('user_uid', 'NOT LIKE', '%mock%')
  .where('user_uid', 'isNull', '', 'OR')
  .get()
  return res
}

export default {
  namespaced: true,
  state: {
    userList: []
  },
  mutations: {
    ...basicMutation,
    levelUpMember(state, payload) {
      const userIndex = state.userList.map(u => u.user_id).indexOf(payload)
      state.userList.splice(userIndex, 1, { ...state.userList[userIndex], user_group: 'ORIGINAL' })
    },
    updateUserList(state, payload) {
      payload.forEach(user => {
        let index = state.userList.map(u => u.user_id).indexOf(user.user_id)
        if (index !== -1) state.userList.splice(index, 1, user)
      })
    }
  },
  actions: {
    ...basicAction,
    async levelUpMember({ commit, state }, payload) {
      const userIndex = state.userList.map(u => u.user_id).indexOf(payload)
      const userOldCoupon = JSON.parse(state.userList[userIndex].user_own_coupons) || []
      const originalCouponRes = await database.table('coupon').where('coupon_super', '=', 'ORIGINAL_LEVEL_UP_200').get()
      const res = await database.table('user').where('user_id', '=', payload).update({
        user_group: 'ORIGINAL',
        user_own_coupons: JSON.stringify([...userOldCoupon, { coupon_id: originalCouponRes.data[0].coupon_id, coupon_get_at: moment().unix() }])
      })
      commit('levelUpMember', payload)
      return res
    },
    async issueVoucher(_, payload) {
      const { user_id, voucher_value } = payload
      await database.table('voucher').set({
        voucher_type: 'BIRTHDAY',
        user_id,
        voucher_value,
        voucher_period: 31536000,
        voucher_create_at: database.FieldValue.serverTimestamp()
      })
      return { status: 200 }
    },
    async distributeCouponToUsers({ commit }, payload) {
      const { coupons, users } = payload
      const promises = users.map(user => new Promise(async (resolve, reject) => {
        let userRow = database.table('user').where('user_id', '=', user.user_id)
        console.log(user.user_own_coupons);
        let userOldCoupon = user.user_own_coupons ? JSON.parse(user.user_own_coupons) : []
        let user_own_coupons = JSON.stringify([
          ...userOldCoupon,
          ...coupons.map(coupon => ({ coupon_id: coupon.coupon_id, coupon_get_at: moment().unix() }))
        ])
        await userRow.update({
          user_own_coupons
        })
        resolve({ ...user, user_own_coupons: user_own_coupons })
      }))
      const usersWithNewCoupon = await Promise.all(promises)
      console.log(usersWithNewCoupon);
      commit('updateUserList', usersWithNewCoupon)
    },
    async registerUser({ commit }, payload) {
      console.log(payload);
      const secondaryApp = initSecondaryApp()
      const userCredential = await secondaryApp.auth().createUserWithEmailAndPassword(payload.user_email || `cf${payload.user_phone}@cfmail.com`, payload.user_phone)
      const uid = userCredential.user.uid
      const response = await database.table('user').set({ ...payload, user_uid: uid, user_create_at: database.FieldValue.serverTimestamp() })
      console.log(response);
      return response
    }
  },
  getters: {
    userDict: ({ userList }) => {
      if (userList.length) {
        return userList.reduce((acc, user) => ({ ...acc, [user.user_id]: { ...user } }), {})
      }
      return null
    },
    monthlyNewUser: ({ userList }) => {
      if (userList.length) {
        const thisYear = moment([2024, 0, 1]).unix()
        return userList.filter(u => u.user_create_at && parseInt(u.user_create_at) > thisYear).reduce((acc, u) => {
          const month = parseInt(timestampParse(u.user_create_at).format('M')) - 1
          acc[month] += 1
          return acc
        }, Array(12).fill(0))
      }
      return Array(12).fill(0)
    }
  }
}
