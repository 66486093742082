import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './config';
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

export const SHARED_ROUTE = ['FocusGroup']
router.beforeEach((to, from, next) => {
  const { loginState  } = store.state.login
  if (SHARED_ROUTE.includes(to.name) && to.query.usp === 'sharing') return next()
  if (to.name !== 'Login' && loginState===false && to.query.usp !== 'sharing') return next({ name: 'Login' })
  next()
})

export default router
