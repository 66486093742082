var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-container',{staticClass:"view-dealer-dashboard",attrs:{"title":"經銷商 Dashboard"}},[_c('section',{staticClass:"header-section"},_vm._l((_vm.headerWedgit),function(item,index){return _c('k-widget-chart',{key:("hwc_" + index),attrs:{"header":_vm.sum(item.key ? _vm.dealerGetters[item.key] : [6969]),"text":item.label,"type":item.chart,"data":item.key ? _vm.dealerGetters[item.key] : Array(12).fill(50),"label":item.label,"chartColor":_vm.COLOR_MAP[item.theme],"options":{
        elements: {
          point: { radius: 0 }
        }
      }}})}),1),_c('k-card',{staticClass:"traffic-chart",staticStyle:{"margin-bottom":"16px"},attrs:{"header":"經銷營業額"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{class:{ 'traffic-info': true, 'hide': _vm.hideLine.includes('footer1') }},[_c('section',[_c('p',[_vm._v("月平均經銷應收帳款")]),_c('k-checkbox',{attrs:{"value":"footer1","uncheckIcon":'eye-fill',"checkedIcon":'eye-slash-fill'},model:{value:(_vm.hideLine),callback:function ($$v) {_vm.hideLine=$$v},expression:"hideLine"}})],1),_c('b',[_vm._v("$"+_vm._s(parseInt(_vm.dealerOrderIncomeMonthly.reduce(function (acc, e) { return acc + e; }) / 12))+" / $10000")]),_c('k-progress',{attrs:{"value":parseInt(_vm.dealerOrderIncomeMonthly.reduce(function (acc, e) { return acc + e; }) / 12),"max":"10000","size":"thin","theme":"warning"}})],1),_c('div',{class:{ 'traffic-info': true, 'hide': _vm.hideLine.includes('footer3') }},[_c('section',[_c('p',[_vm._v("年度總經銷收入")])]),_c('b',[_vm._v("$"+_vm._s(_vm.dealerOrderIncomeMonthly.reduce(function (acc, e) { return acc + e; }))+" / $100000")]),_c('k-progress',{attrs:{"value":_vm.dealerOrderIncomeMonthly.reduce(function (acc, e) { return acc + e; }),"max":"100000","size":"thin","theme":"error"}})],1),_c('div',{class:{ 'traffic-info': true, 'hide': _vm.hideLine.includes('member') }},[_c('section',[_c('p',[_vm._v("企業採購總產值")]),_c('k-checkbox',{attrs:{"value":"footer3","uncheckIcon":'eye-fill',"checkedIcon":'eye-slash-fill'},model:{value:(_vm.hideLine),callback:function ($$v) {_vm.hideLine=$$v},expression:"hideLine"}})],1),_c('b',[_vm._v("$"+_vm._s(_vm.sumOfPurchasing))]),_c('k-progress',{attrs:{"value":"100","size":"thin","theme":"success"}})],1)]},proxy:true}])},[_c('k-chart-bar',{attrs:{"height":"300","datasets":[
        {
          data: Array(12).fill(_vm.dealerOrderIncomeMonthly.reduce(function (acc, e) { return acc + e; }) / 12),
          backgroundColor: _vm.COLOR_MAP.light,
          borderColor: _vm.COLOR_MAP.light,
          borderDash: [10, 5],
          borderWidth: 1,
          label: 'month-avarage',
          hide: true,
          elements: {
            point: { radius: 0 }
          },
          yAxisID: 'y',
        },
        {
          data: _vm.dealerOrderIncomeMonthly,
          backgroundColor: _vm.COLOR_MAP.warning,
          borderColor: _vm.COLOR_MAP.warning,
          hide: _vm.hideLine.includes('footer1'),
          borderWidth: 2,
          tension: 0.4,
          label: '每月應收帳款',
          fill: false,
          yAxisID: 'y',
        },
        {
          data: _vm.cumulativePurchasingIncome,
          backgroundColor: _vm.COLOR_MAP.success,
          borderColor: _vm.COLOR_MAP.success,
          fill: false,
          hide: _vm.hideLine.includes('footer3'),
          borderWidth: 2,
          tension: 0,
          label: '年度採購總額累計',
          yAxisID: 'y1',
        } ],"labels":"months","options":{
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.85,
        categoryPercentage: 0.7,
        scales: {
          x: { grid: { color: '#333' } },
          y: {
            type: 'linear', display: !_vm.hideLine.includes('footer1'), position: 'left',
            grid: { color: '#444' },
            ticks: { color: ((_vm.COLOR_MAP.warning) + "99") }
          },
          y1: {
            type: 'linear', display: !_vm.hideLine.includes('footer3'), position: 'right',
            grid: { display: _vm.hideLine.includes('footer1'), color: '#444' },
            ticks: { color: ((_vm.COLOR_MAP.success) + "99") }
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'bottom'
          }
        },
      }}})],1),_c('k-card',{staticClass:"dealer-order-frequency",attrs:{"header":"經銷商訂貨量與訂貨頻率","collapsible":false}},[_c('k-chart-bubble',{attrs:{"datasets":_vm.bubbleFackData,"options":{
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: function (context) { return (" " + (context.dataset.label) + " | 訂購頻率：" + (context.parsed.x) + " | 平均訂購產值：" + (context.parsed.y)); },
            }
          },
        },
        elements: {
          point: {
            radius: function (context) { return (context.chart.width) / 24 * Math.abs(context.raw.v) / 1000; }
          }
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }