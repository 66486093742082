<template>
  <k-container class="view-order-container">
    <header class="pageTitle">
      <h1>所有訂單</h1>

      <div>
        <k-button theme="primary" @click="showModal">匯出訂單報表</k-button>
        <k-button theme="success" @click="showCreate">建立訂單</k-button>
        <k-button @click="reloadOrders" theme="info"><k-icon icon="arrow-repeat" />刷新訂單</k-button>
      </div>
    </header>
    
    <k-tabs>
      <k-tab-pane tab="訂單" key="order" state="order">
        <k-table
          v-if="ordersList.length"
          ref="order_table"
          :items="ordersList.filter(o => o.order_type === 'BUY')"
          :fields="TableFields"
          :rowSelection="selectionMode"
          :rowSelectionButtonProps="{
            theme: 'primary',
            label: '選擇操作'
          }"
          :pagination="pagination"
          @check="handleOperate"
          hover
        >
          <template #cell(key_id)="{ value, item }">
            <code>{{ value }}</code>
            <k-tag class="copy-orders-id" v-copy="value" v-copy:success="onCopy"><k-icon icon="clipboard"></k-icon></k-tag>
            <k-tooltip title="購物金尚未發放"><k-icon v-if="calculateVoucher(value)" icon="coin" theme="success" /></k-tooltip>
            <k-tooltip title="尚未發送取貨提醒"><k-icon v-if="!checkCVSOrderNoticed(item)" icon="bell" theme="warning" /></k-tooltip>
          </template>
          <template #cell(order_logistics_subtype)="data">
            <k-tag :theme="data.item.order_pay_type === 'COD' ? 'light' : 'dark'">
            {{ data.item.order_pay_type === 'COD' ? '貨到付款' : '信用卡' }} | {{ LOGISTICS_SUBTYPE_DICT[data.value] }}
            </k-tag>
          </template>

          <template #cell(order_logistics_state)="{ item, value }">
            <k-tag :theme="LOGISTICS_ABNORMAL_STATE.includes((value[value.length-1] || {}).code) ? 'error' : 'info'">
              {{ value.length
                ? LOGISTIC_STATE[item.order_logistics_subtype][value[value.length-1]['code']]
                : LOGISTIC_STATE[item.order_logistics_subtype]['300']
              }}
            </k-tag>
          </template>

          <template #cell(order_create_at)="data">
            {{ timestampParse(data.value).format('YYYY-MM-DD HH:mm') }}
          </template>

          <template #cell(order_payment_status)="data">
            <k-tag
              :theme="data.item.order_pay_type === 'COD'
                ? 'light'
                : PAYMENT_STATUS_DICT[data.value] 
                  ? PAYMENT_STATUS_DICT[data.value].theme
                  :'error'
              ">
              {{ data.item.order_pay_type === 'COD'
              ? '貨到付款'
              : PAYMENT_STATUS_DICT[data.value]
                ? PAYMENT_STATUS_DICT[data.value].text
                : '交易失敗'
              }}
            </k-tag>
          </template>

          <template #cell(order_log_operations)="{value}">
            <k-tag v-if="value[0]">{{ ORDER_OPERATION_DICT[value[0]] }}</k-tag>
            <k-icon v-if="value[1]" icon="caret-right-fill" color="#ddd" />
            <k-tag v-if="value[1]">{{ ORDER_OPERATION_DICT[value[1]] }}</k-tag>
            <k-icon v-if="value[2]" icon="caret-right-fill" color="#ddd" />
            <k-tag v-if="value[2]">{{ ORDER_OPERATION_DICT[value[2]] }}</k-tag>
            </template>

          <template #cell(detail)="data">
            <k-button varient="ghost" theme="info" @click="changeFocusOrder(data.item.key_id, 'order')">訂單詳情</k-button>
          </template>
        </k-table>
      </k-tab-pane>


      <template #tabNav(return-order)="info">
        <div><i v-if="returnListCount">{{ returnListCount }}</i><span>{{ info.name }}</span></div>
      </template>
      <k-tab-pane tab="退貨單" key="return-order" state="return-order">
        <k-table
          v-if="returnList.length"
          :items="returnList"
          :fields="ReturnTableFields"
          :rowSelection="selectionMode"
          @check="onCheck"
          hover
        >
          <template #cell(return_order_state)="data">
            <k-tag :theme="data.value === 'RETURN_APPLY' ? 'error' : 'success'">
            {{ data.value === 'RETURN_APPLY' ? '未核准' : '已核准' }}
            </k-tag>
          </template>

          <template #cell(return_order_create_at)="data">
            {{ timestampParse(data.value).format('YYYY-MM-DD HH:mm') }}
          </template>

          <template #cell(order_logistics_subtype)="data">
            <k-tag>
              {{ LOGISTICS_SUBTYPE_DICT[data.value] }}
            </k-tag>
          </template>

          <template #cell(detail)="data">
            <k-button varient="ghost" theme="info" @click="changeFocusOrder(data.item.orders_id, 'return')">訂單詳情</k-button>
          </template>
        </k-table>
      </k-tab-pane>

      <k-tab-pane tab="已封存" key="archive" state="archive">
        <k-table
          :items="archiveOrder"
          :fields="ArchiveFields"
          :rowSelection="selectionMode"
          :rowSelectionButtonProps="{
            theme: 'primary',
            label: '選擇操作'
          }"
          @check="handleOperate"
        >
          <template #cell(key_id)="{ value }"><code>{{ value }}</code></template>
          <template #cell(order_logistics_state)="{value, item}">
            <k-tag v-if="value.length">
              {{ LOGISTIC_STATE[item.order_logistics_subtype][value[value.length-1].code] }}
            </k-tag>
          </template>
          <template #cell(order_logistics_subtype)="data">
            <k-tag :theme="data.item.order_pay_type === 'COD' ? 'light' : 'dark'">
            {{ data.item.order_pay_type === 'COD' ? '貨到付款' : '信用卡' }} | {{ LOGISTICS_SUBTYPE_DICT[data.value] }}
            </k-tag>
          </template>
          <template #cell(order_payment_status)="data">
            <k-tag
              :theme="data.item.order_pay_type === 'COD'
                ? 'light'
                : PAYMENT_STATUS_DICT[data.value] 
                  ? PAYMENT_STATUS_DICT[data.value].theme
                  :'error'
              ">
              {{ data.item.order_pay_type === 'COD'
              ? '貨到付款'
              : PAYMENT_STATUS_DICT[data.value]
                ? PAYMENT_STATUS_DICT[data.value].text
                : '交易失敗'
              }}
            </k-tag>
          </template>
          <template #cell(order_log_operations)="{value}">
            <k-tag v-if="value[value.length-3]">{{ ORDER_OPERATION_DICT[value[value.length-3]] }}</k-tag>
            <k-icon v-if="value[value.length-3]" icon="caret-right-fill" color="#ddd" />
            <k-tag v-if="value[value.length-2]">{{ ORDER_OPERATION_DICT[value[value.length-2]] }}</k-tag>
            <k-icon v-if="value[value.length-2]" icon="caret-right-fill" color="#ddd" />
            <k-tag v-if="value[value.length-1]">{{ ORDER_OPERATION_DICT[value[value.length-1]] }}</k-tag>
            </template>
          <template #cell(order_create_at)="data">
            {{ timestampParse(data.value).format('YYYY-MM-DD HH:mm') }}
          </template>
        </k-table>
      </k-tab-pane>
    </k-tabs>

    <!-- 輸出設定 -->
    <k-modal
      class="report-modal"
      :show.sync="showOutputSetting"
      :hideWithOk="false"
      title="輸出設定"
      :okButtonProps="{ theme: 'primary', loading: outputLoading, label: '確認', hide: lastResult }"
      @ok="$refs.form.submit()"
      @cancel="handleCancel"
    >
      <k-form v-if="!lastResult" ref="form" :questionConfig="QuestionFeilds" @submit="handleExportFormSubmit">
        <k-divider slot="after(title)">進階設定</k-divider>
        <div slot="before(dateRange)" class="date-range-quick-chooser">
          <header class="quick-chooser-header">快速選擇時間範圍</header>
          <div>
            <k-button theme="light" @click="quickSetExportRange('week')" >一週內</k-button>
            <k-button theme="light" @click="quickSetExportRange('month')" >一個月內</k-button>
          </div>
        </div>
        <!-- <div slot="after(dateRange)" class="report-config">
          <header><p>輸出條件</p></header>
          <main>
            <label>
              <k-checkbox v-model="reportCondition" theme="light" value="Receipt" />
              已開立發票
            </label>
            <label>
              <k-checkbox v-model="reportCondition" theme="light" value="Unreceipt" />
              未開立發票
            </label>
            <label>
              <k-checkbox v-model="reportCondition" theme="light" value="Completed" />
              已完成訂單
            </label>
          </main>
        </div> -->
      </k-form>
      <k-result v-else status="success" :title="`已成功輸出 ${ lastResult.exportType }`">
        <p>輸出連結：</p>
        <a
          :href="lastResult.path"
          :download="lastResult.exportType === 'Excel 格式' ? `${lastResult.fileName}.csv` : null"
          target="_blank"
        >
          {{ lastResult.text || lastResult.path }}
        </a>
      </k-result>
    </k-modal>

    <!-- 訂單詳情 -->
    <k-modal class="order-modal" title="訂單詳細資料" :show.sync="showDetail" @cancel="handleDetailCancel" :width="600">
      <div v-if="focusOrderInfo">
        <div class="inner-head">
          <div>
            <h2 class="inner-title">訂單編號 - {{ focusOrderInfo.key_id }}</h2>
            <k-button @click="emailToUser(focusOrderInfo.user_id)" theme="info" icon="envelope-fill">聯絡訂購人</k-button>
          </div>
          <p>{{ timestampParse(focusOrderInfo.order_create_at).format('yyyy-MM-DD HH:mm') }}</p>
          <k-tag theme="info">
            {{ focusOrderInfo.order_logistics_state.length
              ? LOGISTIC_STATE[focusOrderInfo.order_logistics_subtype][focusOrderInfo.order_logistics_state[focusOrderInfo.order_logistics_state.length-1]['code']]
              : LOGISTIC_STATE[focusOrderInfo.order_logistics_subtype]['300']
            }}
          </k-tag>
        </div>

        <k-divider>訂單資訊</k-divider>
        <k-descriptions layout="vertical" bordered>
          <k-descriptions-item label="訂單總額">${{ focusOrderInfo.order_amount }}</k-descriptions-item>
          <k-descriptions-item label="金流 UID">{{ focusOrderInfo.order_bank_trade_id || '-' }}</k-descriptions-item>
          <k-descriptions-item label="物流 UID">{{ focusOrderInfo.order_logistics_id || '-' }}</k-descriptions-item>
          <k-descriptions-item label="付款方式">{{ focusOrderInfo.order_pay_type === 'CREDIT' ? '信用卡' : '貨到付款' }}</k-descriptions-item>
          <k-descriptions-item label="物流類型">
            <template v-if="focusOrderInfo.order_logistics_type === 'INTERNATIONAL'">{{ focusOrderInfo.order_logistics_type }}</template>
            <template v-else>{{ focusOrderInfo.order_logistics_type }} | {{ focusOrderInfo.order_logistics_subtype }}</template>
          </k-descriptions-item>
          <k-descriptions-item label="貨運單號">
            <template v-if="focusOrderInfo.order_pay_type !== 'COD' || focusOrderInfo.order_logistics_type === 'CVS'">
              {{ focusOrderInfo.order_logistics_info[focusOrderInfo.order_logistics_type === 'CVS' ? 'CVSPaymentNo' : 'BookingNote'] }}
            </template>
            <template v-else>
              <span v-if="focusOrderInfo.order_logistics_info.BookingNote">{{ focusOrderInfo.order_logistics_info.BookingNote }}</span>
              <template v-else>
                <k-button v-if="!bookingNoteEditable" varient="ghost" theme="info" @click="toggleBookingNoteEditable(true)">填入物流代碼</k-button>
                <k-form v-else>
                  <input v-model="focusEditBookingNote" type="text" placeholder="單號">
                  <k-button varient="ghost" theme="info" @click="handleBookingNoteSubmit">送出</k-button>
                </k-form>
              </template>
              
            </template>
          </k-descriptions-item>
          <k-descriptions-item label="統一編號">{{ focusOrderInfo.order_receipt_unicode || '-' }}</k-descriptions-item>
          <k-descriptions-item label="公司抬頭">{{ focusOrderInfo.order_receipt_company || '-' }}</k-descriptions-item>
          <k-descriptions-item label="發票號碼">{{ focusOrderInfo.order_receipt_id || '尚未開立' }}</k-descriptions-item>
          
          <k-descriptions-item label="收件資訊" span="3">
            <ul v-if="focusOrderInfo.order_logistics_info">
              <li>收件人：{{ focusOrderInfo.order_logistics_info.ReceiverName }}</li>
              <li>收件人電話：{{ focusOrderInfo.order_logistics_info.ReceiverPhoneArea || '' }} {{ focusOrderInfo.order_logistics_info.ReceiverCellPhone }}</li>
              <li>收件人Email：{{ focusOrderInfo.order_logistics_info.ReceiverEmail }}</li>
              <template v-if="focusOrderInfo.order_logistics_type==='HOME' || focusOrderInfo.order_logistics_type==='INTERNATIONAL'">
                <li>收件地址：{{ focusOrderInfo.order_logistics_info.ReceiverAddress}}</li>
              </template>
              <template v-if="focusOrderInfo.order_logistics_type==='CVS'">
                <li>收件超商：{{ focusOrderInfo.order_logistics_subtype === 'FAMIC2C' ? '全家' : '7-11' }}</li>
                <li>收件門市：
                  {{ focusOrderInfo.order_logistics_cvs_info.CVSStoreName}}
                  【 {{ focusOrderInfo.order_logistics_cvs_info.CVSStoreID}} 】
                </li>
                
              </template>
            </ul>
            <p v-else>未設定</p>
          </k-descriptions-item>
          <k-descriptions-item label="退貨資訊" v-if="focusOrderType === 'return'" span="3">
            <ul v-if="focusOrderInfo.order_return_info">
              <li>退貨人：{{ focusOrderInfo.order_return_info.SenderName }}</li>
              <li>退貨人電話：{{ focusOrderInfo.order_return_info.SenderCellPhone }}</li>
              <li>
                退貨地址：{{ focusOrderInfo.order_return_info.SenderZipCode }}
                {{ focusOrderInfo.order_return_info.SenderAddress }}
              </li>
              <li>退貨原因：{{ focusOrderInfo.order_return_info.returnComment }}</li>
            </ul>
          </k-descriptions-item>
          <k-descriptions-item label="訂單內容" span="3">
            <table class="order-products">
              <tr
                v-for="product in focusOrderInfo.order_products_items"
                :key="`order-detail-${focusOrderInfo.key_id}-${product.product_id}-${product.product_price_class || 'MEMBER'}`"
              >
                <td>{{ productDict[product.product_id].product_uid }}</td>
                <td>
                  {{ productDict[product.product_id].product_name_zh }} {{ productDict[product.product_id].product_size && productDict[product.product_id].product_size+'ml'}}
                  <k-tag v-if="product.fromSetId" theme="light">{{ productDict[product.fromSetId].product_name_zh }}</k-tag>
                  <k-tag
                    v-if="product.product_price_class"
                    :theme="PRODUCT_PRICE_CLASS_DICT[product.product_price_class].theme"
                  >{{ PRODUCT_PRICE_CLASS_DICT[product.product_price_class].name }}
                  </k-tag>
                </td>
                <td>${{ product.product_price }}</td>
                <td>{{ product.product_quantity }}罐</td>
              </tr>
              <tr
                v-for="giveaway in focusOrderInfo.order_giveaways"
                :key="`order-detail-${focusOrderInfo.key_id}-${giveaway.product_uid}`"
                class="order-detail-giveaway"
              >
                <td>{{ giveaway.product_uid }}</td>
                <td>{{ productUidDict[giveaway.product_uid].product_name_zh }} {{ productUidDict[giveaway.product_uid].product_size && productUidDict[giveaway.product_uid].product_size+'ml'}}</td>
                <td>贈品</td>
                <td>1罐</td>
              </tr>
              <tr
                v-for="item in focusOrderInfo.order_products.filter(i => productDict[i.product_id].product_sample)"
                :key="`order-detail-${focusOrderInfo.key_id}-${item.product_id}-sample`"
              >
                <td>{{ getSample(item).product_uid }}</td>
                <td>{{ getSample(item).product_name_zh }} {{ getSample(item).product_size && getSample(item).product_size+'ml'}}</td>
                <td>試用品</td>
                <td>{{ item.product_quantity }}罐</td>
              </tr>
            </table>
          </k-descriptions-item>
          <k-descriptions-item v-if="focusOrderInfo.order_coupon_used.length" span=3 label="使用優惠券">
            <table class="order-products">
              <tr
                v-for="coupon in focusOrderInfo.order_coupon_used"
                :key="`order-detail-${focusOrderInfo.key_id}-${coupon.coupon_uid}`"
                class="order-detail-giveaway"
              >
                <td>{{ coupon.coupon_uid }}</td>
                <td>{{ coupon.coupon_name}}</td>
                <td>{{ coupon.coupon_type === 'DISCOUNT' ? '折扣' : '贈品'}}</td>
              </tr>
            </table>
          </k-descriptions-item>
          <k-descriptions-item label="折扣">${{ focusOrderInfo.order_discount }}</k-descriptions-item>
          <k-descriptions-item label="運費">${{ focusOrderInfo.order_shipping_fee }}</k-descriptions-item>
          <k-descriptions-item label="總計" >
            ${{ focusOrderInfo.order_amount }}
          </k-descriptions-item>
        </k-descriptions>

        <k-divider>物流狀態</k-divider>
        <k-descriptions layout="vertical" bordered>
          <k-descriptions-item label="狀態">
            <table class="order-products">
              <tr>
                <th>狀態代碼</th>
                <th>狀態</th>
                <th>更新時間</th>
              </tr>
              <tr
                v-for="state in focusOrderInfo.order_logistics_state"
                :key="`order-detail-state-${focusOrderInfo.key_id}-${state.code}`"
                class="order-detail-giveaway"
              >
                <td>{{ state.code }}</td>
                <td>{{ state.state_name }}</td>
                <td>{{ state.update_time }}</td>
              </tr>
            </table>
          </k-descriptions-item>
        </k-descriptions>

        <k-divider>用戶資料</k-divider>
        <k-descriptions layout="vertical" bordered>
          <k-descriptions-item label="用戶">{{ focusOrderInfo.user_name || '未註冊' }}</k-descriptions-item>
          <k-descriptions-item label="年齡">{{ focusOrderInfo.user_age || '未設定' }}</k-descriptions-item>
          <k-descriptions-item label="性別">{{ focusOrderInfo.user_gender | renderGender }}</k-descriptions-item>
          <k-descriptions-item label="電子郵件">{{ focusOrderInfo.user_email  || '未註冊' }}</k-descriptions-item>
        </k-descriptions>

        <template v-if="focusOrderInfo.order_logistics_type === 'CVS'">
          <k-divider>提醒紀錄</k-divider>
          <div>
            <table class="order-products history-email" v-if="email_historyList.length">
              <tr
                v-for="edm, index in email_historyList.filter(e => e.email_template_system_id.includes('ORDER_CVS_ARRIVAL') && e.email_history_recipients.variables.orderId === focusOrderInfo.orders_id)"
                :key="`order-detail-${edm.key_id}`"
                class="order-detail-giveaway"
              >
                <td>{{ index+1 }}</td>
                <td>{{ edmSystemIdDict[edm.email_template_system_id].email_template_name }}</td>
                <td>{{ edm.email_history_carrier === 'EMAIL' ? 'EMAIL信件' : 'SMS簡訊' }}</td>
                <td>{{ timestampParse(edm.email_history_create_at).format('YYYY/MM/DD HH:mm:ss') }}</td>
              </tr>
            </table>
            <p v-else>尚無提醒紀錄</p>
          </div>
        </template>
        
      </div>

      <template #footer>
        <template v-if="focusOrderType === 'return'">
          <k-button
            v-if="focusOrderInfo.order_type === 'RETURN_APPLY'" 
            theme="danger" 
            :loading="returnLoading"
            @click="handleConfirmReturnApply(focusOrderInfo)"
          >確認退貨審核
          </k-button>
          <k-button
            v-if="focusOrderInfo.order_type !== 'REFUNDED'"
            :disabled="focusOrderInfo.order_type !== 'RETURN'"
            :loading="returnLoading && focusOrderInfo.order_type === 'RETURN'"
            theme="warning"
            @click="handleRefund(focusOrderInfo)"
          >退款
          </k-button>
        </template>
        <template v-else>
          <k-button
            v-if="focusOrderInfo.order_logistics_type === 'CVS'"
            class="arrival-notice"
            theme="warning"
            icon="bell-fill"
            @click="handleArrivalNotice"
            :loading="arrivalNoticeLoading"
          >
            發送取貨提醒
          </k-button>
          <k-button
            class="arrival-notice"
            theme="danger"
            icon="box-seam-fill"
            @click="handleArchiveUnpickedOrder"
            >
            訂單未取貨
          </k-button>
          <k-button
            v-if="
              (focusOrderInfo.order_voucher_receivable || focusOrderInfo.order_binding_voucher_receivable)
              && focusOrderInfo.order_log_operations.includes('SHIPPED')
            "
            theme="success"
            @click="handleIssueVoucher(focusOrderInfo)" :loading="voucherLoading">
            發放購物金
          </k-button>
          <k-button v-if="(focusOrderInfo.order_pay_type !== 'COD' || focusOrderInfo.order_logistics_type === 'CVS') && !focusOrderInfo.order_logistics_state.find(s => s.code==='900') && focusOrderInfo.order_logistics_type !== 'INTERNATIONAL'" theme="info" @click="genOrderLogisticsNote(focusOrderInfo)">列印托運單</k-button>
          <k-button theme="info" @click="printDoc(focusOrderInfo)" :loading="portDocLoading">快速列印出貨單</k-button>
          <k-button theme="secondary" @click="genPortDocument(focusOrderInfo)" :loading="portDocLoading">列印出貨單</k-button>
          
          <k-button
            v-if="checkOrderComplete(focusOrderInfo) && focusOrderInfo.order_log_operations.includes('SHIPPED')"
            theme="warning"
            @click="onCheck([focusOrderInfo])"
          >完成訂單</k-button>
          <k-button
            v-if="focusOrderInfo.order_pay_type !== 'COD' && focusOrderInfo.order_payment_status !== 'SUCCESS'"
            theme="danger"
            @click="handleArchive(focusOrderInfo.orders_id)"
          >封存訂單</k-button>
        </template>
      </template>
    </k-modal>

    <k-modal
      class="operation-modal"
      :show.sync="showOperation"
      :hideFooter="true"
      title="訂單操作"
    >
        <header>
          <h4>你共選擇了{{ focuseOperationOrder.length }}筆訂單，選擇你要進行的操作：</h4>
          <div class="button-group">
            <div>
              <k-button theme="info" @click="changeOperationMode('Package')">完成包貨</k-button>
              <k-button theme="warning" @click="changeOperationMode('Receipt')">發票開立</k-button>
              <k-button theme="success" @click="changeOperationMode('Shipped')">完成出貨</k-button>
              <k-button 
                v-if="focuseOperationOrder.every(o => o.order_pay_type === 'COD')"
                theme="danger"
                @click="genCsvForTCat">
                匯出黑貓電子檔
              </k-button>
            </div>
            
            <div>
              <k-button theme="secondary" @click="changeOperationMode('CancelReceipt')" icon="file-earmark-x">已作廢發票</k-button>
              <k-button theme="secondary" @click="changeOperationMode('ReturnGoods')" icon="box">已歸還貨品</k-button>
              <k-button theme="secondary" @click="changeOperationMode('ReturnCash')" icon="cash">已退款金流</k-button>
            </div>
          </div>
        </header>

        <main>
          <transition>
            <Package v-if="operationMode === 'Package'"
              :orders="focuseOperationOrder"
              :logisticsDict="LOGISTICS_SUBTYPE_CONFIG"
              @submit="(orders) => handleOperationSubmit(orders, 'PACKAGE')"
            />
            <Receipt v-if="operationMode === 'Receipt'"
              :orders="focuseOperationOrder"
              :logisticsDict="LOGISTICS_SUBTYPE_CONFIG"
              @submit="(orders) => handleOperationSubmit(orders, 'RECEIPT')"
            />
            <Shipped v-if="operationMode === 'Shipped'"
              :orders="focuseOperationOrder"
              :logisticsDict="LOGISTICS_SUBTYPE_CONFIG"
              @submit="(orders) => handleOperationSubmit(orders, 'SHIPPED')"
            />
            <Archive v-if="['CancelReceipt', 'ReturnGoods', 'ReturnCash'].includes(operationMode)"
              :orders="focuseOperationOrder"
              :operation="operationMode"
              :logisticsDict="LOGISTICS_SUBTYPE_CONFIG"
              @submit="(orders) => handleOperationSubmit(orders, operationMode)"
            />
            <GenCsv 
              v-if="operationMode === 'GenCsv'"
              :orders="focuseOperationOrder"
              :logisticsDict="LOGISTICS_SUBTYPE_CONFIG"
            ></GenCsv>
          </transition>
        </main>
    </k-modal>
    <Order-Form :show.sync="showOrderCreateForm" />

    <form ref="ApiForm" target="_blank" method="POST" action="" hidden></form>
  </k-container>
</template>

<script>
import { KForm, KInput, KCheckbox } from '@/components/Form'
import { KDescriptions, KDescriptionsItem } from '@/components/Descriptions'
import { KTabs, KTabPane } from '@/components/Tabs'
import KCard from '@/components/Card'
import KDivider from '@/components/Divider'
import KTable from '@/components/Table'
import KTag from '@/components/Tag'
import KModal from '@/components/Modal'
import KButton from '@/components/Button'
import KButtonGroup from '@/components/ButtonGroup'
import KTooltip from '@/components/Tooltip'
import KResult from '@/components/Result'
import { buildSheet } from '@/utils/googleSheet'
import { createCsvFile } from '@/utils/csv'
import { timestampParse, clearData } from '@/utils'
import { mapState, mapActions, mapGetters } from 'vuex'
import LOGISTIC_STATE from '@/utils/ecpay/ecpayLogisticsState.json'
import { genCheckMacValue, createReverseLogisticsOrder, LOGISTICS_SUBTYPE_DICT } from '@/utils/ecpay/ecpay.js'
import Surenotify, { textOrderCheckEmailContent } from '@/utils/surenotify'
import { fetchApi, setMinus, setIntersection } from '../../utils'
import { genOrderReceiptCreateExcel, genUOrderShortInfo, genOrderReceiptInfoExcel } from './utils/excel'
import Package from './components/Package';
import Receipt from './components/Receipt';
import Shipped from './components/Shipped';
import Archive from './components/Archive';
import OrderForm from './components/OrderForm';
import moment from 'moment'


export default {
  name: 'Order',
  mounted() {
    if (!this.$store.state.orders.ordersList.length)this.$store.dispatch('orders/query')
    if (!this.$store.state.user.userList.length)this.$store.dispatch('user/query')
    if (!this.$store.state.orders.returnList.length)this.$store.dispatch('orders/queryReturns')
    if (!this.$store.state.edm.email_historyList.length)this.$store.dispatch('edm/queryEmailHistory')
    if (this.$route.query && this.$route.query.id && this.$store.state.orders.ordersList.length) {
      const focusOrder = this.$store.state.orders.ordersList.filter(i => i.key_id === this.$route.query.id)[0]
      const orderUser = this.$store.state.user.userList[this.$store.state.user.userList.map(u => u.user_id).indexOf(focusOrder.user_id)]
      this.focusOrderInfo = {
        ...orderUser,
        ...focusOrder,
        order_giveaways: focusOrder.order_coupon_used.reduce((acc, c) => {
          if (c.coupon_items) return [...acc, ...c.coupon_items]
          return acc
        }, []),
      }
      this.focusOrderType = 'order'
      this.showDetail = true
    }
    if (this.$route.query && this.$route.query.fromcvs == '1') this.showOrderCreateForm = true
  },
  data() {
    return {
      LOGISTIC_STATE,
      LOGISTICS_ABNORMAL_STATE,
      LOGISTICS_SUBTYPE_DICT,
      ORDER_OPERATION_DICT,
      PAYMENT_STATUS_DICT,
      LOGISTICS_SUBTYPE_CONFIG,
      PRODUCT_PRICE_CLASS_DICT,
      showOutputSetting: false,
      showOperation: false,
      showDetail: false,
      outputLoading: false,
      portDocLoading: false,
      voucherLoading: false,
      arrivalNoticeLoading: false,
      lastResult: undefined,
      focusOrderInfo: undefined,
      focusOrderType: '',
      focuseOperationOrder: [],
      returnLoading: false,
      selectionMode: true,
      operationMode: null,
      pagination: {
        current: 1,
        limit: 10
      },
      selectedRowKeys: [],
      ORDER_STATE_DICT,
      QuestionFeilds,
      tabPane,
      TableFields,
      ReturnTableFields,
      ArchiveFields,
      bookingNoteEditable: false,
      focusEditBookingNote: "",
      reportCondition: [],
      showOrderCreateForm: false
    }
  },
  computed: {
    ...mapState('orders', ['ordersList', 'returnList']),
    ...mapState('login', ['currentUser']),
    ...mapState('user', ['userList']),
    ...mapState('product', ['productList']),
    ...mapGetters('product', ['productDict', 'productUidDict']),
    ...mapGetters('orders', ['returnListCount', 'filterOrderWithUser', 'archiveOrder']),
    ...mapState('edm', ['email_historyList']),
    ...mapGetters('edm', ['edmSystemIdDict'])
  },
  methods: {
    ...mapActions('orders', ['batchCheckState', 'confirmReturnApply', 'refundOrder', 'genPortSheet', 'logOperation', 'updateCODOrderBookingNote', 'issueVoucher', 'archive', 'archiveUppickedOrder', 'genPortSheetInOtherWindow']),
    ...mapActions('edm', ['sendEmail', 'sendSMS']),
    timestampParse,
    async printDoc(order) {
      const wnd = window.open("about:blank", "", "_blank")
      wnd.document.write(await this.genPortSheetInOtherWindow(order))
      wnd.focus()
      setTimeout(() => wnd.print(), 500)
    },
    onCopy() {
      this.$message.success('已複製到剪貼簿')
    },
    getSample(product) {
      if (this.productList.length === 0 || this.productDict[product.product_id].product_uid === '2716' ) {
        return {}
      }
      return this.productUidDict[this.productDict[product.product_id].product_sample] || {}
    },
    showCreate() {
      this.showOrderCreateForm = true
    },
    async reloadOrders() {
      this.$store.dispatch('orders/query')
    },
    quickSetExportRange(range) {
      const dateRange = {
        start: moment().subtract(1, range),
        end: moment()
      }
      this.QuestionFeilds.splice(1, 1, {
        ...this.QuestionFeilds[1],
        value: dateRange
      })
    },
    async handleExportFormSubmit(formData) {
      const { reportType, dateRange } = formData.values
      console.log(dateRange);
      const unixDateRange = {
        start: dateRange ? dateRange.start.unix() : 0,
        end: dateRange ? dateRange.end.unix() : moment().unix()
      }
      switch (reportType) {
        case 'RECEIPT':
          this.exportOrderReceiptDetail(unixDateRange)
          break;
        case 'DECLARE':
          this.exportOrderDeclare(unixDateRange)
          break;
        case 'ORDER_DETAIL':
          this.exportOrderInfo(unixDateRange)
          break;
        default:
          break;
      }
    },
    exportOrderInfo(dateRange) {
      let orderListInRange = this.ordersList.filter(o => o.order_create_at > dateRange.start && o.order_create_at < dateRange.end)
      genUOrderShortInfo(orderListInRange)
    },
    exportOrderReceiptDetail(dateRange) {
      let noReceiptOrders = this.ordersList.filter(o => !o.order_receipt_id && o.order_create_at > dateRange.start && o.order_create_at < dateRange.end)
      genOrderReceiptCreateExcel(noReceiptOrders, this.productDict, this.productUidDict)
    },
    exportOrderDeclare(dateRange) {
      let RceiptOrders = this.ordersList.filter(o => o.order_create_at > dateRange.start && o.order_create_at < dateRange.end)
      genOrderReceiptInfoExcel(RceiptOrders, this.productDict, this.productUidDict)
    },
    filterData(filters, headerValues, data) {
      const { dateRange, limit } = filters
      let filterData = data.filter(o => o.order_type === 'BUY')
      if (dateRange) {
        filterData = filterData.filter(item => {
          return dateRange.start.unix() < item.order_create_at && item.order_create_at < dateRange.end.unix()
        }).sort((b, a) => b.order_create_at - a.order_create_at)
      }
      if (limit) filterData = filterData.slice(0, limit).sort((b, a) => b.order_create_at - a.order_create_at)
      return filterData.sort((b, a) => b.order_create_at - a.order_create_at)
      // return filterData.map(row => headerValues.map(key => row[key]))
    },
    handleCancel() {
      this.lastResult = undefined
      this.QuestionFeilds.forEach((e, index) => delete this.QuestionFeilds[index].value)
    },
    calculateVoucher(id) {
      const focusOrder = this.ordersList.filter(i => i.key_id === id)[0] || {}
      if (focusOrder.order_log_operations.includes('ISSUE_VOUCHER')) return 0
      if (focusOrder.order_pay_type !== 'COD') return 0
      if (!focusOrder.user_id) return 0
      const orderUser = this.userList[this.userList.map(u => u.user_id).indexOf(focusOrder.user_id)]
      const userHistoryOrders = this.filterOrderWithUser[orderUser.user_id] ? this.filterOrderWithUser[orderUser.user_id].filter(o => {
        return o.order_log_operations.includes('SHIPPED') || (o.order_pay_type === 'CREDIT' && o.order_payment_status === 'SUCCESS')
      }) : []
      let currentOrderIndex = userHistoryOrders.map(o => o.orders_id).indexOf(focusOrder.orders_id)
      if (currentOrderIndex === -1) return 0
      let orderComsumption = userHistoryOrders.slice(0, currentOrderIndex).reduce((acc, o) => acc + parseInt(o.order_amount), 0)
      if (orderUser.user_group === 'ORIGINAL' && orderComsumption >= 3000 && focusOrder.order_log_operations.includes('SHIPPED')) {
        return Math.round(focusOrder.order_amount * 0.015)
      }
      if (orderUser.user_binding) {
        const bindingUser = this.userList[this.userList.map(u => u.user_id).indexOf(orderUser.user_binding)]
        if (bindingUser.user_group === 'ORIGINAL') {
          return Math.round(focusOrder.order_amount * 0.03)
        }
      }
      return 0
    },
    changeFocusOrder(id, orderType) {
      const focusOrder = this.ordersList.filter(i => i.key_id === id)[0]
      const orderUser = this.userList[this.userList.map(u => u.user_id).indexOf(focusOrder.user_id)] || {}
      const userHistoryOrders = this.filterOrderWithUser[orderUser.user_id] ? this.filterOrderWithUser[orderUser.user_id].filter(o => {
        return o.order_log_operations.includes('SHIPPED') || (o.order_pay_type === 'CREDIT' && o.order_payment_status === 'SUCCESS')
      }) : []
      let currentOrderIndex = userHistoryOrders.map(o => o.orders_id).indexOf(focusOrder.orders_id)
      let orderComsumption = userHistoryOrders.slice(0, currentOrderIndex).reduce((acc, o) => acc + parseInt(o.order_amount), 0)
      let bindingUser
      if (orderUser.user_binding) {
        bindingUser = this.userList[this.userList.map(u => u.user_id).indexOf(orderUser.user_binding)]
      }

      const order_products_items = focusOrder.order_products.reduce((acc, p) => {
        const product = this.productDict[p.product_id]
        if (product.product_type === 'PRODUCT') acc.push(p)
        else {
          const setItems = this.productDict[p.product_id].product_set_items.split(',')
          const setItemsOrgPrice = setItems.reduce((acc, i) => acc + parseInt(this.productDict[i].product_price.MEMBER), 0)
          const setDiscountAvg = Math.round((setItemsOrgPrice - parseInt(this.productDict[p.product_id].product_price.MEMBER)) / setItems.length)
          const setItemsInfo = setItems.map(i => {
            const item = this.productDict[i]
            return {
              product_id: item.product_id,
              product_price: item.product_price.MEMBER - setDiscountAvg,
              product_quantity: 1,
              setDiscountAvg,
              fromSetId: p.product_id
            }
          })
          acc = [...acc, ...setItemsInfo]
        }
        return acc
      }, [])
      this.focusOrderInfo = {
        ...orderUser,
        ...focusOrder,
        order_products_items,
        order_original_amount: focusOrder.order_products.reduce((acc, p) => acc + parseInt(p.product_price)*parseInt(p.product_quantity), 0),
        order_logistics_state: focusOrder.order_logistics_state.map(s => ({
          ...s,
          state_name: this.LOGISTIC_STATE[focusOrder.order_logistics_subtype][s.code] || '未設定狀態',
          update_time: moment(s.update_time, 'YYYY/MM/DD HH:mm:ss')
        })).sort((b, a) => b ? b.update_time.unix() - a.update_time.unix() : true),
        order_voucher_receivable: orderUser.user_group === 'ORIGINAL'
          && orderComsumption >= 3000 && currentOrderIndex !== -1
          && focusOrder.order_pay_type === 'COD'
          && !focusOrder.order_log_operations.includes('ISSUE_VOUCHER')
            ? Math.round(focusOrder.order_amount * 0.015)
            : 0,
        order_binding_voucher_receivable: bindingUser && bindingUser.user_group === 'ORIGINAL' ? Math.round(focusOrder.order_amount * 0.03)  : 0,
        order_binding_user: bindingUser,
        order_giveaways: focusOrder.order_coupon_used.reduce((acc, c) => {
          if (c.coupon_items) return [...acc, ...c.coupon_items]
          return acc
        }, []),
      }
      this.focusOrderType = orderType
      this.showDetail = true
    },
    handleDetailCancel() {
      this.focusOrderInfo = undefined
    },
    showModal() {
      this.showOutputSetting = true
    },
    handleOperate(orders) {
      this.showOperation = true
      console.log(this.$refs['order_table']);
      this.$refs['order_table'].resetRowSelected()
      this.focuseOperationOrder = JSON.parse(JSON.stringify(orders))
      console.log(orders);
    },
    changeOperationMode(mode) {
      this.operationMode = mode
    },
    checkOrderComplete(order) {
      const stateCodes = order.order_logistics_state.map(s => s.code)
      const intersection = Array.from(setIntersection(['20000', '3003', '2067', '3022'], stateCodes))
      if (intersection.length) return false
      return true
    },
    onCheck(data) {
      this.$confirm.info({
        title: '確定要將所選訂單狀態變更為『已完成』？',
        text: '真的確定齁齁齁？',
        callback: (bool) => {
          this.handleChangeState(bool, { data, orders_dealer_id: this.currentUser.admin_id })
          this.focusOrderInfo.order_logistics_state = [
            ...this.focusOrderInfo.order_logistics_state,
            { code: '20000', update_time: moment().format('YYYY/MM/DD  HH:mm:ss') }
          ]
        }
      })
    },
    changeSelectionMode() {
      this.selectionMode = !this.selectionMode
    },
    handleChangeState(ensure, { data, orders_dealer_id }) {
      if (ensure) {
        this.batchCheckState({ check_orders: data, orders_dealer_id })
        this.$message.success('已成功修改訂單狀態')
      }
    },
    async emailToUser() {
      // const res = await fetchApi('/api/surenotify/send/', {
      //   data: {
      //     subject: '[Chris Farrell] 訂單通知',
      //     content: textOrderCheckEmailContent
      //   },
      //   recipients: [
      //     {
      //       "name": "GloveCoding",
      //       "address": "glovecoding@gmail.com"
      //     }
      //   ]
      // })
      // console.log(res);
      
      // const surenotifyInst = new Surenotify()
      // const res = await surenotifyInst.sendEmail({
      //   subject: '[Chris Farrell] 訂單通知', content: textOrderCheckEmailContent
      // }, [
      //   {
      //     "name": "GloveCoding",
      //     "address": "glovecoding@gmail.com",
      //     "variables": {
      //       "UUID": "test-1234"
      //     }
      //   }
      // ])
    },
    genOrderLogisticsNote(order) {
      console.log(this.$refs.ApiForm);
      let API = `https://logistics.ecpay.com.tw/Express/Print${order.order_logistics_subtype}OrderInfo`
      if (order.order_logistics_type === 'HOME') {
        API = 'https://logistics.ecpay.com.tw/helper/printTradeDocument'
      }
      const { AllPayLogisticsID, CVSPaymentNo, CVSValidationNo=undefined } = order.order_logistics_info
      const formData = clearData({
        AllPayLogisticsID, CVSPaymentNo, CVSValidationNo, 
        MerchantID: process.env.VUE_APP_ECPAY_LOGISTICS_C2C_MID
      })
      formData.CheckMacValue = genCheckMacValue(formData)
      console.log(formData);
      console.log(Object.entries(formData).map(e => `<input name="${e[0]}" value="${e[1]}">`));
      this.$refs.ApiForm.innerHTML = Object.entries(formData).map(e => `<input name="${e[0]}" value="${e[1]}">`).join('\n')
      this.$refs.ApiForm.setAttribute('action', API)
      this.$refs.ApiForm.submit()
    },
    async genPortDocument(order) {
      if (!order.order_port_doc_url)  {
        this.portDocLoading = true
        const { key_id } = order
        let portDocUrl = await this.genPortSheet({ ...order, orders_id: key_id })
        this.portDocLoading = false
        return window.open(portDocUrl.docURL, '_blank')
      }
      window.open(order.order_port_doc_url, '_blank')
    },
    async handleConfirmReturnApply(order) {
      this.returnLoading = true
      const res = await this.confirmReturnApply(order)
      console.log(res);
      if (res.response === "1|OK") {
        this.focusOrderInfo.order_type = 'RETURN'
        this.$message.success(`已核准${order.orders_id}訂單的退貨申請並建立逆物流訂單`)
        this.returnLoading = false
      } else {
        this.$message.error('逆物流建立失敗，請聯繫工程師處理')
      }
    },
    async handleRefund(order) {
      this.returnLoading = true
      const refundRes = await this.refundOrder(order)
      this.returnLoading = false
    },
    handleArchive(orders_id) {
      this.$confirm.error({
        title: '確定要將『封存』所選訂單？',
        text: '真的確定齁齁齁？',
        callback: async (bool) => {
          if (bool) {
            await this.archive(orders_id)
            this.$message.info('已封存')
            this.showDetail = false
          }
        }
      })
    },
    async handleOperationSubmit(orders, operation) {
      const res = await this.logOperation({ orders , operation})
      if (res.state === 200) {
        this.showOperation = false
        this.focuseOperationOrder = []
        this.$message.success('完成操作')
      } else {
        console.error(res.msg);
      }
    },
    toggleBookingNoteEditable(bool) {
      this.bookingNoteEditable = bool
      if (!bool) this.focusEditBookingNote = ""
    },
    async handleBookingNoteSubmit() {
      if (this.focusEditBookingNote) {
        const res = await this.updateCODOrderBookingNote({
          orders_id: this.focusOrderInfo.orders_id,
          order_logistics_info: { ...this.focusOrderInfo.order_logistics_info,  BookingNote: this.focusEditBookingNote }
        })
        if (res.status === 200) {
          this.$message.success('已加入物流單號')
          this.focusOrderInfo.order_logistics_info = { ...JSON.parse(res.data.order_logistics_info)}
          this.toggleBookingNoteEditable(false)
        }
      } else {
        this.$message.warning('貨運單號不得為空！')
      }
    },
    genCsvForTCat() {
      if (this.focuseOperationOrder.every(o => o.order_pay_type === 'COD')) {
        const csvContent = "data:text/csv;charset=utf-8," + Array(24).fill('').join(',') + "\r\n" + this.focuseOperationOrder.map(o => [
          o.order_logistics_info.ReceiverName, '', `0${o.order_phone}`, o.order_logistics_info.ReceiverAddress, o.order_amount, 1, 10, '',
          o.orders_id, 2, moment().format('YYYY/MM/DD'), moment().add(1, 'days').format('YYYY/MM/DD'), 1, 1, '克莉絲瑞', '0422605008', '0982123505',
          '台中市南區美村路二段186號10樓之2', '0', '保養品', '', '', '' ,''
        ].join(',')).join("\r\n")
        let downloadLink = document.createElement("a")
        downloadLink.href = encodeURI(csvContent)
        downloadLink.download = `黑貓訂單匯入檔${moment().format('YYYY_MM_DD_HH_mm')}.csv`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      }
    },
    handleIssueVoucher(focusOrderInfo) {
      this.$confirm.success({
        title: '確定要發放此訂單的購物金回饋？',
        text: `訂單：${focusOrderInfo.orders_id}
        創始會員現金回饋 | 購物金價值：$${focusOrderInfo.order_voucher_receivable}
        創始會員綁定回饋 | 購物金價值： $${focusOrderInfo.order_binding_voucher_receivable}`,
        icon: 'coin',
        callback: async (bool) => {
          if (bool) {
            this.voucherLoading = true
            await this.issueVoucher(focusOrderInfo)
            this.focusOrderInfo.order_log_operations.push('ISSUE_VOUCHER')
            this.focusOrderInfo.order_voucher_receivable = 0
            this.focusOrderInfo.order_binding_voucher_receivable = 0
            this.voucherLoading = false
            this.$message.success('已發放購物金')
          }
        }
      })
    },
    genEDMOrderItemsHTML() {
      const { order_products, order_giveaways } = this.focusOrderInfo
      const orderItemsSection = [...order_products, ...order_giveaways].map(p => {
        let product = p.product_id ? this.productDict[p.product_id] : this.productUidDict[p.product_uid]
        return `<tr><td width='12px'></td><td width='100'><img src='https://chris-farrell.com.tw/img/product/${product.product_uid}/avatar.webp' style='display: block; width: 100px; border-radius: 4px;'></td><td width='12px'></td><td width='264px'>${product.product_name_en}<br>${product.product_name_zh} x${p.product_quantity}</td><td width='12px'></td></tr>`
      }).join('<tr><td colspan="4" width="400" height="12"></td></tr>')
      console.log(orderItemsSection);
      return orderItemsSection
    },
    handleArrivalNotice() {
      let arrivalCode = this.focusOrderInfo.order_logistics_subtype === 'UNIMARTC2C' ? '2073' : '3018'
      let arrivalState = this.focusOrderInfo.order_logistics_state.find(s => s.code === arrivalCode)
      if (!arrivalState) return this.$message.error('尚未到貨 / 找不到到貨時間')
      let arrivalTime = moment(arrivalState.update_time, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD')
      let orderExp = moment(arrivalState.update_time, 'YYYY/MM/DD HH:mm:ss').add(7, 'days').format('YYYY/MM/DD');
      this.$confirm.warning({
        title: '確定要發送取貨提醒？',
        text: `訂單：${this.focusOrderInfo.orders_id}
        訂購人: ${this.focusOrderInfo.order_logistics_info.ReceiverName}
        取貨超商：${this.focusOrderInfo.order_logistics_subtype === 'UNIMARTC2C' ? '統一超商' : '全家超商'}
        取貨門市：${this.focusOrderInfo.order_logistics_cvs_info.CVSStoreName}
        到貨時間：${arrivalTime}
        `,
        icon: 'bell-fill',
        callback: async (bool) => {
          if (bool) {
            const {
              orders_id, order_amount, order_logistics_info, order_logistics_cvs_info, order_create_at, user_id, order_logistics_subtype, order_pay_type
            } = this.focusOrderInfo
            this.arrivalNoticeLoading = true
            await this.sendEmail({
              systemId: 'ORDER_CVS_ARRIVAL_NOTICE', 
              recipients : [{
                name: order_logistics_info.ReceiverName,
                address: order_logistics_info.ReceiverEmail,
                variables: {
                  userName: order_logistics_info.ReceiverName,
                  orderId: orders_id,
                  cvsType: order_logistics_subtype === 'UNIMARTC2C' ? '7-11' : '全家',
                  createTime: timestampParse(order_create_at).format('YYYY/MM/DD'),
                  cvsStore: order_logistics_cvs_info.CVSStoreName,
                  orderItems: this.genEDMOrderItemsHTML(this.focusOrderInfo),
                  orderAmount: order_amount,
                  payType: order_pay_type === 'CREDIT' ? '信用卡' : '超商付款',
                  arrivalTime,
                  orderExp,
                }
              }],
              user_id
            })
            await this.sendSMS({
              systemId: 'ORDER_CVS_ARRIVAL_SMS_NOTICE',
              recipients: [{
                address: order_logistics_info.ReceiverCellPhone,
                variables: {
                  orderId: orders_id,
                  cvsType: order_logistics_subtype === 'UNIMARTC2C' ? '7-11' : '全家',
                  cvsStore: order_logistics_cvs_info.CVSStoreName,
                  orderExp,
                  bookingNote: order_logistics_info.CVSPaymentNo || order_logistics_info.BookingNote
                }
              }],
              user_id
            })
            this.arrivalNoticeLoading = false
            this.$message.success('已發送到貨通知')
          }
        }
      })
    },
    handleArchiveUnpickedOrder() {
      const orders_id = this.focusOrderInfo.orders_id
      const order = this.ordersList.find(o => o.orders_id === orders_id)
      this.$prompt.error({
        title: '確定要將此訂單標記為未取貨並封存？',
        text: `訂單：${order.orders_id}
        *若為信用卡訂單，請先確認該單金流已退款
        *請於下方輸入欄中輸入「已收到未取貨件」以確認封存`,
        icon: 'box-seam-fill',
        callback: async (context) => {
          if (context === '已收到未取貨件') {
            await this.archiveUppickedOrder(order)
            this.$message.info('以標記未取貨並封存訂單')
            this.showDetail = false
            this.focusOrderInfo = undefined
          }
        }
      })
    },
    checkCVSOrderNoticed(order) {
      if (!this.email_historyList.length) return true
      if (order.order_logistics_type !== 'CVS') return true
      if (order.order_logistics_state.filter(s => ['2067', '3022', '20000'].includes(s.code)).length) return true
      if (!order.order_logistics_state.filter(s => s.code === '2073' || s.code === '3018').length) return true
      const noticeHistory = this.email_historyList.filter(h => h.email_template_system_id === 'ORDER_CVS_ARRIVAL_NOTICE' && h.email_history_recipients.variables.orderId === order.orders_id)
      return noticeHistory.length
    }
  },
  filters: {
    renderGender(val) {
      if (val) {
        switch (val) {
          case "MALE": return "男"
          case "FEMALE": return "女"
          case "OTHER": return "其他"
        }
      }
      return "未設定"
    }
  },
  components: {
    KTable, KButton, KTag, KModal, KForm, KDivider, KResult, KDescriptions, KDescriptionsItem, KCard, KTabs, KTabPane, KInput, KTooltip, KCheckbox,
    Receipt, Package, Shipped, KButtonGroup, Archive, OrderForm
  }
}

const QuestionFeilds = [
  {
    label: '報表格式',
    name: 'reportType',
    questionType: 'k-select',
    options: [{ text: '發票開立檔', value: 'RECEIPT' }, { text: '發票申報檔', value: 'DECLARE'}, { text: '訂單資料', value: 'ORDER_DETAIL' }],
    placeholder: '選擇報表格式',
    theme: 'light',
    required: true
  },
  // {
  //   label: '輸出方式',
  //   name: 'outputType',
  //   questionType: 'k-select',
  //   options: [{ text: 'Google Sheet', value: "GS" }, { text: 'Excel', value: 'XLS' }],
  //   placeholder: '選擇輸出方式',
  //   theme: 'light',
  //   required: true
  // },
  // { label: '檔案名稱', name: 'title', questionType: 'k-input', required: true },
  { label: '輸出範圍', name: 'dateRange', questionType: 'k-range-picker', extra: '若無特別選擇輸出範圍，將輸出所有訂單資料' },
  // { label: '輸出資料筆數', name: 'limit', questionType: 'k-input', type: 'number' }
]

const TableFields = [
  { key: 'key_id', fieldIndex: 'key_id', label: '訂單編號', searchable: true },
  { key: 'order_amount', fieldIndex: 'order_amount', label: '訂單總額' },
  // { key: 'user_name', fieldIndex: 'user_name', label: '定購人', searchable: true },
  { key: 'order_logistics_subtype', fieldIndex: 'order_logistics_subtype', label: '物流方式',
    filters: [
      { value: 'TCAT', text: '黑貓宅配' }, 
      { value: 'UNIMARTC2C', text: '7-11 統一超商' },
      { value: 'FAMIC2C', text: '全家 FamilyMart' }
    ]  
  },
  { key: 'order_logistics_state', fieldIndex: 'order_logistics_state', label: '物流狀態'}, 
  { key: 'order_payment_status', fieldIndex: 'order_payment_status', label: '付款狀態', 
    filters: [{ value: 'SUCCESS', text: '已付款' }, { value: 'HANDLING', text: '未付款' }]
  }, 
  { key: 'order_log_operations', fieldIndex: 'order_log_operations', label: '訂單操作標記' },
  { key: 'order_create_at', fieldIndex: 'order_create_at', label: '訂單成立時間', sorter: (a, b) => b - a },
  { key: 'detail', fieldIndex: 'detail', label: '' }
]

const ReturnTableFields = [
  { key: 'orders_id', fieldIndex: 'orders_id', label: '訂單編號', searchable: true },
  { key: 'order_amount', fieldIndex: 'order_amount', label: '訂單總額' },
  { key: 'return_order_state', fieldIndex: 'return_order_state', label: '核准' },
  { key: 'order_logistics_subtype', fieldIndex: 'order_logistics_subtype', label: '物流方式',
    filters: [
      { value: 'TCAT', text: '黑貓宅配' }, 
      { value: 'UNIMARTC2C', text: '7-11 統一超商' },
      { value: 'FAMIC2C', text: '全家 FamilyMart' }
    ]  
  },
  { key: 'order_logistics_state', fieldIndex: 'order_logistics_state', label: '逆物流狀態'}, 
  { key: 'order_payment_status', fieldIndex: 'order_payment_status', label: '退款狀態', 
    filters: [{ value: 'SUCCESS', text: '已退款' }, { value: 'HANDLING', text: '未退款' }]
  }, 
  { key: 'return_order_create_at', fieldIndex: 'return_order_create_at', label: '退貨申請時間',
    sorter: (a, b) => b - a
  },
  { key: 'detail', fieldIndex: 'detail', label: '' }
]

const ArchiveFields = [
  { key: 'key_id', fieldIndex: 'key_id', label: '訂單編號', searchable: true },
  { key: 'order_amount', fieldIndex: 'order_amount', label: '訂單總額' },
  { key: 'order_logistics_subtype', fieldIndex: 'order_logistics_subtype', label: '物流方式',
    filters: [
      { value: 'TCAT', text: '黑貓宅配' }, 
      { value: 'UNIMARTC2C', text: '7-11 統一超商' },
      { value: 'FAMIC2C', text: '全家 FamilyMart' }
    ]  
  },
  { key: 'order_logistics_state', fieldIndex: 'order_logistics_state', label: '物流狀態'}, 
  { key: 'order_payment_status', fieldIndex: 'order_payment_status', label: '付款狀態', 
    filters: [{ value: 'SUCCESS', text: '已付款' }, { value: 'HANDLING', text: '未付款' }]
  }, 
  { key: 'order_log_operations', fieldIndex: 'order_log_operations', label: '訂單操作標記' },
  { key: 'order_create_at', fieldIndex: 'order_create_at', label: '訂單成立時間', sorter: (a, b) => b - a },
]

const LOGISTICS_ABNORMAL_STATE = ['2070', '2072', '2074', '2076', '3020', '3025', '3019', '3023', '40000']

const LOGISTICS_SUBTYPE_CONFIG = {
  TCAT: { theme: 'dark', name: '黑貓' },
  FAMIC2C: { theme: 'info', name: '全家' },
  UNIMARTC2C: { theme: 'success', name: '˙7-11' }
}

const ORDER_STATE_DICT = {
  HANDLING: { theme: 'warning', text: '處理中' },
  PREPARE_PRODUCT: { theme: 'info', text: '準備中' },
  COMPLETE: { theme: 'success', text: '已完成' },
  CANCEL: { theme: 'secondary', text: '已取消' },
}

const ORDER_OPERATION_DICT = {
  PACKAGE: '已包貨',
  SHIPPED: '已出貨',
  RECEIPT: '已開立發票',
  CancelReceipt: '發票作廢',
  ReturnGoods: '歸還貨品',
  ReturnCash: '已退款',
}

const PAYMENT_STATUS_DICT = {
  HANDLING: { theme: 'warning', text: '未付款' },
  SUCCESS: { theme: 'success', text: '已付款' },
  RETURN: { theme: 'light', text: '已退款' }
}

const tabPane = [
  { key: "public", state: "published", tab: "未處理" },
  { key: "unPublic", state: "unpublish", tab: "已完成" }
]

const PRODUCT_PRICE_CLASS_DICT = {
  'ORIGIN': {
    name: '原價',
    theme: 'dark'
  },
  'MEMBER': {
    name: '會員價',
    theme: 'secondary'
  },
  'ADDITIONAL': {
    name: '加購價',
    theme: 'info'
  },
}
</script>
