<template>
  <ul :class="classes">
    <template v-if="source.length">
      <li
        :class="{ item: true, [`varient--${e.theme}`]: !!e.theme, disabled: e.disabled }"
        v-for="e, index in source"
        :key="e.key || genKey()"
      >
        {{ listType && listType === 'order' ? `${index}.` : type[listType] }}
        <slot :text="e.text || e" :index="index" :k="e.key || genKey()">{{ e.text || e }}</slot>
      </li>
    </template>
    <li v-else class="empty"><k-empty/></li>
  </ul>
</template>

<script>
import KEmpty from '@/components/Empty'

export default {
  name: 'k-list',
  data() {
    return {
      type: {
        'order': 'order',
        'circle': '•',
        'square': '☐',
      }
    }
  },
  props: {
    source: Array,
    listType: String,
    horizontal: Boolean,
    unhoverable: Boolean
  },
  methods: {
    genKey() {
      return `k-${parseInt(Math.random()*100000)}`
    }
  },
  computed: {
    classes() {
      return {
        'k-list': true,
        'horizontal': this.horizontal,
        'unhoverable': this.unhoverable
      }
    }
  },
  components: {
    KEmpty
  }
}
</script>
