import { genBasicModel } from './utils/basicAction'
import { query, create, update } from './utils/basicService'
import database from '@/utils/database'
import { timestampParse } from '@/utils'

const MODEL_NAME = 'cost'
const { basicAction, basicMutation } = genBasicModel(
  MODEL_NAME,
  query(MODEL_NAME),
  create(MODEL_NAME, true),
  update(MODEL_NAME))

export default {
  namespaced: true,
  state: {
    costList: [],
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction,
  },
  getters: {
    groupWithType({ costList }) {
      if (costList.length) {
        return costList.reduce((acc, c) => {
          acc[c.cost_fix_or_variable][c.cost_type]
            ? acc[c.cost_fix_or_variable][c.cost_type].push({ ...c })
            : acc[c.cost_fix_or_variable][c.cost_type] = [{ ...c }]
          return acc
        }, { FIX: {}, VARIABLE: {} })
      }
      return { FIX: {}, VARIABLE: {} }
    }
  }
}
