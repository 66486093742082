<template>
  <k-container class="view-edm">
    <h1>EDM 管理</h1>
    <k-tabs>
      <k-tab-pane class="edm-history" tab="發信紀錄" key="history" state="history">
        <k-card class="edm-history-filter" outline="light" noShadow title="篩選條件">
          <k-divider></k-divider>
          <form>
            <k-input label="EDM ID" v-model="edmFilters.email_history_id" debounce />
            <k-select 
              label="EDM 類型" 
              v-model="edmFilters.email_history_carrier"
              placeholder="選擇 EDM 類型"
              :options="[{ text: 'Email信件', value: 'EMAIL' }, { text: 'SMS簡訊', value: 'SMS' }]"
              theme="secondary" 
              block
            />
            <k-select 
              label="EDM 模板" 
              v-model="edmFilters.email_template_system_id"
              placeholder="選擇 EDM 模板"
              :options="[...edmSystemIdOptions, { text: '其他', value: 'Others' }]"
              theme="secondary" 
              block
            />
            <k-range-picker label="EDM 發送時間" v-model="edmFilters.email_history_create_at" />
            <k-input label="會員名稱" v-model="edmFilters.user_name" debounce />
          </form>
          <template #footer>
            <k-button @click="handleRemoveFilter" theme="light" icon="arrow-clockwise">重置</k-button>
          </template>
        </k-card>
        <k-table
          v-if="Object.keys(edmSystemIdDict).length"
          :fields="historyFields"
          :items="filteredEDMList"
          hover
        >
          <template #cell(email_history_id)="{value, item}">
            <k-tag class="edm-id" :theme="item.email_history_carrier === 'EMAIL' ? 'secondary' : 'dark'">{{ value }}</k-tag>
          </template>
          <template #cell(email_history_carrier)="{value}">
            <k-tag class="edm-id" :theme="value === 'EMAIL' ? 'info' : 'warning'">{{ value }}</k-tag>
          </template>
          <template #cell(email_history_subject)="{value, item}">
            {{ value || edmSystemIdDict[item.email_template_system_id].email_template_name }}
          </template>
          <template #cell(email_history_create_at)="{value}">
            {{ timestampParse(value).format('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template #cell(action)="{item}">
            <k-button v-if="item.email_history_carrier === 'EMAIL'" @click="showHistoryDetail(item)" varient="ghost" theme="info">查看詳情</k-button>
            <k-tooltip
              v-if="item.email_history_carrier === 'SMS' && Object.keys(edmSystemIdDict).length"
              :title="injectTemplateVariable(item.email_history_content || edmSystemIdDict[item.email_template_system_id].email_template_content, item.email_history_recipients.variables)"
              placement="left"
            >
              <k-button varient="ghost" theme="warning">顯示內容</k-button>
            </k-tooltip>
          </template>
        </k-table>
      </k-tab-pane>
      <k-tab-pane tab="所有會員" key="member" state="member">
        <k-card class="edm-history-filter" outline="light" noShadow title="篩選條件">
          <k-divider></k-divider>
          <form>
            <k-input label="會員 Email" v-model="userFilters.user_email" />
            <k-input label="會員姓名" v-model="userFilters.user_name" />
            <k-select 
              label="會員類型" 
              v-model="userFilters.user_group"
              placeholder="選擇會員類型"
              :options="[{ text: 'CF好友', value: 'NORMAL' }, { text: '創始會員', value: 'ORIGINAL' }]"
              theme="secondary" 
              block
            />
            <k-range-picker label="註冊時間" v-model="userFilters.user_create_at" />
          </form>
          <template #footer>
            <k-button @click="handleRemoveUserFilter" theme="light" icon="arrow-clockwise">重置</k-button>
          </template>
        </k-card>
        <k-table
          :fields="fields"
          :items="filteredUserList"
          :rowSelection="true"
          @check="handleChooseUsers"
          hover
        >
          <template #cell(user_group)="{value}">
            <k-tag :theme="value === 'NORMAL' ? 'secondary' : 'info'">{{ value === 'NORMAL' ? 'CF好友' : '創始會員' }}</k-tag>
          </template>

          <template #cell(action)="{item}">
            <k-button @click="handleChooseUsers([item])" varient="ghost" theme="info">EDM發送</k-button>
          </template>
        </k-table>
      </k-tab-pane>
    </k-tabs>

    <k-modal
      class="edm-modal"
      :show.sync="showEdmModal"
      :hideFooter="true"
      title="EDM 發送"
    >
      <Transition name="slide-left">
        <section class="form" v-if="!previewMode">
          <header>
            <div class="space-between">
              <k-radio-group
                label="發信模式"
                v-model="edmType"
                radioType="button"
                defaultValue="NORMAL"
                @onChange="handleEdmTypeChange"
              >
                <k-radio-button theme="info" value="NORMAL">一般內容</k-radio-button>
                <k-radio-button theme="info" value="TEMPLATE">使用模板</k-radio-button>
              </k-radio-group>
              <k-button icon="eye-fill" theme="primary" @click="handlePreview">預覽信件</k-button>
            </div>
            <k-select
              v-if="edmType === 'TEMPLATE'"
              :options="email_templateList.map(e => ({ text: e.email_template_name, value: e.email_template_id }))"
            />
          </header>
          <k-input label="主旨" v-model="edmInfo.subject"></k-input>
          <div>
            <p>收件人:</p>
            <k-input :value="choosedUsers.map(u => u.user_name).join(',')" readonly></k-input>
          </div>
          <div v-if="edmType === 'NORMAL'">
            <p>內容:</p>
            <k-textarea :resize="false" placeholder="輸入內容" v-model="edmInfo.variables.content"></k-textarea>
          </div>
          <div class="button-group">
            <k-button @click="handleSendEdm" theme="info">發送</k-button>
            <k-button @click="handleCancelEdm" theme="light">取消</k-button>
          </div>
        </section>
      </Transition>
      <Transition name="slide-right">
        <article class="preview" v-if="previewMode">
          <k-button theme="light" icon="arrow-left" @click="handlePreview(false)">結束預覽</k-button>
          <iframe :srcdoc="editionEdmContentPreview" frameborder="0"></iframe>
        </article>
      </Transition>
    </k-modal>
    
    <k-modal
      class="history-modal"
      :show.sync="showHistoryModal"
      :hideFooter="true"
      title="信件詳情"
    >
      <k-input label="主旨" :value="focusHistory.email_history_subject" readonly></k-input>
      <k-input label="收件人" :value="focusHistory.user_name" readonly></k-input>
      
      <article>
        <p>信件內容</p>
        <iframe :srcdoc="focusHistory.email_history_content" frame-border="0"></iframe>
      </article>
    </k-modal>
  </k-container>
</template>

<script>
import KCard from '@/components/Card'
import KTable from '@/components/Table'
import KTag from '@/components/Tag'
import KModal from '@/components/Modal'
import KButton from '@/components/Button'
import KDivider from '@/components/Divider'
import KTooltip from '@/components/Tooltip'
import { KForm, KTextarea, KInput, KSelect, KRangePicker, KRadioButton, KRadioGroup } from '@/components/Form'
import { KTabs, KTabPane } from '@/components/Tabs'
import { mapActions, mapGetters, mapState } from 'vuex'
import { timestampParse } from '@/utils'
import { parseMarkdown } from '@/utils/markdownParser'

function injectTemplateVariable(content, variables) {
  if (!variables) return content
  return content.replace(/\{\{(\w+)\}\}/g, (match, variable) => {
    if (variable === 'content') return parseMarkdown(variables[variable] || '')
    return variables[variable] || ''
  })
}

export default {
  name: 'EDM',
  mounted() {
    if (!this.$store.state.user.userList.length) this.$store.dispatch('user/query')
    if (!this.$store.state.edm.email_historyList.length) this.$store.dispatch('edm/queryEmailHistory')
    if (!this.$store.state.edm.email_templateList.length) this.$store.dispatch('edm/queryEmailTemplate')
  },
  data() {
    return {
      historyFields,
      fields: [
        { key: 'user_name', fieldIndex: "user_name", label: '姓名', sortable: true, searchable: true },
        { key: 'user_group', fieldIndex: "user_group", label: '會員等級', filters: [{ value: 'NORMAL', text: 'CF好友' }, { value: 'ORIGINAL', text: '創始會員' }] },
        { key: 'user_phone', fieldIndex: "user_phone", label: '電話', sortable: true, searchable: true },
        { key: 'user_email', fieldIndex: "user_email", label: '信箱', sortable: true, searchable: true },
        { key: 'action', fieldIndex: "action", label: '操作' }
      ],
      edmFilters: {},
      userFilters: {},
      showEdmModal: false,
      showHistoryModal: false,
      choosedUsers: [],
      focusHistory: {},
      previewMode: false,
      edmType: 'NORMAL',
      edmInfo: {
        subject: '',
        content: '',
        variables: {}
      }
    }
  },
  methods: {
    ...mapActions('edm', ['sendEmail']),
    injectTemplateVariable,
    timestampParse,
    parseMarkdown,
    handleChooseUsers(users) {
      this.choosedUsers = users
      const generalTemplate = JSON.parse(JSON.stringify(this.edmSystemIdDict['GENERAL_EDM']))
      console.log(generalTemplate);
      this.edmInfo = {
        subject: '',
        content: generalTemplate.email_template_content,
        variables: {
          ...Object.keys(generalTemplate.email_template_schema).reduce((acc, cur) => ({ ...acc, [cur]: '' }), {})
        }
      }
      this.showEdmModal = true
    },
    showHistoryDetail(history) {
      const { email_history_subject, email_history_content, email_template_system_id, email_history_recipients } = history
      console.log( this.edmSystemIdDict[email_template_system_id]);
      this.focusHistory = JSON.parse(JSON.stringify({
        ...history,
        email_history_subject: 
          injectTemplateVariable(email_history_subject || this.edmSystemIdDict[email_template_system_id].email_template_subject, email_history_recipients.variables),
        email_history_content: 
          injectTemplateVariable(email_history_content || this.edmSystemIdDict[email_template_system_id].email_template_content, email_history_recipients.variables),
        email_history_create_at: timestampParse(history.email_history_create_at).format('YYYY-MM-DD HH:mm:ss')
      }))
      this.showHistoryModal = true
    },
    handleRemoveFilter() {
      this.edmFilters = {}
    },
    handleRemoveUserFilter() {
      this.userFilters = {}
    },
    handleEdmTypeChange(type) {
      this.edmType = type
    },
    async handleSendEdm() {
      const { subject, variables } = this.edmInfo
      if (!subject) return this.$message.warning('請輸入信件主旨')
      if (!Object.values(variables).every(v => v)) return this.$message.warning('請輸入信件內容')
      await this.sendEmail({
        subject,
        content: this.editionEdmContentPreview,
        recipients: this.choosedUsers.map(u => ({
          name: u.user_name,
          address: u.user_email,
          variables
        })),
        user_id: this.choosedUsers.map(u => u.user_id).join(','),
      })
      this.$message.success('已發送 EDM')
    },
    handleCancelEdm() {
      this.showEdmModal = false
    },
    handlePreview(bool=true) {
      this.previewMode = bool
    }
  },
  computed: {
    ...mapState('user', ['userList']),
    ...mapState('edm', ['email_templateList', 'email_historyList']),
    ...mapGetters('edm', ['edmSystemIdDict']),
    filteredEDMList() {
      if (!this.userList.length || !this.email_historyList.length) return []
      const edmWithUser = this.email_historyList.map(e => {
        const user = this.userList.find(u => u.user_id === e.user_id) || {}
        return { ...e, user_name: user.user_name || 'NULL' }
      });

      if (!Object.values(this.edmFilters).length) return edmWithUser
      return edmWithUser.filter(history => {
        return Object.entries(this.edmFilters).every(([key, value]) => {
          if (!value) return true
          switch (key) {
            case 'email_history_create_at': 
              if (!value.end) return history[key] >= value.start.unix()
              return history[key] >= value.start.unix() && history[key] <= value.end.unix()
            default: return history[key].toString().includes(value)
          }
        })
      })
    },
    filteredUserList() {
      if (!this.userList.length) return []
      if (!Object.values(this.userFilters).length) return this.userList
      return this.userList.filter(user => {
        return Object.entries(this.userFilters).every(([key, value]) => {
          if (!value) return true
          switch (key) {
            case 'email_user_create_at': 
              if (!value.end) return user[key] >= value.start.unix()
              return user[key] >= value.start.unix() && user[key] <= value.end.unix()
            default: return user[key].toString().includes(value)
          }
        })
      })
    },
    edmSystemIdOptions() {
      if (Object.keys(this.edmSystemIdDict).length) {
        return Object.values(this.edmSystemIdDict).map(e => ({ text: e.email_template_name, value: e.email_template_system_id }))
      }
      return []
    },
    editionEdmContentPreview() {
      if (this.edmInfo.content) {
        return injectTemplateVariable(this.edmInfo.content, {
          ...this.edmInfo.variables,
          userName: this.choosedUsers[0].user_name,
          content: parseMarkdown(this.edmInfo.variables.content)
        })
      }
      return ''
    }
  },
  components: {
    KTable, KCard, KTag, KModal, KForm, KTextarea, KButton, KInput, KSelect, KTabs, KTabPane, KDivider, KRangePicker, KTooltip,
    KRadioButton, KRadioGroup
  }
}

const historyFields = [
  { key: 'email_history_id', fieldIndex: "email_history_id", label: 'EDM ID', sortable: true },
  { key: 'email_history_carrier', fieldIndex: "email_history_carrier", label: '載體類型', sortable: true},
  { key: 'email_history_subject', fieldIndex: "email_history_subject", label: 'EDM 名稱', sortable: true},
  { key: 'user_name', fieldIndex: "user_name", label: '指向會員'},
  { key: 'email_history_create_at', fieldIndex: "email_history_create_at", label: '發信日期', sorter: (a, b) => b - a },
  { key: 'action', fieldIndex: "action", label: '' }
]
</script>
