<template>
  <div :class="classes">
    <header>
      <a @click="prev('Y')"></a>
      <a @click="prev('M')"></a>
      <a @click="next('M')"></a>
      <a @click="next('Y')"></a>
      <span v-if="positionDate">{{ positionDate.format('YYYY, MMM') }}</span>
    </header>
    <section class="k-calendar-main" v-if="calendar && calendar.length">
      <ul class="weekday-names">
        <li v-for="name in weekName" :key="name"><span class="weekday-cell">{{ name }}</span></li>
      </ul>
      <ul v-for="week, wIndex in calendar" :key="`w_${wIndex}`">
        <li v-for="(date, dIndex) in week" :key="`d_${dIndex}`" @click="handleClick(wIndex, date+1)">
          <span
            :class="{
              'date-cell': true,
              'anther-month-cell': isFocusMonth(wIndex, date+1),
              selected: isSelected(date+1),
              inRange: inRange(date+1)
            }"
          >
            {{ date+1 }}
          </span>
        </li>
      </ul>
    </section>
    <footer v-if="$slots.footer" class="calendar-footer"><slot name="footer"></slot></footer>
  </div>
</template>

<script>
import { range } from '../utils';
import moment from 'moment';

function parseMomentToArray(moment, format='YYYY-M-D-d') {
  return moment.format(format).split('-').map((n, index) => index===1 ? Number(n)-1  : Number(n));
}

function creatCalander(positionMoment) {
  const [y, m, d, wkd] = parseMomentToArray(positionMoment);
  let mDays = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31, 30];
  mDays[1] = y % 4 === 0 ? 29 : 28; //如果年份為閏年，調整二月份天數為29天

  const thisCal = range(mDays[m]); //生成該月全部日期的陣列
  const prevCal = m !== 0 ? range(mDays[m - 1]) : range(mDays[11]); //生成上個月全部日期的陣列
  //計算日曆最後一週沒有補滿的天數
  const diff =
    (thisCal.length + prevCal.slice(prevCal.length - wkd, prevCal.length).length) % 7;
    //將第一週與中間週及最後一週組裝為完整月曆
  const fullCal = [
    ...prevCal.slice(prevCal.length - wkd, prevCal.length),
    ...thisCal,
    ...range(7 - diff),
  ];

  //將一個月曆的陣列，拆解成一週為一個陣列的二維陣列結構月曆
  return range(fullCal.length / 7).map((n) => {
    return fullCal.filter((d, i) => i >= 7 * n && i < 7 * (n + 1));
  });
}

export default {
  props: {
    selectedDate: Object,
    range: Boolean
  },
  mounted() {
    const today = new Date();
    if (this.selectedDate) {
      if (this.selectedDate.start) {
        this.positionDate = moment([...parseMomentToArray(this.selectedDate.start, 'YYYY-M'), 1]);
      }
      else this.positionDate = moment([...parseMomentToArray(this.selectedDate, 'YYYY-M'), 1])
    }
    else this.positionDate = moment([today.getFullYear(), today.getMonth(), 1]);
    this.calendar = creatCalander(this.positionDate);
  },
  data() {
    return {
      calendar: [],
      positionDate: null,
      datePicked: {},
      weekName: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
    }
  },
  methods: {
    next(type) {
      const newMonth = this.positionDate.add(1, type);
      this.saveMonth(newMonth);
    },
    prev(type) {
      const newMonth = this.positionDate.add(-1, type);
      this.saveMonth(newMonth);
    },
    saveMonth(newMonth) {
      const newCalander = creatCalander(newMonth);
      this.positionDate = newMonth;
      this.calendar = [...newCalander];
    },
    isFocusMonth(weekIndex, date) {
      if (weekIndex === 0 && date > 7) return true;
      if (weekIndex === this.calendar.length-1 && date <= 7) return true;
      return false;
    },
    isSelected(date) {
      if (this.selectedDate) {
        const [y, m] = parseMomentToArray(this.positionDate, 'YYYY-M');
        if (this.selectedDate.start) { 
          const { start, end } = this.selectedDate
          return start.format('YYYY-M-D') === moment([y, m, date]).format('YYYY-M-D')
            || end && end.format('YYYY-M-D') === moment([y, m, date]).format('YYYY-M-D')
        }
        return this.selectedDate.format('YYYY-M-D') === moment([y, m, date]).format('YYYY-M-D');
      }
      return false
    },
    inRange(date) {
      if (this.selectedDate) {
        const { start, end } = this.selectedDate
        if (start, end ) {
          const [y, m] = parseMomentToArray(this.positionDate, 'YYYY-M');
          return start.unix() < moment([y, m, date]).unix() && moment([y, m, date]).unix() < end.unix()
        }
      }
    },
    selectRangeDate( y, m, date) {
      const { start, end } = this.datePicked
      if (!start || (start && end )) {
        this.datePicked.start = { y, m, date }
        delete this.datePicked.end
      } else {
        this.datePicked.end = { y, m, date }
      }
      this.$emit('onSelect', this.datePicked);
    },
    handleClick(weekIndex, date) {
      let [y, m] = parseMomentToArray(this.positionDate, 'YYYY-M');
      if (weekIndex === 0 && date > 7) {
        m = m === 0 ? 11 : m - 1;
        y = m === 0 ? y-1 : y;
      };
      if (weekIndex === this.calendar.length-1 && date <= 7) {
        m = m === 11 ? 0 : m + 1;
        y = m === 11 ? y+1 : y;
      }
      const momentDate = moment([y, m, date]);
      if (this.range) this.selectRangeDate( y, m, date)
      else this.$emit('onSelect', momentDate);
    }
  },
  computed: {
    classes() {
      return {
        'k-calendar': true
      }
    }
  },
}
</script>
