<template>
  <k-list v-if="list" :source="list" unhoverable >
    <k-skeleton slot="default" slot-scope="data" :loading="loading" active>
      <div class="blog-list">
        <img class="blog-list-banner" :src="data.text.post_bannerPath" :alt="data.text.post_banner">
        <div class="blog-list-content">
          <i>{{ data.text.post_create_at | dateFormat }}</i>
          <h4>{{ data.text.post_title }}</h4>
          <span>{{ data.text.post_summary }}</span>
        </div>
              
        <span class="blog-list-action edit">
          <k-icon icon="pencil" theme="info" @click="toEdit(data.text.key_id)"/>
        </span>
              
        <k-popconfirm
          class="blog-list-action delete"
          placement="bottom-left"
          message="確定要刪除此篇文章嗎？"
          icon="question-octagon-fill"
          iconTheme="warning"
          @confirm="handleDelete(data.text.key_id)"
        >
          <span>
            <k-icon icon="trash" theme="danger" />
          </span>
        </k-popconfirm>
      </div>
    </k-skeleton>
  </k-list>
</template>

<script>
import KList from '@/components/List'
import KSkeleton from '@/components/Skeleton.vue'
import KPopconfirm from '@/components/Popconfirm.vue'
import { timestampParse } from '@/utils'
import { mapActions } from 'vuex'

export default {
  props: {
    list: Array,
    loading: Boolean
  },
  methods: {
    ...mapActions('posts', ['delete', 'unlinkMedia']),

    toEdit(id) {
      this.$router.push(`/blog/${id}/edit`)
    },
    handleDelete(id) {
      this.unlinkMedia(id)
      this.delete(id)
      this.$message.info('已刪除文章')
    }
  },
  filters: {
    dateFormat: date => timestampParse(date).format('YYYY/MM/DD')
  },
  components: {
    KList, KSkeleton, KPopconfirm
  }
}
</script>
