<template>
  <div :class="classes">
    <svg width="0" height="0" class="hidden">
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="empty">
        <path d="M21.5 22h-19A2.503 2.503 0 010 19.5v-7a.5.5 0 01.044-.205l3.969-8.82A2.506 2.506 0 016.293 2h11.414c.981 0 1.876.579 2.28 1.475l3.969 8.82A.5.5 0 0124 12.5v7c0 1.379-1.122 2.5-2.5 2.5zM1 12.607V19.5c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5v-6.893l-3.925-8.723A1.505 1.505 0 0017.707 3H6.293c-.589 0-1.126.348-1.368.885z"></path>
          <path d="M16.807 17H7.193a1.506 1.506 0 01-1.404-.973l-1.014-2.703A.5.5 0 004.307 13H.75a.5.5 0 010-1h3.557c.622 0 1.186.391 1.405.973l1.013 2.703a.502.502 0 00.468.324h9.613a.5.5 0 00.468-.324l1.013-2.703A1.51 1.51 0 0119.693 12H23.5a.5.5 0 010 1h-3.807a.5.5 0 00-.468.324l-1.013 2.703a1.509 1.509 0 01-1.405.973z"></path>
      </symbol>
    </svg>

    <div class="empty-image">
      <slot name="image">
        <img v-if="image" :src="image" alt="empty-image">
      </slot>
      
      <svg v-if="!image" class="icon">
        <use xlink:href="#empty"></use>
      </svg>
    </div>
    <p>{{ description || '暫無資料' }}</p>
  </div>
</template>

<script>
export default {
  props: {
    description: String,
    image: String
  },
  computed: {
    classes() {
      return {
        'k-empty': true
      }
    }
  },
}
</script>
