import { genBasicModel } from './utils/basicAction'
import { create, update } from './utils/basicService'
import database from '@/utils/database'
import { timestampParse } from '@/utils'

const MODEL_NAME = 'inventory'
const { basicAction, basicMutation } = genBasicModel(
  MODEL_NAME,
  query,
  create(MODEL_NAME, true),
  update(MODEL_NAME))

async function query() {
  const res = await database.table(MODEL_NAME)
    .join('product', 'product_id')
    .join('giveaway', 'giveaway_id')
    .join('cost', 'cost_id')
    .get()
  return res
}

export default {
  namespaced: true,
  state: {
    inventoryList: [],
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction,
  },
  getters: {
    productInventory({ inventoryList }) {
      if (inventoryList.length) {
        return inventoryList.filter(i => i.product_id)
      }
      return []
    },
    giveawayInventory({ inventoryList }) {
      if (inventoryList.length) {
        return inventoryList.filter(i => i.giveaway_id)
      }
      return []
    },
  }
}
