import mockData from '@/mock'

class Mock {
  constructor() {
    this.datasets = mockData
  }

  query(tableName, timeout) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        const data = this.datasets[tableName]
        if (data) res(data) 
        else rej({ status: 404, message: 'table not found' }) 
      }, timeout || 1000);
    })
  }

  async fetch(tableName, timeout) {
    return await this.query(tableName, timeout)
  }
}

export default {
  install(Vue, options) {
    Vue.prototype.$mock = new Mock()
  }
}
