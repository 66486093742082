class XHREventTarget {
  constructor(xhr) {
    let evtTypes = ['loadstart', 'progress', 'abort', 'error', 'load', 'time', 'loadend'];

    let handlers = evtTypes.reduce((handlerAcc, evtType) => {
      handlerAcc[evtType] = new Set();
      return handlerAcc;
    }, {});

    evtTypes.forEach(type => {
      xhr[`on${type}`] = function (event) {
        handlers[type].forEach(handler => handler.call(xhr, event))
      }
    })

    this.xhr = xhr
    this.handlers = handlers
  }

  addEvent(evnType, handler) {
    this.handlers[evnType].add(handler)
    return this
  }

  removeEvent(evnType, handler) {
    this.handlers[evnType].delete(handler);
    return this;
  }
}

class XHRUpload extends XHREventTarget {
  constructor(xhr) {
    super(xhr);
  }
}

class XHR extends XHREventTarget {
  constructor() {
    super(new XMLHttpRequest())

    let handlers = this.handlers
    let xhr = this.xhr
    handlers['readystatechange'] = new Set()
    xhr.onreadystatechange = function(evt) {
      handlers['readystatechange'].forEach(handler => handler.call(this.xhr, evt));
    }
  }

  open(method, url, paraObj, async = true, username = null, password = null) {
    let openUrl = paraObj ? `${url}?${params(paraObj)}` : url; 
    this.xhr.open(method, openUrl, async, username, password);
    return this;
  }

  send(body = null) {
    this.xhr.send(body)
    return this
  }

  uploadXHR() {
    if(this.upload === undefined) {
      this.upload = new XHRUpload(this.xhr.upload);
    }
    return this.upload;
  }
}

export default XHR
