<template>
  <k-container :class="['view-blog-container']" title="文章列表">
    <k-tabs>
      <k-tab-pane v-for="{ tab, key, state } in tabPane" :tab="tab" :key="key">
        <ListItem :list="filterPostByState[state]" :loading="loading" />
      </k-tab-pane>
    </k-tabs>
  </k-container>
</template>

<script>
import { KTabs, KTabPane } from '@/components/Tabs'
import { mapGetters } from 'vuex'
import ListItem from './components/ListItem'

export default {
  name: 'Blog',
  async mounted() {
    if (!this.$store.state.posts.postList.length) {
      this.loading = true
      const res = await this.$store.dispatch('posts/query')
      // if (res) this.loading = false
      if (res) setTimeout(() => { this.loading = false }, 1000);
    }
    
  },
  data() {
    return {
      loading: false,
      tabPane
    }
  },
  computed: {
    ...mapGetters('posts', ['filterPostByState'])
  },
  components: {
    KTabs, KTabPane, ListItem
  }
}

const tabPane = [
  { key: "public", state: "published", tab: "已發佈" },
  { key: "unPublic", state: "unpublish", tab: "未發佈" },
  { key: "hide", state: "archive", tab: "封存" }
]
</script>
