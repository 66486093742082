<template>
  <k-card class="mail-list-component">
    <template #header>
      <k-button class="check-all" :icon="isCheckAll ? 'check-square-fill' : 'square'" theme="secondary" @click="toggleCheckAll">
          {{ isCheckAll ? '取消' : '全選'}}
      </k-button>
      <k-button-group v-if="todoEmail.length">
        <k-tooltip placement="bottom" title="統一回覆">
          <k-button theme="secondary" icon="reply-fill" title="回覆"></k-button>
        </k-tooltip>
        <k-tooltip placement="bottom" title="轉寄">
          <k-button theme="secondary" icon="reply-all-fill"></k-button>
        </k-tooltip>
        <k-tooltip placement="bottom" title="標籤">
          <k-button theme="secondary" icon="tag-fill" @click="showTags"></k-button>
        </k-tooltip>
      </k-button-group>
    </template>

    <k-list :class="{ loading }" :source="loading ? Array(10).fill({}) : emailList" >
      <k-skeleton slot="default" slot-scope="data" :loading="loading" :line="1" hideTitle active dense>
        <div :class="{ choosed: todoEmail.includes(data.text.email_id) }" v-if="!loading">
          <div>
            <k-checkbox
              v-model="todoEmail"
              :theme="todoEmail.includes(data.text.email_id) ? 'warning'  : 'light'"
              :value="data.text.email_id"
            />
          </div>
          <b>{{ showAddress(data.text) }}</b>
          <span
          class="email-content"
          @click="$router.push(`/email/${data.text.email_type.toLowerCase()}/${data.text.email_id}`)">
            <k-tag v-if="showEmailFrom && data.text.email_type">
              {{ data.text.email_type==='INBOX' ? '收件匣' : '寄件' }}
            </k-tag>
            <k-tag v-if="data.text.email_tag_name" theme="secondary">{{ data.text.email_tag_name }}</k-tag>
            <b class="email-subject">{{ data.text.email_title }}</b> - {{ parseContent(data.text.email_content) }}
          </span>
          <i>{{ timestampParse(data.text.email_date).format('MM月DD日 HH:MM') }}</i>
        </div>
      </k-skeleton>
    </k-list>

    <TagsModal ref="tagsModal" :todoEmail="todoEmail" :emailType="emailType"  />
  </k-card>
</template>

<script>
import KButton from '@/components/Button'
import KButtonGroup from '@/components/ButtonGroup'
import KCard from '@/components/Card'
import KList from '@/components/List'
import KSkeleton from '@/components/Skeleton'
import KTag from '@/components/Tag'
import KTooltip from '@/components/Tooltip'
import TagsModal from './TagsModal'
import { KCheckbox } from '@/components/Form'
import { timestampParse } from '@/utils'
import { mapState } from 'vuex'

export default {
  props: {
    loading: Boolean,
    showEmailFrom: Boolean,
    emailList: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.emailType = this.$route.path.split('/').slice(-1)[0].toUpperCase()
  },
  data() {
    return {
      isCheckAll: false,
      todoEmail: [],
      emailType: undefined
    }
  },
  computed: {
    ...mapState('email', ['emailTagList'])
  },
  methods: {
    timestampParse,
    showTags() {
      this.$refs.tagsModal.showTags()
    },
    showAddress(email) {
      if(email.email_from_name) return email.email_from_name
      if(email.email_from) return email.email_from
      if(email.email_target) return typeof email.email_target.length === 'array' ? email.email_target : email.email_target[0]
    },
    toggleCheckAll() {
      this.isCheckAll = !this.isCheckAll
    },
    parseContent(content) {
      let dom = new DOMParser().parseFromString(`${content}`, "text/html")
      let pureContext = dom.body.textContent.replace(/\s+/gm, ' ')
      return pureContext.substring(0,150) + (pureContext.length > 150 ? '...' : '' )
    },
  },
  watch: {
    isCheckAll(condition) {
      if (condition) this.todoEmail = this.emailList.map(e => e.email_id)
      else this.todoEmail = []
    }
  },
  components: {
    KButton, KButtonGroup, KCard, KList, KSkeleton, KTag, KCheckbox, KTooltip, TagsModal
  }
}
</script>
