<template>
  <k-modal class="order-create-modal" :show.sync="showSync" title="建立訂單" :width="1200" hideFooter>
    <Transition name="slideIn">
      <div v-if="currentStep === 1" class="order-form">
        <k-divider>出貨方式</k-divider>
        <k-radio-group
          label="優惠類型"
          v-model="shippingType"
          radioType="button"
          varient="primary"
          :defaultValue="$route.query.fromcvs ? 'CVS' : 'HOME'"
          @onChange="handleShippingTypeChange"
        >
          <k-radio-button theme="info" value="HOME">宅配</k-radio-button>
          <k-radio-button theme="info" value="CVS">超商取貨</k-radio-button>
        </k-radio-group>
        <template v-if="shippingType === 'HOME'">
          <k-input label="收件地址" v-model="userAddress" required></k-input>
        </template>
        <template v-else>
          <div class="cvs-type-button-group">
            <p>選擇超商門市</p>
            <k-button theme="success" @click="handleCvsChoosed('UNIMARTC2C')">7-11 統一超商</k-button>
            <k-button theme="info" @click="handleCvsChoosed('FAMIC2C')">FamilyMart 全家</k-button>
          </div>
          <k-card v-if="cvsInfo" class="cvs-info" bgColor="#34363c" title="超商資訊" noShadow>
            <p>超商類型: {{ cvsInfo.LogisticsSubType === 'UNIMARTC2C' ? '7-11 統一超商' : 'FamilyMart 全家' }}</p>
            <p>門市代號: {{ cvsInfo.CVSStoreID }}</p>
            <p>門市名稱: {{ cvsInfo.CVSStoreName }}</p>
            <p>門市地址: {{ cvsInfo.CVSAddress }}</p>
          </k-card>
        </template>

        <k-divider>付款方式</k-divider>
        <div class="radio-group-container">
          <k-radio-group
            label="付款方式"
            v-model="paymentType"
            radioType="button"
            :defaultValue="$route.query.fromcvs ? 'COD' : 'CREDIT'"
            @onChange="handlePaymentTypeChange"
          >
            <k-radio-button theme="info" value="CREDIT">信用卡</k-radio-button>
            <k-radio-button theme="info" value="COD">取貨付款</k-radio-button>
          </k-radio-group>
          <k-radio-group
            label="發票類型"
            v-model="receiptType"
            radioType="button"
            defaultValue="NORMAL"
            @onChange="handleReceiptTypeChange"
          >
            <k-radio-button theme="primary" value="NORMAL">一般發票</k-radio-button>
            <k-radio-button theme="primary" value="UNIFORM_NUMBER">統一編號</k-radio-button>
          </k-radio-group>
        </div>
        <template v-if="paymentType === 'CREDIT'">
          <k-checkbox theme="white" v-model="hadPaid" :checked="hadPaid">已付清款項</k-checkbox>
        </template>
        <section v-if="receiptType === 'UNIFORM_NUMBER'" class="receipt-info grid c-2">
          <k-input label="統一編號" v-model="receiptUnicode" required></k-input>
          <k-input label="公司抬頭" v-model="receiptCompany" required></k-input>
        </section>

        <k-divider>收件人資訊</k-divider>
        <section class="grid c-2">
          <k-select
            label="收件人"
            :options="userOptions"
            v-model="userName"
            theme="secondary"
            placeholder="輸入收件人姓名"
            @change="handleUserChange"
            @input="handleUserInput"
            searchable
            block
            required
          />
          <k-input
            label="會員資格"
            :value="userDict[userId] ? userDict[userId].user_group === 'ORIGINAL' ? '創始會員' : 'CF 好友' : '無加入會員'"
            readonly
          />
          <k-input label="收件人電話" v-model="userPhone" required></k-input>
          <k-input label="收件人信箱" v-model="userEmail"></k-input>
        </section>
        <k-divider>訂單內容</k-divider>
        <section class="grid c-product product-choosed">
          <k-card class="products" bgColor="#34363c" noShadow>
            <section class="grid c-2">
              <k-button v-for="product in productList"
              :key="product.product_id" icon="plus" @click="handleItemClick(product.product_id)"
              :disabled="itemChoosed.some(item => item.product_id === product.product_id)"
              :theme="itemChoosed.some(item => item.product_id === product.product_id) ? 'dark' : 'light'"
              >
                {{ product.product_uid }} {{ product.product_name_zh }}
              </k-button>
            </section>
          </k-card>
          <div><k-icon icon="caret-right-square"></k-icon></div>
          <k-card class="choosed" bgColor="#34363c" noShadow>
            <ul>
              <li v-for="item in itemChoosed" :key="item.product_id">
                <header>
                  <b>{{ productDict[item.product_id].product_name_zh }} | ${{ item.product_price * item.product_quantity || 0 }}</b>
                  <k-tooltip :title="`價格參考:${Object.keys( productDict[item.product_id].product_price).map(c => '\n'+priceClassDict[c]+' $'+productDict[item.product_id].product_price[c])}`"><k-icon theme="info" icon="info-circle"></k-icon></k-tooltip>
                  <div class="remove"><k-icon icon="x-lg" @click="handleRemoveProduct(item.product_id)" /></div>
                </header>
                <main>
                  <k-input type="Number" addonBefore="單價" placeholder="產品單價" v-model="item.product_price" min="1"></k-input>
                  <k-input type="Number" addonBefore="數量" placeholder="購買數量" v-model="item.product_quantity" min="1"></k-input>
                </main>
              </li>
            </ul>
          </k-card>
        </section>

        <k-divider>使用優惠</k-divider>
        <section class="grid coupon-choosed c-product">
          <k-card class="coupons" bgColor="#34363c" noShadow>
            <section>
              <div class="search">
                <k-input addonBefore="搜尋名稱" placeholder="輸入優惠券 UID / 中文名稱" v-model="couponSearch" debounce></k-input>
              </div>
              <div class="coupon-list">
                <k-button v-for="coupon in couponRecommended.filter(c => !couponChoosed.find(cc => cc.coupon_uid === c.coupon_uid))"
                  :key="`recmd-${coupon.coupon_uid}`"
                  :theme="coupon.coupon_type === 'DISCOUNT' ? 'light' : 'warning'"
                  @click="handleCouponAdd(coupon)"
                >
                  <k-tag class="recmd" theme="success">推薦</k-tag>
                  <k-tag class="exp" theme="secondary" v-if="coupon.coupon_exp">期限{{ timestampParse(coupon.coupon_exp).format('MM/DD') }}</k-tag>
                  <p>{{ coupon.coupon_uid }}</p>
                  <p>{{ coupon.coupon_name }}</p>
                </k-button>

                <k-button v-for="coupon in couponResult"
                :key="coupon.coupon_uid"
                :theme="coupon.coupon_type === 'DISCOUNT' ? 'light' : 'warning'"
                @click="handleCouponAdd(coupon)"
                >
                  <k-tag class="exp" theme="secondary" v-if="coupon.coupon_exp">期限{{ timestampParse(coupon.coupon_exp).format('MM/DD') }}</k-tag>
                  <p>{{ coupon.coupon_uid }}</p>
                  <p>{{ coupon.coupon_name }}</p>
                </k-button>
              </div>
            </section>
          </k-card>
          <div><k-icon icon="caret-right-square"></k-icon></div>
          <k-card class="choosed" bgColor="#34363c" noShadow>
            <ul class="choosed-list">
              <li v-for="coupon in couponChoosed" :key="coupon.key">
                <header>
                  <b>{{ coupon.coupon_name }}</b>
                  <div class="remove"><k-icon icon="x-lg" @click="handleRemoveCoupon(coupon.key)" /></div>
                </header>
                <main>
                  <div v-if="coupon.coupon_type === 'DISCOUNT'">折扣 {{ coupon.coupon_formula }}</div>
                  <div v-if="coupon.coupon_type === 'GIVEAWAY'" class="giveaway-choosed">
                    <k-button
                      v-for="product in coupon.coupon_product" :key="`${coupon.key}_p_${product}`"
                      :theme="coupon.items.find(p => p.product_id === product) ? 'primary' : 'secondary'"
                      :disabled="Boolean(coupon.items.find(p => p.product_id === product))"
                      @click="handleCouponProductAdd(coupon.key, product)"
                    >
                      {{ productDict[product].product_name_zh }}
                      {{ productDict[product].product_size ? productDict[product].product_size+'ml' : '' }}
                    </k-button>
                  </div>
                </main>
              </li>
            </ul>
          </k-card>
        </section>
      </div>

      <div v-if="currentStep === 2" class="order-check">
        <k-table :items="totalCartItems" :fields="fields" hover>
          <template #cell(product_name_zh)="{value, item}">
            <div class="product-info">
              <img :src="`https://chris-farrell.com.tw/img/product/${item.product_uid}/avatar.webp`">
              <div class="content">
                <h3>{{ value }} {{ item.product_size ? item.product_size+'ml' : '' }}</h3>
                <h4>{{ item.product_name_en }}</h4>
                <span v-if="item.coupon_name" class="coupon-tag">{{ item.coupon_name }}</span>
              </div>
            </div>
          </template>

          <template #cell(product_price)="{value, item}">
            <p v-if="!item.coupon_name">${{ value }}</p>
            <p v-else>贈</p>
          </template>

          <template #cell(amount)="{item}">
            <p v-if="!item.coupon_name">${{ item.product_price * item.product_quantity }}</p>
            <p v-else>贈</p>
          </template>
        </k-table>
        <k-divider>金額總計</k-divider>
        
        <table class="amount-info">
          <tr><td></td><td>小計</td><td>${{ orderInfo.amount }}</td></tr>
          <tr><td></td><td>運費</td><td>${{ orderInfo.amount < 1000 ? shippingType === 'HOME' ? 120 : 70 : 0 }}</td></tr>
          <tr><td></td><td>折扣</td><td>${{ orderInfo.amount - amountAfterDiscount }}</td></tr>
          <tr><td></td><td>總計</td><td>${{ amountAfterDiscount }}</td></tr>
        </table>
      </div>
    </Transition>
    <footer>
      <k-button v-if="currentStep === 1" @click="handleNextStep" :disabled="!itemChoosed.length" theme="info" block>下一步</k-button>
      <k-button v-if="currentStep === 2" @click="handlePrevStep" theme="secondary">上一步</k-button>
      <k-button v-if="currentStep === 2" theme="warning" @click="handleCreateOrder" :loading="loading">成立訂單</k-button>
    </footer>
    <form ref="CvsApi" style="display: none;" action="" method="POST"></form>
  </k-modal>
</template>

<script>
import KModal from '@/components/Modal';
import { KInput, KSelect, KRadioButton, KRadioGroup, KCheckbox } from '@/components/Form';
import KDivider from '@/components/Divider';
import KButton from '@/components/Button';
import KCard from '@/components/Card';
import KTag from '@/components/Tag';
import KTable from '@/components/Table';
import KTooltip from '@/components/Tooltip';
import { mapActions, mapGetters, mapState } from 'vuex';
import { timestampParse, fetchApi } from '@/utils';
import Decimal from 'decimal.js';
import moment from 'moment';

export default {
  props: {
    show: Boolean
  },
  mounted(){
    this.showSync = this.show
    if (this.$route.query) {
      const { fromcvs, CVSStoreID, CVSStoreName, CVSAddress, LogisticsSubType, ExtraData } = this.$route.query
      if (fromcvs) {
        this.cvsInfo = {
          CVSStoreID,
          CVSStoreName,
          CVSAddress,
          LogisticsSubType,
          ExtraData
        }
      }
    }
  },
  data() {
    return {
      priceClassDict: { ORIGIN: '原價', MEMBER: '會員價', ADDITIONAL: '加購價' },
      showSync: false,
      fields,
      currentStep: 1,
      loading: false,
      userName: '',
      userId: '',
      userPhone: '',
      userAddress: '',
      userEmail: '',
      receiptType: 'NORMAL',
      receiptUnicode: '',
      receiptCompany: '',
      cvsInfo: null,
      shippingType: 'HOME',
      paymentType: 'CREDIT',
      hadPaid: false,
      itemChoosed: [],
      couponChoosed: [],
      couponSearch: ''
    }
  },
  computed: {
    ...mapState('user', ['userList']),
    ...mapState('product', ['productList']),
    ...mapGetters('user', ['userDict']),
    ...mapGetters('product', ['productDict']),
    ...mapGetters('coupon', ['couponGroupBySuper']),
    ...mapGetters('orders', ['filterOrderWithUser']),
    userOptions() {
      if (this.userList.length) {
        return this.userList.map(user => ({ value: user.user_id, text: user.user_name })).filter(user => user.text)
      }
      return []
    },
    orderInfo() {
      const user = this.userId ? this.userDict[this.userId] : null
      return {
        amount: this.itemChoosed.reduce((acc, cur) => acc + cur.product_price * cur.product_quantity, 0),
        currentUser: user,
        orderList: this.userId ? this.filterOrderWithUser[this.userId] || [] : [],
        user_group: this.userId ? user.user_group : null,
      }
    },
    couponResult() {
      if (this.couponSearch) {
        return this.couponGroupBySuper.filter(c => c.coupon_name.includes(this.couponSearch) || c.coupon_uid.includes(this.couponSearch))
      }
      return this.couponGroupBySuper
    },
    couponRecommended() {
      return this.couponGroupBySuper.filter(c => {
        if (!c.coupon_condition.length) return 
        return !couponConditionJudgment.call(this, c)
      })
    },
    totalCartItems() {
      const products = this.itemChoosed.map(p => ({ ...this.productDict[p.product_id], ...p }))
      const giveaways = this.couponChoosed.reduce((acc, cur) => {
        if (cur.coupon_type === 'GIVEAWAY') {
          return [...acc, ...cur.items.map(p => ({ ...p, ...this.productDict[p.product_id], coupon_name: cur.coupon_name }))]
        }
        return acc
      }, [])
      return [...products, ...giveaways]
    },
    amountAfterDiscount() {
      const discountCoupon = this.couponChoosed.filter(c => c.coupon_type === 'DISCOUNT')
      // if (!discountCoupon.length) return this.orderInfo.amount
      return parseInt(discountCoupon.sort(function(a, b) {
        let couponA = a.coupon_formula
        let couponB = b.coupon_formula
        let index_a = '*-'.indexOf(couponA[0]), index_b = '*-'.indexOf(couponB[0])
        if (index_a === index_b) {
            if (a < b) return -1
            else if (a > b) return 1
            return 0
        } else return index_a-index_b
      }).reduce((acc, cur) => {
        const formula = cur.coupon_formula
        if (!formula) return acc
        return formulaExpressionCalc(formula, acc)
      }, new Decimal(this.orderInfo.amount)).toFixed(0))
    }
  },
  methods: {
    ...mapActions('orders', ['createOrder']),
    ...mapActions('user', ['registerUser']),
    timestampParse,
    handleUserChange(value) {
      this.userId = value
      const user = this.userDict[value]
      if (user) {
        this.userName = user.user_name
        this.userPhone = '0'+user.user_phone
        this.userAddress = `${user.user_city || ''}${user.user_urban_area || ''}${user.user_address || ''}`
        this.userEmail = user.user_email
      }
    },
    handleUserInput() {
      if (this.userId) {
        this.userId = ''
        this.userName = ''
        this.userPhone = ''
      }
    },
    handleShippingTypeChange(value) {
      this.shippingType = value    
    },
    handlePaymentTypeChange(value) {
      this.paymentType = value
    },
    handleReceiptTypeChange(value) {
      this.receiptType = value
    },
    handleCvsChoosed(type) {
      const formRef = this.$refs.CvsApi
      formRef.setAttribute('action', 'https://logistics.ecpay.com.tw/Express/map') //上線前修改成正式環境
      formRef.innerHTML = `
        <input name="ServerReplyURL" value="https://${window.location.hostname}/api/map-back/">
        <input name="MerchantID" value="${process.env.VUE_APP_ECPAY_LOGISTICS_C2C_MID}">
        <input name="LogisticsType" value="CVS">
        <input name="LogisticsSubType" value="${type}">
        <input name="ExtraData" value="${this.paymentType}">
      `//上線前修改成正常網域
      formRef.submit()
    },
    handleItemClick(product_id) {
      if (this.itemChoosed.find(i => i.product_id === product_id)) this.itemChoosed = this.itemChoosed.filter(i => i.product_id !== product_id)
      else {
        let product = this.productList.find(i => i.product_id === product_id)
        this.itemChoosed.push({ product_id, product_price: product.product_price.MEMBER, product_quantity: 1 })
      }
    },
    handleCouponAdd(coupon) {
      const couponProps = { ...JSON.parse(JSON.stringify(coupon)),  key: `c_${coupon.coupon_id}_${moment().unix()}`  }
      if (coupon.coupon_type !== 'DISCOUNT') {
        if (coupon.coupon_max_product_chosen == coupon.coupon_product.length) {
          couponProps.items = coupon.coupon_product.map(p => ({ product_id: p, product_quantity: 1 }))
        } else {
          couponProps.items = []
        }
      }
      this.couponChoosed.push(couponProps)
    },
    handleCouponProductAdd(key, productId) {
      const index = this.couponChoosed.map(c => c.key).indexOf(key)
      const coupon = this.couponChoosed[index]
      if (coupon.items.length < coupon.coupon_max_product_chosen) {
        coupon.items.push({ product_id: productId, product_quantity: 1 })
      } else {
        coupon.items = [...coupon.items.slice(1), { product_id: productId, product_quantity: 1 }]
      }
      this.couponChoosed.splice(index, 1, coupon)
    },
    handleRemoveCoupon(key) {
      this.couponChoosed = this.couponChoosed.filter(c => c.key !== key)
    },
    handleRemoveProduct(product_id) {
      this.itemChoosed = this.itemChoosed.filter(i => i.product_id !== product_id)
    },
    handleNextStep() {
      this.currentStep += 1
    },
    handlePrevStep() {
      this.currentStep -= 1
    },
    async genOrderPayload() {
      const orderUidRes = await fetchApi('/api/genUid/', {});
      const orderProducts = this.itemChoosed.map(i => ({
        product_id: i.product_id, product_quantity: i.product_quantity, product_price: i.product_price
      }))
      const orderCouponUsed = this.couponChoosed.map(c => {
        const couponInfo = {
          coupon_id: c.coupon_id,
          coupon_uid: c.coupon_uid,
          coupon_name: c.coupon_name,
          coupon_type: c.coupon_type,
        }
        if (c.coupon_type === 'GIVEAWAY') couponInfo.coupon_items = c.items.map(i => ({ product_uid: this.productDict[i.product_id].product_uid, product_quantity: i.product_quantity }))
        return couponInfo
      })
      return {
        orders_id: orderUidRes.id,
        user_id: this.userId,
        order_phone: this.userPhone,
        order_pay_type: this.paymentType,
        order_receipt_type: this.receiptType,
        order_receipt_unicode: this.receiptUnicode || undefined,
        order_receipt_company: this.receiptCompany || undefined,
        order_logistics_info: JSON.stringify({
          AllPayLogisticsID: "",
          BookingNote: "",
          LogisticsSubType: "TCAT",
          LogisticsType: "COD",
          ReceiverAddress: this.userAddress,
          ReceiverCellPhone: this.userPhone,
          ReceiverEmail: this.userEmail,
          ReceiverName: this.userName
        }),
        order_logistics_type: "HOME",
        order_logistics_subtype: "TCAT",
        order_logistics_state: '[]',
        order_amount: this.amountAfterDiscount,
        order_products: JSON.stringify(orderProducts),
        order_create_at: 'PHP_SERVER_TIMESTAMP',
        order_coupon_used: JSON.stringify(orderCouponUsed),
        order_voucher_used: undefined,
        order_payment_status: this.hadPaid ? 'SUCCESS' : 'HANDLING'
      }
    },
    async handleCreateOrder() {
      this.loading = true
      const { userName, userPhone, userEmail, userAddress, shippingType } = this
      const logisticsInfoData = { userName, userPhone, userAddress, shippingType }
      //若無會員資格先註冊會員
      if (!this.userId) {
        let userInfo = {
          user_name: userName,
          user_phone: userPhone,
          user_email: userEmail,
          user_first_purchase: '0'
        }
        if (userAddress) {
          const addressInfo = parseAddress(userAddress)
          if (!addressInfo) return this.$message.error('無法解析地址，請檢查地址是否正確')
          userInfo.user_city = addressInfo.city
          userInfo.user_urban_area = addressInfo.district
          userInfo.user_address = addressInfo.detail
        }
        const registerRes = await this.registerUser(userInfo)
      }

      let order = await this.genOrderPayload()
      if (this.shippingType==='CVS') {//超商取貨付款
        logisticsInfoData.cvsInfo = this.cvsInfo
        const logisticRes = await createLogisticOrder(logisticsInfoData, this.paymentType, this.amountAfterDiscount)
        if (logisticRes.state === '1') {
          order = {
            ...order,
            order_logistics_info: JSON.stringify({
              ...logisticRes.data,
            }),
            order_logistics_id: logisticRes.data.MerchantTradeNo,
            order_logistics_type: "CVS",
            order_logistics_subtype: logisticRes.data.LogisticsSubType,
            order_logistics_cvs_info: JSON.stringify(this.cvsInfo),
          }
        } else {
          this.$message.error('物流系統出現問題，請聯繫客服')
        }
      }
      if (this.shippingType === 'HOME' && this.paymentType === 'CREDIT') {//宅配信用卡
        const logisticRes = await createLogisticOrder(logisticsInfoData, this.paymentType, this.amountAfterDiscount)
        if (logisticRes.state === '1') {
          order = {
            ...order,
            order_logistics_info: JSON.stringify({
              ...logisticRes.data,
            }),
            order_logistics_id: logisticRes.data.MerchantTradeNo,
            order_logistics_type: "HOME",
            order_logistics_subtype: logisticRes.data.LogisticsSubType,
          }
        } else {
          this.$message.error('物流系統出現問題，請聯繫客服')
        }        
      }
      // 建立訂單
      const orderCreateRes = await this.createOrder(order)
      //寄發訂單通知 Email
      //刪除使用過的優惠券
      this.handleClear()
      this.showSync = false
    },
    handleClear() {
      this.itemChoosed = []
      this.couponChoosed = []
      this.receiptType = 'NORMAL'
      this.receiptUnicode = ''
      this.receiptCompany = ''
      this.userId = ''
      this.userName = ''
      this.userPhone = ''
      this.userEmail = ''
      this.userAddress = ''
      this.shippingType = 'HOME'
      this.cvsInfo = null
      this.loading = false
    }
  },
  watch: {
    show(newVal) {
      if (newVal !== this.showSync) this.showSync = newVal
    },
    showSync(newVal) {
      if (newVal !== this.show) this.$emit('update:show', newVal)
    }
  },
  components: {
    KModal, KInput, KSelect, KDivider, KButton, KRadioButton, KRadioGroup, KCard, KTag, KTable, KCheckbox, KTooltip
  }
}

function couponConditionJudgment(coupon) {
  const { coupon_condition } = coupon
  const invalidCondition = coupon_condition.map(({ property, operator, value, msg }) => {
    const finalKey = property.split('.').slice(-1)[0]
    const targetColumnValue = this.orderInfo[finalKey]
    switch (operator) {
      case '==': return targetColumnValue === value ? null : msg
      case '!=': return targetColumnValue !== value ? null : msg
      case '>': return targetColumnValue > value ? null : msg
      case '<': return targetColumnValue < value ? null : msg
      case '>=': return targetColumnValue >= value ? null : msg
      case '<=': return targetColumnValue <= value ? null : msg
      case 'in': return targetColumnValue.includes(value) ? null : msg
      case 'exist': return targetColumnValue ? null : msg
      case 'haveLength': return targetColumnValue && targetColumnValue.length ? null : msg
    }
  }).filter(e => e)
  return invalidCondition.length ? invalidCondition : null
}

function parseAddress(address) {
  const addressRegex = /^(?<city>[^縣市]+[縣市]|桃園市)(?<district>[^鄉鎮市區]+[鄉鎮市區])(?<detail>.+)$/;
  const matches = address.match(addressRegex);
  if (matches) {
    const city = matches.groups.city;
    const district = matches.groups.district;
    const detail = matches.groups.detail;
    return { city, district, detail };
  } else {
    console.log("無法解析地址。");
  }
}

async function createLogisticOrder(data, payType='CREDIT', price) {
  try {
    const API = "/api/ecpay/"
    const payload = {
      MerchantTradeDate: moment().format('yyyy/MM/DD HH:mm:ss'),
      LogisticsType: data.shippingType,
      LogisticsSubType: data.shippingType === 'CVS' ? data.cvsInfo.LogisticsSubType : 'TCAT',
      ReceiverStoreID: data.cvsInfo ? data.cvsInfo.CVSStoreID : undefined,
      ReturnStoreID: data.cvsInfo ? '238924': undefined, //7-11 指定退貨門市為百祐門市
      IsCollection: data.cvsInfo ? payType === 'COD' ? 'Y' : 'N' : undefined,
      GoodsAmount:  price,
      GoodsName: '保養品',
      GoodsWeight: 1.245,
      SenderName: '林惠鈴',
      SenderCellPhone: '0982123505',
      SenderZipCode: '402251',
      SenderAddress: '台中市南區美村路二段186號10樓之2',
      ReceiverName: data.userName,
      ReceiverCellPhone: data.userPhone,
      ReceiverZipCode: '237021',
      ReceiverAddress: data.userAddress,
      Temperature: '0001',
      Distance: '00',
      Specification: '0001',
      ScheduledPickupTime: '4',
      ScheduledDeliveryTime: '4',
      TradeDesc: 'Chris Farrell Taiwan 保養品',
      ServerReplyURL: 'https://api.chris-farrell.com.tw/index.php/order/ecpay'
    }
    const res = await fetchApi(API, { api:'/Express/Create', shippingInfo: { ...payload } })
    return res
  } catch (error) {
    console.log(error);
    throw error
  }
}

const fields = [
  { key: 'product_uid', fieldIndex: 'product_uid', label: '貨號' },
  { key: 'product_name_zh', fieldIndex: 'product_name_zh', label: '產品' },
  { key: 'product_price', fieldIndex: 'product_price', label: '價格'},
  { key: 'product_quantity', fieldIndex: 'product_quantity', label: '數量'},
  { key: 'amount', fieldIndex: 'amount', label: '總計'}
]

function formulaExpressionCalc(expression, xValue, inner=false) {
  let formatExpression = expression.replace(/x/g, xValue)
  if (formatExpression.includes('(')) {
    formatExpression = formatExpression.replace(/\(([^()]*)\)/g, (_, innerExpression) => `${formulaExpressionCalc(innerExpression, xValue, true)}`)
  }

  let result = xValue
  let parts = formatExpression.split(' ')
  if (inner) {
    result = parts[0]
    parts = parts.slice(1)
  }
  
  for (let i = 0; i < parts.length; i+=2) {
    const operator = parts[i]
    const operand = Number(parts[i+1])
    switch (operator) {
      case '+': result += operand; break;
      case '-': result -= operand; break;
      case '*': result *= operand; break;
      case '/': result /= operand; break;
      case '//': result = Math.floor(result / operand); break;
    }
  }
  return result
}

</script>
