<template>
  <k-container class="view-event">
    <header class="pageTitle">
      <h1>活動管理</h1>
      <k-button theme="info" icon="plus-lg" @click="handleShowEventCreateModal">建立活動</k-button>
    </header>
    <k-card>
      <k-table :items="eventList" :fields="TableFields" hover>
        <template #cell(event_banner)="{ value }">
          <img :src="`https://www.chris-farrell.com.tw/img/${value}`" alt="event_banner">
        </template>

        <template #cell(event_url)="{ value }">
          <span class="event-url">/{{ value }}<k-icon @click="toEvent(value)" icon="box-arrow-up-right"></k-icon></span>
        </template>

        <template #cell(event_date_start)="{ value, item }">
          <p class="start-and-end-date" v-if="value">{{ timestampParse(value).format('YYYY-MM-DD') }} ~ </p>
          <p class="start-and-end-date" v-else>未設定 ~ </p>
          <p class="start-and-end-date" v-if="item.event_date_end">{{ timestampParse(item.event_date_end).format('YYYY-MM-DD') }}</p>
          <p class="start-and-end-date" v-else>未設定</p>
        </template>

        <template #cell(action)="{ item }">
          <div class="button-group">
            <k-button varient="ghost" theme="info" @click="handleEditEvent(item)">編輯</k-button>
            <k-button varient="ghost" theme="danger" @click="handleDeleteEvent(item)">刪除</k-button>
          </div>
        </template>
      </k-table>
    </k-card>

    <k-modal class="event-create-modal" :show.sync="showEventCreateModal" :title="editMode==='CREATE' ? '建立活動' : '修改活動'" :width="800" hideFooter>
      <section class="avatar-choosed">
        <k-dropdown placement="rightBottom" ref="avatar-dropdown" preventClickAway>
          <k-button theme="secondary">選擇活動封面</k-button>
          <template #overlay>
            <MediaSelector
              @close="()=>$refs['avatar-dropdown'].close()" 
              :mode.sync="mediaSelctorInfo.mode"
              :search.sync="mediaSelctorInfo.search"
              :route.sync="mediaSelctorInfo.route"
              :choosedCallback="handleBannerChoose"
            />
          </template>
        </k-dropdown>
        <div v-if="eventInfo.event_banner" class="image-wrapper">
          <img :src="eventInfo.event_banner">
        </div>
        <div class="hint" v-else>請點選『選擇活動封面』載入/更換活動封面</div>
      </section>
      <section>
        <k-input v-model="eventInfo.event_name" label="活動名稱" placeholder="請輸入活動名稱" :required="true" />
        <k-input v-model="eventInfo.event_url" label="活動 URL" placeholder="請輸入活動 URL" addonBefore="/" :required="true" />
        <k-textarea v-model="eventInfo.event_summary" label="活動描述" placeholder="請輸入活動描述" :resize="false" :required="true" />
        <k-date-picker v-model="eventInfo.event_date_start"  label="活動起始日期" placeholder="請選擇活動起始日期" :required="true" />
        <k-date-picker v-model="eventInfo.event_date_end"  label="活動結束日期" placeholder="請選擇活動結束日期" :required="true" />
        <k-radio-group
          label="活動類型"
          v-model="eventInfo.event_type"
          radioType="button"
          varient="primary"
          defaultValue="PRODUCT"
          @onChange="handleEventTypeChange"
        >
          <k-radio-button theme="info" value="PRODUCT">產品相關</k-radio-button>
          <k-radio-button theme="info" value="CONTENT">一般文章</k-radio-button>
        </k-radio-group>
        <k-divider>活動內容 - {{ eventInfo.event_type === 'PRODUCT' ? '產品選擇' : '活動內文' }}</k-divider>
        <div v-if="eventInfo.event_type === 'PRODUCT'" class="product-form">
          <ProductPicker v-model="eventInfo.event_linked_product_id" />
        </div>
        <div v-else class="content-form">
          <k-textarea lebel="活動內文" v-model="eventInfo.evnet_content" placeholder="請輸入活動內文" :resize="false" height="300"></k-textarea>
        </div>
      </section>
      <k-divider>優惠券鏈接</k-divider>
      <section class="coupon-setting">
        <k-card outline="secondary" noShadow>
          <ul>
            <li v-for="coupon in eventInfo.coupon_id" :key="`event-coupon-${coupon.key}`">
              <div class="head">
                <k-tooltip title="優惠券"><div class="icon">
                  <k-icon :icon="coupon.coupon_id
                    ? couponDict[coupon.coupon_id].coupon_type === 'DISCOUNT' ? 'coin' : 'gift'
                    : 'ticket-perforated'
                  " />
                </div></k-tooltip>
                <k-select
                  theme="secondary"
                  placeholder="選擇優惠券"
                  v-model="coupon.coupon_id"
                  :options="couponOptions"
                />
                <div class="icon close" @click="handleUnlinkCoupon(coupon.key)"><k-icon icon="x-lg"/></div>
              </div>
              <div class="switch">
                <k-switch
                  :value="coupon.show"
                  @input="bool => handleShowCouponInProduct(coupon.key, bool)"
                  theme="info"
                />
                <span>在產品頁顯示優惠券與贈品內容</span>
              </div>
              <div v-if="coupon.coupon_id" class="template-summary">{{ couponDict[coupon.coupon_id].coupon_desc }}</div>
            </li>
            <li class="add">
              <k-button theme="secondary" block icon="chat-right-text" @click="handleAddCoupon">
                新增鏈接優惠券 <k-icon icon="plus-lg" />
              </k-button>
            </li>
          </ul>
        </k-card>
      </section>
      <k-button class="submit-button" theme="info" @click="handleCreateEvent" block>建立</k-button>
    </k-modal>
  </k-container>
</template>

<script>
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import KTable from '@/components/Table'
import KTag from '@/components/Tag'
import KModal from '@/components/Modal'
import MediaSelector from '@/views/blog/components/MediaSelector'
import KDropdown from '@/components/Dropdown'
import KDivider from '@/components/Divider'
import ProductPicker from '@/components/Gather/ProductPicker'
import KTooltip from '@/components/Tooltip'
import { KForm, KInput, KDatePicker, KRadioGroup, KRadioButton, KTextarea, KSelect, KSwitch } from '@/components/Form'
import { timestampParse, gen8ID } from '@/utils'
import { mapActions, mapGetters, mapState } from 'vuex'

const eventInfo = {
  event_name: '',
  event_url: '',
  event_banner: '',
  event_date_start: '',
  event_date_end: '',
  event_type: 'PRODUCT',
  event_summary: '',
  event_content: '',
  event_linked_product_id: [],
  event_link_product_tag: '',
  coupon_id: []
}

export default {
  async mounted() {
    if (!this.$store.state.event.eventList.length) await this.$store.dispatch('event/query')
  },
  data() {
    return {
      COUPON_NAME_DICT,
      TableFields,
      showEventCreateModal: false,
      editMode: 'CREATE',
      editEventId: '',
      eventBanner: '',
      newEventType: 'PRODUCT',
      eventInfo: JSON.parse(JSON.stringify(eventInfo)) || {},
      mediaSelctorInfo: {
        mode: 'ROUTE',
        search: '',
        route: '/'
      }
    }
  },
  computed: {
    ...mapState('event', ['eventList']),
    ...mapState('coupon', ['couponList']),
    ...mapGetters('coupon', ['couponGroupBySuper', 'couponDict']),
    couponOptions() {
      if (this.couponList.length) {
        return this.couponList.map(c => {
          const dateRange = `${c.coupon_start ? timestampParse(c.coupon_start).format('YYYY-MM-DD') : '未設定'} ~ ${c.coupon_exp ? timestampParse(c.coupon_exp).format('YYYY-MM-DD') : '未設定'}`
          return {
            text: `[${COUPON_NAME_DICT[c.coupon_type]}] ${c.coupon_name} <${dateRange}>`,
            value: c.coupon_id
          }
        })
      }
      return []
    }
  },
  methods: {
    ...mapActions('event', ['add', 'update']),
    timestampParse,
    async handleCreateEvent() {
      const { event_name, event_banner, event_url, event_date_start, event_date_end, event_summary, event_content, event_linked_product_id, event_link_product_tag, coupon_id, event_type } = this.eventInfo
      if (!event_banner) return this.$message.error('請選擇活動封面')
      if (!event_name) return this.$message.error('請輸入活動名稱')
      if (!event_date_start) return this.$message.error('請選擇活動起始日期')
      if (!event_date_end) return this.$message.error('請選擇活動結束日期')
      if (!event_summary) return this.$message.error('請輸入活動描述')

      if (this.editMode === 'CREATE') {
        const res = await this.add({
          event_name,
          event_banner: event_banner.replace('https://www.chris-farrell.com.tw/img', ''),
          event_date_start: event_date_start.unix(),
          event_date_end: event_date_end.unix(),
          event_url,
          event_summary,
          event_type,
          event_link_product_tag: event_link_product_tag || undefined,
          event_content: event_content || undefined,
          event_linked_product_id: event_type === 'PRODUCT' ? event_linked_product_id.join(',') : undefined,
          coupon_id: coupon_id.length ? coupon_id.map(c => c.coupon_id).join(',') : undefined
        })
      }
      // else {
      //   const res = await this.update({
      //     event_name,
      //     event_banner: event_banner.replace('https://www.chris-farrell.com.tw/img', ''),
      //     event_date_start: event_date_start.unix(),
      //     event_date_end: event_date_end.unix(),
      //     event_url,
      //     event_summary,
      //     event_type,
      //     event_link_product_tag: event_link_product_tag || undefined,
      //     event_content: event_content || undefined,
      //     event_linked_product_id: event_type === 'PRODUCT' ? event_linked_product_id.join(',') : undefined,
      //     coupon_id: coupon_id.length ? coupon_id.map(c => c.coupon_id).join(',') : undefined
      //   })
      // }
      
      if (res.status === 200) {
        this.$message.success('建立成功')
        this.showEventCreateModal = false
        this.eventInfo = JSON.parse(JSON.stringify(eventInfo))
      }
    },
    handleEditEvent(item) {
      this.editMode = 'EDIT'
      this.editEventId = item.event_id
      this.eventInfo = {
        ...JSON.parse(JSON.stringify(item)),
        event_date_start: timestampParse(item.event_date_start),
        event_date_end: timestampParse(item.event_date_end),
        event_linked_product_id: item.event_linked_product_id ? item.event_linked_product_id.split(',') : [],
        coupon_id: item.coupon_id.map(c => ({
          key: gen8ID(),
          coupon_id: c,
          show: true
        }))
      }
      this.showEventCreateModal = true
    },
    handleDeleteEvent(item) {
      console.log('delete event', item);
    },
    toEvent(url) {
      window.open(`https://www.chris-farrell.com.tw/event/${url}`, '_blank')
    },
    handleShowEventCreateModal() {
      this.showEventCreateModal = true
      this.eventInfo = JSON.parse(JSON.stringify(eventInfo))
    },
    handleBannerChoose(file) {
      this.eventInfo.event_banner = `https://www.chris-farrell.com.tw/img${file.parentRoute}/${file.file}`
    },
    handleEventTypeChange(type) {
      this.eventInfo.event_type = type
    },
    handleShowCouponInProduct(key, bool) {
      const index = this.eventInfo.coupon_id.map(c => c.key).indexOf(key)
      this.eventInfo.coupon_id[index].show = bool
    },
    handleAddCoupon() {
      this.eventInfo.coupon_id = [{
        key: gen8ID(),
        coupon_id: '',
        show: true
      }, ...this.eventInfo.coupon_id]
    },
    handleUnlinkCoupon(key) {
      this.eventInfo.coupon_id = this.eventInfo.coupon_id.filter(c => c.key !== key)
    }
  },
  components: {
    KButton, KCard, KTable, KTag, KModal,  KForm, KInput, KDatePicker, MediaSelector, KDropdown, KDivider, KRadioGroup, KRadioButton,
    KTextarea, KTooltip, KSelect, KSwitch,
    ProductPicker
  }
}

const TableFields = [
  { key: 'key_id', fieldIndex: 'key_id', label: 'NO.' },
  { key: 'event_banner', fieldIndex: 'event_banner', label: '活動 Banner'},
  { key: 'event_name', fieldIndex: 'event_name', label: '活動名稱', searchable: true },
  { key: 'event_url', fieldIndex: 'event_url', label: '活動 URL', searchable: true }, 
  { key: 'event_date_start', fieldIndex: 'event_date_start', label: '起迄時間'},
  { key: 'action', fieldIndex: 'action', label: '' }
]

const COUPON_NAME_DICT = {
  DISCOUNT: '折扣',
  GIVEAWAY: '贈品',
  BONUS: '買N送M'
}
</script>
