<template>
  <k-card class="email-reply-component" outline="light" ref="form">
    <template #header>
      回覆：{{ targetEmail.email_from_name || targetEmail.email_from }} &lt;{{ targetEmail.email_from_address }}&gt;
    </template>
    <k-form :questionConfig.sync="replyForm" :defaultValues="computedTargetEmail" @submit="handleSubmit">
      <template #after(email_content)>
        <k-card class="last-content" header="顯示過去信件內容" outline="secondary" collapsible>
          <span>{{ targetEmail.email_from_name }} &lt;{{ targetEmail.email_from_address }}&gt; 於 2021年9月14日 週二 下午1:35寫道：</span>
          <blockquote v-html="targetEmail.email_content" />
        </k-card>
      </template>
    </k-form>
  </k-card>
</template>

<script>
import KCard from '@/components/Card'
import { KForm } from '@/components/Form'
import { fetchApi } from '@/utils'
import { mapState } from 'vuex'

export default {
  props: {
    targetEmail: Object
  },
  mounted() {
    window.scrollTo({
      left: 0,
      top: this.$refs.form.$root.$el.offsetHeight,
      behavior: 'smooth'
    })
  },
  data() {
    return {
      replyForm
    }
  },
  computed: {
    ...mapState('login', ['currentUser']),
    computedTargetEmail() {
      const { email_from_address, email_title } = this.targetEmail
      return { email_target: email_from_address, email_title: `Re: ${email_title.replace('Re:', '')} | Kirsty Taiwan` }
    }
  },
  methods: {
    async handleSubmit({ values }) {
      const API = '/api/mail/'
      console.log(values);
      const response = await fetchApi(API, { data: {
        to_address: this.targetEmail.email_from_address,
        subject: values.email_title,
        body: values.email_content
      }})
      console.log(response);
      const res = await this.add({
        ...values,
        email_target: this.targetEmail.email_from_address,
        email_dealer: this.currentUser.admin_id
      })
      if (res.status === 200) {
        this.$message.success('已寄出信件')
        this.$router.push('/email/inbox')
      }
    }
  },
  components: {
    KCard, KForm
  }
}

const replyForm = [
  {
    questionType: 'k-input',
    name: 'email_target',
    label: "收件人",
    theme: "light",
    required: true,
  },
  {
    questionType: 'k-input',
    name: 'email_title',
    label: "信件主旨",
    theme: "light",
    required: true,
  },
  {
    questionType: 'k-textarea',
    name: 'email_content',
    label: "信件內文",
    theme: 'light',
    height: 240,
    required: true,
  },
  {
    questionType: 'k-btn',
    name: 'submit',
    label: '寄出信件',
    theme: "primary",
    type: "submit"
  }
]
</script>
