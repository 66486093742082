<template>
  <div :class="{'component-float-dropdown': true, show}" :style="{left: axisX+'px', top: axisY+'px'}">
    <k-dropdown ref="dropdown" placement="leftBottom">
      <div></div>
      <k-menu
        slot="overlay"
      >
        <k-menu-item @click="methods.rename" v-if="files.length < 2 && dirs.length < 2">
          <k-icon
            icon="pencil-square"
            theme="white"
          />
          重新命名
        </k-menu-item>
        <k-menu-item
        v-if="files.length === 1"
          v-copy="`http://www.chris-farrell.com.tw/img${files[0].parentRoute}/${files[0].file}`"
          v-copy:success="() => {$message.success('已將檔案連結複製到剪貼簿');hide();}"
        >
          <k-icon
            icon="link"
            theme="white"
          />
          取得連結
        </k-menu-item>
        <k-menu-item @click="methods.move">
          <k-icon
            icon="folder-symlink-fill"
            theme="white"
          />
          移至
        </k-menu-item>
        <k-menu-divider></k-menu-divider>
        <k-menu-item>
          <k-icon
            icon="info-circle"
            theme="info"
          />
          查看詳細資料
        </k-menu-item>
        <k-menu-item>
          <k-icon
            icon="cloud-download"
            theme="info"
          />
          下載
        </k-menu-item>
        <k-menu-divider></k-menu-divider>
        <k-menu-item @click="methods.delete">
          <k-icon
            icon="trash3-fill"
            theme="danger"
          />
          刪除
        </k-menu-item>
      </k-menu>
    </k-dropdown>
  </div>
</template>

<script>
import KDropdown from '@/components/Dropdown'
import { KMenu, KMenuItem, KMenuDivider } from '@/components/Menu'

export default {
  props: {
    files: {
      default: () => [],
      type: Array
    },
    dirs: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      show: false,
      axisX: 0,
      axisY: 0,
      methods: {}
    }
  },
  methods: {
    active({ axisX, axisY, methods }) {
      console.log('active');
      this.axisX = axisX
      this.axisY = axisY
      this.show = true
      this.methods = methods
      this.$refs.dropdown.active()
    },
    hide() {
      this.show = false
    },
    getLink() {
      const file = this.files[0]

    }
  },
  components: {
    KDropdown, KMenu, KMenuItem, KMenuDivider
  }
}
</script>
