<template>
  <div :class="{ 'k-coder': true, 'invalid': isInvalid }">
    <label v-if="label" :for="uid"><span v-if="required && !hideRequiredMark">＊ </span>{{ label }}</label>
    <input v-if="required" style="display: none;" type="text" :value="value" @invalid="handleInvalid" required>
    <div :class="classes">
      <textarea ref="editor" :id="uid"></textarea>
    </div>
    <span v-if="isInvalid" class="extra invalid"><k-icon icon="exclamation-triangle" theme="danger" />本欄位為必填，請勿留空</span>
  </div>
</template>

<script>
require("codemirror/mode/xml/xml");
require("codemirror/addon/edit/closebrackets");
require("codemirror/theme/material-palenight.css");
require("codemirror/addon/hint/show-hint");
require("codemirror/addon/hint/show-hint.css");
require("codemirror/addon/hint/xml-hint");
const CodeMirror = require("codemirror");
import formMixin from '@/mixin/form'
import { gen8ID } from '@/utils'
import { completeAfter, completeIfInTag, completeIfAfterLt, tags } from '../../utils/codeMirror/autocomplete'

export default {
  mixins: [formMixin],
  props: {
    value: String,
    theme: String,
    label: String,
    required: Boolean,
    hideRequiredMark: Boolean,
    defaultValue: String,
    width: [String, Number],
    height: [String, Number],
  },
  data() {
    return {
      uid: '',
      codeMirror: undefined
    }
  },
  mounted() {
    this.uid = `k-coder-${gen8ID()}`
    this.codeMirror = CodeMirror.fromTextArea(this.$refs.editor, {
      mode:"xml",
      tabSize: 2,
      theme: "material-palenight",
      lineNumbers: true,
      lineWrapping: true,
      extraKeys: {
          "'<'": completeAfter,
          "'/'": completeIfAfterLt,
          "' '": completeIfInTag,
          "'='": completeIfInTag,
        },
        hintOptions: {schemaInfo: tags}
    })
    this.codeMirror.setSize(Number(this.width) || null, Number(this.height) || null)
    this.codeMirror.on('change', inst => {
      this.handleValid()
      this.$emit('input', inst.getValue())
    })
    if (this.defaultValue) this.codeMirror.doc.setValue(this.defaultValue)
  },
  computed: {
    classes() {
      return {
        'k-coder-wrapper': true,
        [`theme--${this.theme}`]: !!this.theme
      }
    }
  },
  watch: {
    defaultValue(newVal) {
      if (newVal) this.codeMirror.doc.setValue(newVal)
    }
  }
}
</script>
