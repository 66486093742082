<template>
  <k-container>
    <header class="pageTitle">
      <h1>所有問卷表單</h1>
      <div>
        <k-button theme="info">
          建立問卷
        </k-button>
      </div>
    </header>
  </k-container>
</template>

<script>
import KButton from '@/components/Button'

export default {
  data() {
    return {

    }
  },
  methods: {

  },
  components: {
    KButton
  }
}
</script>
