import { timestampParse, clearData } from '@/utils'
import { query, create, update, remove } from './utils/basicService'
import { genBasicModel } from './utils/basicAction'
import database from '@/utils/database'

const MODEL_NAME = 'post'
const { basicMutation, basicAction } = genBasicModel(
  MODEL_NAME,
  query(MODEL_NAME, { order: 'post_publishAt', callback: data => ({...data, post_isPublished: data.post_isPublished === '1'}) }),
  create(MODEL_NAME, true),
  update(MODEL_NAME),
  remove(MODEL_NAME))

export default {
  namespaced: true,
  state: {
    postList: [],
    categoryList: [],
    currentPostId: null
  },
  mutations: {
    ...basicMutation,
    addCategory(state, payload) {
      state.categoryList = [{ ...payload }, ...state.categoryList]
    },
    updateCategory(state, payload) {
      const { id, ...data } = payload
      const updateCleanData = clearData(data)
      const index = state.categoryList.map(c => c.key_id).indexOf(id)
      state.categoryList.splice(index, 1, { ...state.categoryList[index], ...updateCleanData })
    },
    deleteCategory(state, payload) {
      const index = state.categoryList.map(c => c.id).indexOf(payload)
      state.categoryList.splice(index, 1)
    },
    changeCurrentPostId(state, payload) {
      state.currentPostId = payload
    }
  },
  actions: {
    ...basicAction,
    async unlinkMedia(_, payload) {
      await database.table('file_use').where('post_id', '=', payload).delete()
    },
    async queryCategories({ commit }) {
      const { data, exists } = await database.table('category')
        .join('file_use', 'category_id').join('file', 'file_id', 'file_use').orderBy('category.category_id', 'ASC').get()
      if (exists) commit('save', { name: 'categoryList', data })
    },
    async createCategory({ commit }, payload) {
      const response = await database.table('category').set(payload)
      if (response.id) commit('addCategory', { key_id: response.id, ...payload })
      return response

    },
    async updateCategory({ commit }, payload) {
      const { id, ...data } = payload
      const clearData = Object.entries(data).map(([key, val]) => (val && { [key]: val }))
      var formData = Object.assign({}, ...clearData)
      const response = await database.table('category').where('category_id', '=', id).update(formData)
      if (response.status === 200) commit('updateCategory', payload)
      return response
    },
    async deleteCategory({ dispatch, commit }, payload) {
      const { id, file_id } = payload
      const response = await database.table('category').where('category_id', '=', id).delete()
      const unlinkResponse = await dispatch('media/unlinkFileUse', { 
        useType: 'category',
        useTypeId: id,
        fileIds: file_id,
        keepFile: false
      }, { root: true })
      if (unlinkResponse.status===200 && response.status===200) commit('deleteCategory', id)
    },
    changeCurrentPostId({ commit }, id) {
      commit('changeCurrentPostId', id)
    },
    async createStructureData({ commit }, payload) {
      const { id, ...formData } = payload
      const response = await database.table('post').where('post_id', '=', id).update(formData)
      if (response.status === 200) commit('update', { name: 'postList', data: { id, ...formData } })
      return response
    }
  },
  getters: {
    currentPost: ({ currentPostId, postList }) => {
      if (currentPostId) {
        const postIndex = postList.map(p => p.id).indexOf(currentPostId)
        const post = postList[postIndex]
        return { ...post, banner: post.bannerPath, publishAt: timestampParse(post.publishAt)  }
      }
      return {}
    },
    filterPostByState: ({ postList }) => {
      if (postList.length) {
        return postList.reduce((acc, p) => {
          if (p.post_isArchive) acc['archive'].push(p)
          else acc[p.post_isPublished  ? 'published' : 'unpublish'].push(p)
          return acc
        }, { published: [],  unpublish: [], archive: [] })
      }
      return { published: [],  unpublish: [], archive: [] }
    },
    filterPostByCategory: ({ categoryList, postList }) => {
      const categoryIndex = categoryList.reduce((acc, c, index) => ({ ...acc, [c.category_id]: index }), {})
      return postList.reduce( (acc, { post_id, post_title, category_id }) => {
        acc[categoryIndex[category_id]].posts.push({ post_id, post_title })
        return acc
      }, categoryList.map(c => ({ ...c, posts: [] })))
    } 
  }
}
