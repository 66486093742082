<template>
  <div class="operation-orders package">
    <h4>{{ operationName }}</h4>
    <ul>
      <li v-for="order in orderCache" :key="`op-package-order-${order.orders_id}`">
        <span>{{ order.orders_id }}</span>
        <k-tag :theme="logisticsDict[order.order_logistics_subtype].theme">
          {{ logisticsDict[order.order_logistics_subtype].name }} | {{ order.order_logistics_info.BookingNote || order.order_logistics_id }}
        </k-tag>
      </li>
    </ul>

    <k-button @click="handleSubmit" :theme="orderCache.length ? 'info' : 'error'" :disabled="!orderCache.length" block>
      {{ orderCache.length ? `確認完成${operationName}` : `無可確認${operationName}訂單` }}
    </k-button>
  </div>
</template>

<script>
import KTag from '@/components/Tag'
import KButton from '@/components/Button'

export default {
  props: {
    orders: Array,
    logisticsDict: Object,
    operation: String
  },
  data() {
    return {
      orderCache: [],
      orderIncompatible: []
    }
  },
  mounted() {
    if (['CancelReceipt', 'ReturnGoods'].includes(this.operation)) {
      this.orderCache = this.orders.filter(o => !o.order_log_operations.includes(this.operation))
      this.orderIncompatible = this.orders.filter(o => o.order_log_operations.includes(this.operation))
    } else {
      this.orderCache = this.orders
        .filter(o => !o.order_log_operations.includes(this.operation) && o.order_pay_type === 'CREDIT' && o.order_payment_status === 'SUCCESS')
      this.orderIncompatible = this.orders
        .filter(o => o.order_log_operations.includes(this.operation) ||  o.order_pay_type !== 'CREDIT' || o.order_payment_status !== 'SUCCESS')
    }
  },
  computed: {
    operationName() {
      switch (this.operation) {
        case 'CancelReceipt': return '發票作廢'
        case 'ReturnGoods': return '貨品歸還'
        case 'ReturnCash': return '金流返還'
      }
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.orderCache)
    }
  },
  watch: {
    operation() {
      if (['CancelReceipt', 'ReturnGoods'].includes(this.operation)) {
        this.orderCache = this.orders.filter(o => !o.order_log_operations.includes(this.operation))
        this.orderIncompatible = this.orders.filter(o => o.order_log_operations.includes(this.operation))
      } else {
        this.orderCache = this.orders
          .filter(o => !o.order_log_operations.includes(this.operation) && o.order_pay_type === 'CREDIT' && o.order_payment_status === 'SUCCESS')
        this.orderIncompatible = this.orders
          .filter(o => o.order_log_operations.includes(this.operation) ||  o.order_pay_type !== 'CREDIT' || o.order_payment_status !== 'SUCCESS')
      }
    }
  },
  components: {
    KButton, KTag
  }
}
</script>
