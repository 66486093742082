const LinkModal = (editor, editorComponent, postEditComponent) => {
  const CreateLink = {
    type: 'menuitem',
    text: '插入超連結',
    icon: 'Link45deg',
    tooltip: '插入超連結',
    onAction: () => {
      let entity = {}
      let target = editor.selection.getNode()
      if (target.tagName === 'A') {
        entity.link_url = target.href
        entity.link_context = target.innerHTML
        entity.link_target = target.target
      }
      postEditComponent.$refs.LinkModal.execute({
        callback: values => {
          if (target.tagName === 'A') {
            target.href = values.link_url
            target.innerHTML = values.link_context
            target.target = values.link_target
          } else {
            const { link_url, link_context, link_target } = values
            let newLink = document.createElement('a')
            newLink.href = link_url
            newLink.target = link_target
            newLink.innerHTML = link_context
            editor.selection.setNode(newLink)
          }
          editorComponent.editorValue.html = editor.getContent()
        },
        entity: { ...entity }
      })
    }
  }
  const RemoveLink = {
    type: 'menuitem',
    text: '移除超連結',
    icon: 'XSquare',
    tooltip: '移除超連結',
    onAction: () => {
      let target = editor.selection.getNode()
      if (target.tagName === 'A') {
        target.outerHTML = target.innerHTML
      }
      editorComponent.editorValue.html = editor.getContent()
    }
  }

  editor.ui.registry.addMenuButton('LinkMenu', {
    text: '超連結設定',
    icon: 'Link',
    fetch: callback => {
      callback([CreateLink, RemoveLink ])
    }
  })


  editor.ui.registry.addContextMenu('LinkMenu', {
    update: element => {
      return [CreateLink, RemoveLink ];
    }
  })
}

export default LinkModal
