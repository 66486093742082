<template>
  <k-container class="view-product-comment">
    <header class="pageTitle">
      <h1>產品評價</h1>
      <k-button theme="primary" icon="plus-lg" @click="handleShowUpload">上傳評價</k-button>
    </header>

    <k-tabs>
      <k-tab-pane tab="已上架" key="published" state="published">
        <CommentTable name="comment-published" :data="publishedComments" :showDetail="showDetail"/>
      </k-tab-pane>

      <template #tabNav(pending)="info">
        <div><i v-if="pendingComments.length">{{ pendingComments.length }}</i><span>{{ info.name }}</span></div>
      </template>
      <k-tab-pane tab="待審核" key="pending" state="pending">
        <CommentTable name="comment-published" :data="pendingComments" :showDetail="showDetail"/>
      </k-tab-pane>
    </k-tabs>

    <k-modal class="comment-detail" :show.sync="showCommentDetail" title="評論詳情">
      <div v-if="focusComment">
        <h2>{{ focusComment.comment_title }}</h2>
        <p>{{ timestampParse(focusComment.comment_create_at).format('YYYY-MM-DD') }}</p>
        <p>{{ focusComment.comment_content }}</p>
        <div class="images" v-if="focusComment.comment_images">
          <div
            class="image-wrapper"
            v-for="image in focusComment.comment_images.split(',')"
            :key="`comment-modal-image-${focusComment.key_id}-${image}`"
          >
            <img :src="`https://www.chris-farrell.com.tw/img/_comment/${image}`" @error="e => ImgError(e)">
          </div>
        </div>
      </div>
    </k-modal>

    <k-modal class="upload-modal" :show.sync="showUpload" title="上傳評論" :width="1200" hideFooter>
      <header class="upload-header">
        <k-button theme="secondary" icon="arrow-left-square-fill">上一步</k-button>
        <div :class="{'active': currentStep === '1' }">Step1. 上傳評論 CVS檔案</div>
        <span><k-icon icon="caret-right-fill" /></span>
        <div :class="{'active': currentStep === '2' }">Step2. 上傳圖片檔案</div>
      </header>
      <k-divider></k-divider>
      <section v-if="currentStep === '1'" class="step1">
        <div class="title">
          <h2><k-icon icon="cloud-arrow-up-fill"/> 上傳CVS檔案</h2>
          <k-button icon="download" theme="info">下載範例CVS</k-button>
        </div>
        <k-upload @change="handleCvsUpload" :droppable="true" v-if="!newCommentData && !loading">
          <div class="k-upload-button block">
            <k-icon icon="plus"></k-icon>
            <span>{{ '點擊或將評論 CVS 檔拖拉至此處'}}</span>
          </div>
        </k-upload>
        <div v-if="loading" class="loading">
          <k-loader-scale></k-loader-scale>
          <p>讀取 CVS 檔案中...</p>
        </div>
      </section>

      <section v-if="currentStep === '2'" class="step2">
        <k-card v-if="newCommentData" outline="secondary" noShadow>
          <template #header>
            <k-range-picker label="隨機生成區間：" v-model="newCommentDateRange"></k-range-picker>
            <k-button icon="cloud-arrow-up-fill" theme="warning" @click="handleSubmit">上傳評論</k-button>
          </template>
          <CommentDataTable :data="newCommentData" :handleAddImageToRow="handleAddImageToRow" />
        </k-card>
      </section>
    </k-modal>
  </k-container>
</template>

<script>
import KButton from '@/components/Button';
import KTable from '@/components/Table';
import KModal from '@/components/Modal';
import { KTabs, KTabPane } from '@/components/Tabs'
import KCard from '@/components/Card'
import KDivider from '@/components/Divider'
import KTag from '@/components/Tag'
import KTooltip from '@/components/Tooltip'
import { KUpload, KRangePicker } from '@/components/Form'
import { KLoaderScale } from '@/components/Loader'
import CommentTable from './components/commentTable.vue'
import CommentDataTable from './components/commentDataTable.vue'
import { mapActions, mapGetters, mapState } from 'vuex';
import { timestampParse } from '../../utils';
const iconv = require('iconv-lite');
import moment from 'moment';
import Papa from 'papaparse';

export default {
  mounted() {

  },
  data() {
    return {
      currentStep: '1',
      showCommentDetail: false,
      showUpload: false,
      focusComment: null,
      commentCvsFile: null,
      newCommentData: null,
      newCommentDateRange: {
        start: moment(),
        end: moment(),
      },
      loading: false,
    }
  },
  computed: {
    ...mapState('comment', ['commentList']),
    ...mapGetters('comment', ['pendingComments', 'publishedComments']),
    ...mapState('product', ['productList']),
    ...mapGetters('product', ['productDict', 'productUidDict']),
    CommentFields() {
      if (this.productList.length) {
        return [
          { key: 'product_id', fieldIndex: 'product_id', label: '產品', filters: this.productList.map(p => ({ value: p.key_id, text: `${p.product_uid} | ${p.product_name_zh}` })) },
          ...CommentFields.filter(f => f.key !== 'product_id'),
        ]
      }
      return CommentFields
    }
  },
  methods: {
    ...mapActions('comment', ['createNewCommentList']),
    timestampParse,
    showDetail(comment) {
      this.showCommentDetail = true
      this.focusComment = comment
    },
    handleShowUpload() {
      this.showUpload = true
    },
    ImgError(event) {
      event.target.src = "https://admin.chris-farrell.com.tw/static/product_default_img.png";
      return true;
    },
    handleCvsUpload({ files }) {
      this.loading = true
      this.commentCvsFile = files[0]
      let reader = new FileReader();
      reader.addEventListener('load', e => {
        let cvsString = e.target.result; 
        let buf = Buffer.from(cvsString, 'binary');
        let cvsData = iconv.decode(buf, 'utf8')
        const { data } = Papa.parse(cvsData)
        this.newCommentData = data.slice(1).map((row, index) => {
          let [product_id, comment_user_name, comment_stars, comment_title, comment_content, comment_skin_type, comment_gender, comment_create_time, comment_source] = row
          return {
            index,
            product_id: this.productUidDict[product_id].product_id,
            comment_user_name,
            comment_stars,
            comment_title,
            comment_content,
            comment_skin_type,
            comment_gender,
            comment_images: [],
            comment_create_at: moment(comment_create_time, 'YYYY-MM-DD').unix(),
            comment_source
          }
        })
        this.loading = true
        this.currentStep = '2'
      });
      reader.readAsBinaryString(this.commentCvsFile);
    },
    handleAddImageToRow(row, files, srcs) {
      const entries = Array.from(files).reduce((acc, file, index) => [...acc, { file, src: srcs[index] }], [])
      const rowIndex = row.index
      this.newCommentData.splice(rowIndex, 1, {
        ...this.newCommentData[rowIndex],
        comment_images: [...this.newCommentData[rowIndex].comment_images, ...entries]
      })
    },
    async handleSubmit() {
      const { start, end } = this.newCommentDateRange
      const maxTimeStamp = end.unix()
      const minTimeStamp = start.unix()
      await this.createNewCommentList(this.newCommentData.map(c => ({ ...c, comment_create_at: getRandomInt( minTimeStamp, maxTimeStamp ) })))
      this.showUpload = false
      this.newCommentData = null
      this.newCommentData = null
      
    }
  },
  components: {
    CommentTable, CommentDataTable,
    KButton, KTable, KModal, KTabs, KTabPane, KCard, KDivider, KTag, KTooltip, KUpload, KDivider, KLoaderScale, KRangePicker
  }
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
</script>
