var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-card',{class:_vm.classes,on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.type && _vm.data.length)?_c('div',{staticClass:"widget-content"},[_c('div',{staticClass:"head-area"},[_c('h2',[_vm._v(_vm._s(_vm.header))]),_c('p',[_vm._v(_vm._s(_vm.text))])]),_c(_vm.chartType,{tag:"component",staticClass:"testchart",attrs:{"datasets":[
        {
          data: _vm.data,
          backgroundColor: _vm.chartColor,
          borderColor: _vm.borderColor || _vm.chartColor,
          tension: 0.4,
          label: _vm.label,
          fill: _vm.fill
        }
      ],"labels":"months","options":Object.assign({}, {responsive:true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
        },
        layout: {
          padding: {
            bottom: -10,
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
              padding: 0,
            }
          },
          y: {
            grid: {
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false,
              padding: 0
            }
          }
        }},
        _vm.options)}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }