import { genBasicModel } from './utils/basicAction'
import { query, create } from './utils/basicService'

const MODEL_NAME = 'seo_template'
const { basicMutation, basicAction } = genBasicModel(
  MODEL_NAME,
  query(MODEL_NAME, { callback: data => ({ ...data, seo_template_features: JSON.parse(data['seo_template_json']) }) }),
  create(MODEL_NAME, true))

export default {
  namespaced: true,
  state: {
    seo_templateList: []
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction,
  },
  getters: {
  
  }
}
