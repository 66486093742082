import Clipboard from 'clipboard'

export default {
  install(Vue, options) {
    Vue.directive('copy', {
      bind: function(el, binding, vnode) {
        if (binding.arg === 'success') {
          el._vCopy_success = binding.value
        } else if (binding.arg === 'error') {
          el._vCopy_error = binding.value
        } else {
          let clipboard = new Clipboard(el, {
            text: () => binding.value,
            action: () => 'copy'
          });
          clipboard.on('success', function (e) {
            let callback = el._vCopy_success
            callback && callback(e)
          })
          clipboard.on('error', function (e) {
            let callback = el._vCopy_error
            callback && callback(e)
          })
          el._vCopy = clipboard
        }
      },
      update: function (el, binding) {
        if (binding.arg === 'success') {
          el._vCopy_success = binding.value
        } else if (binding.arg === 'error') {
          el._vCopy_error = binding.value
        } else {
          el._vCopy.text = () => binding.value
        }
      },
      unbind: function (el, binding) {
        if (binding.arg === 'success') {
          delete el._vCopy_success
        } else if (binding.arg === 'error') {
          delete el._vCopy_error
        } else {
          el._vCopy.destroy()
          delete el._vCopy
        }
      }
    })
  }
}
