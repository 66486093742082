<template>
  <div class="feature-button">
    <k-button
      v-if="!feature.features"
      :theme="isChoosed ? 'primary' : 'secondary'"
      :icon="isChoosed ? 'check2' : null"
      @click="handleClick(typeKey, feature.key)"
    >
      {{ feature.name }}
    <k-icon v-if="feature.features" icon="list" />
    </k-button>
    <k-card :key="`feature_${feature.key}`" v-else :header="feature.name" outline="light" collapsible>
      <Feature
        v-for="feat in feature.features"
        :key="feat.key"
        :ref="feat.features ? feat.key : null"
        :typeKey="feature.key"
        :feature="feat"
        :choosedFeatures="choosedChildFeatures"
        :handleClick="()=>{handleChildClick(feat.key)}" />
    </k-card>
  </div>
</template>

<script>
import KButton from '@/components/Button'
import KCard from '@/components/Card'

export default {
  name: 'Feature',
  props: {
    typeKey: String,
    feature: Object,
    choosedFeatures: [Object, Array],
    handleClick: Function
  },
  data() {
    return {
      choosedChildFeatures: []
    }
  },
  computed: {
    isChoosed() {
      return this.choosedFeatures ? this.choosedFeatures.includes(this.feature.key) : false
    }
  },
  methods: {
    handleChildClick(feature) {
      const index = this.choosedChildFeatures.indexOf(feature)
      if (index === -1) this.choosedChildFeatures = [...this.choosedChildFeatures, feature]
      else this.choosedChildFeatures.splice(index, 1)
    },
    handleSubmit() {
      const refsResult = Object.keys(this.$refs).reduce((acc,ref)=>{
        if (this.$refs[ref][0].handleSubmit().length) {
          return [...acc, {key: ref, features: this.$refs[ref][0].handleSubmit()}]
        }
        return acc
      }, [...this.choosedChildFeatures])
      return refsResult
    }
  },
  components: {
    KButton, KCard
  }
}
</script>
