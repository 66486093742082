import { fetchApi } from './index'

class Database {
  constructor() {
    this.FieldValue = {
      serverTimestamp() {
        return "PHP_SERVER_TIMESTAMP"
      },
      serverUid() {
        return "PHP_SERVER_UID"
      }
    }
  }

  static init() {
    return new Database()
  }

  table(name) {
    if (!name) throw 'table function need 1 argument but recive 0'
    return new Query(name)
  }
}

class Query {
  constructor(tableName, _deligate = {}) {
    const { joins, conditions, sort, selected, group } = _deligate
    this.selected = selected || []
    this.tableName = tableName
    this.conditions = conditions || []
    this.joins = joins || []
    this.sort = sort || ''
    this.group = group || ''
  }

  static regex() {
    return /[a-zA-Z0-9]\.[a-zA-Z0-9]/
  }

  joinValidation(column) {
    if (this.joins.length && !Query.regex().test(column)) {
      throw `When using join method, other condition fields need to add tablename like "[tableName].[column]"`
    }
  }

  join(table, column, table2, column2) {
    const joinCondition = Object.assign({ table, column }, table2 && { table2 })
    if (column2) joinCondition.column2 = column2
    this.joins.push(joinCondition)
    return new Query(this.tableName, this)
  }

  select(array) {
    this.selected = [...array]
    return new Query(this.tableName, this)
  }

  where(column, operator, value='', conj="AND") {
    this.joinValidation(column)
    this.conditions.push({ column, operator, value, conj })
    return new Query(this.tableName, this)
  }

  orderBy(column, method='ASC') {
    this.joinValidation(column)
    this.sort = { column, method }
    return new Query(this.tableName, this)
  }

  groupBy(column) {
    this.joinValidation(column)
    this.group = column
    return new Query(this.tableName, this)
  }

  async set(data) {
    if (!data) throw "Query.set() expect 1 argument but get 0"
    const API = '/api/set/'
    console.log(data);
    const response = await fetchApi(API, { table: this.tableName, data })
    console.log(response);
    return response
  }

  async get() {
    const API = '/api/get/'
    const { tableName, conditions, sort, joins, group, selected } = this
    const response = await fetchApi(API, { table: tableName, conditions: conditions.length ? conditions : [], orderBy: sort, joins, group, selected })
    console.log(this);
    console.log(response);
    return response
  }

  async update(data) {
    if (!data) throw "Query.update() expect 1 argument but get 0"
    const API = '/api/update/'
    const response = await fetchApi(API, { table: this.tableName, conditions: this.conditions, updateData: data })
    console.log(response);
    return response
  }

  async delete() {
    if (!this.conditions.length) throw "Query.delete() method expect where() define the condition"
    const API = '/api/delete/'
    const response = await fetchApi(API, { table: this.tableName, conditions: this.conditions })
    return response
  }
}

const db = Database.init()

export default db
