<template>
  <k-container class="view-seo-container" title="SEO 模板管理">
    <section class="template-list" v-if="seo_templateList.length">
      <k-card v-for="temp in seo_templateList" :key="`templste_${temp.key_id}`" :header="temp.seo_template_name">
        <template #header>
          <h3>{{ temp.seo_template_name }}</h3>
          <k-icon icon="eye-fill" theme="info" title="Priview" @click="focusOn(temp.key_id)" />
        </template>
        <span>{{ temp.seo_template_desc || '無設定模板描述' }}</span>
      </k-card>
    </section>

    <k-modal :show.sync="showPreview" title="結構化資料設定預覽" hideFooter>
      <NestedForm :templateFeatures="focusTemplate.seo_template_features" />
    </k-modal>
  </k-container>
</template>

<script>
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import KTag from '@/components/Tag'
import KModal from '@/components/Modal'
import NestedForm from './components/NestedForm'
import SeoFeatures from '@/assets/structuredData.json'
import { KForm, KInput } from '@/components/Form'
import { timestampParse } from '@/utils'
import { mapState } from 'vuex'

export default {
  name: 'SEO-Manager',
  SeoFeatures,
  mounted() {
    this.$store.dispatch('seo/query')
  },
  data() {
    return {
      showPreview: false,
      focusId: undefined,
      templateQuestions: undefined
    }
  },
  methods: {
    moment(timestamp) {
      return timestampParse(timestamp).format('YYYY-MM-DD')
    },
    focusOn(id) {
      this.focusId = id
      this.showPreview = true
    }
  },
  computed: {
    ...mapState('seo', ['seo_templateList']),

    focusTemplate() {
      if (this.focusId) {
        return this.seo_templateList.filter(t => t.key_id === this.focusId)[0]
      }
      return {}
    }
  },
  components: {
    KButton, KCard, KTag, KModal, KForm, KInput, NestedForm
  }
}
</script>
