<template>
  <k-modal class="media-link-modal" :show="this.show" :closable="false" title="圖片連結" hideFooter @cancel="handleClick(false)">
    <MediaSelector route="/" mode="ROUTE" />
    <div class="img-link-list">
      <header>
        <h2>圖片連結</h2>
      </header>
      <ul>
        <li v-for="img, index in linkImages" :key="`img-link-list-${index}`">
          <div class="img-wrapper">
            <img :src="images[index].src" alt="" />
          </div>
          <k-icon icon="link"></k-icon>
          <div class="link-info" v-if="img">
            <div class="img-wrapper">
              <img v-if="img.src" :src="img.src" :alt="img.alt" />
              <p v-else>輸入src連結</p>
            </div>
            <div class="info">
              <k-input v-model="img.src" theme="secondary" addonBefore="src" placeholder="設定 src" debounce />
              <k-input v-model="img.alt" theme="secondary" addonBefore="alt" placeholder="設定 alt" debounce />
            </div>
          </div>
        </li>
      </ul>

      <div class="button-group">
        <k-button label="取消" theme="light" @click="() => this.handleClick(false)" />
        <k-button label="連結" theme="info" @click="() => this.handleClick(true)" />
      </div>
    </div>
  </k-modal>
</template>

<script>
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import MediaSelector from './MediaSelector.vue'
import { KInput } from '@/components/Form'

export default {
  data() {
    return {
      show: false,
      callback: void 0,
      images: [],
      linkImages: [],
    }
  },
  methods: {
    execute({images, callback}) {
      this.show = true
      this.callback = callback
      this.images = images
      this.linkImages = Array(this.images.length).fill().map(() => ({ src: '', alt: '' }))
    },
    handleClick(bool) {
      this.show = false
      if (bool) this.callback(this.linkImages)
      else this.callback()
    }
  },
  computed: {
    classes() {
      return {
        'k-confirm': true,
        'show': this.show,
      }
    },
    spanClasses() {
      return {
        [`confirm--${this.type}`]: this.type
      }
    }
  },
  components: {
    KModal, KButton, MediaSelector, KInput
  }
}
</script>
