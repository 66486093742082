<template>
  <div :class="classes">
    <nav class="k-tabs-navbar">
      <div
        :class="{ 'k-tabs-tab': true, [`tab-${key}`]: true, 'active': key === activeKey }" v-for="({ tab, key }) in tabsRefs" 
        :key="key"
        @click="handleClick(key)"
      >
        <slot :name="`tabNav(${key})`" v-bind="{ name: tab, key: key }" >
          {{ tab }}
        </slot>
      </div>
    </nav>

    <div class="k-tabs-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    defaultActiveKey: String,
  },
  mounted() {
    this.tabsRefs = this.$children.map(c => ({ key: c.$vnode.key, tab: c.tab, $el: c.$el }))
    this.activeKey = this.defaultActiveKey || this.tabsRefs[0].key
    this.classNameEffect(this.activeKey);
  },
  data() {
    return {
      tabsRefs: [],
      activeKey: ''
    }
  },
  methods: {
    handleClick(key) {
      this.activeKey = key
      this.classNameEffect(key)
    },
    classNameEffect(key) {
      this.tabsRefs.forEach(ref => {
        if (ref.key === key) ref.$el.classList.add("active")
        else ref.$el.classList.remove('active')
      })
    }
  },
  computed: {
    classes() {
      return {
        'k-tabs': true,
      }
    }
  },
}
</script>
