<script>
export default {
  name: 'KDescriptionsItem',
  props: {
    label: String,
    span: {
      type: [Number, String],
      default: 1
    },
    icon: String
  },
  render(h) {
    const { span } = this.$props
    return (
        <td colSpan={span}>
          <div>
            <span class="label">{this.icon && <k-icon icon={this.icon} />}{this.label}</span>
            <span class="value">{this.$slots.default}</span>
          </div>
        </td>
    )
  }
}
</script>

<style lang="scss" scoped>
.label {
  i {
    margin-right: 4px;
  }
}
</style>
