<template>
  <label :class="classes" :for="uid">
    <input
      :id="uid"
      type="radio"
      :value="staticValue"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
      :checked="staticValue===value"
    >
    <span class="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  mounted() {
    this.uid = `k-input-group-${parseInt(Math.random()*1000)}`
  },
  data() {
    return {
      uid: ''
    }
  },
  props: {
    staticValue: String,
    value: String,
    theme: String,
    disabled: Boolean,
    label: String,
  },
  computed: {
    classes() {
      return {
        'k-radio': true,
        'disabled': this.disabled
      }
    },
  }
}
</script>
