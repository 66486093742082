<template>
  <k-card :class="classes" @click="$emit('click')">
    <div class="widget-content" v-if="type && data.length">
      <div class="head-area">
        <h2>{{ header }}</h2>
        <p>{{ text }}</p>
      </div>
      <component
        :is="chartType"
        class="testchart"
        :datasets="[
          {
            data,
            backgroundColor: chartColor,
            borderColor: borderColor || chartColor,
            tension: 0.4,
            label,
            fill
          }
        ]"
        labels="months"
        :options="{
          responsive:true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
          },
          layout: {
            padding: {
              bottom: -10,
            }
          },
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
                padding: 0,
              }
            },
            y: {
              grid: {
                display: false,
                drawBorder: false
              },
              ticks: {
                display: false,
                padding: 0
              }
            }
          },
          ...options
        }"
      />
    </div>
  </k-card>
</template>

<script>
import { KChartBar, KChartLine, KChartHistogram } from '@/components/Chart'
import KCard from '../Card';

const chartTypeMap = {
  bar: 'KChartBar',
  line: 'KChartLine',
  histogram: 'KChartHistogram'
}

export default {
  props: {
    header: [String, Number],
    text: String,
    data: Array,
    label: [Array, String],
    type: String,
    chartColor: String,
    borderColor: String,
    options: {
      type: Object,
      default: () => ({})
    },
    fill: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        'k-widget': true,
        'k-widget-chart': true
      }
    },
    chartType() {
      if (this.type) {
        return chartTypeMap[this.type]
      }
      return null
    }
  },
  components: {
    KCard, KChartBar, KChartLine, KChartHistogram
  }
}
</script>
