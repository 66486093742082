export const genBasicModel = (namespace, queryList, addRule, updateRule, deleteRule) => {
  const name = `${namespace}List`

  return {
    basicAction: {
      async query({ commit }, payload) {
        const res = await queryList(payload)
        commit('save', { name, data: res.data })
        return res
      },
      async add({ commit }, payload) {
        const res = await addRule(payload)
        commit('add', { name, data: { key_id: res.id, ...res.data } })
        return res
      },
      async update({ commit }, payload) {
        const res = await updateRule(payload)
        commit('update', { name, data: payload })
        return res
      },
      async delete({ commit }, payload) {
        const res = await deleteRule(payload)
        commit('delete', { name, id: payload })
        return res
      }
    },
    basicMutation: {
      save(state, payload) {
        const { name, data } = payload
        state[name] = [...data]
      },
      add(state, payload) {
        const { name, data } = payload
        state[name] = [{ ...data } , ...state[name]]
      },
      update(state, payload) {
        const { name, data } = payload
        const index = state[name].map(e => e.key_id).indexOf(data.id)
        state[name].splice(index, 1, { ...state[name][index] ,...data })
      },
      delete(state, payload) {
        const { name, id } = payload
        const index = state[name].map(e => e.key_id).indexOf(id)
        state[name].splice(index, 1)
      }
    }
  }
}
