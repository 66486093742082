const templateAttrs = [
  {
    questionType: 'k-input',
    name: 'email_template_name',
    label: "模板名稱",
    theme: "light",
    required: true,
  },
  {
    questionType: 'k-textarea',
    name: 'email_template_desc',
    label: "模板描述",
    theme: "light",
    required: true,
  },
]

export const templateConfig = {
  SIMPLE: [
    ...templateAttrs,
    {
      questionType: 'k-input',
      name: 'email_template_title',
      label: "信件主旨",
      theme: "light",
      required: true,
    },
    {
      questionType: 'k-textarea',
      name: 'email_template_content',
      label: "信件內文",
      theme: 'light',
      required: true,
    },
    {
      questionType: 'k-btn',
      label: '建立模板',
      theme: "primary",
      type: "submit"
    }
  ],
  ADVANCE: [
    ...templateAttrs,
    {
      questionType: 'k-input',
      name: 'email_template_title',
      label: "信件主旨",
      theme: "light",
      required: true,
    },
    {
      questionType: 'k-coder',
      name: 'email_template_content',
      label: "信件 HTML Body",
      theme: 'light',
      height: 400,
      required: true,
    },
    {
      questionType: 'k-btn',
      name: 'submit',
      label: '建立模板',
      theme: "primary",
      type: "submit"
    }
  ]
}

export const emailConfig = {
  SIMPLE: target => [
    {
      questionType: 'k-input',
      name: 'email_sent_target',
      label: "收件人",
      theme: "light",
      value: target || undefined,
      required: true,
      readonly: !!target
    },
    {
      questionType: 'k-input',
      name: 'email_sent_title',
      label: "信件主旨",
      theme: "light",
      required: true,
    },
    {
      questionType: 'k-textarea',
      name: 'email_sent_content',
      label: "信件內文",
      theme: 'light',
      required: true,
    },
    {
      questionType: 'k-btn',
      name: 'submit',
      label: '寄出信件',
      theme: "primary",
      type: "submit"
    }
  ],
  ADVANCE: target => [
    {
      questionType: 'k-input',
      name: 'email_sent_target',
      label: "收件人",
      theme: "light",
      value: target || undefined,
      required: true,
    },
    {
      questionType: 'k-input',
      name: 'email_sent_title',
      label: "信件主旨",
      theme: "light",
      required: true,
    },
    {
      questionType: 'k-coder',
      name: 'email_sent_content',
      label: "信件 HTML Body",
      theme: 'light',
      height: 400,
      required: true,
    },
    {
      questionType: 'k-btn',
      name: 'submit',
      label: '寄出信件',
      theme: "primary",
      type: "submit"
    }
  ]
}
