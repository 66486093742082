<template>
  <k-container class="view-product-container">
    <template #header>
      <h2>所有產品</h2>
      <k-button theme="info" icon="sort-down" @click="handleShowAdjustModal">調整產品順序</k-button>
    </template>
    <k-card class="product-list">
      <k-table :items="productList" :fields="fields" hover>
        <template #cell(file_path)="{item}" v-if="layoutDict['PRODUCT_AVATAR_NAME']">
          <img
            v-if="item.product_type === 'PRODUCT'"
            @error="e => ImgError(e)"
            :src="
              `https://chris-farrell.com.tw/img/product/${item.product_uid}/avatar.webp`
            "
          >
          <div class="img-wrapper" v-else>
            <img :src="`https://chris-farrell.com.tw/img/product/${item.product_images[0]}`" alt="">
          </div>
        </template>

        <template #cell(product_name_zh)="{value, item}">
          <h3 class="name_en">{{ item.product_name_en }}</h3>
          <h2 class="name_zh">{{ value }}</h2>
        </template>

        <template #cell(product_price)="{value, item}">
          <ul class="price-classes">
            <li v-for="price_class in Object.keys(value)" :key="`price_${item.product_uid}_${price_class}`">
              {{ priceClassDict[price_class] }}: <span>${{ value[price_class] }}</span>
            </li>
          </ul>
        </template>

        <template #cell(product_status)="item">
          <k-tag :theme="item.item.product_quantity===0 ? 'error' : 'success'">
            {{ item.item.product_quantity===0 ? '補貨中' : '尚有庫存' }}
          </k-tag>
        </template>

        <template #cell(product_is_additional)="{value, item}">
          <k-switch :value="value === '1'" @input="bool => handleAdditionalStateChange(item.product_id, bool)" theme="info"></k-switch>
        </template>

        <template #cell(product_is_launched)="{value}">
          <k-switch :value="value === '1'" theme="success"></k-switch>
        </template>

        <template #cell(product_states)="item">
          <k-progress-star :value="item.value" showValue></k-progress-star>
        </template>

        <template #cell(action)="{item}">
          <k-button varient="ghost" @click="changeFocusProduct(item.product_id)" theme="info">詳細資訊</k-button>
        </template>
      </k-table>
    </k-card>

    <k-modal
      class="product-detail-modal"
      :show.sync="showProduct"
      title="訂單操作"
      :okButtonProps="{ label: '儲存', theme: 'info'}"
      @ok="handleEditSave"
      @cancel="handleEditCancel"
    >
      <template v-if="focusProductInfo">
        <header>
          <img style="width: 200px; border-radius: 8px;"
            @error="e => ImgError(e)"
            :src="
              `https://chris-farrell.com.tw/img/product/${focusProductInfo.product_uid}/avatar.webp`
          ">
          <div class="content">
            <h2>{{ focusProductInfo.product_name_en }}</h2>
            <h1>{{ focusProductInfo.product_name_zh }}</h1>
            <h4>貨號: {{ focusProductInfo.product_uid }}</h4>
          </div>
        </header>
        <k-descriptions layout="vertical" bordered>
          <k-descriptions-item label="貨號">{{ focusProductInfo.product_uid }}</k-descriptions-item>
          <k-descriptions-item label="中文名稱"><k-input v-model="focusProductInfo.product_name_zh" /></k-descriptions-item>
          <k-descriptions-item label="英文名稱"><k-input v-model="focusProductInfo.product_name_en" /></k-descriptions-item>
          <k-descriptions-item label="產品價格" span="3">
            <k-input class="price" addonBefore="原價 $" v-model="focusProductInfo.product_price.ORIGIN" />
            <k-input class="price" addonBefore="會員價 $" v-model="focusProductInfo.product_price.MEMBER" />
            <k-input class="price" addonBefore="加購價 $" v-model="focusProductInfo.product_price.ADDITIONAL" />
          </k-descriptions-item>
          <k-descriptions-item label="產品說明" span="3">
            <k-textarea v-model="focusProductInfo.product_summary" height="100"></k-textarea>
          </k-descriptions-item>
          <k-descriptions-item label="產品詳情圖" span="3">
            <div class="detail-image" v-if="focusProductInfo.product_detail_images">
              <Container
                @drop="result => { onDetailDrop(result)}"
                :get-ghost-parent="getGhostParent"
                drag-class="product-detail-drag"
                :drop-placeholder="{
                  className: 'product-detail-drop-preview',
                  animationDuration: '100',
                  showOnTop: true
                }"
              >
                <Draggable
                  v-for="img, index in focusProductInfo.product_detail_images"
                  :key="`product-detail-${focusProductInfo.product_id}-${img.src}`"
                >
                  <div class="product">
                    <k-icon icon="list" />
                    <div class="img-wrapper">
                      <img :src="img.src">
                    </div>
                    <p>{{ img.name }}</p>
                  </div>
                  <span class="close" @click="handleRemoveDetail(index)"><k-icon icon="x" /></span>
                </Draggable>
              </Container>
            </div>
          </k-descriptions-item>
        </k-descriptions>
      </template>
    </k-modal>

    <k-modal
      class="product-sort-modal"
      :show.sync="showAdjustModal"
      :width="1200"
      :okButtonProps="{ label: '儲存', theme: 'info'}"
      @ok="handleSaveSort"
      @cancel="handleCancelSort"
    >
      <template #header><k-icon id="header-icon" icon="sort-down"/>順序調整</template>
      <k-card class="product-list" header="產品順序" outline="light">
        <Container
          @drop="result => { onDrop(result, 'PRODUCT' )}"
          :get-ghost-parent="getGhostParent"
          drag-class="product-item-drag"
          :drop-placeholder="{
            className: 'product-drop-preview',
            animationDuration: '100',
            showOnTop: true
          }"
        >
          <Draggable  v-for="item in productSortIndexCache.PRODUCT" :key="`product-sort-${item.product_id}`">
            <div class="product">
              <k-icon icon="list" />
              <div class="img-wrapper">
                <img :src="`https://www.chris-farrell.com.tw/img/product/${productDict[item.product_id].product_uid}/avatar.webp`">
              </div>
              <div class="content">
                <h4>{{ productDict[item.product_id].product_uid }}</h4>
                <h2>{{ productDict[item.product_id].product_name_zh }}</h2>
              </div>
            </div>
          </Draggable>
        </Container>
      </k-card>

      <k-card class="product-list" header="組合順序" outline="light">
        <Container
          @drop="result => { onDrop(result, 'SET' )}"
          :get-ghost-parent="getGhostParent"
          drag-class="product-item-drag"
          :drop-placeholder="{
            className: 'product-drop-preview',
            animationDuration: '100',
            showOnTop: true
          }"
        >
          <Draggable  v-for="item in productSortIndexCache.SET" :key="`product-sort-${item.product_id}`">
            <div class="product">
              <k-icon icon="list" />
              <div class="img-wrapper">
                <img :src="`https://www.chris-farrell.com.tw/img/product/${productDict[item.product_id].product_images[0]}`">
              </div>
              <div class="content">
                <h4>{{ productDict[item.product_id].product_uid }}</h4>
                <h2>{{ productDict[item.product_id].product_name_zh }}</h2>
              </div>
            </div>
          </Draggable>
        </Container>
      </k-card>
    </k-modal>
  </k-container>
</template>

<script>
import { KProgress, KProgressCircle, KProgressStar } from '@/components/Progress'
import KTag from '@/components/Tag'
import KCard from '@/components/Card'
import KTable from '@/components/Table'
import KModal from '@/components/Modal'
import { KSwitch, KInput, KTextarea } from '@/components/Form'
import { KDescriptions, KDescriptionsItem } from '@/components/Descriptions'
import { KTabs, KTabPane } from '@/components/Tabs'
import KButton from '@/components/Button'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Container, Draggable } from "vue-smooth-dnd"
import { applyDrag } from '@/utils/dnd'

export default {
  name: 'Product',
  mounted() {
    this.$store.dispatch('product/query')
  },
  data() {
    return {
      fields,
      showProduct: false,
      focusProductInfo: null,
      priceClassDict: { ORIGIN: '原價', MEMBER: '會員價', ADDITIONAL: '加購價' },
      showAdjustModal: false,
      productSortIndexCache: {}
    }
  },
  computed: {
    ...mapState('product', ['productList']),
    ...mapGetters('layout', ['layoutDict']),
    ...mapGetters('product', ['productDict'])
  },
  methods: {
    ...mapActions('product', ['update', 'saveProductSortIndex', 'updateProduct']),
    ImgError(event) {
      event.target.src = "https://admin.chris-farrell.com.tw/static/product_default_img.png";
      return true;
    },
    handleAdditionalStateChange(product_id, bool) {
      this.update({ id: product_id, product_is_additional: bool ? '1' : '0' })
      
    },
    changeFocusProduct(product_id) {
      const product = this.productList.filter(item => item.product_id === product_id)[0]
      this.focusProductInfo = {
        ...JSON.parse(JSON.stringify(product)),
        product_detail_images: product.product_detail_images
          ? product.product_detail_images.split(',').map(imgNo => this.getProductDetailImage(imgNo, this.productDict[product_id].product_uid))
          : [],
      }
      this.showProduct = true
    },
    handleShowAdjustModal() {
      this.productSortIndexCache = {
        PRODUCT: this.productList
          .filter(item => item.product_type === 'PRODUCT' && item.product_is_launched === '1')
          .map(({product_id, product_sort_index}) => ({ product_id, product_sort_index })),
        SET: this.productList
          .filter(item => item.product_type === 'SET' && item.product_is_launched === '1')
          .map(({product_id, product_sort_index}) => ({ product_id, product_sort_index })),
      }
      this.productSortIndexCache.PRODUCT.sort((a, b) => a.product_sort_index - b.product_sort_index)
      this.productSortIndexCache.SET.sort((a, b) => a.product_sort_index - b.product_sort_index)
      this.showAdjustModal = true
    },
    getGhostParent(){
      return document.body;
    },
    onDropReady(dropResult){
      console.log('drop ready', dropResult);
    },
    onDrop (dropResult, type) {
      this.productSortIndexCache[type] = applyDrag(this.productSortIndexCache[type], dropResult)
    },
    async handleSaveSort() {
      this.productSortIndexCache.PRODUCT.forEach((item, index) => {
        item.product_sort_index = index + 1
      })
      this.productSortIndexCache.SET.forEach((item, index) => {
        item.product_sort_index = index + 1
      })
      await this.saveProductSortIndex(this.productSortIndexCache)
      this.$message.success('已儲存排序')
      this.showAdjustModal = false
    },
    handleCancelSort() {
      this.productSortIndexCache = {}
    },
    getProductDetailImage(imgNO, uid) {
      const [imgName, imgRouteLink] = imgNO.split('|')
      const [imgNumber, imgExtension='webp'] = imgName.split('.')
      return {
        link: imgRouteLink,
        src: `https://www.chris-farrell.com.tw/img/product/${uid}/detail_${imgNumber}.${imgExtension}`,
        name: `detail_${imgNumber}.${imgExtension}`
      }
    },
    onDetailDrop(result) {
      console.log(result);
      this.focusProductInfo.product_detail_images = applyDrag(this.focusProductInfo.product_detail_images, result)
    },
    handleRemoveDetail(index) {
      this.focusProductInfo.product_detail_images.splice(index, 1)
    },
    async handleEditSave() {
      const { product_id, product_name_zh, product_name_en, product_price, product_summary, product_detail_images } = this.focusProductInfo
      await this.updateProduct({
        product_id,
        product_name_zh,
        product_name_en,
        product_price: JSON.stringify(Object.keys(product_price).reduce((acc, key) => {
          if (product_price[key]) acc[key] = parseInt(product_price[key])
          return acc
        }, {})),
        product_summary,
        product_detail_images: product_detail_images.map(i => {
          const { name, link } = i
          const [imgNumber, imgExtension='webp'] = name.split('.')
          const number = imgNumber.replace('detail_', '')
          const numberWithExtension = `${number}${imgExtension !== 'webp' ? '.'+imgExtension : ''}`
          if (link) return `${numberWithExtension}|${link}`
          return numberWithExtension
        }).join(',')
      })
      this.showProduct = false
      this.focusProductInfo = null
      this.$message.success('已儲存')
    },
    handleEditCancel() {
      const cache = JSON.parse(JSON.stringify(this.focusProductInfo))
      this.$confirm.info({
        title: '確定要取消此次改動？',
        text: `若點選「確定」，則會取消此次改動，並且不會儲存任何改動`,
        callback: async (bool) => {
          if (!bool) {
            this.focusProductInfo = cache
            this.showProduct = true
          }
        }
      })
    }
  },
  components: {
    KProgressCircle, KCard, KTable, KTag, KProgress, KProgressStar, KSwitch, KModal, KDescriptions, KDescriptionsItem, KTabs, KTabPane, KButton, KInput, KTextarea,
    Container, Draggable
  }
}

const fields = [
  { key: 'product_uid', fieldIndex: 'product_uid', label: '貨號' },
  { key: 'file_path', fieldIndex: 'file_path', label: '產品首圖' },
  { key: 'product_name_zh', fieldIndex: 'product_name_zh', label: '產品名稱', sorter: (a, b) => ('' + a).localeCompare(b), searchable: true },
  { key: 'product_price', fieldIndex: 'product_price', label: '價格', sorter: (a, b) => b - a },
  { key: 'product_type', fieldIndex: 'product_type', label: '產品類型', filters: [
    { value: 'PRODUCT', text: '產品' },
    { value: 'SET', text: '組合' },
  ] },
  // { key: 'product_states', fieldIndex: 'product_states', label: '評分', sorter: (a, b) => b - a },
  { key: 'product_is_additional', fieldIndex: 'product_is_additional', label: '開放加價購', filters: [
    { value: '1', text: '已開啟' },
    { value: '0', text: '未開放' },
  ] },
  { key: 'product_is_launched', fieldIndex: 'product_is_launched', label: '上架', filters: [
    { value: '1', text: '已上架' },
    { value: '0', text: '未上架' },
  ] },
  { key: 'action', fieldIndex: 'action', label: '' }
]
</script>
