<template>
  <k-card class="k-product-picker" v-if=" Object.keys(productDict).length" outline="secondary" :header="title" noShadow>
    <div class="product-card">
      <div class="product product-unchoosed">
        <k-button
          icon="plus"
          theme="secondary"
          v-for="product in productList.filter(p => !value.includes(p.product_id))" :key="`${uid}-${product.product_id}`"
          @click="handleChoosedProduct(product.product_id)"
        >
          {{ product.product_name_zh }}
        </k-button>
      </div>
      <div class="arrow"><k-icon icon="caret-right-fill" /></div>
      <div class="product product-choosed">
        <k-button
          theme="light" v-for="product_id in value" 
          icon="dash"
          :key="`${uid}-${product_id}-choosed`"
          @click="handleRemoveProduct(product_id)"
        >
          {{ productDict[product_id].product_name_zh }}
        </k-button>
      </div>
    </div>
  </k-card>
</template>

<script>
import KButton from '@/components/Button';
import KCard from '@/components/Card';
import { gen8ID } from '@/utils';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    title: String,
    value: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.uid = `product-picker-${gen8ID()}`;
  },
  data() {
    return {
      uid: null,
      productChoosed: []
    }
  },
  methods: {
    handleChoosedProduct(product_id) {
      const productChoosed = [...this.value, product_id];
      this.$emit('input', productChoosed);
    },
    handleRemoveProduct(product_id) {
      const productChoosed = this.value.filter(id => id !== product_id);
      this.$emit('input', productChoosed);
    },
  },
  computed: {
    ...mapState('product', ['productList']),
    ...mapGetters('product', ['productDict'])
  },
  components: {
    KButton, KCard
  }
}
</script>
