import Chart from './Chart'

const KChartBar = Object.assign({}, Chart, {
  name: 'KChartBar',
  type: 'bar'
})

const KChartLine = Object.assign({}, Chart, {
  name: 'KChartLine',
  type: 'line'
})

const KChartHistogram = Object.assign({}, Chart, {
  name: 'KChartHistogram',
  type: 'bar',
  cuzType: 'histogram'
})

const KChartPie = Object.assign({}, Chart, {
  name: 'KChartPie',
  type: 'pie'
})

const KChartDoughnut = Object.assign({}, Chart, {
  name: 'KChartdoughnut',
  type: 'doughnut'
})

const KChartBubble = Object.assign({}, Chart, {
  name: 'KChartBubble',
  type: 'bubble'
})

export {
  KChartBar,
  KChartHistogram,
  KChartLine,
  KChartPie,
  KChartDoughnut,
  KChartBubble
}
