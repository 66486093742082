<template>
  <k-card class="questionnaire-cell" collapsible defaultOpen>
    <template #header>
      <div class="title">
        <i class="index">{{ fieldIndex+1 }}</i>
        <span class="icon">
          <k-icon :icon="FIELD_TYPE[field.type].icon"></k-icon>
        </span>
        <b>{{ FIELD_TYPE[field.type].name }}</b>
        <k-input :ref="field.key" @click.stop="void 8" v-model="fieldCache.label" placeholder="標題" required />
      </div>
      <div class="actions">
        <span class="icon" @click.stop="handleCopy(field.key)">
          <k-icon icon="files"></k-icon>
        </span>
        <span class="icon" @click.stop="handleDelete(field.key)">
          <k-icon icon="trash3-fill"></k-icon>
        </span>
      </div>
    </template>
    
    <k-input label="題目說明" v-if="fieldCache.type !== 'EXPLAIN'" v-model="fieldCache.desc"></k-input>
    <k-switch v-if="FIELD_TYPE[field.type].required" label="必填" theme="info" v-model="fieldCache.required"></k-switch>
    

    <component
      v-if="FIELD_TYPE[field.type].needSetting"
      :is="FIELD_TYPE[field.type].settingComponent"
      :field.sync="fieldCache"
    />

    <!-- <div class="preview">
      <component :is="FIELD_TYPE[field.type].component" label="預覽" placeholder=" " readonly />
    </div> -->

    <!-- <template #footer v-if="isEditing">
      <k-button theme="secondary" @click="handleCancel">取消</k-button>
      <k-button theme="warning" @click="handleSave">儲存</k-button>
    </template> -->
  </k-card>
</template>

<script>
import { KInput, KRadio, KSelect, KTextarea, KSwitch } from '@/components/Form'
import { MULTIPLE_CHOICE, LINEAR_SCALE, EXPLAIN } from './settings'
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import { euqalObject } from '@/utils'
import FIELD_TYPE from "../utils/fieldType.json"

export default {
  mounted() {
    this.fieldCache =JSON.parse(JSON.stringify(this.field))
  },
  props: {
    field: Object,
    fieldIndex: Number,
  },
  data() {
    return {
      FIELD_TYPE,
      // isEditing: true,
      fieldCache: {}
    }
  },
  methods: {
    handleSave() {
      this.$emit('save', JSON.parse(JSON.stringify(this.fieldCache)))
    },
    handleCopy(key) {
      this.$emit('copy', key)
    },
    handleCancel() {
      this.isEditing = false
      this.fieldCache = JSON.parse(JSON.stringify(this.field))
      console.log(this.fieldCache);
    },
    handleDelete(key) {
      this.$emit('delete', key)
    }
  },
  watch: {
    fieldCache: {
      handler(newVal) {
        // console.log(newVal.scalePoints, this.field.scalePoints);
        // console.log(euqalObject(newVal, this.field) );
        if (!euqalObject(newVal, this.field)) this.$emit('save', JSON.parse(JSON.stringify(this.fieldCache)))
      },
      deep: true
    }
  },
  components: {
    KButton, KInput, KRadio, KSelect, KCard, KTextarea, KSwitch,
    MULTIPLE_CHOICE, LINEAR_SCALE, EXPLAIN
  }
}
</script>
