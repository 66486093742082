<template>
  <k-container class="view-user-container">
    <h1>官網會員</h1>
    <k-card stickyHeader>
      <div slot="header">
        <p>會員列表</p>
        <div class="action" v-if="userSelected.length">
          <k-button theme="info" @click="handleDistributeCoupon">發送優惠券</k-button>
        </div>
      </div>
      <k-table
        :items="userList"
        :fields="fields"
        v-model="userSelected"
        rowSelection
        :rowSelectionButtonProps="null"
        footerCaption
        hover
      >
        <!-- <p slot="table-caption">User Data</p> -->
        <template #cell(user_name)="{value, item}">
          <span>{{ value || '未設定' }}</span>
          <k-tooltip title="可升級為創始會員" v-if="
              filterOrderWithUser[item.user_id] && item.user_group !== 'ORIGINAL' &&
              filterOrderWithUser[item.user_id].reduce((acc, o) => acc+parseInt(o.order_amount), 0) >= 3000
            "><k-icon
            class="level-up"
            theme="info"
            icon="person-up"
          /></k-tooltip>
          <!-- <k-tooltip title="優惠券到期提醒" v-if="checkCouponsExpired(item.user_own_coupons, item.user_id)"><k-icon
            class="level-up"
            theme="danger"
            icon="ticket-perforated"
          /></k-tooltip> -->
          <k-tooltip title="生日禮提醒" v-if="checkBirthday(item)"><k-icon
            class="level-up"
            theme="warning"
            icon="calendar2-check"
          /></k-tooltip>
        </template>
        <template #cell(user_group)="{value}">
          <k-tag :theme="value === 'NORMAL' ? 'light' : 'info'">{{ value === 'NORMAL' ? 'CF好友' : '創始會員' }}</k-tag>
        </template>
        <template #cell(user_email)="data">
          <span style="margin-right: 8px">{{ data.value }}</span>
          <k-icon v-copy="data.value" v-copy:success="onCopy" icon="clipboard"></k-icon>
        </template>
        <template #cell(user_birthday)="{value}">
          {{ value ? moment().diff(timestampParse(parseInt(value)), 'years') : '未設定' }}
        </template>
        <template #cell(user_gender)="data">
          <k-tag v-if="data.value" :theme="data.value === 'Male' ? 'success' : 'danger'">
            {{ data.value }}
          </k-tag>
          <span v-else>-</span>
        </template>

        <template #cell(user_binding)="{value}">
          <k-tag :theme="userDict[value] ? 'warning' : 'dark'">{{  userDict[value] ? userDict[value].user_name : '未設定' }}</k-tag>
        </template>

        <template #cell(detail)="data">
          <k-button theme="info" varient="ghost" @click="changeFocusUser(data.item.user_id)">詳細資料</k-button>
        </template>
      </k-table>
    </k-card>

    <k-modal title="用戶資料" :show.sync="show" :width="600">
      <div v-if="focusUserInfo">
        <h2>{{ focusUserInfo.user_name }}</h2>
        <k-tag :theme="focusUserInfo.user_group === 'NORMAL' ? 'light' : 'info'">
          {{ focusUserInfo.user_group === 'NORMAL' ? 'CF好友' : '創始會員' }}
        </k-tag>
        <p class="register-at">{{ timestampParse(focusUserInfo.user_create_at).format('註冊時間：YYYY-MM-DD HH:mm') }}</p>

        <k-descriptions layout="vertical" bordered>
          <k-descriptions-item label="用戶" icon="person-fill">{{ focusUserInfo.user_name }}</k-descriptions-item>
          <k-descriptions-item label="年齡" icon="calendar-fill">{{ moment().diff(timestampParse(focusUserInfo.user_birthday), 'y') }}【{{ timestampParse(focusUserInfo.user_birthday).format('YYYY / MM / DD') }}】</k-descriptions-item>
          <k-descriptions-item label="性別" icon="gender-ambiguous">{{ focusUserInfo.user_gender || '未設定' }}</k-descriptions-item>
          <k-descriptions-item label="電子郵件" icon="envelope-at-fill">
            {{ focusUserInfo.user_email }}
            <k-icon v-copy="focusUserInfo.user_email" v-copy:success="onCopy" icon="clipboard"></k-icon>
          </k-descriptions-item>
          <k-descriptions-item label="手機號碼" icon="phone">0{{ focusUserInfo.user_phone }}</k-descriptions-item>
          <k-descriptions-item label="綁定好友" span="3" icon="people-fill">
            {{ focusUserInfo.user_binding 
              ?`${userDict[focusUserInfo.user_binding].user_name} | ${userDict[focusUserInfo.user_binding].user_email}` 
              : '未綁定'
            }}
          </k-descriptions-item>
          <k-descriptions-item label="詳細資訊" span="3" icon="geo-alt-fill">
            登入方式：{{ focusUserInfo.user_login_type }}<br>
            出貨地址：{{ focusUserInfo.user_city+focusUserInfo.user_urban_area+focusUserInfo.user_address }}
          </k-descriptions-item>
          <k-descriptions-item label="優惠券" span="3" icon="ticket-perforated-fill">
            <table class="user-order coupon" >
              <tr>
                <th>優惠券名稱</th>
                <th>類型</th>
                <th>優惠券到期日</th>
                <th></th>
              </tr>
              <tr v-for="coupon in JSON.parse(focusUserInfo.user_own_coupons)" :key="`${focusUserInfo.user_id}_coupon_${coupon.coupon_id}`">
                <td>{{ couponDict[coupon.coupon_id].coupon_name }}</td>
                <td>{{ couponDict[coupon.coupon_id].coupon_type === 'DISCOUNT' ? '折扣' : '贈品' }}</td>
                <td>{{ 
                  couponDict[coupon.coupon_id].coupon_exp
                    ? timestampParse(couponDict[coupon.coupon_id].coupon_exp).format('YYYY-MM-DD')
                    : timestampParse(coupon.coupon_get_at).add(couponDict[coupon.coupon_id].coupon_period, 'seconds').format('YYYY-MM-DD')
                }}</td>
                <td>
                  <span
                    @click="sendCouponExpiredMail(focusUserInfo, coupon, getCouponNoticeEdm(coupon))"
                  >
                    <k-tag theme="danger">
                      <k-icon icon="envelope-exclamation" /> 發送到期提醒
                    </k-tag>
                  </span>
                </td>
              </tr>
            </table>
          </k-descriptions-item>
          <k-descriptions-item icon="file-ruled-fill" label="歷史訂單" span="3">
            <table class="user-order" v-if="Object.keys(filterOrderWithUser).length && filterOrderWithUser[focusUserInfo.user_id]">
              <tr>
                <th>訂單編號</th>
                <th>金額</th>
                <th>下訂日期</th>
                <th>付款類型</th>
                <th></th>
              </tr>
              <tr
                v-for="order in filterOrderWithUser[focusUserInfo.user_id]"
                :key="`user-order-${focusUserInfo.user_id}-${order.orders_id}`"
                class="user-order-detail"
              >
                <td>{{ order.orders_id }}</td>
                <td>${{ order.order_amount }}</td>
                <td>{{ timestampParse(order.order_create_at).format('YYYY-MM-DD') }}</td>
                <td :class="{credit: order.order_pay_type === 'CREDIT'}">{{ order.order_pay_type === 'CREDIT' ? '刷卡' : '到付' }}</td>
                <td @click="toOrder(order.orders_id)"><k-tag theme="secondary"><k-icon icon="search" /> 查看訂單</k-tag></td>
              </tr>
            </table>
            <p v-else>無訂單</p>
          </k-descriptions-item>
        </k-descriptions>
      </div>
      <template #footer>
        <template>
          <k-button
            v-if="
              filterOrderWithUser &&
              filterOrderWithUser[focusUserInfo.user_id] && focusUserInfo.user_group !== 'ORIGINAL' &&
              filterOrderWithUser[focusUserInfo.user_id].reduce((acc, o) => acc+parseInt(o.order_amount), 0) >= 3000
            "
            @click="levelToOrginal({
              ...focusUserInfo,
              user_consumption_amount: filterOrderWithUser[focusUserInfo.user_id].reduce((acc, o) => acc+parseInt(o.order_amount), 0)
            })"
            theme="info" 
          >升級為創始會員
          </k-button>
          <k-button
            v-if="checkBirthday(focusUserInfo)"
            @click="sendBirthdayRemind(focusUserInfo)"
            theme="warning">派發生日禮提醒
          </k-button>
        </template>
      </template>
    </k-modal>

    <k-modal class="coupon-modal" title="選擇優惠券" :show.sync="showCouponModal" :width="800" @ok="handleDistribute">
      <k-card outline="light" noShadow>
        <h3>選擇會員</h3>
        <div class="user-button-list">
          <k-button
            v-for="user in userSelected" :key="`user-choosed-${user.user_id}`"
            theme="secondary"
            icon="x"
            @click="handleUnselectUser(user)"
          >
            {{ user.user_name || '未設定' }}[{{ user.user_phone || '未設定' }}]
          </k-button>
        </div>
      </k-card>
      <k-divider>選擇派發優惠券</k-divider>
      <k-card bgColor="#18181c" noShadow>
        <k-table
          :items="memberCoupons"
          :fields="CouponFields"
          v-model="couponSelected"
          rowSelection
          :rowSelectionButtonProps="null"
          footerCaption
          hover
        >
          <template #cell(coupon_name)="{value}"><k-tag theme="warning">{{ value }}</k-tag></template>
          <template #cell(coupon_type)="{value}">
            <k-tag :theme="value === 'DISCOUNT' ? 'light': 'white'">{{ value === 'DISCOUNT' ? '折扣' : '贈品' }}</k-tag>
          </template>
        </k-table>
      </k-card>
    </k-modal>
  </k-container>
</template>

<script>
import KTable from '@/components/Table'
import KCard from '@/components/Card'
import KTag from '@/components/Tag'
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import KIcon from '@/components/Icon'
import KTooltip from '@/components/Tooltip'
import { KDescriptions, KDescriptionsItem } from '@/components/Descriptions'
import KDivider from '@/components/Divider'
import AdminCard from './components/AdminCard'
import { mapActions, mapGetters, mapState } from 'vuex'
import { timestampParse, capitalize } from '@/utils'
import moment from 'moment'

export default {
  name: 'User',
  mounted() {
    this.$store.dispatch('admin/query')
    this.$store.dispatch('user/query')
    if (!this.$store.state.orders.ordersList.length) this.$store.dispatch('orders/query')
    if (!this.$store.state.edm.email_historyList.length) this.$store.dispatch('edm/queryEmailHistory')
  },
  data() {
    return {
      fields: UserFields,
      CouponFields,
      items: [
        { user_id: 'k001' ,user_name: 'glove', user_email: 'glovecoding@gmail.com', user_age: 24, user_gender: 'Male' },
        { user_id: 'k002' ,user_name: 'paboya', user_email: 'paboya@gmail.com', user_age: 69, user_gender: 'Female' },
        { user_id: 'k003' ,user_name: 'dexter', user_email: 'dexisbitch@gmail.com', user_age: 25, user_gender: 'Male' },
        { user_id: 'k004' ,user_name: 'alexander', user_email: 'alex058188@gmail.com', user_age: 24, user_gender: 'Male' },
        { user_id: 'k005' ,user_name: 'chikenBreast', user_email: 'welovechikenbreast@gmail.com', user_age: 24, user_gender: 'Male' }, 
      ],
      buttonNum: 1,
      show: false,
      showCouponModal: false,
      pagination: {
        current: 1,
        limit: 5
      },
      focusUserInfo: null,
      userSelected: [],
      couponSelected: [],
    }
  },
  computed: {
    ...mapState('user', ['userList']),
    ...mapGetters('orders', ['filterOrderWithUser']),
    ...mapGetters('user', ['userDict']),
    ...mapGetters('coupon', ['couponDict', 'memberCoupons']),
    ...mapGetters('edm', ['emailHistoryTemplateDict', 'edmSystemIdDict']),
  },
  methods: {
    ...mapActions('user', ['levelUpMember', 'issueVoucher', 'distributeCouponToUsers']),
    ...mapActions('edm', ['sendEmail', 'sendSMS']),
    timestampParse,
    moment(value) {
      return value ? moment(value) : moment()
    },
    onCopy() {
      this.$message.success('已複製到剪貼簿')
    },
    changeFocusUser(id) {
      const userIndex = this.userList.map(i => i.user_id).indexOf(id)
      this.focusUserInfo = { ...this.userList[userIndex] }
      this.show = true
    },
    showModal() {
      this.show = true
    },
    levelToOrginal(user) {
      this.$confirm.info({
        title: '確定要將此會員等級升級為『創始會員』？',
        text: `會員：${user.user_name} | 累計消費金額：$${user.user_consumption_amount}`,
        icon: 'person-up',
        callback: async (bool) => {
          if (bool) {
            await this.levelUpMember(user.user_id)
            await this.sendEmail({
              systemId: 'ORIGINAL_MAMBER_QUALIFY',
              user_id: user.user_id,
              recipients : [{ name: user.user_name, address: user.user_email }]
            })
            this.focusUserInfo.user_group = 'ORIGINAL'
            this.$message.success('已將會員升級為創始會員')
          }
        }
      })
    },
    toOrder(orders_id) {
      this.$router.push({ path: '/e-commerce-order', query: { id: orders_id } })
    },
    checkCouponsExpired(coupons, user_id) {
      if (!coupons || !JSON.parse(coupons.length) || !Object.keys(this.couponDict).length || !this.$store.state.edm.email_historyList.length) return false
      const couponList = JSON.parse(coupons)
      return couponList.filter(c => {
        const coupon = this.couponDict[c.coupon_id]
        // const edmList = this.getCouponNoticeEdm(c)
        const couponExpire = parseInt(coupon.coupon_exp)|| parseInt(coupon.coupon_period) + parseInt(c.coupon_get_at)
        if (edmList && edmList.length) {
          return edmList.some(edm => {
            const history = this.emailHistoryTemplateDict[edm.template_id].find(history => history.user_id === user_id)
            return couponExpire - new moment().unix() - edm.lastTime < 86400 && (!history || !history.email_history_info || history.email_history_info.coupon_id !== c.coupon_id) 
          })
        }
        return couponExpire - new moment().unix() > 0 && couponExpire - new moment().unix() < 259200
      }).length
    },
    getCouponNoticeEdm(coupon) {
      const currentCoupon = this.couponDict[coupon.coupon_id]
      const timeList = currentCoupon.coupon_notice_templates.map(t => t.lastTime).filter((value, index, array) => array.indexOf(value) === index).sort((a, b) => b - a) // get unique time
      if (!timeList) return
      for (let index = 0; index < timeList.length; index++) {
        const time = timeList[index]
        if (currentCoupon.coupon_exp && parseInt(time) + new moment().unix() < parseInt(currentCoupon.coupon_exp)) {
          console.log(time);
          return currentCoupon.coupon_notice_templates.filter(t => t.lastTime === time)
        }
        if (currentCoupon.coupon_period && parseInt(coupon.coupon_get_at) + parseInt(currentCoupon.coupon_period) - new moment().unix() > parseInt(time))  {
          console.log(time);
          return currentCoupon.coupon_notice_templates.filter(t => t.lastTime === time)
        }
        continue
      }
    },
    sendCouponExpiredMail(user, coupon, edms) {
      const couponInfo = this.couponDict[coupon.coupon_id]
      const userCamelInfo = Object.entries(user).reduce((acc, [key, value]) => ({
        ...acc, [key.split('_').map((w, index) => index ? capitalize(w): w).join('')]: value
      }), {})
      const couponCamelInfo = Object.entries(couponInfo).reduce((acc, [key, value]) => ({
        ...acc, [key.split('_').map((w, index) => index ? capitalize(w): w).join('')]: value
      }), {})
      if (!edms) return this.$message.error('所有鏈結的 EDM 皆以超時，建議設置倒數天數為 0 的 EDM 確保能夠做最後提醒通知', 8000)
      const edmList = edms.map(e => ({ ...e, ...this.edmSystemIdDict[e.template_id] }))
      const edmString = edmList.map(e => `[剩餘 ${e.lastTime / 86400} 天] ${e.email_template_name} (${e.email_template_carrier})`).join("\n")

      this.$confirm.info({
        title: '確定要寄送優惠券即將到期通知信？',
        text: `會員：${user.user_name}
        到期優惠券：${couponInfo.coupon_name}
        ----------------------------------------------------------------------------------------
        鏈結 EDM:
        ${edmString}`,
        icon: 'send-check',
        callback: async (bool) => {
          if (bool) {
            const edmPromises = edmList.map(e => new Promise(async resolve => {
              if (e.email_template_carrier === 'SMS' && !user.userPhone && user.user_phone.length !== 9) return resolve()
              const recipients = [{
                name: user.user_name,
                address: e.email_template_carrier === 'EMAIL' ? user.user_email : `0${user.user_phone}`,
                variables: { ...userCamelInfo, ...couponCamelInfo }
              }]
              const edmPayload = {
                systemId: e.email_template_system_id,
                user_id: user.user_id,
                email_info: { coupon_id: coupon.coupon_id },
                recipients
              }
              if (e.email_template_carrier === 'EMAIL') resolve(await this.sendEmail(edmPayload))
              else resolve(await this.sendSMS(edmPayload))
            }))
            const res = await Promise.all(edmPromises)
            this.$message.success('已寄送優惠券即將到期通知信')
          }
        }
      })
    },
    checkBirthday(user) {
      const birthday = timestampParse(user.user_birthday)
      const today = new moment()
      const historyWithBirthday = [
        ...(this.emailHistoryTemplateDict['BHD_ORIGINAL'] || []),
        ...(this.emailHistoryTemplateDict['BHD_NORMAL'] || [])]
        .filter(h => h.user_id === user.user_id).map(h => this.timestampParse(h.email_history_create_at).format('YYYY-MM'))
      if (historyWithBirthday.length && historyWithBirthday.includes(today.format('YYYY-MM'))) return false
      return birthday.month() === today.month()
    },
    sendBirthdayRemind(user) {
      this.$confirm.info({
        title: '確定要寄送生日禮通知信？',
        text: `會員：${user.user_name}
        生日：${this.timestampParse(user.user_birthday).format('YYYY-MM-DD')}`,
        icon: 'mail',
        callback: async (bool) => {
          if (bool) {
            if (user.user_group === 'NORMAL') await this.issueVoucher({ user_id: user.user_id, voucher_value: 50 })
            const recipients = [{
              name: user.user_name, address: user.user_email,
              variables: { userName: user.user_name }
            }]
            await this.sendEmail({
              systemId: user.user_group === 'NORMAL' ? 'BHD_NORMAL' : 'BHD_ORIGINAL',
              user_id: user.user_id,
              recipients
            })
            this.$message.success('已寄送生日禮通知信')
          }
        }
      })
    },
    handleDistributeCoupon() {
      this.showCouponModal = true
    },
    handleUnselectUser(user) {
      this.userSelected = this.userSelected.filter(u => u.user_id !== user.user_id)
    },
    async handleDistribute() {
      await this.distributeCouponToUsers({ coupons: this.couponSelected, users: this.userSelected })
      this.$message.success('已派發優惠券至指定帳號')
      this.couponSelected = []
      this.userSelected = []
      this.showCouponModal = false
    }
  },
  components: {
    AdminCard,
    KTable, KCard, KTag, KButton, KModal, KDescriptions, KDescriptionsItem, KIcon, KTooltip, KDivider
  }
}

const UserFields = [
  { key: 'user_name', fieldIndex: 'user_name', label: '用戶名稱', searchable: true,
    sorter: (a, b) => ('' + a).localeCompare(b)
  },
  { key: 'user_group', fieldIndex: 'user_group', label: '會員等級', filters: [
    { value: 'NORMAL', text: 'CF好友' },
    { value: 'ORIGINAL', text: '創始會員' }
  ]},
  { key: 'user_login_type', fieldIndex: 'user_login_type', label: '登入方式', filters: true},
  { key: 'user_email', fieldIndex: 'user_email', label: '信箱', searchable: true},
  { key: 'user_birthday', fieldIndex: 'user_birthday', label: '年齡', sorter: (a, b) => a - b }, 
  { key: 'user_gender', fieldIndex: 'user_gender', label: '性別', filters: true,
    sorter: (a, b) => ('' + a).localeCompare(b),
  },
  { key: 'user_binding', fieldIndex: 'user_binding', label: '綁定會員' },
  { key: 'detail', fieldIndex: 'detail', label: '' }, 
]
const CouponFields = [
  // { key: 'coupon_uid', fieldIndex: 'coupon_uid', label: '優惠碼', searchable: true },
  { key: 'coupon_name', fieldIndex: 'coupon_name', label: '優惠券名稱', searchable: true }, 
  { key: 'coupon_desc', fieldIndex: 'coupon_desc', label: '描述' }, 
  { key: 'coupon_type', fieldIndex: 'coupon_type', label: '類型', sorter: (a, b) => a === 'DISCOUNT' ? 0 : -1 },
]
</script>
