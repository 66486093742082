<script>
import { KInput, KSelect, KDatePicker } from '@/components/Form'
import KDivider from '@/components/Divider'
import KCard from '@/components/Card'
import SeoFeatures from '@/assets/structuredData.json'
import { getRandom } from '@/utils'

function featureFormType(type) {
  switch (type) {
    case 'String': return ""
    case 'Rating': return {
      "@type": "Rating",
      ratingValue: "",
      bestRating: ""
    }
    case 'MonetaryAmount': return {
      "@type": "MonetaryAmount",
      value: "",
      currency: ""
    }
    case 'AggregateRating': return {
      "@type": 'AggregateRating',
      ratingValue: "",
      reviewCount: ""
    }
    default: return ""
  }
}

const transformTemplateFeatures = (templateFeatures, SeoFeatures, translateFeature) => 
  Object.keys(templateFeatures).reduce((acc, key) => {
    acc[key] = {
      '@type': SeoFeatures[key].type,
      ...templateFeatures[key].reduce((acc, f) => {
        const SeofeatureLayIndex = SeoFeatures[key]['features'].map(f => f.key).indexOf(f.key || f)
        return {
          ...acc,
          [typeof f === 'string'? f : f.key]: typeof f === 'string'
            ? featureFormType(SeoFeatures[key]['features'][SeofeatureLayIndex]['type'])
            : translateFeature(f.features, SeoFeatures[key]['features'][SeofeatureLayIndex])
        }
      }, {})
    }
    return acc
  }, {})

export default {
  SeoFeatures,
  props: {
    templateFeatures: Object,
    originalStructureData: Object
  },
  mounted() {
    const { SeoFeatures } = this.$options
    console.log(this.originalStructureData);
    this.templateForm = this.originalStructureData || transformTemplateFeatures(this.templateFeatures, SeoFeatures, this.translateFeature)
  },
  data() {
    return {
      templateForm: undefined,
      mutated: false
    }
  },
  methods: {
    translateFeature(featureLay, seoFeatureLay) {
      const findChildSeoFeatureLay = (key) => seoFeatureLay.features[seoFeatureLay.features.map(f => f.key).indexOf(key)]
      return featureLay.reduce((acc, f) => {
        acc['@type']= seoFeatureLay.type
        return {
          ...acc,
          [typeof f === 'string'? f : f.key]: typeof f === 'string'
            ? featureFormType(findChildSeoFeatureLay(f).type)
            : this.translateFeature(f.features, findChildSeoFeatureLay(f.key))
        }
      }, {})
    },
    handleInput(target, prop, val) {
      target[prop] = val
      if (!this.mutate) {
        this.mutated = true
        this.$emit('mutate')
      }
    },
    getData() {
      return this.templateForm
    },
    findFeatureInLayer: (featureLayer, key) => featureLayer.features.filter(f => f.key === key)[0]
  },
  watch: {
    templateFeatures(newValue) {
      console.log(newValue);
      const { SeoFeatures } = this.$options
      this.templateForm = transformTemplateFeatures(newValue, SeoFeatures, this.translateFeature)
    }
  },
  components: {
    KInput, KDivider, KCard, KSelect, KDatePicker
  },
  render(h) {
    const renderFeatureInput = (featureLay, seoFeatureLayer) => Object.entries(featureLay)
      .map(([f, v]) => {
        const featureFormRenderType = () => {
          const { name, type } = this.findFeatureInLayer(seoFeatureLayer, f)
          const $attrs = { label: name, placeholder: `輸入${name}`, value: v, block: true, theme: 'secondary' }

          switch (type) {
            case 'String': return (
              <k-input {...{props: $attrs}} onInput={val => this.handleInput(featureLay, f, val)} />
            )
            case 'Number': return (
              <k-input {...{props: $attrs}} onInput={val => this.handleInput(featureLay, f, val)} type="number" />
            )
            case 'Select': return (
              <k-select
                {...{props: $attrs}}
                onInput={val => this.handleInput(featureLay, f, val)}
                options={[
                  { value: 'TWD', text: '新台幣' },
                  { value: 'USD', text: '美金' }
                ]}
              />
            )
            case 'Date': return (
              <k-datePicker
                {...{props: $attrs}} onInput={val => this.handleInput(featureLay, f, val.format('yyyy-MM-DD'))}
              />
            )
            case 'Rating': return (
              <div>
                <label class="group-label">{name}</label>
                <div class="input-group">
                  <k-input
                    onInput={val => this.handleInput(featureLay[f], 'ratingValue', val)}
                    placeholder={`輸入評分`}
                    type="number"
                    value={v.ratingValue}
                  />
                  <k-input
                    onInput={val => this.handleInput(featureLay[f], 'bestRating', val)}
                    placeholder={`輸入總分`}
                    type="number"
                    value={v.bestRating}
                  />
                </div>
              </div>
            )
            case 'AggregateRating': return (
              <div>
                <label class="group-label">{name}</label>
                <div class="input-group">
                  <k-input
                    onInput={val => this.handleInput(featureLay[f], 'ratingValue', val)}
                    placeholder={`輸入平均評分`}
                    type="number"
                    value={v.ratingValue}
                  />
                  <k-input
                    onInput={val => this.handleInput(featureLay[f], 'reivewCount', val)}
                    placeholder={`輸入評分總數`}
                    type="number"
                    value={v.bestRating}
                  />
                </div>
              </div>
            )
            case 'MonetaryAmount': return (
              <div>
                <label class="group-label">{name}</label>
                <div class="input-group">
                  <k-input
                    onInput={val => this.handleInput(featureLay[f], 'value', val)}
                    placeholder={`輸入運費`}
                    type="number"
                    value={v.value}
                  />
                  <k-select
                    onInput={val => this.handleInput(featureLay[f], 'currency', val)}
                    options={[
                      { value: 'TWD', text: '新台幣' },
                      { value: 'USD', text: '美金' }
                    ]}
                    theme="secondary"
                    placeholder={`選擇幣別`}
                    value={v.currency}
                    block
                  />
                </div>
              </div>
            )
            default: return (
              <k-input {...{props: $attrs}} onInput={val => this.handleInput(featureLay, f, val)} value={v} />
            )
          }
        }
        return typeof v === 'string' || ['Rating', 'MonetaryAmount', 'AggregateRating'].includes(v['@type'])
        ? f !== '@type'
          ? featureFormRenderType()
          : null
        : (<div>
          <k-divider>{ f }</k-divider>
          {renderFeatureInput(v, this.findFeatureInLayer(seoFeatureLayer, f))}
        </div>)
      })

    const { SeoFeatures } = this.$options
    return this.templateForm && (<div class="seo-nested-form">
      {Object.keys(this.templateForm).map(type => (
        <k-card outline="secondary" header={SeoFeatures[type].name}>
          {renderFeatureInput(this.templateForm[type], SeoFeatures[type])}
        </k-card>
      ))}
    </div>)
  }
}
</script>
