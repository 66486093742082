const { GoogleSpreadsheet } = require('google-spreadsheet');
const key = require(`./${process.env.VUE_APP_GOOGLE_SHEET_TOKEN_JSON}`)
const doc = new GoogleSpreadsheet(process.env.VUE_APP_GOOGLE_SHEET_ID);

export async function buildSheet({ title, headerValues, headerTexts, rows }) {
  await doc.useServiceAccountAuth({
    client_email: key.client_email,
    private_key: key.private_key
  })
  const sheet = await doc.addSheet({ title, headerValues: headerTexts || headerValues });
  await sheet.addRows(rows)
  return sheet
}

export async function copySheet(doc, sheetId='866588761') {
  const sheet = doc.sheetsById[sheetId]
  const response = await sheet.copyToSpreadsheet(doc.spreadsheetId)
  await doc.loadInfo()
  const newSheet = doc.sheetsById[response.data.sheetId]
  return newSheet
}

export async function connectSheet(docId) {
  const doc = new GoogleSpreadsheet(docId)
  await doc.useServiceAccountAuth({
    client_email: key.client_email,
    private_key: key.private_key
  })
  await doc.loadInfo()
  return doc
}

export async function updateSheet(sheet, properties={}, data={}, range) {
  if (!sheet) throw 'Sheet is undefinded'
  if(Object.keys(properties).length) await sheet.updateProperties({ ...properties })
  if (Array.isArray(data)) updateRows(sheet, data)
  else updateCells(sheet, range, data)
}

async function updateCells(sheet, range="A1:Z100", positionWithValueDict = {}) {
  await sheet.loadCells(range)
  const entries = Object.entries(positionWithValueDict)
  if (entries.length) {
    for (const [cellPosition, value] of entries) {
      let cell = sheet.getCellByA1(cellPosition)
      cell.value = value
    }
    await sheet.saveUpdatedCells();
  }
}

async function updateRows(sheet, rowArray=[]) {
  return void 8
}
