<template>
  <k-container class="view-email-build-container">
    <template #header>
      <div>
        <h2>建立信件模板</h2>
        <k-select
          v-model="mode"
          :options="[{ value: 'SIMPLE', text: '一般信件模式' }, { value: 'ADVANCE', text: 'HTML模式' }]"
        />
      </div>
      <div>
        <Variable />
        <k-button theme="primary" @click="$router.back()">返回郵件</k-button>
      </div>
    </template>

    <k-card>
      <k-form :questionConfig.sync="questionConfig" :defaultValues="defaultValues" @submit="handleSubmit">
        <k-divider slot="after(email_template_desc)">模板內容</k-divider>
        <k-card
          class="preview"
          v-if="questionConfig[3].questionType === 'k-coder'"
          slot="after(email_template_content)"
          header="模板內容預覽："
          outline="light"
        >
          <section v-html="questionConfig[3].value"/>
        </k-card>
      </k-form>
    </k-card>
  </k-container>
</template>

<script>
import KCard from '@/components/Card'
import KButton from '@/components/Button'
import KDivider from '@/components/Divider'
import { KMenu, KMenuItem, KMenuDivider } from '@/components/Menu'
import { KForm, KSelect, KCoder } from '@/components/Form'
import Variable from './components/Variable'
import { templateConfig } from './config'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      questionConfig: [],
      defaultValues: {},
      mode: 'SIMPLE',
      codeMirror: undefined,
    }
  },
  methods: {
    ...mapActions('email', ['createEmailTemplate']),
    async handleSubmit({ values }) {
      console.log(values);
      const res = await this.createEmailTemplate({
        ...values,
        email_template_type: this.mode === 'SIMPLE' ? 'TXT' : 'HTML' 
      })
      if (res.status === 200) {
        this.$message.success('已成功建立模板')
        this.$router.back()
      }
    }
  },
  watch: {
    mode: {
      immediate: true,
      handler(newVal) {
        this.questionConfig = templateConfig[newVal]
      }
    }
  },
  components: {
    KCard, KButton, KForm, KSelect, KCoder, KMenu, KMenuItem, KMenuDivider, KDivider ,Variable
  }
}
</script>
