<template>
  <div class="setting linear-scale">
    <div class="max-scale">
      <k-input label="刻度總數（分量數)" v-model="maxScale" type="number" placeholder="刻度總數" />
      <k-select @change="useTemplate" theme="secondary" placeholder="使用預設模板" :options="templateOptions" block/>
    </div>
    <section class="scale-setting">
      <div class="scale" v-for="scale, index in scalePoints" :key="`linear-scale-${index}`">
        <header><i class="circle"></i></header>
        <k-input v-model="scalePoints[index]" :placeholder="`選項${index+1}`" />
      </div>
    </section>
  </div>
</template>

<script>
import { KInput, KSelect } from '@/components/Form'

export default {
  props: {
    field: Object
  },
  data() {
    return {
      LINEAR_SCALE_TEMPLATES,
      maxScale: "5",
      scalePoints: Array(5).fill('')
    }
  },
  computed: {
    templateOptions() {
      return this.LINEAR_SCALE_TEMPLATES.map((temp, index) => ({ text: temp.text, value: `${index}` }))
    }
  },
  methods: {
    useTemplate(index) {
      console.log(this.LINEAR_SCALE_TEMPLATES[index]);
      this.maxScale = this.LINEAR_SCALE_TEMPLATES[index].maxScale
      this.scalePoints = this.LINEAR_SCALE_TEMPLATES[index].scalePoints
    }
  },
  watch: {
    maxScale(newVal) {
      if (parseInt(newVal) > this.scalePoints.length) {
        this.scalePoints = [...this.scalePoints, ...Array(parseInt(newVal) - this.scalePoints.length).fill('')]
      } 
      
      if (parseInt(newVal) < this.scalePoints.length){
        this.scalePoints = this.scalePoints.slice(0, this.scalePoints.length - 1)
      }
    },
    scalePoints: {
      handler() {
        this.$emit('update:field', { ...JSON.parse(JSON.stringify(this.field)), scalePoints: this.scalePoints })
      },
      deep: true
    }
  },
  components: {
    KInput, KSelect
  }
}

const LINEAR_SCALE_TEMPLATES = [
  { text: '滿意度 5 分量表', maxScale: "5", scalePoints: ['非常不滿意', '不滿意', '普通', '滿意', '非常滿意'] },
  { text: '滿意度 7 分量表', maxScale: "7", scalePoints: ['非常不滿意', '不滿意', '稍微不滿意', '普通', '稍微滿意', '滿意', '非常滿意'] },
  { text: '同意度 5 分量表', maxScale: "5", scalePoints: [ '非常不同意', '不同意', '沒意見', '同意', '非常同意'] },
  { text: '同意度 7 分量表', maxScale: "7", scalePoints: ['非常不同意', '不同意', '有點不同意', '沒意見', '有點同意', '同意', '非常同意'] },
]
</script>
