<template>
  <k-container class="view-seo-edit-container">
    <h2 slot="header">建立 SEO 模板 <k-icon icon="info-circle" theme="info" @click="showDrawer = true"/></h2>

    <k-card class="template-name">
      <k-input v-model="templateName" label="模板名稱" placeholder="請輸入模板名稱" required></k-input>
      <k-textarea v-model="templateDesc" label="模板描述" placeholder="請輸入模板描述" required></k-textarea>
    </k-card>

    <k-card v-for="item in seoTypes" :key="item.key" :header="item.name">
      <Feature
        v-for="feat in item.features"
        :key="feat.key"
        :ref="feat.features ? feat.key : null"
        :choosedFeatures="choosedFeatures[item.key]"
        :typeKey="item.key"
        :feature="feat"
        :handleClick="handleClick"
      />
    </k-card>

    <k-button class="newCategory" @click="showModal(true)" theme="secondary" block>加入屬性</k-button>
    <k-button @click="handleSubmit" block theme="primary">建立模板</k-button>

    <k-modal :show.sync="modalVisiable" hideFooter>
      <div v-if="unusedType.length">
        <k-button
          v-for="[itemKey, item] in unusedType"
          :key="`choose_key_${itemKey}`"
          theme="secondary"
          block
          @click="chooseType(itemKey)"
        >{{ item.name }} ({{ item.type }})</k-button>
      </div>
      <k-empty description="已沒有可選擇屬性" v-else></k-empty>
    </k-modal>

    <k-drawer :show.sync="showDrawer" title="SEO模板說明" width="360">
      <section class="drawer-content">
        建立SEO模板的用意在於：不同的類型頁面會使用不同的 Structured Data 屬性設定，所以讓後台人員可以建立 SEO 的模板直接套用於建立各類型項目時的設定。
        <img :src="require('@/assets/seo-example.webp')" alt="seo-example">
      </section>
    </k-drawer>
  </k-container>
</template>

<script>
import { KForm, KInput, KTextarea } from '@/components/Form'
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import KModal from '@/components/Modal'
import KEmpty from '@/components/Empty'
import KDrawer from '@/components/Drawer'
import SeoFeatures from '@/assets/structuredData.json'
import Feature from './components/Feature'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      SeoFeatures,
      modalVisiable: false,
      templateName: "",
      templateDesc: "",
      choosedFeatures: {},
      showDrawer: false
    }
  },
  computed: {
    seoTypes() {
      return Object.keys(this.SeoFeatures)
        .filter(type => Object.keys(this.choosedFeatures).includes(type))
        .map(type => ({ key: type, ...this.SeoFeatures[type] }))
    },
    unusedType() {
      return Object.entries(this.SeoFeatures)
        .filter(([typeKey, type]) => !Object.keys(this.choosedFeatures).includes(typeKey))
    },
  },
  methods: {
    ...mapActions('seo', ['add']),

    handleClick(typeKey, feature) {
      const index = this.choosedFeatures[typeKey].indexOf(feature)
      if (index === -1) this.choosedFeatures[typeKey] = [...this.choosedFeatures[typeKey], feature]
      else this.choosedFeatures[typeKey].splice(index, 1)
    },
    validation() {
      if (!this.templateName) return "EMPTY_NAME"
      if (!Object.values(this.choosedFeatures).some(f => Array.isArray(f) && f.length)) return "EMPTY_FEATURE"
      return "VALID"
    },
    async handleSubmit() {
      if (!this.templateName) {
        this.$message.warning('未設定模板名稱')
        return
      }
      const refsResult = Object.keys(this.$refs).reduce((acc,ref)=>{
        const typeKey = this.$refs[ref][0].$props.typeKey
        if (this.$refs[ref][0].handleSubmit().length) {
          acc[typeKey].push({key: ref, features: this.$refs[ref][0].handleSubmit()})
        }
        return acc
      }, JSON.parse(JSON.stringify(this.choosedFeatures)))
      if (!Object.values(refsResult).some(f => Array.isArray(f) && f.length)) {
        this.$message.warning('未選擇屬性')
        return
      }
      const response = await this.add({
        seo_template_name: this.templateName,
        seo_template_desc: this.templateDesc,
        seo_template_json: JSON.stringify(refsResult),
      })
      if (response) {
        this.$message.success('已建立模板')
      }
    },
    showModal(condition) {
      this.modalVisiable = condition
    },
    chooseType(type) {
      this.choosedFeatures = { ...this.choosedFeatures, [type]: [] }
      this.showModal(false)
    }
  },
  components: {
    Feature,
    KForm, KCard, KButton, KModal, KInput, KTextarea, KEmpty, KDrawer
  }
}
</script>
