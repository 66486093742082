<template>
  <k-drawer v-if="Object.keys(focusFile).length" title="File Info" :width="360" :show.sync="show">
      <section class="drawer-content">
        <div class="drawer-file-type">
          <k-icon :icon="focusFile.type==='image' ? 'image' : 'play-btn-fill'"></k-icon>
        </div>

        <div class="drawer-file-desc">
          <div>
            <h3>{{ focusFile.file_name }}</h3>
            <p>上次使用：{{ focusFile.file_uploadAt.format('YYYY / MM / DD HH:mm:ss') }}</p>
            <p>資料夾路徑：{{ focusFile.file_path | parseFilePath }}</p>
            <p>檔案大小：{{ focusFile.file_size }}Kb</p>
          </div>
          <span>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis mollitia, ut porro voluptates hic ad quia dolore iusto vel quos fugit laudantium deleniti rerum illum quasi? Illum ratione ipsum architecto?
          </span>
        </div>
      </section>

      <section  class="drawer-file-used">
          <h3>Used in {{ focusFile.useIn }}</h3>
          <k-menu>
            <template v-if="focusFile.useIn==='Post'">
              <k-menu-item v-for="post in filterPostByIds" :key="post.key_id" @click="toSource('post', post.key_id)">
                <k-icon icon="file-earmark-text-fill"></k-icon>
                <span>{{post.post_title}}</span>
              </k-menu-item>
            </template>
            <k-menu-item v-if="focusFile.useIn==='Category'">
              <k-icon icon="diagram-3"></k-icon>
              <span @click="toSource('category', focusFile.category_id)">{{focusFile.category_name}}</span>
            </k-menu-item>
            <k-menu-item v-if="focusFile.useIn==='Product'">
              <k-icon icon="cart-fill"></k-icon>
              <span @click="toSource('product', focusFile.product_id)">{{focusFile.product_name}}</span>
            </k-menu-item>
            <k-menu-item v-if="!focusFile.useIn">
              <k-empty></k-empty>
            </k-menu-item>
          </k-menu>
        </section>

        <footer>
          <div
            class="drawer-footer-btn"
            v-copy="focusFile.file_path"
            v-copy:success="onCopy"
          >
            <k-icon icon="link"></k-icon>
            Get Url
          </div>
          <k-popconfirm
            icon="question-octagon-fill"
            iconTheme="danger"
            placement="left"
            message="確定要刪除此檔案嗎？"
            :disabled="!!focusFile.useIn"
            @confirm="handleDelete(focusFile)"
            @prohibit="handleProhibit"
          >
            <div class="drawer-footer-btn"><k-icon icon="trash"></k-icon>Delete</div>
          </k-popconfirm>
        </footer>
    </k-drawer>
</template>

<script>
import KDrawer from '@/components/Drawer'
import KEmpty from '@/components/Empty'
import KPopconfirm from '@/components/Popconfirm'
import { fileSizeParse } from '@/utils'
import { KMenu, KMenuItem } from '@/components/Menu'
import { mapActions, mapState } from 'vuex'
import storage from '@/utils/storage'

export default {
  props: {
    focusFile: Object,
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    ...mapActions('media', ['deleteFile']),
    fileSizeParse,
    onCopy() {
      this.$message.success('以複製圖片網址至剪貼簿')
    },
    toSource(type, id) {
      this.$router.push({ path: '/media/image', query: { type, id } })
    },
    handleProhibit() {
      this.$message.error('此圖片正在被使用,無法進行刪除！')
    },
    async handleDelete(item) {
      const response = await this.deleteFile(item)
      // const response = await storage.path(item.file_path).delete();
      console.log(response);
      if (response.status === 200) {
        this.$message.success('已刪除檔案')
        this.show = false
      }
    }
  },
  computed: {
    ...mapState('posts', ['postList']),

    filterPostByIds() {
      if (this.focusFile && this.postList.length) {
        return this.postList.filter(e => this.focusFile.post_id.includes(e.key_id))
      }
      return []
    }
  },
  watch: {
    focusFile() {
      this.show = true
    }
  },
  filters: {
    parseFilePath: path => {
      const pathArray = path.split('/')
      return pathArray.slice(1, pathArray.length-1).join('/')
    }
  },
  components: {
    KMenu, KMenuItem, KDrawer, KEmpty, KPopconfirm
  },
}
</script>
