<template>
  <span :class="classes" :style="styles">
    <slot></slot>
    <i v-if="closable" class="bi bi-x" @click="handleClick"></i>
  </span>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'light'
    },
    closable: Boolean,
    color: String
  },
  methods: {
    handleClick() {
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    }
  },
  computed: {
    classes() {
      return {
        'k-tag': true,
        [`k-tag--${this.theme}`]: !!this.theme,
      }
    },
    styles() {
      return {
        backgroundColor: this.color || null
      }
    }
  },
}
</script>
