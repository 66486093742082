<template>
  <span
    :class="classes"
    v-click-away="hide"
    @mouseenter="cleanable ? hendleHover('x-circle-fill') : void 8"
    @mouseleave="hendleHover('calendar')"
  >
    <k-input ref="self" type="text" v-bind="$props" :value="dateFormat" @onClick="toggleShow" :readonly="isReadonly">
      <k-icon slot="icon" :icon="icon" color="#757575" @click="cleanable ? handleClean : void 8" />
    </k-input>
    <transition name="date-picker-calendar">
      <k-calendar
        v-if="showCalendar"
        :style="{ transform: fixedTransform ? `translateY(-${fixedTransform + 15}px)` : null }"
        :selectedDate="value"
        @onSelect="handleSelect">
        <span slot="footer" @click="getToday">today</span>
      </k-calendar>
    </transition>
  </span>
</template>

<script>
import KInput from './KInput'
import KCalendar from '../Calendar'
import moment from 'moment'

export default {
  props: {
    placeholder: {
      type: String,
      default: '請選擇日期'
    },
    value: [String, Object],
    label: String,
    extra: String,
    theme: String,
    type: String,
    required: Boolean,
    hideRequiredMark: Boolean,
    format: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    cleanable: Boolean
  },
  mounted() {
    setTimeout(() => {
      const document = window.document
      const body = document.body
      const documentHeight = document.body.offsetHeight
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || body.scrollTop
      const { top: pickerTop, height: pickerHeight }= this.$refs.self.$el.getBoundingClientRect()
      const fixedTransform = scrollTop + pickerTop + pickerHeight - documentHeight + 390 //k-calendar 的整體高度為 390px
      if (fixedTransform > 0) this.fixedTransform = fixedTransform
    }, 500);
  },
  data() {
    return {
      showCalendar: false,
      icon: 'calendar',
      fixedTransform: 0,
      isReadonly: false
    }
  },
  methods: {
    toggleShow() {
      this.isReadonly = true
      this.showCalendar = !this.showCalendar
    },
    hide() {
      this.isReadonly = false
      this.showCalendar = false;
    },
    getToday() {
      this.showCalendar = false;
      this.$emit('input', moment());
    },
    handleSelect(val) {
      this.showCalendar = false;
      this.$emit('input', moment(val));
    },
    hendleHover(type) {
      this.icon = type
    },
    handleClean() {
      this.$emit('input', null)
    }
  },
  computed: {
    classes() {
      return {
        'k-date-picker': true
      }
    },
    dateFormat() {
      return this.value
        ? typeof this.value === 'string'
          ? moment(this.value).format(this.format)
          : this.value.format(this.format)
        : ''
    }
  },
  components: {
    KInput,
    KCalendar
  }
}
</script>
