import { genBasicModel } from './utils/basicAction'
import { query } from './utils/basicService'

const MODEL_NAME = 'summary'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME, query(MODEL_NAME))

export default {
  namespaced: true,
  state: {
    summaryList: []
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction,
  },
  getters: {
    summaryDict({ summaryList }) {
      if (summaryList.length) {
        return summaryList.reduce((acc, column) => {
          acc[column.summary_table] = acc[column.summary_table]
            ? [...acc[column.summary_table], column]
            : [column]
          return acc
        },{})
      }
      return {}
    }
  }
}
