<template>
  <li :class="classes" :style="{ paddingLeft: indent ? `${indent+16}px` : null }" @click="routerTo">
    <slot></slot>
  </li>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    to: String
  },
  methods: {
    routerTo(e) {
      if (this.to && this.$router && this.$route.fullPath !== this.to) {
        console.log(true);
        this.$router.push(this.to)
      } else {
        console.log(false);
        this.$emit('click', e)
      }
    }
  },
  computed: {
    classes() {
      return {
        'k-menu-item': true,
        'disabled': !!this.disabled
      }
    },
    indent() {
      if (this.$parent.indent) {
        return 12+(this.$parent.indent || 0);
      }
      return null;
    }
  },
}
</script>
