<template>
  <k-container class="view-inbox-container">
    <template #header>
      <div>
        <h2>寄件紀錄</h2>
        <k-button @click="reload" icon="arrow-repeat" theme="light" :loading="loading">
          {{ loading ? '載入中' : '重新整理' }}
        </k-button>
      </div>
      <k-button theme="primary" @click="$router.push('/email/compose')">撰寫郵件</k-button>
    </template>
    
    <MailList :loading="loading" :emailList="sentWithTag" />
  </k-container>
</template>

<script>
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import KTable from '@/components/Table'
import MailList from './components/MailList'
import { timestampParse } from '@/utils'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  async mounted() {
    // const { emailUser, sentList } = this.$store.state.email
    // if (!emailUser) {
    //   const username = await this.$store.dispatch('email/checkLoginState')
    //   if (!sentList.length && username) {
    //     await this.$store.dispatch('email/querySent', username)
    //     this.loading = false
    //   }
    //   if (!username) this.$router.replace('/email/login')
    // }
    // if (emailUser && !sentList.length) {
    //   await this.$store.dispatch('email/querySent', emailUser)
    //   this.loading = false
    // }
    // if (sentList.length) this.loading = false
  },
  data() {
    return {
      fields,
      loading: true
    }
  },
  methods: {
    timestampParse,
    ...mapActions('email', ['querySent']),
    parseContent(content) {
      let dom = new DOMParser().parseFromString(`${content}`, "text/html")
      let pureContext = dom.body.textContent
      return pureContext.substring(0,90) + (pureContext.length > 90 ? '...' : '' )
    },
    async reload() {
      await this.querySent(this.emailUser)
      this.loading = false
    }
  },
  computed: {
    ...mapState('email', ['emailUser']),
    ...mapGetters('email', ['sentWithTag'])
  },
  watch: {
    sentWithTag: {
      immediate: true,
      handler(newValue) {
        if (newValue.length) this.loading = false
      }
    }
  },
  components: {
    KButton, KCard, KTable, MailList
  }
}

const fields = [
  { key: 'email_title', fieldIndex: 'email_title', label: '信件', searchable: true },
  { key: 'email_target', fieldIndex: 'email_target', label: '收件者', searchable: true },
  { key: 'admin_name', fieldIndex: 'admin_name', label: '寄件人員', filters: true },
  { key: 'email_create_at', fieldIndex: 'email_create_at', label: '寄件日期' },
]
</script>
