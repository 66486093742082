<template>
  <label :class="classes" :for="uid">
    <span class="label"><span v-if="required && !hideRequiredMark">＊ </span>{{ label }}</span>
    <input :id="uid" type="checkbox" :value="value" :checked="value" :disabled="disabled" @input="$emit('input', $event.target.checked)"> 
    <span :class="{'k-switch-slider': true, [`varient--${this.theme}`]: !!this.theme,}"></span>
  </label>
</template>

<script>
export default {
  mounted() {
    this.uid = `k-input-group-${parseInt(Math.random()*1000)}`
    if (this.value === undefined) this.$emit('input', false)
  },
  data() {
    return {
      uid: ''
    }
  },
  props: {
    value: Boolean,
    theme: String,
    disabled: Boolean,
    label: String,
    required: Boolean,
    hideRequiredMark: Boolean
  },
  computed: {
    classes() {
      return {
        'k-switch': true,
        'checked': Boolean(this.value),
        'disabled': this.disabled
      }
    }
  }
}
</script>
