<template>
  <k-dropdown>
    <k-button theme="light">顯示模板變數 ▾</k-button>
    <template #overlay>
      <k-menu>
        <template v-for="table in Object.keys(summaryDict)">
          <k-menu-divider :key="table">
            {{ table }}
          </k-menu-divider>
          <k-menu-item
            v-for="column in summaryDict[table]"
            :key="column.summary_id"
            v-copy="`{{ ${column.summary_column} }}`"
            v-copy:success="()=>{$message.success('已將欄位變數複製到剪貼簿')}"
          >
            <code>{{ column.summary_column }}</code> {{ column.summary_desc }}
          </k-menu-item>
        </template>
      </k-menu>
    </template>
  </k-dropdown>
</template>

<script>
import KButton from '@/components/Button'
import KDropdown from '@/components/Dropdown'
import { KMenu, KMenuItem, KMenuDivider } from '@/components/Menu'
import { mapGetters } from 'vuex'

export default {
  mounted() {
    if (!this.$store.state.summary.summaryList.length) this.$store.dispatch('summary/query')
  },
  computed: {
    ...mapGetters('summary', ['summaryDict'])
  },
  components: {
    KButton, KDropdown, KMenu, KMenuItem, KMenuDivider
  }
}
</script>
