<template>
  <i @click="$emit('click') || null" :class="classes" :style="{ color: color || null }" />
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'bi-question-square',
    },
    color: String,
    theme: String,
  },
  computed: {
    classes() {
      return {
        'k-icon': true,
        'bi': true,
        [`bi-${this.icon}`]: !!this.icon,
        [`k-icon--${this.theme}`]: !!this.theme
      }
    }
  }
}
</script>
