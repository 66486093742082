import { timestampParse, fetchApi } from '@/utils'
import { genBasicModel } from './utils/basicAction'
import database from '@/utils/database'
import storage from '@/utils/storage'

const MODEL_NAME = 'media'
const { basicMutation, basicAction } = genBasicModel(MODEL_NAME, query)
async function query() {
  const response = await database.table('file')
    .join('file_use', 'file_id')
    .join('post', 'post_id', 'file_use')
    .join('category', 'category_id', 'file_use')
    .join('product', 'product_id', 'file_use')
    .orderBy('file.file_uploadAt', 'desc')
    .get()
  response.data = response.data.map(d => ({
    ...d,
    file_uploadAt: timestampParse(d.file_uploadAt),
    file_type: d.file_type.split('/')[0],
    useIn: imageUseIn(d),
    file_size: fileSizeFormat(d.file_size, 'Kb'),
    product_id: ['0', null].includes(d.product_id) ? null : d.product_id
  }))
  return response
}

function imageUseIn(info) {
  if (parseInt(info.post_id) > 0) return 'Post'
  if (parseInt(info.category_id) > 0) return 'Category'
  if (parseInt(info.product_id) > 0) return 'Product'
  return ''
}

function fileSizeFormat(size, type) {
  switch (type) {
    case 'Kb':
      return Math.round(size/1024, 2)
    case 'Mb':
      return Math.round(size/1024/1024, 2)
    case 'Gb':
      return Math.round(size/1024/1024/1024, 2)
    default:
      return Math.round(size/1024, 2)
  }
}

export default {
  namespaced: true,
  state: {
    mediaTree: null,
    mediaLayers: null,
    mediaList: [],
    sortType: null,
  },
  mutations: {
    ...basicMutation,
    saveMediaTree(state, payload) {
      state.mediaTree = payload
    },
    saveMediaLayers(state, payload) {
      state.mediaLayers = payload
    },
    moveFiles(state, payload) {
      const { files, route } = payload
      files.forEach(file => {
        state.mediaLayers[file.parentRoute].file = state.mediaLayers[file.parentRoute].file.filter(f => f.file !== file.file)
        state.mediaLayers[route].file.push(file)
      })
    },
    renameFile(state, payload) {
      const { file, newName, isDir } = payload
      if (isDir) {
        const newPath = `${file.parentRoute}/${newName}`
        state.mediaLayers[newPath] = { ...JSON.parse(JSON.stringify(state.mediaLayers[file.path])), path: newPath }
        delete state.mediaLayers[file.path]
        state.mediaLayers[file.parentRoute].dir = state.mediaLayers[file.parentRoute].dir.map(f => {
          if (f.path === file.path) {
            f.path = newPath
          }
          return f
        })
      } else {
        const fileSplit = newName.split('.')
        const name = fileSplit.slice(0, fileSplit.length - 1).join('.')
        file.file = newName
        file.name = name
        state.mediaLayers[file.parentRoute].file = state.mediaLayers[file.parentRoute].file.map(f => {
          if (f.file === file.file) return file
          return f
        })
      }
    },
    deleteFile(state, payload) {
      const { files, deleteType } = payload
      if (deleteType === 'dir') {
        files.forEach(file => {
          delete state.mediaLayers[file.path]
          state.mediaLayers[file.parentRoute].dir = state.mediaLayers[file.parentRoute].dir.filter(f => f.path !== file.path)
        });
      }
      if (deleteType === 'file') {
        files.forEach(file => {
          state.mediaLayers[file.parentRoute].file = state.mediaLayers[file.parentRoute].file.filter(f => f.file !== file.file)
        });
      }
    },
    // saveFile(state, payload) {
    //   state.mediaList = [{
    //     ...payload,
    //     file_uploadAt: timestampParse(payload.file_uploadAt),
    //     file_size: fileSizeFormat(payload.file_size, 'Kb')
    //   }, ...state.mediaList]
    // },
    insertFile(state, payload) {
      const { file, parrent_path } = payload
      const fileSplit = file.name.split('.')
      const extension = fileSplit[fileSplit.length - 1]
      const name = fileSplit.slice(0, fileSplit.length - 1).join('.')
      console.log(parrent_path);
      console.log(state.mediaLayers[parrent_path]);
      state.mediaLayers[parrent_path].file.splice(0,0, {
        name, extension, file: file.name, fileType: 'IMAGE', parentRout: parrent_path
      })
    },
    insertDir(state, payload) {
      const { name, path } = payload
      const dirInfo =  { path: `${path}/${name}`, file: [], dir: [] }
      state.mediaLayers[`${path}/${name}`] = dirInfo
      state.mediaLayers[path].dir.push(dirInfo)
    },
  },
  actions: {
    ...basicAction,
    async fetchMediaTree({ commit }, payload) {
      const { mediaTree } = await fetchApi('/api/getFile/', payload)
      const mediaLayers = parseLayer(mediaTree)
      commit('saveMediaTree', mediaTree)
      commit('saveMediaLayers', mediaLayers)
    },
    async insertFileInfo({ commit }, payload) {
      const { file, parrent_path } = payload
      //database function
      commit('insertFile', { file, parrent_path })
    },
    async insertDir({ commit }, payload) {
      const { name, path } = payload
      commit('insertDir', { name, path })
    },
    async uploadFileList({ commit }, payload) {
      const { fileList, path } = payload
      const promises = [...fileList].map(file => new Promise(async (res, rej) => {
        try {
          const response = await storage.path(path).put(file, {
            onLoad: (response) => {
              commit('insertFile', { ...response })
            }
          })
          res(response)
        } catch (error) {
          rej(error)
        }
      }))
      const response = await Promise.all(promises)
    },
    async uploadFile({ commit }, payload) {
      const { file, path } = payload
      const API = '/api/file/'
      const { state, locations, files, message } = await fetchApi(API, { file, path })
      console.log({ state, locations, files, message });
      if (state) {
        commit('saveFile', { file: files[file.name] })
        return true
      }
      return false
    },
    async moveFiles({ commit }, payload) {
      const { files, route } = payload
      const API = '/api/renameFile/'
      const res = await fetchApi(API, { files, destination: route })
      commit('moveFiles', payload)
    },
    async renameFile({ commit }, payload) {
      const { file, newName } = payload
      const API = '/api/renameFile/'
      const res = await fetchApi(API, { file, newFileName: newName })
      commit('renameFile', payload)
    },
    async deleteFile({ commit }, payload) {
      const { files, deleteType } = payload
      const API = '/api/deleteFile/'
      const res = await fetchApi(API, { files, deleteType })
      console.log(res);
      commit('deleteFile', payload)
      return res
    }
  },
  getters: {
    // mediaLayers: ({ mediaTree }) => {
    //   if (mediaTree) {
    //     return parseLayer(mediaTree)
    //   }
    //   return {}
    // },
    mediafiles: ({ mediaLayers }) => {
      const files = Object.values(mediaLayers).reduce((acc, layer) => {
        return [...acc, ...layer.file.map(f => ({ ...f, parentRoute: layer.path }))]
      }, [])
      return files
    },
    recentFile: ({ mediaList }) => {
      console.log(mediaList);
      return mediaList.slice(0, mediaList.length < 5 ? length : 7)
    },
    filterByMediaType: ({ mediaList, sortType }) => {
      const newMediaList = [...mediaList]
      return newMediaList
      .sort((a,b) => {
        if (sortType === 'file_name') return ('' + a[sortType]).localeCompare(b[sortType])
        if (sortType === 'file_uploadAt') return b[sortType] - a[sortType]
      })
      .reduce((acc, f) => {
        if (!f.post_id && !f.category_id && !f.product_id) acc.unused = [...acc.unused, { ...f }]
        else {
          const index = acc[f.file_type].map(e => e.key_id).indexOf(f.key_id)
          if (index === -1) {
            acc[f.file_type] = [...acc[f.file_type], { ...f, post_id: [f.post_id] }]
          } else {
            acc[f.file_type][index].post_id.push(f.post_id)
          }
        }
        return acc
      } , { image: [], video: [], unused: [] })
    }
  }
}

function parseLayer(layer) {
  console.log(layer.path);
  return {
    [layer.path]: layer,
    ...layer.dir.reduce((acc, l) => ({ ...acc, ...parseLayer(l) }), {})
  }
}
