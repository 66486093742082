<template>
  <k-container class="view-account-container" title="帳號訊息">
    <section class="account-edit" v-if="currentUser">
      <k-upload @change="handleChange">
        <k-avatar backgroundColor="#009688" :src="previewSrc || null">
          {{ currentUser.admin_name.slice(0,1) }}
        </k-avatar>
      </k-upload>
      <k-form :questionConfig.sync="questions" :defaultValues="defaultValues" @submit="handleSubmit">
        <template #after(password)>
          <transition name="fade">
            <k-button v-if="edited"  type="submit" theme="primary" block>Save Change</k-button>
          </transition>
        </template>
      </k-form>
    </section>
    <k-divider>Recent Message</k-divider>
    <k-card class="account-recent-message">
      <template v-if="messageList.length">
        <k-card :class="{emergency: message.emergency}" v-for="message in messageList" :key="message.key" outline="light" noShadow>
          <k-avatar backgroundColor="#009688">{{ getRandomWord() }}</k-avatar>
          <h3>{{ message.title }}</h3>
          <k-tag v-if="message.emergency" theme="danger">Emergency</k-tag>
          <k-tag>{{ timestampParse(message.createAt) }}</k-tag>
          <!-- <span>{{ message.content }}</span> -->
        </k-card>
      </template>
        <k-empty v-else></k-empty>
    </k-card>
  </k-container>
</template>

<script>
import { KForm, KUpload } from '@/components/Form'
import KDivider from '@/components/Divider'
import KCard from '@/components/Card'
import KEmpty from '@/components/Empty'
import KTag from '@/components/Tag'
import KAvatar from '@/components/Avatar'
import KButton from '@/components/Button'
import { timestampParse, getRandom } from '@/utils'
import { mapState } from 'vuex'

const lorem = 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus cumque veniam voluptatum ex, mollitia repellat molestias totam consequatur deserunt voluptatibus?'

const fakeTimestamp = (m,d) => new Date(2021,m,d).getTime()

export default {
  data() {
    return {
      questions: genQuestions(this.handleEdit),
      defaultValues: null,
      previewSrc: null,
      avatarFile: null,
      edited: false,
      messageList: [
        { key: 'm4', title: 'Something You Should Know', content: lorem, createAt: fakeTimestamp(4,4) },
        { key: 'm2', title: 'Please Confirm The Invitation', content: lorem, createAt: fakeTimestamp(3,28) },
        { key: 'm3', title: '4/24 Meeting Record', content: lorem, createAt: fakeTimestamp(3,29) },
        { key: 'm5', title: '4/17 Hot Fix Requestion', content: lorem, createAt: fakeTimestamp(3,18), emergency: true },
        { key: 'm1', title: 'Welcome to Join Kirsty!', content: lorem, createAt: fakeTimestamp(2,20) },
      ]
    }
  },
  methods: {
    handleChange({ src, files }) {
      this.avatarFile = files[0]
      this.previewSrc = src
      this.handleEdit()
    },
    handleEdit(e) {
      console.log(e);
      this.edited = true
    },
    handleSubmit(value) {
      console.log(value);
    },
    timestampParse(date) {
      return timestampParse(date).format('YYYY/MM/DD')
    },
    getRandomWord() {
      return String.fromCharCode(getRandom(65,90))
    },
  },
  computed: {
    ...mapState('login', ['currentUser'])
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(newVal) {
        this.defaultValues = { ...newVal }
      }
    }
  },
  components: {
    KForm, KUpload, KDivider, KCard, KEmpty, KTag, KAvatar, KButton
  }
}

const genQuestions = (handleEdit) => [
  {
    questionType: 'k-input',
    name: 'admin_name',
    label: '使用人名稱',
    required: true,
    onChange: handleEdit
  },
  {
    questionType: 'k-input',
    name: 'password',
    label: '新密碼',
    placeholder: '欲更改新密碼請在此欄位輸入',
    onChange: handleEdit
  }
]
</script>
