<template>
  <label
    :class="{ 'k-select-wraper': true, 'invalid': isInvalid }"
    for="select1"
  >
    <span
      class="label"
      v-if="label"
    ><span
        class="mark"
        v-if="required && !hideRequiredMark"
      >＊ </span>{{ label }}</span>
    <input
      class="select-value"
      v-if="required"
      :value="value"
      @invalid="handleInvalid"
      required
    >
    <div
      :class="classes"
      v-click-away="unSpread"
    >
      <div
        class="k-select-render"
        @click.stop="toggleSpread"
      >
        <input
          class="k-select-title"
          v-model="searchValue"
          @input="handleSearchInput"
          :placeholder="placeholder || 'Please select an option'"
          :readonly="!searchable"
        >
      </div>
      <transition name="drop-down">
        <ul
          v-if="($slots.default || this.options.length) && isSpread"
          class="option-list"
          @click.stop="handleSelect"
        >
          <slot></slot>
          <option
            v-for="item in mergeOptions"
            :value="item.value"
            :key="item.value"
          >{{ item.text }}</option>
        </ul>
      </transition>
    </div>
    <span
      v-if="isInvalid"
      class="extra"
    >
      <k-icon
        icon="exclamation-triangle"
        theme="danger"
      />本欄位為必填，請選擇選項
    </span>
  </label>
</template>

<script>
import formMixin from '@/mixin/form'

export default {
  mixins: [formMixin],
  props: {
    value: String,
    theme: String,
    label: String,
    placeholder: String,
    spread: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    block: Boolean,
    required: Boolean,
    searchable: Boolean,
    hideRequiredMark: Boolean
  },
  mounted() {
    if (this.spread) this.isSpread = this.spread
  },
  data() {
    return {
      isSpread: false,
      searchValue: ''
    }
  },
  methods: {
    toggleSpread() {
      this.isSpread = !this.isSpread;
    },
    unSpread() {
      this.isSpread = false
    },
    handleSelect(e) {
      this.handleValid()
      console.log(e.target.value);
      console.log( this.options.filter(o => o.value === e.target.value));
      this.$emit('input', e.target.value)
      this.$emit('change', e.target.value)
      this.searchValue = this.options.filter(o => o.value === e.target.value)[0]['text']
      this.isSpread = false
    },
    handleSearchInput(e) {
      this.$emit('input', e.target.value)
      // this.$emit('change', e.target.value)
      this.handleValid()
    }
  },
  computed: {
    classes() {
      return {
        'k-select': true,
        'isSpread': this.isSpread,
        [`k--${this.theme}`]: !!this.theme,
        'block': !!this.block
      }
    },
    mergeOptions() {
      if (this.searchable) {
        return this.options
          .filter(o => o.text.toLowerCase().includes(this.searchValue.toLowerCase()) || o.value.toLowerCase().includes(this.searchValue.toLowerCase()))
      }
      return this.options
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal) {
          const filterOption = this.options.filter(o => o.value === newVal)
          if (filterOption.length) this.searchValue = filterOption[0].text
        }
        else this.searchValue = ''
      },
      immediate: true
    }
  }
}
</script>
