<template>
  <k-container class="view-order-container">
    <header class="pageTitle">
      <h1>所有訂單</h1>
      <div>
        <k-button
          :theme="selectionMode ? 'error' : 'success'"
          @click="changeSelectionMode"
        >
          {{ selectionMode ? '取消操作' : '批量完成訂單' }}
        </k-button>
        <k-button theme="primary" @click="showModal">匯出訂單資料</k-button>
      </div>
    </header>
    
    <k-card>
      <k-table
        v-if="ordersList.length"
        :items="ordersList"
        :fields="TableFields"
        :rowSelection="selectionMode"
        @check="onCheck"
        hover
      >
        <template #cell(orders_state)="data">
          <k-tag :theme="ORDER_STATE_DICT[data.value].theme">{{ ORDER_STATE_DICT[data.value].text }}</k-tag>
        </template>

        <template #cell(orders_create_at)="data">
          {{ timestampParse(data.value).format('YYYY-MM-DD HH:mm') }}
        </template>

        <template #cell(detail)="data">
          <k-button varient="ghost" theme="info" @click="changeFocusOrder(data.item.key_id)">訂單詳情</k-button>
        </template>
      </k-table>
    </k-card>

    <k-modal
      :show.sync="showOutputSetting"
      :hideWithOk="false"
      title="輸出設定"
      :okButtonProps="{ theme: 'primary', loading: outputLoading, label: '確認', hide: lastResult }"
      @ok="$refs.form.submit()"
      @cancel="handleCancel"
    >
      <k-form v-if="!lastResult" ref="form" :questionConfig="QuestionFeilds" @submit="handleSubmit">
        <k-divider slot="after(title)">進階設定</k-divider>
      </k-form>
      <k-result v-else status="success" :title="`已成功輸出 ${ lastResult.exportType }`">
        <p>輸出連結：</p>
        <a
          :href="lastResult.path"
          :download="lastResult.exportType === 'Excel 格式' ? `${lastResult.fileName}.csv` : null"
          target="_blank"
        >
          {{ lastResult.text || lastResult.path }}
        </a>
      </k-result>
    </k-modal>

    <k-modal class="order-modal" title="訂單詳細資料" :show.sync="showDetail" @cancel="handleDetailCancel" :width="600">
      <div v-if="focusOrderInfo">
        <div class="inner-head">
          <div>
            <h2 class="inner-title">訂單編號 - {{ focusOrderInfo.orders_id }}</h2>
            <k-button @click="emailToUser(focusOrderInfo.user_id)" theme="info" icon="envelope-fill">聯絡訂購人</k-button>
          </div>
          <p>{{ timestampParse(focusOrderInfo.orders_create_at).format('yyyy-MM-DD HH:mm') }}</p>
          <k-tag :theme="ORDER_STATE_DICT[focusOrderInfo.orders_state].theme">
            {{ ORDER_STATE_DICT[focusOrderInfo.orders_state].text }}
          </k-tag>
        </div>

        <k-divider>訂單資訊</k-divider>
        <k-descriptions layout="vertical" bordered>
          <k-descriptions-item label="產品名稱">{{ focusOrderInfo.product_name }}</k-descriptions-item>
          <k-descriptions-item label="訂單總額">{{ focusOrderInfo.orders_count }}</k-descriptions-item>
          <k-descriptions-item label="幣別">{{ focusOrderInfo.orders_currency }}</k-descriptions-item>
          <k-descriptions-item label="付款方式" span="2">{{ focusOrderInfo.orders_pay_type }}</k-descriptions-item>
          <k-descriptions-item label="付款方式">{{ focusOrderInfo.orders_pay_type }}</k-descriptions-item>
        </k-descriptions>

        <k-divider>用戶資料</k-divider>
        <k-descriptions layout="vertical" bordered>
          <k-descriptions-item label="用戶">{{ focusOrderInfo.user_name }}</k-descriptions-item>
          <k-descriptions-item label="年齡">{{ focusOrderInfo.user_age }}</k-descriptions-item>
          <k-descriptions-item label="性別">{{ focusOrderInfo.user_gender === 'Male' ? '男' : '女' }}</k-descriptions-item>
          <k-descriptions-item label="電子郵件">{{ focusOrderInfo.user_email }}</k-descriptions-item>
          <k-descriptions-item label="詳細資訊" span="3">
            Data disk type: MongoDB<br/>
            Database version: 3.4<br/>
            Package: bbs.kirsty.omg<br/>
            Storage space: 10 GB<br/>
            Replication factor: 3<br/>
            Region: East Asia 1
          </k-descriptions-item>
        </k-descriptions>
      </div>

      <template #footer>
        <k-button theme="warning" @click="onCheck([focusOrderInfo.key_id])">完成訂單</k-button>
      </template>
    </k-modal>
  </k-container>
</template>

<script>
import { KForm } from '@/components/Form'
import { KDescriptions, KDescriptionsItem } from '@/components/Descriptions'
import KCard from '@/components/Card'
import KDivider from '@/components/Divider'
import KTable from '@/components/Table'
import KTag from '@/components/Tag'
import KModal from '@/components/Modal'
import KButton from '@/components/Button'
import KResult from '@/components/Result'
import { buildSheet } from '@/utils/googleSheet'
import { createCsvFile } from '@/utils/csv'
import { timestampParse } from '@/utils'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Order',
  mounted() {
    this.$store.dispatch('orders/query')
  },
  data() {
    return {
      showOutputSetting: false,
      showDetail: false,
      outputLoading: false,
      lastResult: undefined,
      focusOrderInfo: undefined,
      selectionMode: false,
      pagination: {
        current: 1,
        limit: 6
      },
      selectedRowKeys: [],
      ORDER_STATE_DICT,
      QuestionFeilds,
      tabPane,
      TableFields,
    }
  },
  computed: {
    ...mapState('orders', ['ordersList']),
    ...mapState('login', ['currentUser'])
  },
  methods: {
    ...mapActions('orders', ['batchCheckState']),
    timestampParse,
    async handleSubmit(formData) {
      const { title, outputType, ...filter } = formData.values
      var realField = TableFields.filter(e => e.key !== 'detail')
      var headerValues = realField.map(e => e.key)
      var headerTexts = realField.map(e => e.label)

      switch (outputType) {
        case 'GS':
          this.outputLoading = true
          var sheet = await buildSheet({ title, headerValues, headerTexts, rows: this.filterData(filter, headerValues, this.ordersList) })

          this.lastResult = {
            ...sheet._rawProperties,
            exportType: "Google 試算表",
            path: `https://docs.google.com/spreadsheets/d/${process.env.VUE_APP_GOOGLE_SHEET_ID}/edit#gid=${sheet._rawProperties.sheetId}`
          }
          break
        
        case 'XLS':
          const link = createCsvFile(headerTexts, this.filterData(filter, headerValues, this.ordersList))
          this.lastResult = {
            exportType: "Excel 格式",
            fileName: title,
            path: link,
            text: `下載：${title}.csv`
          }
          break
        default: break;
      }
    },
    filterData(filters, headerValues, data) {
      const { dateRange, limit } = filters
      var filterData = data
      if (dateRange) {
        filterData = filterData.filter(item => {
          return dateRange.start.unix() < item.orders_create_at && item.orders_create_at < dateRange.end.unix()
        })
      }
      if (limit) filterData = filterData.slice(0, limit)
      return filterData.map(row => headerValues.map(key => row[key]))
    },
    handleCancel() {
      this.lastResult = undefined
      this.QuestionFeilds.forEach((e, index) => delete this.QuestionFeilds[index].value)
    },
    changeFocusOrder(id) {
      this.focusOrderInfo = { ...this.ordersList.filter(i => i.orders_id === id)[0] }
      this.showDetail = true
    },
    handleDetailCancel() {
      this.focusOrderInfo = undefined
    },
    showModal() {
      this.showOutputSetting = true
    },
    onCheck(data) {
      this.$confirm.info({
        title: '確定要將所選訂單狀態變更為『已完成』？',
        text: '真的確定齁齁齁？',
        callback: (bool) => this.handleChangeState(bool, { data, orders_dealer_id: this.currentUser.admin_id })
      })
    },
    changeSelectionMode() {
      this.selectionMode = !this.selectionMode
    },
    handleChangeState(ensure, { data, orders_dealer_id }) {
      if (ensure) {
        this.batchCheckState({ id_array: data.map(e => e.key_id), orders_dealer_id })
        this.$message.success('已成功修改訂單狀態')
        this.selectionMode = false
      }
    },
    async emailToUser(user_id) {
      // this.$router.push({ name: 'Email-Compose', query: { user_id } })
      const headers = new Headers()
      headers.append("Content-Type", "application/json");
      headers.append("AuthToken", "A9055EAA-FE9B-4340-A292-9967C323EA96");
      const res = await fetch("https://clientapi.benchmarkemail.com/Contact/19481235/ContactDetails", {
        headers,
        method: "GET"
      })

      console.log(res);
    }
  },
  components: {
    KTable, KButton, KTag, KModal, KForm, KDivider, KResult, KDescriptions, KDescriptionsItem, KCard
  }
}

const QuestionFeilds = [
  {
    label: '輸出方式',
    name: 'outputType',
    questionType: 'k-select',
    options: [{ text: 'Google Sheet', value: "GS" }, { text: 'Excel', value: 'XLS' }],
    placeholder: '選擇輸出方式',
    theme: 'light',
    required: true
  },
  { label: '檔案名稱', name: 'title', questionType: 'k-input', required: true },
  { label: '輸出範圍', name: 'dateRange', questionType: 'k-range-picker', extra: '若無特別選擇輸出範圍，將輸出所有訂單資料' },
  { label: '輸出資料筆數', name: 'limit', questionType: 'k-input', type: 'number' }
]

const TableFields = [
  { key: 'orders_id', fieldIndex: 'orders_id', label: '訂單編號' },
  { key: 'product_name', fieldIndex: 'product_name', label: '產品', filters: true }, 
  { key: 'orders_count', fieldIndex: 'orders_count', label: '訂單總額' },
  { key: 'user_name', fieldIndex: 'user_name', label: '定購人', searchable: true,
    sorter: (a, b) => ('' + a).localeCompare(b)
  },
  { key: 'user_email', fieldIndex: 'user_email', label: '信箱', searchable: true},
  { key: 'orders_state', fieldIndex: 'orders_state', label: '訂單狀態',
    filters: [
      { text: '處理中', value: 'HANDLING' },
      { text: '已確認', value: 'CONFIRM' },
      { text: '已完成', value: 'COMPLETE' },
      { text: '已取消', value: 'CANCEL' },
    ] 
  }, 
  { key: 'orders_create_at', fieldIndex: 'orders_create_at', label: '訂單成立時間', sorter: (a, b) => b - a },
  { key: 'detail', fieldIndex: 'detail', label: '' }
]

const ORDER_STATE_DICT = {
  HANDLING: { theme: 'warning', text: '處理中' },
  CONFIRM: { theme: 'info', text: '已確認' },
  COMPLETE: { theme: 'success', text: '已完成' },
  CANCEL: { theme: 'secondary', text: '已取消' },
}

const tabPane = [
  { key: "public", state: "published", tab: "未處理" },
  { key: "unPublic", state: "unpublish", tab: "已完成" }
]
</script>
