<template>
  <k-modal class="email-tag-modal-component" :show.sync="showTagModal" title="選擇標籤" hideFooter>
        <section v-if="emailTagList.length">
          <k-button
            v-for="tag in emailTagList"
            :key="tag.email_tag_id"
            theme="secondary"
            :icon="tag.email_tag_icon || 'tag-fill'"
            block
            @click="chooseTag(tag.email_tag_id)"
          >
            {{ tag.email_tag_name }}
          </k-button>
          <k-card class="create-tag" header="新建標籤" collapsible>
            <k-input label="標籤名稱" v-model="newTag.email_tag_name"></k-input>
            <section>
              <k-input label="使用Icon" placeholder="未設定則預設為標籤圖示" v-model="newTag.email_tag_icon">
                <a slot="extra" href="https://icons.getbootstrap.com/#usage" target="_blank">
                  https://icons.getbootstrap.com/#usage
                </a>
                <k-icon slot="addonBefore" :icon="newTag.email_tag_icon"></k-icon>
              </k-input>
              <k-input label="標籤顏色" type="color" v-model="newTag.email_tag_color"></k-input>
            </section>
            <k-button theme="primary" :loading="loading" block @click="createTag">新增標籤</k-button>
          </k-card>
        </section>
      </k-modal>
</template>

<script>
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import KModal from '@/components/Modal'
import { KInput } from '@/components/Form'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    todoEmail: Array,
    emailType: String,
  },
  data() {
    return {
      showTagModal: false,
      newTag: {
        email_tag_name: '',
        email_tag_icon: 'tag-fill',
        email_tag_color: '#6c6e7e'
      },
      loading: false
    }
  },
  computed: {
    ...mapState('email', ['emailTagList']),
  },
  methods: {
    ...mapActions('email', ['addEmailTag', 'batchRegistEmailTagUse']),

    showTags() {
      this.showTagModal = true
    },
    async chooseTag(tagId) {
      this.$confirm.info({
        title: "確定要將郵件設定為此標籤嗎？",
        callback: async (ensure) => {
          if (ensure) {
            this.showTagModal = false
            const res = await this.batchRegistEmailTagUse({
              todoEmail: this.todoEmail, 
              email_tag_id: tagId, 
              email_tag_use_type: this.email_type
            })
            if (res) this.$message.success('已成功將信件添加標籤')
          }
        }
      })
    },
    async createTag() {
      this.loading = true
      if (this.newTag.email_tag_name) {
        const res = await this.addEmailTag({ ...this.newTag })
        if (res.status === 200) this.$message.success('已建立新標籤')
      }
      this.newTag = {
        email_tag_icon: 'tag-fill',
        email_tag_color: '#6c6e7e'
      }
      this.loading = false
    }
  },
  components: {
    KButton, KCard, KModal, KInput
  }
}
</script>
