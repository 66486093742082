<template>
  <k-container class="view-email-piece-container">
    <k-card>
      <template #title>
        <section class="title">
          <k-skeleton :loading="loading" :line="0" active>
            <h2>{{ targetEmail.email_title }}</h2>
          </k-skeleton>
        </section>
      </template>
      <div v-if="['SENT', 'INBOX|Sent'].includes(targetEmailType) ">
        <p class="target-email">寄給
          <span v-for="target, index in targetEmail.email_target" :key="`target_${index}`">
          &lt;{{ target }}&gt;
          </span>
        </p>
        <k-card class="email-content" outline="secondary">
          <template #header>
            <k-button-group>
              <k-tooltip placement="bottom" title="轉寄">
                <k-button theme="secondary" icon="reply-all-fill"/>
              </k-tooltip>
              <k-tooltip placement="bottom" title="標註">
                <k-button theme="secondary" icon="tag-fill" @click="showTags"/>
              </k-tooltip>
            </k-button-group>
          </template>
          <div v-html="targetEmail.email_content"></div>
        </k-card>
      </div>

      <div v-if="targetEmailType === 'INBOX'">
        <k-skeleton :loading="loading" :line="0" dense active>
          <p class="target-email">
            寄自
            <b>{{ targetEmail.email_from_name || targetEmail.email_from }}</b>
            <span v-if="targetEmail.email_from_address"> &lt;{{ targetEmail.email_from_address }}&gt;</span>
          </p>
        </k-skeleton>

        <k-card
          v-if="targetEmail.email_attachment && targetEmail.email_attachment.length"
          class="email-attachment"
          header="附件"
          outline="secondary"
        >
          <k-button
            v-for="attachment, index in targetEmail.email_attachment"
            :key="`attch_${index}`"
            theme="warning"
            varient="ghost"
            :icon="parseAttachmentType(attachment.subtype)"
            @click="download(attachment)"
          >
            {{ attachment.filename }}
            <span>(~ {{ parseInt(attachment.bytes / 1024) }} KB)</span>
          </k-button>

        </k-card>
        <k-card class="email-content" outline="secondary">
          <template #header>
            <k-button-group>
              <k-tooltip placement="bottom" title="回覆">
                <k-button theme="secondary" icon="reply-fill" title="回覆" @click="reply"/>
              </k-tooltip>
              <k-tooltip placement="bottom" title="轉寄">
                <k-button theme="secondary" icon="reply-all-fill"/>
              </k-tooltip>
              <k-tooltip placement="bottom" title="標註">
                <k-button theme="secondary" icon="tag-fill" @click="showTags"/>
              </k-tooltip>
            </k-button-group>
          </template>
          <k-skeleton :loading="loading" :line="20" dense active>
            <div v-html="targetEmail.email_content"></div>
          </k-skeleton>
        </k-card>
      </div>

      <TagsModal ref="tagsModal" :todoEmail="[targetEmail.email_id]" :emailType="targetEmailType" />
    </k-card>

    <Reply v-if="replying" :targetEmail="targetEmail" />
  </k-container>
</template>

<script>
import KCard from '@/components/Card'
import KButtonGroup from '@/components/ButtonGroup'
import KButton from '@/components/Button'
import KSkeleton from '@/components/Skeleton'
import KTooltip from '@/components/Tooltip'
import Reply from './components/Reply'
import TagsModal from './components/TagsModal'
import { mapState } from 'vuex'

function htmlCidTransform(attachmentsInHtml, content) {
  const parser = new DOMParser();
  let doc = parser.parseFromString(content, "text/html")
  attachmentsInHtml.forEach(attachment => {
    let targetElement = doc.querySelector(`[src="cid:${attachment.id}"]`)
    if (targetElement) targetElement.setAttribute('src', `data:image/png;base64, ${attachment.body}`)
  });
  return doc.documentElement.innerHTML
}

function anchorTransform(content) {
  function transform(text) {
    const parser = new DOMParser();
    let doc = parser.parseFromString(text, "text/html")
    let anchors = doc.querySelectorAll('a')
    if (anchors.length) anchors.forEach(a => a.setAttribute('target', '_blank'));
    return doc.querySelector('body').innerHTML
  }

  if (content.length === 1) return transform(content[0])
  else return content.reduce((acc, html) => `${acc}${transform(html)}`,'')
}

export default {
  name: 'Email-Piece',
  async mounted() {
    const [email_type, email_id] = this.$route.params.id.split('-')
    this.targetEmailId = this.$route.params.id
    this.targetEmailType = email_type
    if (email_type === 'INBOX' && !this.$store.state.email.emailList.length) {
      this.loading = true
      const res = await this.$store.dispatch('email/queryWebmailInbox')
      if (res.status === 200) this.loading = false
    }
    if (email_type === 'SENT' && !this.$store.state.email.sentList.length) {
      const { emailUser, sentList } = this.$store.state.email
      if (!emailUser) {
        const username = await this.$store.dispatch('email/checkLoginState')
        if (!sentList.length && username) {
          await this.$store.dispatch('email/querySent', username)
          this.loading = false
        }
        if (!username) this.$router.replace('/email/login')
      }
    }
  },
  data() {
    return {
      targetEmailId: null,
      targetEmailType: null,
      loading: false,
      replying: false,
      showTagModal: false,
    }
  },
  computed: {
    ...mapState('email', ['emailList', 'sentList', 'emailTagList']),
    targetEmail() {
      if (this.targetEmailId && this.targetEmailType && this.emailList.length && this.sentList.length) {
        const targetEmail =  this.targetEmailType === 'INBOX'
          ? JSON.parse(JSON.stringify(this.emailList.filter(e => e.email_id === this.targetEmailId)[0]))
          : JSON.parse(JSON.stringify(this.sentList.filter(e => e.email_id === this.targetEmailId)[0]))
        targetEmail.email_content = anchorTransform(targetEmail.email_content)
        if (targetEmail.message_type === 'PLAIN') {
          targetEmail.email_content = targetEmail.email_content.replace(/\n/g, '<br />')
        }
        
        if (targetEmail.email_attachment) {
          const attachmentsInHtml = targetEmail.email_attachment.filter(a => a.id)
          const cidImageFormatHtml = htmlCidTransform(attachmentsInHtml, targetEmail.email_content)
          return { ...targetEmail, email_content: cidImageFormatHtml }
        }
        return targetEmail
      }
      return {}
    }
  },
  methods: {
    reply() {
      this.replying = true
    },
    parseAttachmentType(type) {
      if (['PNG', 'JPEG'].includes(type)) return 'file-earmark-image'
      if (type === 'PDF') return 'file-earmark-pdf'
      return 'file-earmark'
    },
    download(attachment) {
      if (attachment.encoding === 3) {
        let anchor = document.createElement("a");
        anchor.href = 'data:application/octet-stream;base64,' + attachment.body;
        anchor.download = attachment.filename
        anchor.click()
      }
    },
    parseSpecialChracter(plain) {
      return plain.replace(/&gt;/g, '>')
    },
    showTags() {
      this.$refs.tagsModal.showTags()
    },
  },
  components: {
    KCard, KButton, KButtonGroup, KSkeleton, KTooltip, Reply, TagsModal
  }
}
</script>
