<template>
  <k-container class="view-email-inbox-container">
    <template #header>
      <div>
        <h2>收件匣</h2>
        <k-button @click="reload" icon="arrow-repeat" theme="light" :loading="loading">
          {{ loading ? '載入中' : '重新整理' }}
        </k-button>
      </div>
      <k-button theme="primary" @click="$router.push('/email/compose')">撰寫郵件</k-button>
    </template>

    <MailList :loading="loading" :emailList="emailWithTag" />
  </k-container>
</template>

<script>
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import KList from '@/components/List'
import KSkeleton from '@/components/Skeleton'
import MailList from './components/MailList'
import { timestampParse } from '@/utils'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Email-Inbox',
  data() {
    return {
      loading: true,
      start: 1,
    }
  },
  computed: {
    ...mapState('email', ['emailList', 'emailUser']),
    ...mapGetters('email',['emailWithTag']),
  },
  methods: {
    ...mapActions('email', ['queryWebmailInbox', 'webmailLogout']),
    timestampParse,
    async reload() {
      this.loading = true
      const res = await this.queryWebmailInbox()
      if (res.status === 200) this.loading = false
    },
    parseContent(content) {
      let dom = new DOMParser().parseFromString(`${content}`, "text/html")
      let pureContext = dom.body.textContent.replace(/\s+/gm, ' ')
      return pureContext.substring(0,150) + (pureContext.length > 150 ? '...' : '' )
    },
    handleLogout() {
      this.$confirm.info({
        title: '確定要登出此帳號？',
        callback: ensure => {
          if(ensure) {
            this.webmailLogout()
            this.$router.replace('/email/login')
          }
        }
      })
    }
  },
  watch: {
    emailWithTag: {
      immediate: true,
      handler(newValue) {
        if (newValue.length) this.loading = false
      }
    }
  },
  components: {
    KButton, KCard, KList, KSkeleton, MailList
  }
}
</script>
