var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-container',{staticClass:"view-inventory-container",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("存貨管理")]),_c('section',[_c('k-button',{attrs:{"theme":"warning"}},[_vm._v("倉庫轉移")]),_c('k-button',{attrs:{"theme":"info"}},[_vm._v("庫存進貨")])],1)]},proxy:true}])},[_c('k-card',{attrs:{"header":"產品庫存"}},[_c('k-table',{attrs:{"items":_vm.productInventory,"fields":_vm.TableFieldProduct},scopedSlots:_vm._u([{key:"cell(inventory_uid)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('k-tag',{staticClass:"inventory-uid",attrs:{"theme":item.inventory_type === 'IMPORT' ? 'info' : 'warning'}},[_vm._v(_vm._s(value))])]}},{key:"cell(cost_value)",fn:function(ref){
var value = ref.value;
return [_vm._v("$"+_vm._s(value))]}},{key:"cell(inventory_quantity)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_vm._v(_vm._s(value - item.inventory_used))]}},{key:"cell(inventory_place)",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.PLACE_DICT[value]))]}},{key:"cell(inventory_import_date)",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.timestampParse(value).format('YYYY/MM/DD')))]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('k-button',{attrs:{"theme":item.inventory_type === 'IMPORT' ? 'info' : 'warning',"varient":"ghost"}},[_vm._v(_vm._s(item.inventory_type === 'IMPORT' ? '進貨單' : '轉倉單'))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }