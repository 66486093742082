import { genBasicModel } from './utils/basicAction'
import { query, create } from './utils/basicService'

const MODEL_NAME = 'event'
const saveRule = ({ event_link_product_id, coupon_id, ...otherData }) => ({
  ...otherData,
  event_link_product_id: event_link_product_id ? event_link_product_id.split(',') : undefined,
  coupon_id: coupon_id ? coupon_id.split(',') : undefined,
})

const { basicMutation, basicAction } = genBasicModel(
  MODEL_NAME,
  query(MODEL_NAME, { callback: saveRule }),
  create(MODEL_NAME, true, { callback: saveRule }))

export default {
  namespaced: true,
  state: {
    eventList: []
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction
  },
  getters: {
    eventDict: state => state.eventList.reduce((acc, cur) => {
      acc[cur.event_id] = cur
      return acc
    }
    , {})
  }
}
