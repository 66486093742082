<template>
  <k-container class="view-active-container">
    <k-result 
      v-if="adminInfo" 
      :status="adminInfo.admin_is_confirm === '1' ? 'success' : 'info'" 
      :title="adminInfo.admin_is_confirm === '1' ? '此帳號已經激活' : '激活管理帳號程序'" 
      :subTitle="`帳號使用信箱：${adminInfo.admin_email} ｜ 權限：${adminInfo.auth_name}`"
    >
      <k-icon slot="icon" :icon="adminInfo.admin_is_confirm === '1' ? 'check-circle-fill' : 'person-check-fill'"></k-icon>
      <span v-if="adminInfo.admin_is_confirm === '0'">
        <p style="margin: 4px 0">請確認此帳號是否為您使用中的電子郵件信箱，若無誤請進一步輸入詳細帳號資訊點選『激活帳號』。</p>
        <k-input extra="請輸入您的英文名字第一個字母請使用大寫" v-model="admin_name">
          <template #addonBefore>
            <span>名稱</span>
          </template>
        </k-input>

        <k-input v-model="password" :type="showPassword ? 'text' : 'password'" pattern="[A-Za-z]{6,}">
          <template #addonBefore>
            <span>密碼</span>
          </template>
          <template #addonAfter>
            <k-icon @click="toggleShowPassword" icon="eye-fill"></k-icon>
          </template>
        </k-input>
      </span>
      <k-button slot="extra" v-if="adminInfo.admin_is_confirm === '0'" theme="primary" :loading="loading" @click="handleActive">激活帳號</k-button>
      <k-button slot="extra" v-else theme="primary" :loading="loading" @click="toLogin">回登入頁</k-button>
    </k-result>
  </k-container>
</template>

<script>
import { KForm, KInput } from '@/components/Form'
import KCard from '@/components/Card'
import KDivider from '@/components/Divider'
import KResult from '@/components/Result'
import KButton from '@/components/Button'
import database from '@/utils/database'
import { mapActions } from 'vuex'

export default {
  name: 'Active',
  async mounted() {
    this.uid = this.$route.params.uid
    const { data } = await database.table('admin').join('auth', 'auth_id').where('admin.admin_uid', '=', this.uid).get()
    this.adminInfo = data[0]
  },
  data() {
    return {
      loading: false,
      showPassword: false,
      admin_name: '',
      password: '',
      adminInfo: null
    }
  },
  methods: {
    ...mapActions('admin', ['activeAdmin']),

    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
    toLogin() {
      this.$router.replace('/login')
    },
    async handleActive() {
      if (this.password.length < 6) {
        this.$message.warning('密碼至少需有6個字母')
        return
      }
      if (this.admin_name && this.password) {
        this.loading = true
        const { uid, admin_name, password } = this
        const res = await this.activeAdmin({ uid, admin_email: this.adminInfo.admin_email, admin_name, password})
        this.$message.success('成功激活帳號！')
        if (res) this.$router.push('/')
      } else {
        this.$message.warning('請確認輸入欄位是否為空！')
        this.loading = false
      }
    }
  },
  components: {
    KForm, KInput, KCard, KDivider, KResult, KButton
  }
}
</script>
