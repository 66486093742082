<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Email',
  async mounted() {
    const { emailUser, emailList, sentList } = this.$store.state.email
    
    if (!emailUser) {
      const username = await this.$store.dispatch('email/checkLoginState')
      if (username) {
        if (!emailList.length) {
          let res = await this.$store.dispatch('email/queryWebmailInbox')
          switch (res.status) {
            case 200:
              this.loading = false
              break
            case 404:
              this.$router.push('/email/login')
              break
            default:
              this.$router.push('/email/login')
              break
          }
        }
        if (!sentList.length) this.$store.dispatch('email/querySent', username)
      }
    } else {
      if (!emailList.length) {
        let res = await this.$store.dispatch('email/queryWebmailInbox')
          switch (res.status) {
            case 200:
              this.loading = false
              break
            case 404:
              this.$router.push('/email/login')
              break
            default:
              this.$router.push('/email/login')
              break
          }
      }
      if (!sentList.length) this.$store.dispatch('email/querySent', emailUser)
    }
  },
}
</script>
