<template>
  <li :class="classes">
    <span v-if="$slots.default"><slot></slot></span>
  </li>
</template>

<script>
export default {
  props: {
    disabled: Boolean
  },
  computed: {
    classes() {
      return {
        'k-menu-divider': true,
      }
    },
  },
}
</script>
