<template>
  <k-container class="view-add-admin-container">
    <h1>新增管理帳號</h1>
    <k-card>
      <k-form :questionConfig.sync="questions" @submit="handleSubmit" :submitLoading="loading"></k-form>
    </k-card>
    
    <k-divider><k-icon icon="caret-down-fill"></k-icon></k-divider>

    <k-card v-if="activeLink">
      <k-result
        status="success"
        title="已成功建立管理帳號"
        :subTitle="`帳號使用信箱： ${newAdminInfo.admin_email} ｜ 權限：${newAdminAuthName}`">
      <span>
        <p style="margin: 4px 0">下方連結為激活帳號使用，請將該連結傳給此新帳號的使用者，點選帳號激活後始可進行帳號細節資料設定</p>
        <k-icon icon="link-45deg" theme="info" /> <a>{{ activeLink }}</a>
      </span>
      <k-button slot="extra" theme="primary" v-copy="activeLink" v-copy:success="onCopy">複製連結</k-button>
    </k-result>
    </k-card>
    
  </k-container>
</template>

<script>
import { KForm } from '@/components/Form'
import KCard from '@/components/Card'
import KDivider from '@/components/Divider'
import KResult from '@/components/Result'
import KButton from '@/components/Button'
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      activeLink: '',
      newAdminInfo: {},
      loading: false,
      questions: genQuestions()
    }
  },
  methods: {
    ...mapActions('admin', ['createNewAdmin']),

    async handleSubmit({ values }) {
      this.loading = true
      const response = await this.createNewAdmin(values)
      console.log(response);
      if (response.data.admin_uid) {
        this.$message.success('已成功建立新管理帳號')
        this.activeLink = `https://admin.kirstytaiwan.com/#/active/${response.data.admin_uid}`
        this.newAdminInfo = response.data
      }
      if (response.error) {
        const { code } = response.error
        switch (code) {
          case 'auth/email-already-in-use':
            this.$message.error('此信箱已有註冊帳號，請使用其他信箱')
            break
          default:
            this.$message.error('發生錯誤，請重新操作')
            break
        }
      }
      this.loading = false
    },
    onCopy() {
      this.$message.success('已成功將激活連結複製到剪貼簿')
    }
  },
  computed: {
    ...mapState('admin',['authList']),
    newAdminAuthName() {
      if (Object.keys(this.newAdminInfo)) {
        return this.authList.filter(a => a.key_id == this.newAdminInfo.auth_id)[0]['auth_name']
      }
    }
  },
  watch: {
    authList: {
      immediate: true,
      handler() {
        if (this.authList.length) {
          this.questions = genQuestions(this.authList.filter(a => a.key_id !== '1'));
        }
      }
    }
  },
  components: {
    KForm, KCard, KDivider, KResult, KButton
  }
}

const genQuestions = (authList=[]) => [
  {
    questionType: 'k-input',
    name: 'admin_email',
    placeholder: "Enter...",
    label: "Email",
    type: 'email',
    theme: "light",
    required: true,
    title: '請輸入管理帳號使用信箱'
  },
        {
          questionType: 'k-select',
          name: 'auth_id',
          label:"權限類別",
          options: authList.map(a => ({ value: a.key_id, text: a.auth_name })),
          required: true,
          theme: 'light',
          placeholder: '請選擇管理權限'
        },
        {
          questionType: 'k-btn',
          label: "送出",
          theme: "primary",
          type: "submit"
        }
      ]
</script>
