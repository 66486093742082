<template>
  <span
    :class="classes"
    v-click-away="hide"
    @mouseenter="cleanable ? hendleHover('x-circle-fill') : void 8"
    @mouseleave="hendleHover('calendar')"
  >
    <k-input ref="self" type="text" v-bind="$props" :value="dateFormat" @onClick="toggleShow" :readonly="isReadonly">
      <k-icon slot="icon" :icon="icon" color="#757575" @click="cleanable ? handleClean : void 8" />
    </k-input>
    <transition name="date-picker-calendar">
      <k-calendar
        v-if="showCalendar"
        :style="{ transform: fixedTransform ? `translateY(-${fixedTransform + 15}px)` : null }"
        :selectedDate="value"
        @onSelect="handleSelect"
        range
      >
        <span slot="footer" @click="getToday">today</span>
      </k-calendar>
    </transition>
  </span>
</template>

<script>
import KDatePicker from './KDatePicker.vue'
import moment from 'moment'

export default {
  extends: KDatePicker,
  methods: {
    getToday() {
      if (!this.value || (start && end) || (!start && !end)) this.$emit('input', { start: moment() })
      else {
        this.$emit('input', { start: this.value.start, end: moment() })
        this.showCalendar = false;
      }
    },
    handleSelect(val) {
      const { start, end } = val
      const value = {
        start: moment([start.y, start.m, start.date]),
        end: end ? moment([end.y, end.m, end.date]) : undefined
      }
      if (start && end) {
        if (value.start.unix() > value.end.unix()) {
          value.start = moment([end.y, end.m, end.date])
          value.end = moment([start.y, start.m, start.date])
        }

        this.showCalendar = false;
      }
      this.$emit('input', value);
    },
    formatDate(value) {
      return value
        ? typeof value === 'string'
          ? moment(value).format(this.format)
          : value.format(this.format)
        : ''
    }
  },
  computed: {
    dateFormat() {
      return this.value
        ? `${this.formatDate(this.value.start)} ~ ${this.formatDate(this.value.end)}`
        : ''
    }
  }
}
</script>
