<template>
  <k-container
    class="view-dealer-dashboard"
    title="經銷商 Dashboard"
  >
    <section class="header-section">
      <k-widget-chart
        v-for="(item, index) in headerWedgit"
        :key="`hwc_${index}`"
        :header="sum(item.key ? dealerGetters[item.key] : [6969])"
        :text="item.label"
        :type="item.chart"
        :data="item.key ? dealerGetters[item.key] : Array(12).fill(50)"
        :label="item.label"
        :chartColor="COLOR_MAP[item.theme]"
        :options="{
          elements: {
            point: { radius: 0 }
          }
        }"
      />
    </section>

    <k-card
      class="traffic-chart"
      header="經銷營業額"
      style="margin-bottom: 16px"
    >
      <k-chart-bar
        height="300"
        :datasets="[
          {
            data: Array(12).fill(dealerOrderIncomeMonthly.reduce((acc, e) => acc + e) / 12),
            backgroundColor: COLOR_MAP.light,
            borderColor: COLOR_MAP.light,
            borderDash: [10, 5],
            borderWidth: 1,
            label: 'month-avarage',
            hide: true,
            elements: {
              point: { radius: 0 }
            },
            yAxisID: 'y',
          },
          {
            data: dealerOrderIncomeMonthly,
            backgroundColor: COLOR_MAP.warning,
            borderColor: COLOR_MAP.warning,
            hide: hideLine.includes('footer1'),
            borderWidth: 2,
            tension: 0.4,
            label: '每月應收帳款',
            fill: false,
            yAxisID: 'y',
          },
          {
            data: cumulativePurchasingIncome,
            backgroundColor: COLOR_MAP.success,
            borderColor: COLOR_MAP.success,
            fill: false,
            hide: hideLine.includes('footer3'),
            borderWidth: 2,
            tension: 0,
            label: '年度採購總額累計',
            yAxisID: 'y1',
          },
        ]"
        labels="months"
        :options="{
          responsive: true,
          maintainAspectRatio: false,
          barPercentage: 0.85,
          categoryPercentage: 0.7,
          scales: {
            x: { grid: { color: '#333' } },
            y: {
              type: 'linear', display: !hideLine.includes('footer1'), position: 'left',
              grid: { color: '#444' },
              ticks: { color: `${COLOR_MAP.warning}99` }
            },
            y1: {
              type: 'linear', display: !hideLine.includes('footer3'), position: 'right',
              grid: { display: hideLine.includes('footer1'), color: '#444' },
              ticks: { color: `${COLOR_MAP.success}99` }
            }
          },
          plugins: {
            legend: {
              display: false,
              position: 'bottom'
            }
          },
        }"
      >
      </k-chart-bar>

      <template #footer>
        <div :class="{ 'traffic-info': true, 'hide': hideLine.includes('footer1') }">
          <section>
            <p>月平均經銷應收帳款</p>
            <k-checkbox
              v-model="hideLine"
              value="footer1"
              :uncheckIcon="'eye-fill'"
              :checkedIcon="'eye-slash-fill'"
            />
          </section>
          <b>${{ parseInt(dealerOrderIncomeMonthly.reduce((acc, e) => acc + e) / 12) }} / $10000</b>
          <k-progress
            :value="parseInt(dealerOrderIncomeMonthly.reduce((acc, e) => acc + e) / 12)"
            max="10000"
            size="thin"
            theme="warning"
          />
        </div>
        <div :class="{ 'traffic-info': true, 'hide': hideLine.includes('footer3') }">
          <section>
            <p>年度總經銷收入</p>
            <!-- <k-checkbox
              v-model="hideLine"
              value="footer2"
              :uncheckIcon="'eye-fill'"
              :checkedIcon="'eye-slash-fill'"
            /> -->
          </section>
          <b>${{ dealerOrderIncomeMonthly.reduce((acc, e) => acc + e) }} / $100000</b>
          <k-progress
            :value="dealerOrderIncomeMonthly.reduce((acc, e) => acc + e)"
            max="100000"
            size="thin"
            theme="error"
          />
        </div>
        <div :class="{ 'traffic-info': true, 'hide': hideLine.includes('member') }">
          <section>
            <p>企業採購總產值</p>
            <k-checkbox
              v-model="hideLine"
              value="footer3"
              :uncheckIcon="'eye-fill'"
              :checkedIcon="'eye-slash-fill'"
            />
          </section>
          <b>${{ sumOfPurchasing }}</b>
          <k-progress
            value="100"
            size="thin"
            theme="success"
          />
        </div>
      </template>
    </k-card>

    <k-card
      class="dealer-order-frequency"
      header="經銷商訂貨量與訂貨頻率"
      :collapsible="false"
    >
      <k-chart-bubble
        :datasets="bubbleFackData"
        :options="{
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: context => ` ${context.dataset.label} | 訂購頻率：${context.parsed.x} | 平均訂購產值：${context.parsed.y}`,
              }
            },
          },
          elements: {
            point: {
              radius: context => (context.chart.width) / 24 * Math.abs(context.raw.v) / 1000
            }
          }
        }"
      />
    </k-card>
  </k-container>
</template>

<script>
import { KCheckbox } from '@/components/Form'
import { KWidgetChart, KWidgetBrand } from '@/components/Widget'
import { KChartLine, KChartDoughnut, KChartBubble, KChartBar } from '@/components/Chart'
import { KProgress, KProgressBar } from '@/components/Progress'
import KCard from '@/components/Card'
import { COLOR_MAP, getRandom } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'Dealer-Dashboard',
  mounted() {
    if (!this.$store.state.dealer.dealerList.length) this.$store.dispatch('dealer/query')
    if (!this.$store.state.dealer.orderList.length) this.$store.dispatch('dealer/queryOrderList')
  },
  data() {
    return {
      COLOR_MAP,
      headerWedgit,
      hideLine: [],
    }
  },
  methods: {
    getRandom,
    sum(arr) {
      return arr.reduce((arr, e) => arr + e)
    }
  },
  computed: {
    ...mapGetters('dealer', ['dealerGrowth', 'dealerOrderIncomeMonthly', 'cumulativePurchasingIncome', 'productOrderMonthly']),
    dealerGetters() {
      return {
        dealerGrowth: this.dealerGrowth,
        quantity50Monthly: this.productOrderMonthly.fibra_gel_50,
        quantity5Monthly: this.productOrderMonthly.fibra_gel_5,
      }
    },
    bubbleFackData() {
      let data = []
      for (let index = 0; index < 50; index++) {
        let type = getRandom(0, 1)
        data.push({
          data: [{ x: this.getRandom(20, 50), y: this.getRandom(20, 50), v: 400 }],
          label: 'test1',
          borderWidth: 3,
          backgroundColor: type ? COLOR_MAP.info + '80' : COLOR_MAP.warning + '80',
          borderColor: type ? COLOR_MAP.info : COLOR_MAP.warning
        })
      }
      return data
    },
    sumOfPurchasing() {
      if (this.cumulativePurchasingIncome.length) {
        return this.cumulativePurchasingIncome.reduce((acc, e) => acc + e)
      }
      return 0
    }
  },
  components: {
    KWidgetChart, KWidgetBrand, KCard, KCheckbox,
    KProgress, KProgressBar, KChartLine, KChartDoughnut, KChartBubble, KChartBar
  }
}

const headerWedgit = [
  { theme: 'error', label: '經銷商簽約數', chart: 'bar', key: 'dealerGrowth' },
  { theme: 'primary', label: '50ml 每月訂購數量', chart: 'bar', key: 'quantity50Monthly' },
  { theme: 'info', label: '5*5ml 每月訂購數量', chart: 'bar', key: 'quantity5Monthly' },
  { theme: 'secondary', label: '還沒想到要放啥', chart: 'line' },
]
</script>
