<script>
export default {
  props: {
    value: [Number, String],
    max: [Number, String],
    theme: String,
    showPercentage: Boolean,
    showValue: Boolean,
    r: {
      type: [Number, String],
      default: 70
    },
    size: {
      type: String,
      default: 'normal'
    },
    title: String
  },
  computed: {
    classes() {
      return {
        'k-progress-circle': true,
        'progress-circle': true
      }
    },
    computedMax() {
      return parseInt(this.max) || 100
    },
    dynamicPercentage() {
      if (this.inGroup) return parseInt(this.value) * 100 / this.$parent.computedMax
      return parseInt(this.value) * 100 / this.computedMax
    },
    computedBox() {
      const radius = parseInt(this.r)
      const plus = this.size === 'thin' ? 2.5 : 5
      return {
        x: radius + plus,
        y: radius + plus,
        r: radius
      }
    },
    circleStyle() {
      const radius = parseInt(this.r)
      const percentage = this.value / this.computedMax
      return {
        strokeDasharray: `calc(${radius}px * 3.14159 * 2 * ${percentage}),
          calc(${radius}px * 3.14159 * 2 * ${1 - percentage})`
      }
    }
  },
  render(h) {
    return (<div class={this.classes}>
      <div class="progress">
        <div class="content">
          <h3>{this.showValue ? this.value : `${Math.round(this.value * 100 / this.computedMax)}%`}</h3>
          <p>{this.$slots.default || this.title}</p>
        </div>

        <svg ref="svg" width={this.computedBox.x * 2} height={this.computedBox.x * 2}>
          <circle
            class={{ thin: this.size === 'thin' || null }}
            cx={ this.computedBox.x }
            cy={ this.computedBox.y}
            r={this.computedBox.r}
          />
          <circle
            style={this.circleStyle}
            class={{ thin: this.size === 'thin' || null, [`theme--${this.theme}`]: !!this.theme }}
            cx={ this.computedBox.x }
            cy={ this.computedBox.y}
            r={this.computedBox.r}
          />
        </svg>
      </div>
    </div>)
  },
}
</script>
