<template>
  <label :class="classes" @click="onClick">
    <span><slot>{{ label }}</slot></span>
  </label>
</template>

<script>

export default {
  mounted() {
    this.uid = `k-input-group-${parseInt(Math.random()*1000)}`;
    this.selected = this.defaultValue || '';
  },
  props: {
    'defaultValue': String
  },
  data() {
    return {
      uid: '',
      selected: ''
    }
  },
  props: {
    value: String,
    theme: String,
    label: String,
  },
  methods: {
    onClick() {
      this.$parent.handleSelected(this.value)
    }
  },
  computed: {
    classes() {
      return {
        'k-radio-btn': true,
        [`k-btn--${this.theme}`]: !!this.theme,
        'selected': this.value === this.$parent.selected
      }
    },
  },
  watch: {
  },
  components: {
  }
}
</script>
