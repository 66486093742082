<template>
  <k-table class="component-product-comment-table new" :items="data" :fields="CommentFields" hover>
    <template #cell(image-upload)="{item}">
      <k-upload :droppable="true" :multiple="true" @change="({files, srcs}) => {handleAddImageToRow(item, files, srcs)}">
        <div class="k-upload-button block">
          <k-icon icon="plus"></k-icon>
          <span>{{ '點擊或將圖檔拖拉至此處'}}</span>
        </div>
      </k-upload>
    </template>

    <template #cell(product_id)="{value}">
      <span>{{ productDict[value].product_name_zh }}</span>
    </template>

    <template #cell(comment_stars)="{value, item}">
      <k-icon v-for="(i, index) of Array(parseInt(value)).fill()" :key="`comment-stars-${item.key_id}-${index}`" icon="star-fill"/>
    </template>

    <template #cell(comment_skin_type)="{value}">{{ SKIN_TYPE_DICT[value] }}</template>

    <template #cell(comment_content)="{value}">
      <p>{{ value }}</p>
    </template>

    <template #cell(comment_images)="{value, item}">
      <ul>
        <li v-for="({ src }, index) in value" :key="`new-comment-images-${item.index}-${index}`">
          <div class="img-wrapper">
            <div><img :src="src"></div>
            <span class="tooltip"><img :src="src" alt=""></span>
          </div>
        </li>
      </ul>
    </template>

    <template #cell(comment_create_at)="{value}">
      {{ value ? timestampParse(value).format('YYYY-MM-DD') : '區間隨機' }}
    </template>

    <template #cell(action)="{item}">
      <k-button varient="ghost" theme="info" @click="showDetail(item)">查看詳情</k-button>
    </template>
  </k-table>
</template>

<script>
import KButton from '@/components/Button';
import KTable from '@/components/Table';
import KModal from '@/components/Modal';
import KCard from '@/components/Card'
import KDivider from '@/components/Divider'
import KTag from '@/components/Tag'
import KTooltip from '@/components/Tooltip'
import { KUpload, KInput } from '@/components/Form'
import { mapGetters, mapState } from 'vuex';
import { timestampParse } from '../../../utils';

const SKIN_TYPE_DICT = {
  dry: '乾肌',
  sensitive: '敏弱肌',
  fragile: '特殊美容',
  oil: '油肌',
  combination: '混合肌',
  rough: '毛孔粗大',
  acne: '痘肌',
  others: '其他'
}

export default {
  props: {
    name: String,
    data: {
      type: Array,
      default: () => []
    },
    showDetail: Function,
    handleAddImageToRow: Function
  },
  mounted() {

  },
  data() {
    return {
      CommentFields,
      SKIN_TYPE_DICT
    }
  },
  computed: {
    ...mapState('comment', ['commentList']),
    ...mapState('product', ['productList']),
    ...mapGetters('product', ['productDict']),
  },
  methods: {
    timestampParse
  },
  components: {
    KButton, KTable, KModal, KCard, KDivider, KTag, KTooltip, KUpload, KInput
  }
}

const CommentFields = [
  { key: 'image-upload', fieldIndex: 'image-upload', label: '圖檔上傳' },
  { key: 'product_id', fieldIndex: 'product_id', label: '產品' },
  { key: 'comment_stars', fieldIndex: 'comment_stars', label: '星數', sorter: (a, b) => b - a},
  { key: 'comment_user_name', fieldIndex: 'comment_user_name', label: '評論人' },
  { key: 'comment_skin_type', fieldIndex: 'comment_skin_type', label: '膚質' },
  { key: 'comment_title', fieldIndex: 'comment_title', label: '評論標題'},
  { key: 'comment_content', fieldIndex: 'comment_content', label: '評論內容' },
  { key: 'comment_images', fieldIndex: 'comment_images', label: '圖檔' },
  { key: 'comment_create_at', fieldIndex: 'comment_create_at', label: '評論時間' },
  { key: 'detail', fieldIndex: 'detail', label: '' }
]
</script>
