<template>
  <k-modal class="product-link-modal" :show="this.show" :closable="false" title="選擇插入產品" hideFooter @cancel="handleClick(false)">
    <div class="img-link-list">
      <header>
        <h2>選擇插入產品</h2>
      </header>
      <ul>
        <li
          v-for="product in productList.filter(p => p.product_is_launched === '1')"
          :key="`product-link-modal-${product.product_id}`"
          :class="{ 'choosed': productChoosed.includes(product.product_id) }"
          @click="toggleProduct(product.product_id)"
        >
          <div>
            <k-checkbox
              :name="product.product_id"
              :value="product.product_id"
              :checked="productChoosed.includes(product.product_id)"
            />
          </div>
          <img
            v-if="product.product_type === 'PRODUCT'"
            @error="e => ImgError(e)"
            :src="`https://chris-farrell.com.tw/img/product/${product.product_uid}/avatar.webp`"
          >
          <div class="img-wrapper" v-else>
            <img :src="`https://chris-farrell.com.tw/img/product/${product.product_images[0]}`" alt="">
          </div>

          <div class="info">
            <h3>{{ product.product_name_en }}</h3>
            <h3>{{ product.product_name_zh }}</h3>
          </div>
        </li>
      </ul>

      <div class="button-group">
        <k-button label="取消" theme="light" @click="() => this.handleClick(false)" />
        <k-button label="插入商品" theme="info" @click="() => this.handleClick(true)" />
      </div>
    </div>
  </k-modal>
</template>

<script>
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import MediaSelector from './MediaSelector.vue'
import { KInput, KCheckbox } from '@/components/Form'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      show: false,
      callback: void 0,
      productChoosed: []
    }
  },
  methods: {
    execute({callback}) {
      this.show = true
      this.callback = callback
    },
    handleClick(bool) {
      this.show = false
      if (bool) this.callback(this.productChoosed.map(id => this.productDict[id]))
      this.productChoosed = []
    },
    ImgError(event) {
      event.target.src = "https://admin.chris-farrell.com.tw/static/product_default_img.png";
      return true;
    },
    toggleProduct(id) {
      if (this.productChoosed.includes(id)) {
        this.productChoosed = this.productChoosed.filter(p => p !== id)
      } else {
        this.productChoosed.push(id)
      }
    }
  },
  computed: {
    ...mapState('product', ['productList']),
    ...mapGetters('product', ['productDict']),
    classes() {
      return {
        'k-confirm': true,
        'show': this.show,
      }
    },
    spanClasses() {
      return {
        [`confirm--${this.type}`]: this.type
      }
    }
  },
  components: {
    KModal, KButton, MediaSelector, KInput, KCheckbox
  }
}
</script>
