<template>
  <span :class="classes" @click="$refs['fileInput'].click()">
    <label v-if="label" for="upload"><span v-if="required && !hideRequiredMark">＊ </span>{{ label }}</label>
    <input
      name="upload"
      ref="fileInput"
      type="file"

      @invalid="handleInvalid"
      @change="handleChange($event.target.files)"
      :required="!!required"
    >
    <section 
      class="representation" 
      @dragover.prevent="void 8" 
      @dragleave.prevent="void 8"
      @drop.prevent="handleFileDrop($event)"
    >
      <slot></slot>
    </section>
    <span v-if="isInvalid" class="extra"><k-icon icon="exclamation-triangle" theme="danger" />圖檔不可為空，請上傳文章圖檔！</span>
  </span>
</template>

<script>
import formMixin from '@/mixin/form'

export default {
  mixins: [formMixin],
  props: ['value', 'required', 'block', 'label', 'hideRequiredMark', 'circle', 'droppable', 'multiple'],
  methods: {
    handleChange(files) {
      this.handleValid()
      if (this.multiple && files) {
        const promises = Array.from(files).reduce((acc, f) => [...acc, new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = el => resolve(el.target.result);
          reader.readAsDataURL(f);
        })], [])
        return Promise.all(promises).then(srcs => {
          this.$emit('change', { srcs, files });
          this.$emit('input', files);
        })
      }
      if (files && files.length === 1) {
        var reader = new FileReader();
        
        reader.onload = el => {
          this.$emit('change', { src: el.target.result, files });
          this.$emit('input', files);
        }
        
        reader.readAsDataURL(files[0]);
      }
    },
    handleFileDrop(e) {
      if (this.droppable) {
        const files = e.dataTransfer.files
        this.handleChange(files)
        this.$refs.fileInput.files = files
      }
    }
  },
  computed: {
    classes() {
      return {
        'k-upload': true,
        'block': !!this.block,
        'circle': !!this.circle,
        'invalid': this.isInvalid
      }
    }
  },
}
</script>
