<template>
  <section class="bread-crumbs">
    <router-link to="/">Home</router-link>
    <div v-if="!breadCrumbLink.length"><span>/</span>Dashboard</div>
      
    <div v-for="c, index in breadCrumbLink" :key="`bc_${index}`">
      <span>/</span>
      <a v-if="index !== breadCrumbLink.length - 1" @click="toRoute(c.path)" >{{ c.name.replace('-', '') }}</a>
      <p v-else>{{ c.name.replace('-', '') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      breadCrumbLink: []
    }
  },
  methods: {
    toRoute(path) {
      const target = this.breadCrumbLink.filter(e => e.path === path)[0]
      this.$router.push({ ...target })
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        const { name, path, params, query } = newVal
        const routeIndex = this.breadCrumbLink.map(e=>e.name).indexOf(name)
        if (newVal.path === '/') {
          this.breadCrumbLink = []
          return
        }
        if (routeIndex === -1) {
          this.breadCrumbLink.push({ name, path, params, query })
        } else {
          this.breadCrumbLink = [...this.breadCrumbLink.slice(0, routeIndex+1)]
        }
      }
    }
  }
}
</script>
