<template>
  <k-container v-if="mediaList.length" class="view-media-folder-container">
    <k-widget-icon
      v-if="folderType"
      :icon="folderTypeIcon.icon"
      :theme="folderTypeIcon.theme"
      :header="typeInfo ? `【 ${typeInfo.post_title} 】使用的所有媒體` : filterSource[folderType].length"
      :text="folderType !== 'unused' ? folderType.toUpperCase() : 'Not in Used'"
    >
      <k-radio-group
        v-if="!$route.query.type"
        class="right"
        radioType="button"
        defaultValue="file_uploadAt"
        varient="primary"
        label="排序方式："
        @onChange="handleSortChange"
      >
        <k-radio-button value="file_name" theme="warning">名稱</k-radio-button>
        <k-radio-button value="file_uploadAt" theme="warning">日期</k-radio-button>
      </k-radio-group>
      <div class="right" v-else>
        <k-button theme="secondary" @click="$router.back()">返回所有媒體</k-button>
      </div>
    </k-widget-icon>
    <hr>
    <k-card v-if="filterSource[folderType] && !filterSource[folderType].length" class="file-empty">
      <k-empty />
    </k-card>
    <div class="file-container">
      
      <k-widget-image
        v-for="file in filterSource[folderType]"
        :key="`file_${file.key_id}`"
        theme="info"
        :src="file.file_path"
        :alt="file.file_name"
        :info="file"
        @click="changeFocusFile(file)"
      >
        <template #default="info">
          <h3>{{ info.file_name }}</h3>
          <span v-if="info.useIn" :class="{ 'image-use-in': true, [info.useIn]: !!info.useIn }">
            <k-icon :icon="typeIcon[info.useIn]"></k-icon> In {{ info.useIn }}
          </span>
          <span v-else class="image-use-in not-used">
            <k-icon icon="folder2-open"></k-icon>{{ info.file_path | parseFilePath }}
          </span>
          <div class="image-info">
            <span>{{ info.file_uploadAt.format('YYYY/MM/DD HH:mm') }}</span>
            <span>{{ info.file_size }}Kb</span>
          </div>
        </template>
      </k-widget-image>
    </div>

    <MediaDrawer :focusFile="focusFile" />
  </k-container>
</template>

<script>
import { KWidgetIcon, KWidgetImage } from '@/components/Widget'
import { KRadioGroup, KRadioButton } from '@/components/Form'
import MediaDrawer from './components/drawer'
import KCard from '@/components/Card'
import KEmpty from '@/components/Empty'
import KTag from '@/components/Tag'
import KButton from '@/components/Button'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'MediaFolder',
  mounted() {
    const { folder } = this.$route.params
    this.folderType = folder
    if (!this.mediaList.length) this.$store.dispatch('media/query')
  },
  data() {
    return {
      folderType: null,
      focusFile: {},
      showDrawer: false,
      sizeType: 'Kb',
      typeIcon: {
        'Category':  'diagram-3',
        'Post': 'file-earmark-text-fill',
        'Product': 'cart-fill'
      }
    }
  },
  methods: {
    ...mapActions('media', ['changeSortType', 'deleteFile']),

    changeFocusFile(item) {
      console.log(item);
      this.focusFile = { ...item }
    },
    handleSortChange(type) {
      this.changeSortType(type);
    },
  },
  watch: {
    $route(newVal) {
      this.focusFile = {}
    }
  },  
  computed: {
    ...mapState('media',['mediaList']),
    ...mapState('posts', ['postList']),
    ...mapGetters('media', ['filterByMediaType']),
    
    folderTypeIcon() {
      switch (this.folderType) {
        case 'image':
          return { icon:'image', theme: 'warning'}
        case 'video' :
          return { icon:'play-btn-fill', theme: 'info' }
        case 'unused':
          return { icon: 'file-earmark-excel', theme: 'danger' }
        default:
          return 'file'
      }
    },
    filterSource() {
      const { type, id } = this.$route.query
      if (type && this.folderType) {
        return {
          ...this.filterByMediaType,
          [this.folderType]: this.filterByMediaType[this.folderType].filter(e => {
            if (type === 'post') {
              return e.post_id.includes(id)
            }
            return e[`${type}_id`]
          })
        }
      }
      return this.filterByMediaType
    },
    typeInfo() {
      const { type, id } = this.$route.query
      if (type === 'post' && this.postList) {
        return this.postList.filter(e => e.key_id === id)[0]
      }
      return null
    }
  },
  filters: {
    parseFilePath: path => {
      const pathArray = path.split('/')
      return pathArray.slice(1, pathArray.length-1).join('/')
    }
  },
  components: {
    KWidgetIcon, KWidgetImage, KCard, MediaDrawer, KEmpty, KRadioGroup, KRadioButton, KTag, KButton
  }
}
</script>
