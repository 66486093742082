<template>
  <li :class="classes">
    <span v-if="$slots.default || label"><slot>{{ label }}</slot></span>
  </li>
</template>

<script>
export default {
  props: {
    label: String
  },
  computed: {
    classes() {
      return {
        'k-menu-title': true,
      }
    },
  },
}
</script>
