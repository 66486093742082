import database from '@/utils/database'
import firebase from './firebase'

export default {
  namespaced: true,
  state: {
    currentUser: null,
    loginState: null
  },
  mutations: {
    saveUser(state, payload) {
      state.currentUser = { ...payload }
      state.loginState = true
    },
    updateUser(state, payload) {
      const { ...data } = payload
      state.currentUser = { ...state.currentUser, ...data }
    },
    rejectLogin(state) {
      state.loginState = false
    },
    logout(state) {
      state.currentUser = null,
      state.loginState = null
    }
  },
  actions: {
    async updateUser({ commit }, payload) {
      const { uid, ...data } = payload
      await database.table('admin').where('admin_uid', '=', uid).update({ ...data })
      commit('updateUser', data)
    },
    async hasLogin({ commit, dispatch }) {
      firebase.auth().onAuthStateChanged(async user => {
        if (user && user.uid) {
          const { data } = await database.table('admin').join('auth', 'auth_id').where('admin.admin_uid', '=', user.uid).get()
          await dispatch('admin/queryAuth', {}, { root: true })
          commit('saveUser', data[0])
        } else {
          commit('rejectLogin')
        }
      })
    },
    async login({ commit }, payload) {
      const { email, password } = payload
      try {
        let userCredential = await firebase.auth().signInWithEmailAndPassword(email, password)
        let user = userCredential.user
        console.log(user);
        if (user.uid) {
          const { data, exists } = await database.table('admin')
            .join('auth', 'auth_id').where('admin.admin_uid', '=', user.uid).get()
          if (exists) commit('saveUser', data[0])
          return { state: true, uid: user.uid }
        }
      } catch (error) {
        const { code, message } = error
        console.log(error);
        return { state: false, code, message }
      }
    },
    async logout({ commit }) {
      try {
        await firebase.auth().signOut()
        commit('logout')
        return true
      } catch (error) {
        const { code, message } = error
        console.log(error);
        return { code, message }
      }
    }
  },
  getters: {
  
  }
}
