<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  mounted() {
    this.controlPanel(this.value)
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    mutateActiveKey(key) {
      const index = [...this.value].indexOf(key);
      const newValue = [...this.value];
      if (index===-1) {
        this.$emit('input', [...this.value, key]);
      } else {
        newValue.splice(index, 1);
        this.$emit('input', newValue);
      }
    },
    controlPanel(activeKey) {
      this.$children.forEach(child => {
        let isOpen = activeKey.includes(child.$vnode.key);
        child.mutationOpenState(isOpen);
      })
    }
  },
  computed: {
    classes() {
      return {
        'k-collapse': true,
      }
    }
  },
  watch: {
    value(newVal) {
      this.controlPanel(newVal);
    }
  }
}
</script>
