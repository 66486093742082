<template>
  <k-card class="seo-setting-component" header="SEO Structured Data Setting ( Optional )" collapsible>
    <k-select 
      v-if="templateOptions.length" 
      placeholder="選擇模板" 
      v-model="templateUse" 
      :options="templateOptions" 
      theme="secondary"
      @change="handleChange"
      block
    />
    <k-divider>SEO Features</k-divider>
    <section v-if="templateUse">
      <NestedForm
        ref="form"
        :templateFeatures="templateUseFeatures"
        @mutate="$emit('mutate')"
        :originalStructureData ="originalStructureData"
      />
    </section>
  </k-card>
</template>

<script>
import { KSelect } from '@/components/Form'
import KDivider from '@/components/Divider'
import KCard from '@/components/Card'
import NestedForm from './NestedForm'
import KButton from '@/components/Button'
import { mapState } from 'vuex'

export default {
  mounted() { 
    if (!this.$store.state.seo.seo_templateList.length) this.$store.dispatch('seo/query')
    if (this.originalTemplateUse) this.templateUse = this.originalTemplateUse
  },
  props: {
    originalTemplateUse: String,
    originalStructureData: Object
  },
  data() {
    return {
      templateUse: undefined,
      templateUseFeatures: {}
    }
  },
  methods: {
    handleChange(val) {
      this.templateUseFeatures = this.seo_templateList.filter(t => t.key_id === val)[0].seo_template_features
    },
    getData() {
      return { templateUse: this.templateUse, data: this.$refs.form.getData()}
    }
  },
  computed: {
    ...mapState('seo', ['seo_templateList']),

    templateOptions() {
      if (this.seo_templateList.length) return this.seo_templateList.map(t => ({value: t.key_id, text: t.seo_template_name}))
      return []
    }
  },
  components: {
    NestedForm, KCard, KSelect, KDivider, KButton
  }
}
</script>
