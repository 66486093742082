function showError(component) {
  const element = component.$refs.input
  const { valueMissing, typeMismatch, tooShort, patternMismatch } = element.validity
  if (valueMissing && element.required) return "此欄位為必填，請勿留空"
  if (typeMismatch) return `請填入符合規範的${element.type}格式`
  if (tooShort) return `此欄位字數至少要${ element.minLength }字，您輸入的字數為${ element.value.length }，請增加至需求字數`
  if (patternMismatch) return `填入欄位內容不符合規範格式${component.patternExample && ('，格式範例：' + component.patternExample)}`
  return ""
}

export default {
  data() {
    return {
      isInvalid: false
    }
  },
  methods: {
    handleInvalid() {
      if (this.$refs.input) {
        this.invalidContext = showError(this)
      }
      this.isInvalid = true
    },
    handleValid() {
      this.isInvalid = false
    }
  },
}

