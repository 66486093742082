<template>
  <section class="user-admin-card" v-if="adminList.length && Object.keys(authDict).length">
    <k-card v-if="currentUser">
      <template #header>
        <p>Admin Management</p>
        <k-button theme="info" varient="outline" @click="toUserAdd">新增管理者</k-button>
      </template>
      <k-table :items="adminList" :fields="fields" hover footerCaption :pagination="adminList.length > 10 ? pagination : false">
        <template #cell(admin_name)="data">{{ data.value || '尚未設定' }}</template>
        <template #cell(admin_email)="data">
          <span style="margin-right: 8px">{{ data.value }}</span>
          <k-icon style="cursor: pointer" v-copy="data.value" v-copy:success="onCopy" icon="clipboard"></k-icon>
        </template>

        <template #cell(auth_id)="data">
          {{ authDict[data.value].auth_name }}
          <k-tag v-if="data.item.admin_is_confirm==='0'" theme="light">尚未激活</k-tag>
        </template>

        <template #cell(action)="data">
            <k-button
              v-if="parseInt(currentUser.auth_id) < parseInt(data.item.auth_id)"
              theme="danger"
              varient="ghost"
            >封存</k-button>
        </template>
      </k-table>
    </k-card>
  </section>
</template>

<script>
import KTable from '@/components/Table'
import KCard from '@/components/Card'
import KTag from '@/components/Tag'
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import KPopconfirm from '@/components/Popconfirm'
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    onCopy: Function
  },
  data() {
    return {
      fields,
      pagination: {
        current: 1,
        limit: 5
      },
    }
  },
  methods: {
    toUserAdd() {
      this.$router.push('/user/add')
    },
  },
  computed: {
    ...mapState('admin', ['adminList']),
    ...mapState('login', ['currentUser']),
    ...mapGetters('admin', ['authDict'])
  },
  components: {
    KTable, KCard, KTag, KButton, KModal, KPopconfirm
  }
}

const fields = [
  { key: 'admin_name', fieldIndex: 'admin_name', label: '名稱', sorter: (a, b) => ('' + a).localeCompare(b), searchable: true },
  { key: 'admin_email', fieldIndex: 'admin_email', label: '信箱', searchable: true },
  { key: 'auth_id', fieldIndex: 'auth_id', label: '權限', sorter: (a, b) => b - a, filters: [
    { text: '超級管理員', value: '1' },
    { text: '管理員', value: '2' },
    { text: '補充兵', value: '3' },
  ] },
  { key: 'action', fieldIndex: 'action', label: '編輯' }, 
]
</script>
