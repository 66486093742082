<template>
  <section class="view-questionnaire-edit-container">
    <k-container  title="建立表單">
      <k-card class="questionnaire-header">
        <k-input v-model="questionnaireName" label="問券名稱"></k-input>
        <k-input v-model="questionnaireRoute" label="問券網址" addonBefore="www.chris-farrell.com.tw/"></k-input>
      </k-card>
      
      <k-card header="問券內容" class="question-fields">
        <Container @drop="onDrop" :get-ghost-parent="getGhostParent" @drop-ready="onDropReady">
          <Draggable  v-for="cell, index in fields" :key="cell.key">
            <QuestionCell
              :field="cell"
              :fieldIndex="index"
              @copy="copyCell"
              @delete="deleteCell"
              @save="updateCell"
            />
          </Draggable>
        </Container>
      </k-card>

      
    </k-container>
    <aside>
      <div class="type-choose">
        <h3>新增題目</h3>
        <ul>
          <li v-for="field in fieldTypeArray" :key="field.type" @click="addCell(field.type)">
            <div class="icon"><k-icon :icon="field.icon" /></div>
            <span>{{ field.name }}</span>
          </li>
        </ul>
      </div>

      <div class="action">
        <k-button theme="light" icon="eye-fill">預覽</k-button>
        <k-button theme="info" @click="handleCreate">建立問卷</k-button>
      </div>
    </aside>
  </section>
</template>

<script>
import { KInput, KRadio, KSelect } from '@/components/Form'
import KCard from '@/components/Card'
import KButton from '@/components/Button'
import QuestionCell from './components/questionCell.vue'
import { gen8ID } from '@/utils'
import { applyDrag } from '@/utils/dnd'
import { Container, Draggable } from "vue-smooth-dnd"
import FIELD_TYPE from "./utils/fieldType.json"
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      FIELD_TYPE,
      questionnaireName: "",
      questionnaireRoute: "",
      fields: [
        // {
        //   type: 'ONE_LINE_INPUT',
        //   key: 'BGDD-1234',
        //   label: ''
        // }
      ]
    }
  },
  computed: {
    fieldTypeArray() {
      return Object.keys(FIELD_TYPE).map(key => ({ ...FIELD_TYPE[key] }))
    }
  },
  methods: {
    ...mapActions('questionnaire', ['createTemplate']),
    updateCell(newField) {
      const index = this.fields.map(f => f.key).indexOf(newField.key)
      this.fields.splice(index, 1, { ...newField })
    },
    addCell(type) {
      this.fields.push({
        ...FIELD_TYPE[type],
        type,
        key: gen8ID(),
        label: '',
        required: false,
      })
    },
    copyCell(key) {
      const index = this.fields.map(f => f.key).indexOf(key)
      this.fields.splice(index+1, 0, { ...this.fields[index], key: gen8ID(), label: "" })
    },
    deleteCell(key) {
      const index = this.fields.map(f => f.key).indexOf(key)
      this.fields.splice(index, 1)
    },
    onDrop (dropResult) {
      this.fields = applyDrag(this.fields, dropResult)
    },
    getGhostParent(){
      return document.body;
    },
    onDropReady(dropResult){
      console.log('drop ready', dropResult);
    },
    async handleCreate() {
      console.log(this.fields)
      if (!this.questionnaireName || !this.questionnaireRoute) return this.$message.warning('未設定問卷標題或網址')
      const isValid = this.fields.every((f, index) => {
        if (!f.label) {
          this.$message.warning('請確認所有題目都有輸入標題')
          return false
        }
        if (
          f.needSetting && f.settingComponent === 'MULTIPLE_CHOICE'
          && !f.options && !f.options.filter(e => e.text).length
        ) {
          this.$message.warning(`第${index+1}題沒有安排選項`)
          return false
        }
        if (f.settingComponent === 'LINEAR_SCALE' && (!f.scalePoints || !f.scalePoints?.every(e => e))) {
          this.$message.warning(`第${index+1}題未設定所有刻度`)
          return false
        }
        console.log(f);
        console.log(f.settingComponent);
        console.log(this.fields);
        return true
      })

      if (isValid) {
        const res = await this.createTemplate({
          questionnaire_template_name: this.questionnaireName,
          questionnaire_template_route: this.questionnaireRoute,
          fields: this.fields
        })
        console.log(res);
      }
    }
  },
  components: {
    QuestionCell, KButton, KCard, Container, Draggable,
    KInput, KRadio, KSelect,
  }
}
</script>
