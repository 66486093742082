<template>
  <k-container class="view-media-container">
    <template #header>
      <h2>媒體庫</h2>
      <k-button theme="primary" @click="showModal">上傳檔案</k-button>
    </template>
    <section class="media-widgets">
      <k-widget-icon 
        @click="toFolder('image')"
        icon="image" theme="warning"
        :header="filterByMediaType.image.length"
        text="IMAGE" />
      <k-widget-icon 
        @click="toFolder('video')"
        icon="play-btn-fill"
        theme="info"
        :header="filterByMediaType.video.length"
        text="VIDEO" />
      <k-widget-icon
        @click="toFolder('unused')"
        icon="file-earmark-excel"
        theme="danger"
        :header="filterByMediaType.unused.length"
        text="Not in Used" />
    </section>

    <section>
      <k-card class="media-drive">
        <template #header>
          <div v-if="!fileChoosed.length">
            <router-link :to="{ path: '/media', query: { dir: '/' } }"><h3>Media Drive</h3></router-link>
            <div v-for="route in currentBreadCrumb" :key="`media-route-${route.path}`">
              <k-icon icon="caret-right-fill" />
              <router-link :to="{ path: '/media', query: { dir: route.path } }"><h3>{{ route.name }}</h3></router-link>
            </div>
          </div>
          <div v-else class="control">
            <k-checkbox @change="handleFileAllCheck" :checked="fileChoosed.length === currentDir.file.length" />
            <h3>已選取 {{ fileChoosed.length }} 個</h3>
            <k-tooltip title="移至" placement="bottom">
              <span class="action"><k-icon icon="folder-symlink-fill" @click="handleMove" /></span>
            </k-tooltip>
            <k-tooltip title="刪除" placement="bottom">
              <span class="action" @click="handleDelete"><k-icon icon="trash3-fill" /></span>
            </k-tooltip>
            <k-tooltip title="取得連結" placement="bottom">
              <span class="action"><k-icon icon="link" /></span>
            </k-tooltip>
            <k-tooltip v-if="fileChoosed.length === 1"  title="重新命名" placement="bottom">
              <span class="action" @click="handleRename"><k-icon icon="pencil-square" /></span>
            </k-tooltip>
          </div>
          <k-button theme="primary" icon="folder-plus" @click="handleMkdir">新增資料夾</k-button>
        </template>

        <section v-if="currentDir"
          :class="{ 'draging': fileDragIn}"
          @dragenter.prevent="e => fileDraging(e)" 
          @dragover.prevent="e => fileDraging(e)" 
          @drop.prevent="handleFileDrop($event)"
        >
          <div class="dirs" v-if="currentDir.dir && currentDir.dir.length">
            <header><h4>資料夾</h4></header>
            <div class="list">
              <k-widget-icon
                v-for="dir in currentDir.dir"
                :key="`media-file-${currentDir.path}${dir.path}`"
                icon="folder-fill"
                theme="light"
                :header="dir.path.split('/').slice(-1)[0]"
                text="檔案夾"
                @dblclick="e => toRoute(dir.path, e)"
                @mousedown.native="e => toRoute(dir.path, e)"
                @contextmenu="e => rightClickHandler(dir, e, 'dir')"
              />
            </div>
          </div>
          <div class="files" v-if="currentDir.file && currentDir.file.length">
            <header><h4>檔案</h4></header>
            <div class="list">
              <k-card
                :class="{ 'file-card': true, choosed: fileChoosed.find(f => f.file === file.file)}"
                v-for="file in currentDir.file"
                :key="`media-file-${currentDir.path}/${file.name}`"
                noShadow
                @click="e => handleFileClick(e, file)"
                @dblclick="handleFocusFile(file)"
                @contextmenu="e => rightClickHandler(file, e, 'file')"
              >
                <div slot="header">
                  <k-icon
                    v-if="!fileChoosed.find(f => f.file === file.file)"
                    :icon="file.type === 'IMAGE' ? 'file-earmark-image' : 'file-earmark-fill'" theme="warning"
                  />
                  <k-checkbox
                    v-else
                    theme="success"
                    @change="bool => handleFileCheck(bool, file)"
                    :checked="Boolean(fileChoosed.find(f => f.file === file.file))"
                  />
                  <h3>{{ file.file }}</h3>
                </div>
                <div class="img-wrapper">
                  <img :src="`https://www.chris-farrell.com.tw/img/${currentRoute}/${file.file}`" alt="">
                </div>
              </k-card>
            </div>
          </div>
        </section>
      </k-card>
    </section>

    <!-- <MediaDrawer :focusFile="focusFile" /> -->

    <k-modal :show.sync="modalIsShow" title="Upload File" hideFooter @hide="resetForm">
      <k-form :questionConfig="questions" @submit="handleSubmit"></k-form>
    </k-modal>

    <MoveMadal :show.sync="showMoveModal" :route="currentRoute" :files="fileChoosed || []"/>
    <FloatDropdown ref="float_dropdown" v-click-away="hideFloatDropdown" :files="fileChoosed" :dirs="dirChoosed" />

    <transition name="slide-up">
      <k-card
        v-if="fileUploadCache && fileUploadCache.length"
        :class="{'upload-list': true, 'header-only': uploadListOnlyShowHeader}"
      >
        <template #header>
          <h3>上傳 {{ fileUploadCache.length }} 個項目</h3>
          <div class="button-group">
            <span :class="{hide: uploadListOnlyShowHeader}" @click="toggleuploadListHeaderOnly"><k-icon icon="chevron-down"/></span>
            <span class="close" @click="handleCleanUploadCache"><k-icon icon="x-lg"/></span>
          </div>
        </template>

        <template #default>
          <ul>
            <li v-for="file in fileUploadCache" :key="`file-upload-list-${file.name}`">
              <div class="file">
                <k-icon theme="warning" icon="image-fill" />
                <h4>{{ file.name }}</h4>
              </div>
              <div class="progress">
                <k-progress :value="file.progress" size="thin" theme="info" />
              </div>
              <div class="state">
                <span class="waiting" v-if="file.state === 'waiting'"><k-icon theme="llight" icon="hourglass"/></span>
                <span v-if="file.state === 'success'"><k-icon theme="success" icon="check-circle-fill"/></span>
              </div>
            </li>
          </ul>
        </template>
      </k-card>
    </transition>

    <article v-if="focusFile" class="focus-file" @click="e => blurFile(e)">
      <header>
        <div class="file-name">
          <span class="back" @click="()=>blurFile()"><k-icon icon="arrow-left-short" /></span>
          <div>
            <k-icon  theme="warning" icon="image-fill" />
            <h3>{{ focusFile.file }}</h3>
          </div>
        </div>
        <div class="operation">
          <span @click="handleDownload(focusFile)"><k-icon icon="download" /></span>
          <span><k-icon icon="trash3-fill" /></span>
          <span><k-icon icon="pencil-square" /></span>
          <span><k-icon icon="three-dots-vertical" /></span>
        </div>
      </header>
      <main id="focus-file">
        <img :src="`https://www.chris-farrell.com.tw/img${focusFile.parentRoute}/${focusFile.file}`" alt="">
      </main>
    </article>
  </k-container>
</template>

<script>
import KTable from '@/components/Table'
import KCard from '@/components/Card'
import KDropdown from '@/components/Dropdown'
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import { KForm, KCheckbox } from '@/components/Form'
import { KMenu, KMenuItem } from '@/components/Menu'
import { KWidgetIcon, KWidgetImage } from '@/components/Widget'
import { KProgress, KProgressBar } from '@/components/Progress'
import KTag from '@/components/Tag'
import KTooltip from '@/components/Tooltip'
import MediaDrawer from './components/drawer'
import MoveMadal from './components/moveModal'
import FloatDropdown from './components/floatDropdown'
import storage from '@/utils/storage'
import { fetchApi } from '@/utils'
import { mapActions, mapGetters, mapState } from 'vuex'

const fields = [
  { key: 'file_name', fieldIndex: 'file_name', label: '名稱', sorter: (a, b) => ('' + a).localeCompare(b), searchable: true },
  { key: 'file_path', fieldIndex: 'file_path', label: '資料夾'},
  { key: 'useIn', fieldIndex: 'useIn', label: '使用於'},
  { key: 'file_uploadAt', fieldIndex: 'file_uploadAt', label: '上傳時間', sorter: (a, b) => a.unix() - b.unix() }, 
  { key: 'action', fieldIndex: 'action', label: '' }, 
]

export default {
  name: 'Media',
  mounted() {
    this.$store.dispatch('media/query')
    if (!this.$store.state.media.mediaTree) this.$store.dispatch('media/fetchMediaTree')
    if (this.$route.query.dir) this.currentRoute = this.$route.query.dir
  },
  data() {
    return {
      fields,
      questions: genQuestions(this.handleFileChange),
      focusFile: {},
      typeIcon: {
        'Category':  'diagram-3',
        'Post': 'file-earmark-text-fill',
        'Product': 'cart-fill'
      },
      showDrawer: false,
      modalIsShow: false,
      currentRoute: '/',
      fileDragIn: false,
      fileUploadCache: null,
      dragTimeout: null,
      showUploadList: false,
      showMoveModal: false,
      uploadListOnlyShowHeader: false,
      fileChoosed: [],
      dirChoosed:[],
      focusFile: null
    }
  },
  computed: {
    ...mapState('media', ['mediaLayers']),
    ...mapGetters('media', ['recentFile', 'filterByMediaType']),
    currentBreadCrumb() {
      if (this.currentRoute === '/') return []
      return [...this.currentRoute.split('/').filter(item => item && item !== '/').reduce((acc, cur) => {
        acc.push({ path: `${acc[acc.length - 1] ? acc[acc.length - 1].path : ''}/${cur}`, name: cur })
        return acc
      }, [])]
    },
    currentDir() {
      if (this.mediaLayers) {
        return {
          ...this.mediaLayers[this.currentRoute]
        }
      }
      return
    }
  },
  methods: {
    ...mapActions('media', ['insertFileInfo', 'insertDir', 'renameFile', 'deleteFile']),
    rightClickHandler(inst, e, type) {
      e.preventDefault()
      console.log(inst);
      this.dirChoosed = []
      if (type === 'dir') {
        this.dirChoosed = [inst]
        this.fileChoosed = []
      }
      if (type === 'file' && !this.fileChoosed.find(f => f.file === inst.file)) {
        this.fileChoosed.push(inst)
      }
      this.$refs.float_dropdown.active({
        axisX: e.clientX,
        axisY: e.clientY,
        methods: {
          rename: type === 'file' ? this.handleRename : this.handleRenameDir,
          move: this.handleMove,
          delete: this.handleDelete
        }
      })
    },
    hideFloatDropdown() {
      this.$refs.float_dropdown.hide()
      // this.fileChoosed = []
    },
    toRoute(route, event) {
      if (event.type === 'dblclick') {
        this.currentRoute = route
        this.$router.push(`/media?dir=${route}`)
      }
      if (event.type === 'mousedown') {
        if (event.button === 1) window.open(`/#/media?dir=${route}`, '_blank').focus();
      }
    },
    fileDraging(e) {
      this.fileDragIn = true
      if (this.dragTimeout !== null) {
        clearTimeout(this.dragTimeout)
      }
      this.dragTimeout = setTimeout(() => {
        this.fileDragIn = false
        console.log('drag timeout');
        this.dragTimeout = null
      }, 100)
    },
    async handleFileDrop(e) {
      const files = e.dataTransfer.files
      this.fileUploadCache = [...[...files].map(f => ({
        name: f.name, size: f.size, type: f.type, progress: 0, state: 'waiting'
      })), ...(this.fileUploadCache || [])]
      
      const promises = [...files].map(file => new Promise(async (res, rej) => {
        try {
          const response = await storage.path(this.currentRoute).put(file, {
            onProgress: evt => {
              const progress = (evt.loaded / evt.total) * 100
              this.fileUploadCache = this.fileUploadCache.map(item => {
                if (item.name === file.name) item.progress = progress
                return item
              })
            },
            onLoad: (response) => {
              this.insertFileInfo({ ...response })
              this.fileUploadCache = this.fileUploadCache.map(item => {
                if (item.name === file.name) item.state = 'success'
                return item
              })
            }
          })
          res(response)
        } catch (error) {
          rej(error)
        }
      }))
      const uploadResults = await Promise.all(promises)
      this.fileDraging(false)
    },
    toggleuploadListHeaderOnly() {
      this.uploadListOnlyShowHeader = !this.uploadListOnlyShowHeader
    },
    handleCleanUploadCache() {
      this.fileUploadCache = null
    },
    handleFocusFile(file) {
      this.focusFile = file
    },
    handleDownload(file) {
      const href = `http://www.chris-farrell.com.tw/img${file.parentRoute}/${file.file}`
      this.downloadByBlob(href, file.file)
    },
    downloadByBlob(url,name) {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = href
          link.download = name
          link.click()
          link.remove()
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    },
    handleFileClick(e, file) {
      if (e.target.className !== 'img-wrapper' && !this.fileChoosed.find(f => f.file === file.file)) this.fileChoosed.push(file)
    },
    handleFileCheck(bool, file) {
      if (bool) this.fileChoosed.push(file)
      else this.fileChoosed = this.fileChoosed.filter(item => item.file !== file.file)
    },
    handleFileAllCheck(val) {
      if (val) this.fileChoosed = [...this.currentDir.file]
      else this.fileChoosed = []
    },
    handleMove() {
      this.showMoveModal = true
    },
    handleRename() {
      this.$prompt.info({
        title: '重新命名檔案',
        text: '',
        icon: 'pencil-square',
        value:  this.fileChoosed[0].file,
        callback: async (name) => {
          const reg = /^.*\.[A-Za-z0-9]+$/g
          if (this.currentDir.file.find(item => item.file === name)) return this.$message.error('檔名重複')
          if (reg.test(name)) {
            this.renameFile({ file: this.fileChoosed[0], newName: name })
            return this.$message.success('已修改檔案名稱')
          }
          return this.$message.error('檔案名稱不符合規範')
        }
      })
    },
    handleRenameDir() {
      this.$prompt.info({
        title: '重新命名資料夾',
        text: '',
        icon: 'folder-fill',
        value:  this.dirChoosed[0].path.split('/').slice(-1)[0],
        callback: async (name) => {
          if (this.currentDir.dir.find(item => item.path.split('/').slice(-1)[0]===name)) return this.$message.error('名稱重複')
          console.log({
            file: this.dirChoosed[0].path.split('/').slice(-1)[0],
            parentRoute: this.dirChoosed[0].path.split('/').slice(0, -1).join('/') || '/',
            path: this.dirChoosed[0].path
          });
          this.renameFile({ file: {
            file: this.dirChoosed[0].path.split('/').slice(-1)[0],
            parentRoute: this.dirChoosed[0].path.split('/').slice(0, -1).join('/') || '/',
            path: this.dirChoosed[0].path
          }, newName: name, isDir: true })
            return this.$message.success('已修改資料夾名稱')
        }
      })
    },
    handleDelete() {
      this.$confirm.error({
        title: '確定要將檔案進行刪除？',
        text: `${this.dirChoosed.length ? '資料夾' : '檔案'} (${this.dirChoosed.length || this.fileChoosed.length}個項目)：
        ${this.dirChoosed.length
          ? this.dirChoosed.map((item, index) => `${index+1}. ${item.path}`).join('\n')
          : this.fileChoosed.map((item, index) => `${index+1}. ${item.file}`).join('\n')
        }`,
        icon: 'trash3-fill',
        callback: async (ensure) => {
          if (ensure) {
            if (this.dirChoosed.length) {
              await this.deleteFile({
                files: this.dirChoosed.map(d => ({
                  file: d.path.split('/').slice(-1)[0],
                  parentRoute: this.dirChoosed[0].path.split('/').slice(0, -1).join('/') || '/',
                  path: d.path
                })),
                deleteType: 'dir'
              })
              this.$message.info('已刪除資料夾')
            }
            else {
              await this.deleteFile({
                files: this.fileChoosed,
                deleteType: 'file'
              })
              this.$message.info('已刪除檔案')
            }
          }
        }
      })
    },
    blurFile(e) {
      if (e) {
        if (e.target.id === 'focus-file') this.focusFile = null
      } else {
        this.focusFile = null
      }
    },
    handleMkdir() {
      this.$prompt.info({
        title: '請輸入要新增的資料夾名稱',
        text: `當前路徑：${this.currentRoute}`,
        icon: 'folder-plus',
        callback: async (context) => {
          if (context) {
            const res = await fetchApi('/api/mkdir/', { path: this.currentRoute, name: context })
            if (res.response) {
              this.insertDir({ path: this.currentRoute, name: context })
            }
          } else {
            this.$message.warning('未輸入資料夾名稱')
          }
        }
      })
    },
    changeFocusFile(item) {
      this.focusFile = { ...item }
    },
    toFolder(type) {
      this.$router.push(`/media/${type}`)
    },
    onCopy() {
      this.$message.success('以複製圖片網址至剪貼簿')
    },
    showModal() {
      this.modalIsShow = true;
    },
    handleFileChange(info) {
      this.questions[1].imgSrc = info.src
    },
    async handleSubmit({ fileList, values }) {
      const { folder } = values
      const { file } = fileList
      console.log(folder);
      console.log(file);
      const response = await storage.path(folder || this.currentRoute).put(file[0])
      console.log(response);
      // await this.insertFileInfo({ ...response })
      // console.log(response)
      switch (response.status) {
        case 200:
          this.$message.success('已完成上傳')
          this.insertFileInfo({ ...response })
          this.modalIsShow = false
          return
        case 409:
          this.$message.error('此檔案已存在於目標資料夾')
          return
        default: return
      }
    },
    resetForm() {
      this.questions.forEach(q => { if(q.value) delete q.value });
    }

  },
  watch: {
    $route(newRoute) {
      console.log(newRoute);
      if (newRoute.query.dir) this.currentRoute = newRoute.query.dir
    }
  },
  filters: {
    parseFilePath: path => {
      const pathArray = path.split('/')
      return pathArray.slice(1, pathArray.length-1).join('/')
    }
  },
  components: {
    KTable, KCard, KWidgetIcon, KDropdown, KMenu, KMenuItem, MediaDrawer, KButton, KModal, KForm, KTag, KWidgetImage,
    KProgress, KProgressBar, KTooltip, KCheckbox, MoveMadal, FloatDropdown
  }
}

const genQuestions = uploadCallback => [
  {
          questionType: 'k-select',
          name: 'folder',
          label:"上傳資料夾",
          options: [
            { value: '/category', text: '/category' },
            { value: '/post', text: '/post' },
            { value: '/product', text: '/product' },
            { value: '/self', text: '/self' },
          ],
          theme: 'light',
          placeholder: '選擇要上傳至哪一個資料夾'
        },
        {
          questionType: 'k-upload',
          name: 'file', 
          value: null,
          imgSrc: null,
          alt: 'banner',
          label: '選擇檔案',
          required: true,
          block: true,
          onChange: info => uploadCallback(info)
        },
        {
          questionType: 'k-btn',
          label: "submit",
          theme: "primary",
          type: "submit"
        }
]
</script>
