<template>
  <k-container class="view-crm-container">
    <template #header>
      <h2>經銷商管理</h2>
      <k-button
        theme="info"
        @click="showEdit"
      >新增經銷商</k-button>
    </template>

    <k-card>
      <k-table
        v-if="dealerWithAvarageOrder.length"
        :items="dealerWithAvarageOrder"
        :fields="TableFields"
        hover
      >
        <template #cell(dealer_type)="data">
          <k-tag :theme="DEALER_TYPE_STATE_DICT[data.value].theme">{{ DEALER_TYPE_STATE_DICT[data.value].text }}</k-tag>
        </template>

        <template #cell(contract_due)="{ item }">
          {{ item.dealer_contract_signed_at ? timestampParse(item.dealer_contract_signed_at).add(1,
              'years').format('YYYY/MM/DD') : ''
          }}
        </template>

        <template #cell(dealer_contract_signed_at)="{ value }">
          {{ value ? timestampParse(value).format('YYYY/MM/DD') : '' }}
        </template>

        <template #cell(avarage_order_duration)="{ value }">
          {{ value || '-' }}
        </template>

        <template #cell(dealer_price_50)="{ value }">
          <k-tooltip
            v-if="value && value.length > 1"
            placement="bottom"
            :title="`歷史定價：\n ${value.join(' | ')}`"
          >
            {{ value && value[0] }}
          </k-tooltip>
          <span v-else>{{ value && value[0] }}</span>
        </template>

        <template #cell(dealer_price_5)="{ value }">
          <k-tooltip
            v-if="value && value.length > 1"
            placement="bottom"
            :title="`歷史定價：\n ${value.join(' | ')}`"
          >
            {{ value && value[0] }}
          </k-tooltip>
          <span v-else>{{ value && value[0] }}</span>
        </template>

        <template #cell(action)="{ item }">
          <k-button
            v-if="item.dealer_type !== 'PURCHASING'"
            varient="ghost"
            theme="info"
            @click="editDealer(item.dealer_id)"
          >編輯</k-button>
        </template>
      </k-table>
    </k-card>

    <k-modal
      class="dealer-modal"
      :show.sync="showDealerModal"
      :hideWithOk="false"
      title="建立經銷商資訊"
      :okButtonProps="{ theme: 'primary', loading: editLoading, label: Object.keys(focusDealer).length ? '更新' : '建立' }"
      @ok="$refs.form.submit()"
      @cancel="handleCancel"
    >
      <k-form
        ref="form"
        :questionConfig.sync="QuestionFeilds"
        :defaultValues="{ dealer_price_50: '1000', dealer_price_5: '550', ...focusDealer }"
        @submit="handleDealerSubmit"
      >
      </k-form>
    </k-modal>
  </k-container>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import KTable from '@/components/Table'
import KTag from '@/components/Tag'
import KModal from '@/components/Modal'
import KTooltip from '@/components/Tooltip'
import { KForm } from '@/components/Form'
import { timestampParse } from '@/utils'

export default {
  name: 'Dealer',
  mounted() {
    if (!this.$store.state.dealer.dealerList.length) this.$store.dispatch('dealer/query')
    if (!this.$store.state.dealer.orderList.length) this.$store.dispatch('dealer/queryOrderList')
  },
  data() {
    return {
      DEALER_TYPE_STATE_DICT,
      TableFields,
      QuestionFeilds,
      showDealerModal: false,
      editLoading: false,
      focusDealer: {}
    }
  },
  methods: {
    ...mapActions('dealer', ['add', 'update']),
    timestampParse,
    showEdit() {
      this.showDealerModal = true
    },
    handleCancel() {
      this.QuestionFeilds.forEach((e, index) => delete this.QuestionFeilds[index].value)
      this.focusDealer = {}
    },
    async handleDealerSubmit({ values }) {
      this.editLoading = true
      const res = await this.add({ ...values, dealer_contract_signed_at: values.dealer_contract_signed_at.unix() })
      if (res.status === 200) {
        this.$message.success('已建立經銷商')
        this.showDealerModal = false
        this.editLoading = false
      }
    },
    editDealer(id) {
      const dealer = this.dealerList.filter(d => d.dealer_id === id)[0]
      const { dealer_contract_signed_at, dealer_price_50, dealer_price_5, ...data } = dealer
      this.focusDealer = {
        ...data,
        dealer_contract_signed_at: timestampParse(dealer_contract_signed_at),
        dealer_price_50: dealer_price_50[0],
        dealer_price_5: dealer_price_5[0]
      }
      console.log(this.focusDealer);
      this.showEdit()
    }
  },
  computed: {
    ...mapState('dealer', ['dealerList']),
    ...mapGetters('dealer', ['dealerWithAvarageOrder'])
  },
  components: {
    KButton, KCard, KTable, KTag, KForm, KModal, KTooltip
  }
}

const TableFields = [
  { key: 'dealer_name', fieldIndex: 'dealer_name', label: '工作室名稱', searchable: true },
  { key: 'dealer_contact', fieldIndex: 'dealer_contact', label: '聯絡人' },
  {
    key: 'dealer_type', fieldIndex: 'dealer_type', label: '合作類型', filters: [
      { text: '買斷制經銷', value: 'DEALER_BUYOUT' },
      { text: '寄賣制經銷', value: 'DEALER_CONSIGNMENT' },
      { text: '企業採購', value: 'PURCHASING' },
    ]
  },
  {
    key: 'dealer_contract_signed_at', fieldIndex: 'dealer_contract_signed_at', label: '簽約時間',
    sorter: (a, b) => b - a
  },
  { key: 'contract_due', fieldIndex: 'contract_due', label: '合約截止日' },
  { key: 'dealer_price_50', fieldIndex: 'dealer_price_50', label: '50ml 經銷價', },
  { key: 'dealer_price_5', fieldIndex: 'dealer_price_5', label: '5*5ml 經銷價', },
  { key: 'avarage_order_duration', fieldIndex: 'avarage_order_duration', label: '平均叫貨天數' },
  { key: 'action', fieldIndex: 'action', label: '編輯' }
]

const QuestionFeilds = [
  {
    label: '經銷商名稱',
    name: 'dealer_name',
    questionType: 'k-input',
    required: true,
  },
  {
    label: '經銷商聯絡人',
    name: 'dealer_contact',
    questionType: 'k-input',
  },
  {
    label: '經銷商出貨地址',
    name: 'dealer_address',
    questionType: 'k-input',
  },
  {
    label: '經銷合約方案',
    name: 'dealer_type',
    questionType: 'k-select',
    options: [
      { value: 'DEALER_CONSIGNMENT', text: '寄賣制' },
      { value: 'DEALER_BUYOUT', text: '買斷制' },
    ],
    placeholder: '請選則經銷方案',
    block: true,
    theme: 'secondary',
    required: true
  },
  {
    label: '簽約日期',
    name: 'dealer_contract_signed_at',
    questionType: 'k-date-picker',
    required: true,
  },
  {
    label: '50ml 正貨經銷價',
    name: 'dealer_price_50',
    questionType: 'k-input',
    type: 'number',
    required: true,
    addonBefore: 'TWD $',
  },
  {
    label: '5*5ml 組合經銷價',
    name: 'dealer_price_5',
    questionType: 'k-input',
    type: 'number',
    required: true,
    addonBefore: 'TWD $',
  },
]

const DEALER_TYPE_STATE_DICT = {
  DEALER_CONSIGNMENT: {
    theme: 'info',
    text: '寄賣制經銷'
  },
  DEALER_BUYOUT: {
    theme: 'success',
    text: '買斷制經銷'
  },
  PURCHASING: {
    theme: 'warning',
    text: '企業採購'
  }
}
</script>
