import { genBasicModel } from './utils/basicAction'
import { update, remove } from './utils/basicService'
import database from '@/utils/database'
import { timestampParse } from '@/utils'
import { copySheet, connectSheet, updateSheet } from '@/utils/googleSheet'
import moment from 'moment'

const MODEL_NAME = 'dealer'
const { basicAction, basicMutation } = genBasicModel(
  MODEL_NAME,
  query,
  create,
  update(MODEL_NAME))

const PORT_TYPE_DICT = {
  IN_PERSON: '親送',
}

async function query() {
  const res = await database.table(MODEL_NAME).orderBy('dealer_id', 'desc').get()
  res.data = res.data.map(({ dealer_price_50, dealer_price_5, dealer_price, ...data }) => ({
    ...data,
    dealer_price_50: dealer_price_50 ? dealer_price_50.split('|') : null,
    dealer_price_5: dealer_price_5 ? dealer_price_5.split('|') : null,
    dealer_price: dealer_price ? JSON.parse(dealer_price) : []
  }))
  return res
}

async function create(payload) {
  const res = await database.table(MODEL_NAME).set({ ...payload })
  res.data = {
    ...res.data,
    dealer_price_50: res.data.dealer_price_50.split('|'),
    dealer_price_5: res.data.dealer_price_5.split('|')
  }
  return res
}

export default {
  namespaced: true,
  state: {
    dealerList: [],
    orderList: []
  },
  mutations: {
    ...basicMutation,
    saveOrderList(state, payload) {
      state.orderList = payload
    },
    incomeRecieve(state, payload) {
      let index = state.orderList.map(e => e.dealer_order_id).indexOf(payload)
      state.orderList[index].dealer_order_has_paid = true
    },
    orderPort(state, payload ) {
      let index = state.orderList.map(e => e.dealer_order_id).indexOf(payload)
      state.orderList[index].dealer_order_has_port = true
    },
    createOrder(state, payload) {
      state.orderList = [{ ...payload }, ...state.orderList]
    },
    deleteOrder(state, payload) {
      let index = state.orderList.map(e => e.dealer_order_id).indexOf(payload)
      state.orderList.splice(index, 1)
    },
    updateOrder(state, payload) {
      let index = state.orderList.map(e => e.dealer_order_id).indexOf(payload.dealer_order_id)
      state.orderList.splice(index, 1, { ...state.orderList[index], ...payload })
    }
  },
  actions: {
    ...basicAction,
    async queryOrderList({ commit }) {
      const res = await database.table('dealer_order')
        .join('dealer', 'dealer_id')
        .orderBy('dealer_order.dealer_order_create_at', 'desc')
        .get()
      commit('saveOrderList', res.data.map(e => ({
        ...e,
        dealer_order_has_paid: e.dealer_order_has_paid === '0' ? false : true,
        dealer_order_has_port: e.dealer_order_has_port === '0' ? false : true,
        dealer_order_other_product: e.dealer_order_other_product && JSON.parse(e.dealer_order_other_product),
        dealer_order_products: JSON.parse(e.dealer_order_products),
        dealer_order_giveaways: JSON.parse(e.dealer_order_giveaways)
      })))
    },
    async orderIncomeRecieve({ commit }, payload) {
      const res = await database.table('dealer_order')
        .where('dealer_order_id', '=', payload)
        .update({ dealer_order_has_paid: true })
      console.log(res);
      commit('incomeRecieve', payload)
      return res
    },
    async orderOutPort({ commit }, payload) {
      const res = await database.table('dealer_order')
        .where('dealer_order_id', '=', payload)
        .update({ dealer_order_has_port: true })
      console.log(res);
      commit('orderPort', payload)
      return res
    },
    async createOrder({ commit }, { dealer_name, dealer_type, ...data }) {
      const res = await database.table('dealer_order').set(data)
      commit('createOrder', {
        key_id: res.id,
        dealer_order_id: res.id,
        dealer_order_has_paid: false,
        dealer_order_has_port: false,
        dealer_name, dealer_type, ...data
    })
      return res
    },
    async deleteOrder({ commit }, payload) {
      await database.table('dealer_order').where('dealer_order_id', '=', payload).delete()
      commit('deleteOrder', payload)
    },
    async genPortSheet({ commit }, payload) {
      console.log(payload);
      const { dealer_order_id, dealer_name, dealer_order_create_at } = payload
      const title = `${dealer_name} | ${timestampParse(dealer_order_create_at).format('YYYYMMDD')}`
      const doc = await connectSheet(process.env.VUE_APP_PORT_DOC_ID)
      const sheet = await copySheet(doc)
      await updateSheet(sheet, { title }, genOrderPortCells(payload), "B3:G34")
      const sheetURL = `https://docs.google.com/spreadsheets/d/${process.env.VUE_APP_PORT_DOC_ID}/edit#gid=${sheet.sheetId}`
      await database.table('dealer_order').where('dealer_order_id', '=', dealer_order_id).update({ dealer_order_port_doc: sheetURL })
      commit('updateOrder', { dealer_order_id, dealer_order_port_doc: sheetURL })
      return { docURL: sheetURL }
    }
  },
  getters: {
    dealerGrowth: ({ dealerList }) => {
      if (dealerList.length) {
        return dealerList.reduce((acc, { dealer_contract_signed_at }) => {
          if (dealer_contract_signed_at) {
            let moment = timestampParse(dealer_contract_signed_at)
            let month = moment.format('M')
            acc[parseInt(month) - 1]++
          }
          return acc
        }, Array(12).fill(0))
      }
      return Array(12).fill(0)
    },
    dealerWithAvarageOrder: ({ dealerList, orderList }) => {
      if (dealerList.length && orderList.length) {
        return dealerList.reduce((acc, dealer, index) => {
          let dealerOrders = orderList.filter(order => order.dealer_id === dealer.dealer_id)
          if (dealerOrders.length > 1) {
            let sumOfDeviations = dealerOrders
              .sort((a,b)=>b.dealer_order_create_at - a.dealer_order_create_at)
              .reduce(( a, o, i ) => {
                if (dealerOrders[i+1]) {
                  return a + Number(o.dealer_order_create_at  - dealerOrders[i+1].dealer_order_create_at)
                }
                return a
              }, 0)
              acc[index] = { 
                ...acc[index],
                avarage_order_duration: Math.round(moment.duration(sumOfDeviations / ( dealerOrders.length - 1 ), 'seconds').asDays() * 10) / 10
              }
              return acc
          }
          return acc
        }, [...dealerList])
      }
      return []
    },
    dealerOrderIncomeMonthly: ({ dealerList, orderList }) => {
      if (dealerList.length && orderList.length) {
        return orderList.filter(order => order.dealer_type !== 'PURCHASING').reduce((acc, order) => {
          const month = parseInt(timestampParse(order.dealer_order_create_at).format('M')) - 1
          acc[month] += parseInt(order.dealer_order_income)
          return acc
        }, Array(12).fill(0))
      }
      return Array(12).fill(0)
    },
    cumulativePurchasingIncome: ({ orderList }) => {
      if (orderList.length) {
        let purchasingOrders = orderList.filter(o => o.dealer_type === 'PURCHASING')
        if (purchasingOrders.length) return purchasingOrders.reduce((acc, o) => {
          // const month = parseInt(timestampParse(o.dealer_order_create_at).format('M')) - 1
          // for (let index = 11; index >= month; index--) {
          //   acc[index] += Number(o.dealer_order_income)
          // }
          // return acc
          const month = parseInt(timestampParse(o.dealer_order_create_at).format('M')) - 1
          acc[month] += parseInt(o.dealer_order_income)
          return acc
        }, Array(12).fill(0))
      }
      return Array(12).fill(0)
    },
    productOrderMonthly: ({ orderList }) => {
      if (orderList) {
        return orderList.filter(o => o.dealer_type !== 'PURCHASING').reduce((acc, o) => {
          const month = parseInt(timestampParse(o.dealer_order_create_at).format('M')) - 1
          acc.fibra_gel_50[month] += parseInt(o.dealer_order_quantity_50)
          acc.fibra_gel_5[month] += parseInt(o.dealer_order_quantity_5)
          return acc
        }, { fibra_gel_50: Array(12).fill(0), fibra_gel_5: Array(12).fill(0)})
      }
      return {
        fibra_gel_50: Array(12).fill(0),
        fibra_gel_5: Array(12).fill(0)
      }
    }
  }
}

const genOrderPortCells = order => ({
  'F3': moment().format('YYYY/MM/DD'), //開立日期
  'F4': moment().format('YYYYMMDDHHmm'), //訂單編號
  'B11': order.dealer_name, //客戶資訊（經銷商名稱）
  'E11': order.dealer_name, //收貨地址（經銷商名稱）
  'B12': `統一編號：${order.dealer_tax_id || ''}`, //經銷商統一編號
  'B13': `聯絡人：${order.dealer_contact || ''}`, //經銷商聯絡人
  'E12': order.dealer_address, //經銷商地址
  'F13': order.dealer_order_port_type ? PORT_TYPE_DICT[order.dealer_order_port_type] : '-', //送貨方式
  'E18': '$'+order.dealer_price_50, //舒敏復原膠 50ml 正貨單價
  'F18': order.dealer_order_quantity_50, //舒敏復原膠 50ml 正貨數量
  'G18': `$${order.dealer_order_quantity_50 * order.dealer_price_50}`, //舒敏復原膠 50ml 總額
  'E19': '$'+order.dealer_price_5, //舒敏復原膠 5*5ml 正貨單價
  'F19': order.dealer_order_quantity_5, //舒敏復原膠 5*5ml 正貨數量
  'G19': `$${order.dealer_order_quantity_5 * order.dealer_price_5}`, //舒敏復原膠 5*5ml 總額
  'F20': parseInt(order.dealer_order_quantity_50) + parseInt(order.dealer_order_quantity_5), //紙提袋數量
  'F21': parseInt(order.dealer_order_quantity_50) + parseInt(order.dealer_order_quantity_5), //刺青保養手冊數量
  //小計（含稅）
  'F30': `$${order.dealer_order_quantity_50 * order.dealer_price_50 + order.dealer_order_quantity_5 * order.dealer_price_5}`,
  //運費
  'F31': `$${parseInt(order.dealer_order_quantity_50)+parseInt(order.dealer_order_quantity_5) * 0.5 < 5 ? 120 : 0}`,
  'F32': '$'+order.dealer_order_income //總計
})
