<template>
  <div :class="classes">
    <div class="result-icon">
      <slot name="icon">
        <k-icon :icon="statusIcon[status] || statusIcon['info']"></k-icon>
      </slot>
    </div>
    <div class="result-title">{{ title }}</div>
    <div class="result-sub-title">{{ subTitle }}</div>
    <div class="result-content" v-if="$slots['default']">
      <slot></slot>
    </div>
    <div class="result-extra" v-if="$slots['extra']">
      <slot name="extra"></slot>
    </div>
  </div>
</template>

<script>
import KIcon from './Icon'

export default {
  props: {
    status: String,
    title: String,
    subTitle: String
  },
  data() {
    return {
      statusIcon: {
        success: 'check-circle-fill',
        warning: 'exclamation-triangle-fill',
        info: 'exclamation-circle-fill',
        error: 'x-circle-fill',
      }
    }
  },
  computed: {
    classes() {
      return {
        'k-result': true,
        [`status--${this.status}`]: !!this.status
      }
    }
  },
  components: {
    KIcon
  }
}
</script>
