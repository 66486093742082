const PostMark = (editor, editorComponent) => {
  const MarkChapter = {
    type: 'menuitem',
    text: '標記章首',
    icon: 'CardHeading',
    tooltip: '將選取段落標示為章首',
    onAction: () => {
      const target = editor.selection.getNode()
      const content = editor.selection.getContent()
      if (target.classList.contains('chapter-header')) {
        const fragment = document.createDocumentFragment()
        Array.from(target.children).forEach(ele => fragment.appendChild(ele))
        target.replaceWith(fragment)
      } else {
        if (target.tagName !== 'BODY') {
          let chapterElement = document.createElement('div')
          chapterElement.appendChild(target)
          editor.selection.setContent(`<div class="chapter-header">${chapterElement.innerHTML}</div>`)
        } else {
          editor.selection.setContent(`<div class="chapter-header">${content}</div>`)
        }
      }
      editorComponent.editorValue.html = editor.getContent()
    }
  }
  const MarkSectionHeader = {
    type: 'menuitem',
    text: '標記目錄標題',
    tooltip: '將文字標記為目錄標題',
    icon: 'TypeH1',
    onAction: () => {
      const target = editor.selection.getNode()
      if (target.classList.contains("section-header")) {
        target.removeAttribute('id')
        target.classList.remove("section-header")
      }
      else {
        if (target.tagName === 'BODY') return editorComponent.$message.error('標題限為單一行內文字')
        target.id = target.textContent.split(' ').join('')
        if (target.className.includes('chapter-header')) {
          console.log(target);
        } else {
          target.classList.add("section-header")
          if (target.tagName !== 'h2') {
            let newTag = document.createElement('h2')
            newTag.innerHTML = target.innerHTML
            newTag.id = target.id
            newTag.className = target.className
            console.log(newTag);
            target.replaceWith(newTag)
          }
        }
      }
      editorComponent.editorValue.html = editor.getContent()
    }
  }

  editor.ui.registry.addMenuButton('PostMarkMenu', {
    text: '文章標記',
    icon: 'BookmarksFill',
    fetch: callback => {
      callback([MarkChapter, MarkSectionHeader ])
    }
  })

  editor.ui.registry.addContextMenu('PostMarkMenu', {
    update: element => {
      return [MarkChapter, MarkSectionHeader];
    }
  })
}

export default PostMark
