<script>
export default {
  render(h) {
    return (
      <div class="k-button-group">
        {this.$slots.default}
      </div>
    )
  }
}
</script>
