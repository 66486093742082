import { genBasicModel } from './utils/basicAction'
import { query, create } from './utils/basicService'
import database from '@/utils/database'

const MODEL_NAME = 'comment'
const { basicMutation, basicAction } = genBasicModel(
  MODEL_NAME,
  query(MODEL_NAME),
)

export default {
  namespaced: true,
  state: {
    commentList: []
  },
  mutations: {
    ...basicMutation,
    createNewCommentList(state, payload) {
      state.commentList = [
        ...payload.map(comment => ({
          ...comment.data,
          key_id: comment.id,
          comment_id: comment.id,
          comment_published: '0'
        })),
        ...state.commentList
      ]
    }
  },
  actions: {
    ...basicAction,
    async createNewCommentList({ commit, dispatch }, payload) {
      const commentData = payload.map(({  index, comment_images, ...otherData }) => ({
        ...otherData,
        comment_images: comment_images.map(({ file }) => file.name).join(',')
      }))
      const fileData = payload.reduce((acc, comment) => {
        if (comment.comment_images.length)  return [...acc, ...comment.comment_images.map(({ file }) => file)]
        return acc
      }, [])
      const promises = commentData.map(c => new Promise(async (resolve, reject) => {
        const setRes = await database.table('comment').set({ ...c, comment_system: 'BACKEND'})
        resolve(setRes)
      }))
      const resList = await Promise.all(promises)
      commit('createNewCommentList', resList)
      dispatch('media/uploadFileList', { fileList: fileData, path: '/_comment' }, { root: true })
    }
  },
  getters: {
    pendingComments(state) {
      return state.commentList.filter(comment => comment.comment_published === '0')
    },
    publishedComments(state) {
      return state.commentList.filter(comment => comment.comment_published === '1')
    },
    filterByProduct(state) {
      if (state.commentList.length) {
        return state.commentList.reduce((acc, comment) => ({
          ...acc,
          [comment.product_id]: [...(acc[comment.product_id] || []), comment]
        }), {})
      }
      return {}
    },
    productScore(state, getters) {
      const productScore = {}
      Object.keys(getters.filterByProduct).forEach(productId => {
        const comments = getters.filterByProduct[productId]
        const score = comments.reduce((acc, comment) => parseInt(acc) + parseInt(comment.comment_stars), 0) / comments.length
        productScore[productId] = Math.round(score * 10) / 10
      })
      return productScore
    }
  }
}
