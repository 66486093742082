import database from '@/utils/database'
import { genBasicModel } from './utils/basicAction'
import moment from 'moment'
import firebase from './firebase'

const MODEL_NAME = 'coupon'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME, query)

async function query() {
  const res = await database.table(MODEL_NAME).orderBy('coupon_id').orderBy('coupon_exp', 'desc').get()
  res.data = res.data.map(item => ({
    ...item,
    coupon_product: item.coupon_product ? item.coupon_product.split(',') : [],
    coupon_condition: item.coupon_condition ? JSON.parse(item.coupon_condition) : [],
    coupon_notice_templates: item.coupon_notice_templates ? JSON.parse(item.coupon_notice_templates) : []
  }))
  return res
}

export default {
  namespaced: true,
  state: {
    couponList: []
  },
  mutations: {
    ...basicMutation,
    createCoupon(state, payload) {
      state.couponList.push(payload)
    },
    updateCoupon(state, payload) {
      const { coupon_notice_templates } = payload
      const couponIndex = state.couponList.map(item => item.coupon_id).indexOf(payload.coupon_id)
      if (couponIndex !== -1) state.couponList.splice(couponIndex, 1, {
        ...payload,
        coupon_notice_templates: coupon_notice_templates ? JSON.parse(coupon_notice_templates) : [],
      })
    }
  },
  actions: {
    ...basicAction,
    async createCoupon({ commit }, payload) {
      const { coupon_exp, coupon_period, coupon_product } = payload
      const insertData = {
        ...payload,
        coupon_period: coupon_period ? parseInt(coupon_period) * 86400 : undefined,
        coupon_exp: coupon_exp ? moment(coupon_exp, 'YYYY-MM-DD').unix() : undefined,
        coupon_product: coupon_product ? coupon_product.join(',') : undefined,
      }
      const res = await database.table('coupon').set(insertData)
      commit('createCoupon', {
          ...insertData,
          coupon_id: res.id,
          key_id: res.id,
          coupon_notice_templates: []
        })
      return res
    },
    async updateCoupon({ commit }, payload) {
      console.log(payload)
      const { coupon_exp, coupon_formula, coupon_notice_templates } = payload
      const notNullData = Object.entries(payload).reduce((acc, [key, value]) => {
        if (value) acc[key] = value
        return acc
      }, {})
      const updateData = {
        ...notNullData,
        coupon_exp: coupon_exp ? moment(coupon_exp, 'YYYY-MM-DD').unix() : undefined,
        coupon_notice_templates: coupon_notice_templates.length ? JSON.stringify(coupon_notice_templates) : undefined,
        coupon_formula: coupon_formula ? coupon_formula.join(' ') : undefined
      }
      console.log(updateData);
      const res = await database.table('coupon').where('coupon_id', '=', payload.coupon_id).update({
        ...updateData, key_id: undefined //去除資料庫的非欄位 key_id
      })
      commit('updateCoupon', updateData)
      // return res
    }
  },
  getters: {
    couponGroupBySuper: state => {
      return state.couponList.reduce((acc, coupon) => {
        const { coupon_super } = coupon
        const index = acc.map(acc => acc.coupon_super).indexOf(coupon_super)
        if (index === -1 || !coupon_super) acc.push(coupon)
        else {
          if (acc[index].coupon_uids) acc[index].coupon_uids.push(coupon.coupon_uid)
          else acc[index].coupon_uids = [coupon.coupon_uid]
        }
        return acc
      }, [])
    },
    couponDict: state => {
      if (!state.couponList.length) return {}
      return state.couponList.reduce((acc, coupon) => {
        acc[coupon.coupon_id] = coupon
        return acc
      }, {})
    },
    memberCoupons: state => {
      return state.couponList.filter(coupon => coupon.coupon_scope === 'MEMBER')
    }
  }
}
