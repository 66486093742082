<template>
  <div :class="classes">
    <transition name="msg-show">
    <span v-if="show" :class="spanClasses">
      <i :class="`bi ${typeIcon[type]}`"></i>
      <p>{{ text }}</p>
    </span>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: '',
      show: false,
      type: null,
      timeout: 3000,
      typeIcon: {
        success: 'bi-check-circle-fill',
        warning: 'bi-exclamation-octagon-fill',
        error: 'bi-x-circle-fill',
        info: 'bi-info-circle-fill',
      }
    }
  },
  methods: {
    showMsg(text, type, timeout) {
      this.show = true
      this.type = type
      this.text = text
      this.timeout = timeout || 3000
    }
  },
  computed: {
    classes() {
      return {
        'k-message': true,
        'show': this.show,
      }
    },
    spanClasses() {
      return {
        [`msg--${this.type}`]: this.type
      }
    }
  },
  watch: {
    show(newValue, oldValue) {
      console.log(newValue);
      if (newValue && newValue !== oldValue) {
        setTimeout(() => {
          this.show = false
        }, this.timeout);
      }
    }
  }
}
</script>
