var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-table',{staticClass:"component-product-comment-table new",attrs:{"items":_vm.data,"fields":_vm.CommentFields,"hover":""},scopedSlots:_vm._u([{key:"cell(image-upload)",fn:function(ref){
var item = ref.item;
return [_c('k-upload',{attrs:{"droppable":true,"multiple":true},on:{"change":function (ref) {
var files = ref.files;
var srcs = ref.srcs;
_vm.handleAddImageToRow(item, files, srcs)}}},[_c('div',{staticClass:"k-upload-button block"},[_c('k-icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v(_vm._s('點擊或將圖檔拖拉至此處'))])],1)])]}},{key:"cell(product_id)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.productDict[value].product_name_zh))])]}},{key:"cell(comment_stars)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return _vm._l((Array(parseInt(value)).fill()),function(i,index){return _c('k-icon',{key:("comment-stars-" + (item.key_id) + "-" + index),attrs:{"icon":"star-fill"}})})}},{key:"cell(comment_skin_type)",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.SKIN_TYPE_DICT[value]))]}},{key:"cell(comment_content)",fn:function(ref){
var value = ref.value;
return [_c('p',[_vm._v(_vm._s(value))])]}},{key:"cell(comment_images)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('ul',_vm._l((value),function(ref,index){
var src = ref.src;
return _c('li',{key:("new-comment-images-" + (item.index) + "-" + index)},[_c('div',{staticClass:"img-wrapper"},[_c('div',[_c('img',{attrs:{"src":src}})]),_c('span',{staticClass:"tooltip"},[_c('img',{attrs:{"src":src,"alt":""}})])])])}),0)]}},{key:"cell(comment_create_at)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.timestampParse(value).format('YYYY-MM-DD') : '區間隨機')+" ")]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('k-button',{attrs:{"varient":"ghost","theme":"info"},on:{"click":function($event){return _vm.showDetail(item)}}},[_vm._v("查看詳情")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }