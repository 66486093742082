import { gen8ID } from '@/utils'

const DEALER_PRODUCT_DICT = {
  TATTOO: {
    product: ['2012', '2012-1'],
    giveaway: ['g1001', 'g2001']
  }
}

export const defaultDealerProduct = (dealer, productList, giveawayList) => {
  const itemUids = DEALER_PRODUCT_DICT[dealer.dealer_category]
  let orderDefaultObject = {}
  if (itemUids) {
    orderDefaultObject.product = itemUids.product.map(uid => {
      const product = productList.filter(p => p.product_uid === uid)[0]
      if (product) {
        const { product_id, product_name_zh } = product
        const itemsPrice = dealer.dealer_price.filter(p => p.product_uid === uid)
        const product_price = itemsPrice.length ? String(itemsPrice[0].price) : '0'
        return { key: gen8ID(), product_id, product_price, product_name: product_name_zh, product_quantity: '0' }
      }
      return null
    }).filter(p => p)
    orderDefaultObject.giveaway = itemUids.giveaway.map(uid => {
      const giveaway = giveawayList.filter(g => g.giveaway_uid === uid)[0]
      if (giveaway) return {
        key: gen8ID(),
        giveaway_id: giveaway.giveaway_id,
        giveaway_name: giveaway.giveaway_name_zh,
        giveaway_quantity: '0'
      }
      return null
    }).filter(g => g)
  }
  return orderDefaultObject
}
