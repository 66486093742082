<template>
  <ul :class="classes">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  props: {
    openKeys: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'inline'
    }
  },
  data() {
    return {
      indent: 0
    }
  },
  computed: {
    classes() {
      return {
        'k-menu': true,
        [`k-menu-${this.mode}`]: true
      }
    }
  },
}
</script>
