<template>
  <k-container class="view-inventory-container">
    <template #header>
      <h2>存貨管理</h2>
      <section>
        <k-button theme="warning">倉庫轉移</k-button>
        <k-button theme="info">庫存進貨</k-button>
      </section>
    </template>

    <k-card header="產品庫存">
      <k-table
        :items="productInventory"
        :fields="TableFieldProduct"
      >
        <template #cell(inventory_uid)="{ item, value }">
          <k-tag
            class="inventory-uid"
            :theme="item.inventory_type === 'IMPORT' ? 'info' : 'warning'"
          >{{ value }}</k-tag>
        </template>
        <template #cell(cost_value)="{ value }">${{ value }}</template>
        <template #cell(inventory_quantity)="{ item, value }">{{ value - item.inventory_used }}</template>
        <template #cell(inventory_place)="{ value }">{{ PLACE_DICT[value] }}</template>
        <template #cell(inventory_import_date)="{ value }">{{ timestampParse(value).format('YYYY/MM/DD') }}</template>
        <template #cell(action)="{ item }">
          <k-button
            :theme="item.inventory_type === 'IMPORT' ? 'info' : 'warning'"
            varient="ghost"
          >{{ item.inventory_type === 'IMPORT' ? '進貨單' : '轉倉單' }}</k-button>
        </template>
      </k-table>
    </k-card>
  </k-container>
</template>

<script>
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import KTable from '@/components/Table'
import KTag from '@/components/Tag'
import { timestampParse } from '@/utils'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Inventory',
  async mounted() {
    if (!this.$store.state.inventory.inventoryList.length) this.$store.dispatch('inventory/query')
  },
  data() {
    return {
      PLACE_DICT,
      TableFieldProduct: genTableFields('product'),
      TableFieldGiveaway: genTableFields('giveaway')
    }
  },
  methods: {
    timestampParse
  },
  computed: {
    ...mapState('inventory', ['inventoryList']),
    ...mapGetters('inventory', ['productInventory', 'productTableFilters'])
  },
  watch: {
    productInventory: {
      handler(newVal) {
        let filters = [...new Set(newVal.map(e => e.product_name_zh))].map(e => ({ value: e, text: e }))
        this.TableFieldProduct = genTableFields('product', filters)
      },
      deep: true,
      immediate: true
    }
  },
  components: {
    KButton, KCard, KTable, KTag
  }
}

const genTableFields = (type, filters) => [
  { key: `${type}_name_zh`, fieldIndex: `${type}_name_zh`, label: '存貨品名', filters },
  { key: 'inventory_uid', fieldIndex: 'inventory_uid', label: '進貨批號' },
  { key: 'cost_value', fieldIndex: 'cost_value', label: '進價' },
  { key: 'inventory_quantity', fieldIndex: 'inventory_quantity', label: '存貨量', },
  { key: 'inventory_place', fieldIndex: 'inventory_place', label: '存儲倉庫', },
  { key: 'inventory_import_date', fieldIndex: 'inventory_import_date', label: '進貨時間', sorter: (a, b) => b - a },
  { key: 'action', fieldIndex: 'action', label: '' }
]

const PLACE_DICT = {
  TAICHUNG: '台中倉庫',
  SANXIA: '三峽倉庫'
}
</script>
