<template>
  <k-card :class="classes">
    <div class="widget-content">
      <h2>{{ header }}</h2>
      <p>{{ text }}</p>
      <slot></slot>
    </div>
  </k-card>
</template>

<script>
import KCard from '../Card';

export default {
  props: {
    header: [String, Number],
    text: String
  },
  computed: {
    classes() {
      return {
        'k-widget': true,
        'k-widget-simple': true
      }
    }
  },
  components: {
    KCard
  }
}
</script>
