<template>
  <k-table class="component-product-comment-table" :items="data" :fields="CommentFields">
    <template #cell(product_id)="{value}">
      <span>{{ productDict[value].product_name_zh }}</span>
    </template>

    <template #cell(comment_stars)="{value, item}">
      <k-icon v-for="(i, index) of Array(parseInt(value)).fill()" :key="`comment-stars-${item.key_id}-${index}`" icon="star-fill"/>
    </template>

    <template #cell(comment_content)="{value}">
      <p>{{ value }}</p>
    </template>

    <template #cell(comment_images)="{value}">
      <p>{{ value ? value.split(',').length : '-' }}</p>
      <span><k-icon></k-icon></span>
    </template>

    <template #cell(comment_create_at)="{value}">
      {{ timestampParse(value).format('YYYY-MM-DD') }}
    </template>

    <template #cell(action)="{item}">
      <k-button varient="ghost" theme="info" @click="showDetail(item)">查看詳情</k-button>
    </template>
  </k-table>
</template>

<script>
import KButton from '@/components/Button';
import KTable from '@/components/Table';
import KModal from '@/components/Modal';
import KCard from '@/components/Card'
import KDivider from '@/components/Divider'
import KTag from '@/components/Tag'
import KTooltip from '@/components/Tooltip'
import { mapGetters, mapState } from 'vuex';
import { timestampParse } from '../../../utils';

const SKIN_TYPE_DICT = {
  dry: '乾肌',
  sensitive: '敏弱肌',
  fragile: '特殊美容',
  oil: '油肌',
  combination: '混合肌',
  rough: '毛孔粗大',
  acne: '痘肌',
  others: '其他'
}

export default {
  props: {
    name: String,
    data: {
      type: Array,
      default: () => []
    },
    showDetail: Function
  },
  mounted() {

  },
  data() {
    return {
      SKIN_TYPE_DICT
    }
  },
  computed: {
    ...mapState('comment', ['commentList']),
    ...mapState('product', ['productList']),
    ...mapGetters('product', ['productDict']),
    CommentFields() {
      if (this.productList.length) {
        return [
          { key: 'product_id', fieldIndex: 'product_id', label: '產品', filters: this.productList.filter(p => p.product_is_launched === '1').map(p => ({ value: p.key_id, text: `${p.product_uid} | ${p.product_name_zh}` })) },
          ...CommentFields.filter(f => f.key !== 'product_id'),
        ]
      }
      return CommentFields
    }
  },
  methods: {
    timestampParse
  },
  components: {
    KButton, KTable, KModal, KCard, KDivider, KTag, KTooltip
  }
}

const CommentFields = [
  { key: 'product_id', fieldIndex: 'product_id', label: '產品' },
  { key: 'comment_stars', fieldIndex: 'comment_stars', label: '星數', sorter: (a, b) => b - a},
  { key: 'comment_title', fieldIndex: 'comment_title', label: '評論標題'},
  { key: 'comment_content', fieldIndex: 'comment_content', label: '評論內容' },
  { key: 'comment_images', fieldIndex: 'comment_images', label: '圖檔數量' },
  { key: 'comment_create_at', fieldIndex: 'comment_create_at', label: '評論時間' },
  { key: 'action', fieldIndex: 'action', label: '' },
  // { key: 'order_logistics_state', fieldIndex: 'order_logistics_state', label: '逆物流狀態'}, 
  // { key: 'order_payment_status', fieldIndex: 'order_payment_status', label: '退款狀態', 
  //   filters: [{ value: 'SUCCESS', text: '已退款' }, { value: 'HANDLING', text: '未退款' }]
  // }, 
  // { key: 'return_order_create_at', fieldIndex: 'return_order_create_at', label: '退貨申請時間',
  //   sorter: (a, b) => b - a
  // },
  { key: 'detail', fieldIndex: 'detail', label: '' }
]
</script>
