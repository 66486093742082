<template>
  <div class="operation-orders receipt">
    <h4>發票開立</h4>
    <div class="setting">
      <k-input addonBefore="發票序號開頭英文" placeholder="ex.YF" v-model="receiptHead"></k-input>
      <k-button theme="secondary" @click="genReceiptIdFromFirstId">依序生成編號</k-button>
    </div>

    <ul>
      <li v-for="order, index in orderCache" :key="`op-receipt-order-${order.orders_id}`">
        <span>{{ order.orders_id }}</span>
        <k-tag :theme="logisticsDict[order.order_logistics_subtype].theme">
          {{ logisticsDict[order.order_logistics_subtype].name }} | {{ order.order_logistics_info.BookingNote || order.order_logistics_id }}
        </k-tag>
        <k-input
          placeholder="發票編號"
          v-model="orderCache[index].order_receipt_id"
          maxlength="10"
          :theme="order.receipt_error ? 'error' : 'light'"
          :extra="order.receipt_error ? '格式錯誤' : null"
        />
      </li>
    </ul>

    <k-button @click="handleSubmit" theme="warning" block>確認開立</k-button>
  </div>
</template>

<script>
import { KInput } from '@/components/Form'
import KTag from '@/components/Tag'
import KButton from '@/components/Button'

export default {
  props: {
    orders: Array,
    logisticsDict: Object
  },
  data() {
    return {
      orderCache: [],
      receiptHeadProxy: ''
    }
  },
  mounted() {
    this.orderCache = JSON.parse(JSON.stringify(this.orders)).sort((a,b) => a.order_create_at - b.order_create_at).map(o => ({
      orders_id: o.orders_id,
      order_logistics_subtype: o.order_logistics_subtype,
      order_logistics_id: o.order_logistics_id,
      order_logistics_info: o.order_logistics_info,
      order_receipt_id: ''
    }))
  },
  computed: {
    receiptHead: {
      get() {
        return this.receiptHeadProxy
      },
      set(val) {
        this.receiptHeadProxy = val
        if (this.orderCache.length) {
          this.orderCache = this.orderCache.map(o => {
            let regexBody = /\d+/g
            let receiptBody = o.order_receipt_id.match(regexBody) || ''
            return { ...o, order_receipt_id:`${val}${receiptBody}` }
          })
        }
      }
    }
  },
  methods: {
    genReceiptIdFromFirstId() {
      const firstId = this.orderCache[0].order_receipt_id
      console.log(firstId);
      if (firstId) {
        const receiptHead = firstId.slice(0, 2)
        const number = Number(firstId.slice(2, 10))
        this.orderCache.forEach((o, index) => {
          this.orderCache.splice(index, 1, { ...o, order_receipt_id: `${receiptHead}${number + index}` })
        })
      }
    },
    handleSubmit() {
      this.orderCache.forEach((order, index) => {
        let regex = /[A-Z]{2}\d{8}/g
        if (!regex.test(order.order_receipt_id)) this.orderCache.splice(index, 1, { ...order, receipt_error: true })
        else this.orderCache.splice(index, 1, { ...order, receipt_error: false })
      });
      if (this.orderCache.every(o => !o.receipt_error)) this.$emit('submit', this.orderCache)
      else this.$message.error('有訂單發票編號格式錯誤請檢查')
    }
  },
  components: {
    KInput, KTag, KButton
  }
}
</script>
