import { genBasicModel } from './utils/basicAction'
import { query, update } from './utils/basicService'
import database from '@/utils/database'

const MODEL_NAME = 'user_land'
const { basicAction, basicMutation } = genBasicModel(
  MODEL_NAME,
  query(MODEL_NAME),
  update(MODEL_NAME))

function userFrom(fromUrl) {
  switch (fromUrl) {
    case 'https://www.google.com/': return 'Google'
    default: return 'Direct'
  }
}

export default {
  namespaced: true,
  state: {
    user_landList: []
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction,
  },
  getters: {
    landFrom({ user_landList }) {
      if (user_landList.length) {
        return user_landList.reduce((acc, log) => {
          const { user_land_cookie_id, user_land_from, user_land_membership } = log
          if (!acc.uniqueCookieIds.includes(user_land_cookie_id) && user_land_membership === '0') {
            acc.uniqueCookieIds.push(user_land_cookie_id)
            acc.newUserCount += 1
            acc.from[userFrom(user_land_from)] += 1
          }
          if (user_land_membership === '1') {
            acc.membershipCount += 1
            acc.from[userFrom(user_land_from)] += 1
          }
          return acc
        }, { membershipCount: 0, newUserCount:0, uniqueCookieIds: [], from: { 'Direct': 0, 'Google': 0} })
      }
      return {}
    }
  }
}
