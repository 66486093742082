<template>
  <k-container class="view-email-tag-component" v-if="emailTagList.length && emailList.length && sentList.length">
    <template #header>
      <div>
        <k-icon :icon="focusTag.email_tag_icon || 'tag-fill'" :color="focusTag.email_tag_color || '#ddd'"></k-icon>
        <h2>{{ `信件標籤：${focusTag.email_tag_name}` }}</h2>
      </div>
    </template>
    <MailList :loading="loading" :emailList="emailsFromFocusTag" showEmailFrom />
  </k-container>
</template>

<script>
import MailList from './components/MailList'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Email-Tag',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('email', ['emailList', 'sentList', 'emailTagList']),
    ...mapGetters('email', ['emailFilterByTag']),

    focusTag() {
      if (this.emailTagList.length) {
        return this.emailTagList.filter(tag => tag.email_tag_id === this.$route.params.tagId)[0]
      }
      return {}
    },
    emailsFromFocusTag() {
      if (this.emailFilterByTag && this.emailFilterByTag[this.focusTag.email_tag_id] && this.emailList.length && this.sentList.length) {
        return this.emailFilterByTag[this.focusTag.email_tag_id].map(email => {
          if (email.email_tag_use_type === 'INBOX') {
            return this.emailList.filter(e => e.email_id === `${email.email_tag_use_type}-${email.email_id}`)[0]
          } else {
            return this.sentList.filter(e => e.email_id === `${email.email_tag_use_type}-${email.email_id}`)[0]
          }
        })
      }
      return []
    }
  },
  components: {
    MailList
  }
}
</script>
