<template>
  <li :class="classes">
    <div @click="toggleSpread" class="k-menu-submenu-title" :style="{ paddingLeft: `${indent+16}px` }">
      <slot name="title"><span>{{ title }}</span></slot>
    </div>
    <transition name="menu">
      <ul :class="{ 'k-menu': true}" :style="{ maxHeight: isSpread ? `${this.$children.length*52}px`  : 0 }">
        <slot ></slot>
      </ul>
    </transition>
  </li>
</template>

<script>
export default {
  mounted() {
    console.log(this.$parent.indent);
    if (this.spread) this.isSpread = this.spread;
  },
  props: {
    title: String,
    spread: Boolean
  },
  data() {
    return {
      isSpread: false
    }
  },
  methods: {
    toggleSpread() {
      this.isSpread = !this.isSpread;
    }
  },
  computed: {
    classes() {
      return {
        'k-menu-submenu': true,
        'unspread': !this.isSpread
      }
    },
    indent() {
      return 12+(this.$parent.indent || 0);
    }
  },
}
</script>
