<template>
  <div class="setting multiple-choice">
    <h4>選項</h4>
    <div class="option" v-for="item, index in options" :key="item.value">
      <b>{{ index + 1 }}.</b>
      <k-input v-model="item.text" placeholder="選項描述"></k-input>
      <div class="action">
        <k-icon @click="addOption" icon="plus-lg" />
        <k-icon @click="deleteOption" icon="dash-lg" />
      </div>
    </div>
  </div>
</template>

<script>
import { KInput, KRadio, KSelect, KTextarea, KSwitch } from '@/components/Form'
import { gen8ID } from '@/utils'

export default {
  props: {
    field: Object
  },
  mounted() {
    this.$emit('update:field', { ...JSON.parse(JSON.stringify(this.field)), options: this.options })
  },
  data() {
    return {
      options: [{ value: gen8ID(), text: '' }]
    }
  },
  methods: {
    addOption() {
      this.options.push({ value: gen8ID(), text: '' })
    },
    deleteOption(value) {
      if (this.options.length === 1) {
        this.$message.warning('請至少保留一個選項，再刪就沒了QQ')
        return
      }
      const index = this.options.map(o => o.value).indexOf(value)
      this.options.splice(index, 1)
    }
  },
  watch: {
    options: {
      handler() {
        this.$emit('update:field', { ...JSON.parse(JSON.stringify(this.field)), options: this.options })
      },
      deep: true
    }
  },
  components: {
    KInput
  }
}
</script>
