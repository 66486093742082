<template>
  <div class="operation-orders shipped">
    <h4>無法出貨遞單</h4>
    <ul>
      <li v-for="order in orderCache.incompatible" :key="`op-shipped-order-${order.orders_id}`">
        <span>{{ order.orders_id }}</span>
        <k-tag :theme="logisticsDict[order.order_logistics_subtype].theme">
          {{ logisticsDict[order.order_logistics_subtype].name }} | {{ order.order_logistics_info.BookingNote || order.order_logistics_id }}
        </k-tag>
        <div>
          <span v-if="!order.order_log_operations.includes('PACKAGE')">未完成包貨</span>
          <span v-if="!order.order_log_operations.includes('RECEIPT')">未開立發票</span>
        </div>
      </li>
    </ul>

    <h4>可出貨訂單確認</h4>
    <ul>
      <li v-for="order in orderCache.canShip" :key="`op-shipped-order-${order.orders_id}`">
        <span>{{ order.orders_id }}</span>
        <k-tag :theme="logisticsDict[order.order_logistics_subtype].theme">
          {{ logisticsDict[order.order_logistics_subtype].name }} | {{ order.order_logistics_info.BookingNote || order.order_logistics_id }}
        </k-tag>
      </li>
    </ul>

    <k-button @click="handleSubmit" theme="success" :disabled="!orderCache.canShip.length" block>
      {{ orderCache.canShip.length ? '確認可出貨訂單' : '無可出貨訂單' }}
    </k-button>
  </div>
</template>

<script>
import KTag from '@/components/Tag'
import KButton from '@/components/Button'
import { contains } from 'micromatch';

export default {
  props: {
    orders: Array,
    logisticsDict: Object
  },
  data() {
    return {
      orderCache: { canShip: [], incompatible: [] },
      orderIncompatible: []
    }
  },
  mounted() {
    this.orderCache =  this.orders.reduce((acc, o) => {
      if (!o.order_log_operations.includes('PACKAGE')) return { ...acc, incompatible: [...acc.incompatible, { ...o }] }
      if (!o.order_log_operations.includes('RECEIPT')) return { ...acc, incompatible: [...acc.incompatible, { ...o }] }
      if (o.order_log_operations.includes('SHIPPED')) return { ...acc }
      return { ...acc, canShip: [...acc.canShip, { ...o }] }
    }, { canShip: [], incompatible: [] })
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.orderCache.canShip)
    }
  },
  components: {
    KButton, KTag
  }
}
</script>
