<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    tab: {
      type: String,
      require: true
    }
  },
  mounted() {
    // console.log(this.$children)
  },
  computed: {
    classes() {
      return {
        'k-tabs-pane': true,
      }
    }
  },
}
</script>
