import Home from '../views/Home'
import Login from '../views/Login'
import Account from '../views/account'
import Admin from '../views/Admin'
import Blog from '../views/blog'
import BlogEdit from '../views/blog/edit'
import BlogCategoy from '../views/blog/category'
import Compose from '../views/email/compose'
import EcommerceDashboard from '../views/ecommerce'
import EcommerceCoupon from '../views/coupon'
import EcommerceOrder from '../views/ecommerce/order'
import EcommerceEdm from '../views/edm'
import EcommerceEvent from '../views/event'
import CostList from '../views/cost'
import DealerList from '../views/dealer/list'
import DealerOrder from '../views/dealer/order'
import DealerDashboard from '../views/dealer'
import Email from '../views/email'
import EmailLogin from '../views/email/login'
import EmailInbox from '../views/email/inbox'
import EmailSent from '../views/email/sent'
import EmailBuild from '../views/email/build'
import EmailPiece from '../views/email/piece'
import EmailTag from '../views/email/tag'
import Group from '../views/group'
import Focus from '../views/group/focus'
import Inventory from '../views/Inventory'
import User from '../views/user'
import UserAdd from '../views/user/Add'
import Active from '../views/active'
import Media from '../views/media'
import MediaFolder from '../views/media/folder'
import Questionnaire from '../views/Questionnaire'
import QuestionnaireEdit from '../views/Questionnaire/edit'
import Seo from '../views/seo'
import SeoEdit from '../views/seo/edit'
import Order from '../views/order'
import Product from '../views/product'
import ProductEdit from '../views/product/edit'
import Comment from '../views/product/comment'

import store from '../store'
import { fetchApi } from '../utils'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/blog/create',
    name: 'BlogCreate',
    component: BlogEdit
  },
  {
    path: '/blog/:id/edit',
    name: 'BlogEdit',
    component: BlogEdit
  },
  {
    path: '/blog/category',
    name: 'BlogCategory',
    component: BlogCategoy
  },
  {
    path: '/e-commerce',
    name: 'Ecommerce',
    component: EcommerceDashboard
  },
  {
    path: '/e-commerce-coupon',
    name: 'EcommerceCoupon',
    component: EcommerceCoupon
  },
  {
    path: '/e-commerce-event',
    name: 'EcommerceEvent',
    component: EcommerceEvent
  },
  {
    path: '/e-commerce-order',
    name: 'EcommerceOrder',
    component: EcommerceOrder
  },
  {
    path: '/e-commerce-edm',
    name: 'EcommerceEdm',
    component: EcommerceEdm
  },
  {
    path: '/cost-list',
    name: 'CostList',
    component: CostList
  },
  {
    path: '/comment',
    name: 'Comment',
    component: Comment
  },
  {
    path: '/dealer',
    name: 'Dealer',
    component: DealerDashboard
  },
  {
    path: '/dealer-list',
    name: 'DealerList',
    component: DealerList
  },
  {
    path: '/dealer-order',
    name: 'DealerOrder',
    component: DealerOrder
  },
  {
    path: '/email',
    name: 'Email',
    component: Email,
    redirect: '/email/inbox',
    children: [
      {
        path: '/email/compose',
        name: 'Email-Compose',
        component: Compose
      },
      {
        path: '/email/login',
        name: 'Email-Login',
        component: EmailLogin
      },
      {
        path: '/email/inbox',
        name: 'Email-Inbox',
        component: EmailInbox,
        beforeEnter: async (to, from, next) => {
          const { emailUser } = store.state.email
          if (!emailUser) {
            const res = await fetchApi('/api/session/', { get: ['email_username'] })
            if (res.status ===200 && res.data.email_username) next()
            else next({ name: 'Email-Login' })
          }
          next()
        }
      },
      {
        path: '/email/sent',
        name: 'Email-Sent',
        component: EmailSent
      },
      {
        path: '/email/build',
        name: 'Email-Build',
        component: EmailBuild
      },
      {
        path: '/email/inbox/:id',
        name: 'Email-Inbox-Piece',
        component: EmailPiece
      },
      {
        path: '/email/sent/:id',
        name: 'Email-Sent-Piece',
        component: EmailPiece
      },
      {
        path: '/email/tag/:tagId',
        name: 'Email-Tag',
        component: EmailTag
      },
    ]
  },
  {
    path: '/group',
    name: 'Group',
    component: Group
  },
  {
    path: '/group/:address',
    name: 'FocusGroup',
    component: Focus
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
  {
    path: '/user/add',
    name: 'UserAdd',
    component: UserAdd
  },
  {
    path: '/active/:uid',
    name: 'Active',
    component: Active
  },
  {
    path: '/media',
    name: 'Media',
    component: Media
  },
  {
    path: '/media/:folder',
    name: 'MediaFolder',
    component: MediaFolder
  },
  {
    path: '/questionnaire',
    name: 'Questionnaire',
    component: Questionnaire
  },
  {
    path: '/questionnaire/create',
    name: 'QuestionnaireCreate',
    component: QuestionnaireEdit
  },
  {
    path: '/seo',
    name: 'SEO',
    component: Seo
  },
  {
    path: '/seo/create',
    name: 'SEOCreate',
    component: SeoEdit
  },
  {
    path: '/seo/:id/edit',
    name: 'SEOEdit',
    component: SeoEdit
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/product/create',
    name: 'ProductCreate',
    component: ProductEdit
  },
  {
    path: '/product/:id/edit',
    name: 'ProductEdit',
    component: ProductEdit
  },
  {
    path: '*',
    redirect: '/login'
  }
]


export default routes;
