<template>
  <button :type="type || 'button'" :class="classes" @click="onClick">
    <div v-if="loading" class="progress" />
    <i v-if="icon" :class="iconClasses"></i>
    <slot>{{ label }}</slot>
  </button>
</template>

<script>

export default {
  name: 'k-btn',
  props: {
    label: {
      type: String,
    },
    varient: {
      type: String,
    },
    theme: {
      type: String
    },
    type: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    block: {
      type: Boolean
    },
    icon: {
      type: String
    },
    loading: {
      type: Boolean
    }
  },

  computed: {
    classes() {
      return {
        'k-btn': true,
        [`k-btn-${this.varient}`]: !!this.varient,
        [`k-btn--${this.theme}`]: true,
        'disabled': this.disabled,
        'block': this.block,
        'loading': this.loading
      };
    },
    iconClasses() {
      return {
        'bi': true,
        [`bi-${this.icon}`]: !!this.icon
      }
    }
  },

  methods: {
    onClick(e) {
      this.$emit('click');
    },
  },
};
</script>
