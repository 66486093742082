<template>
  <k-container class="view-cost-list-container">
    <template #header>
      <h2>成本項目列表</h2>
    </template>

    <k-card header="變動成本項目">
      <k-collapse v-if="groupWithType.VARIABLE">
        <k-collapse-panel
          v-for="type in Object.keys(groupWithType.VARIABLE)"
          :key="`cost_type_${type}`"
          :header="COST_TYPE_DICT[type]"
        >
          <k-menu>
            <k-menu-item
              v-for="cost in groupWithType.VARIABLE[type]"
              :key="`cost_${cost.cost_id}`"
            >
              <!-- <k-icon icon="file-earmark-richtext-fill"></k-icon> -->
              {{ cost.cost_name }}
            </k-menu-item>
          </k-menu>
          <!-- <div slot="extra">
            <k-tag :style="{ marginRight: '16px' }" theme="primary">{{ item.posts.length }}</k-tag>
            <k-dropdown>
              <div><k-icon icon="three-dots"></k-icon></div>
              <k-menu slot="overlay">
                <k-menu-item key="1" @click.stop="changeFocuesCategory(item)">
                  <k-icon icon="pencil-square" theme="info" />編輯
                </k-menu-item>
                <k-menu-item key="2" @click.stop="handleDelete(item)">
                  <k-icon icon="eye" color="#fff" />刪除分類
                </k-menu-item>
              </k-menu>
            </k-dropdown>
          </div> -->
        </k-collapse-panel>
      </k-collapse>
    </k-card>
    <k-card header="固定成本項目">
      {{ groupWithType.FIX }}
    </k-card>
  </k-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { KCollapse, KCollapsePanel } from '@/components/Collapse';
import { KMenu, KMenuItem, KMenuDivider } from '@/components/Menu';
import KButton from '@/components/Button'
import KCard from '@/components/Card'

export default {
  name: 'CostList',
  mounted() {
    this.$store.dispatch('cost/query')
  },
  data() {
    return {
      COST_TYPE_DICT
    }
  },
  computed: {
    ...mapState('cost', ['costList']),
    ...mapGetters('cost', ['groupWithType'])
  },
  components: {
    KCard, KButton, KCollapse, KCollapsePanel, KMenu, KMenuItem, KMenuDivider
  }
}

const COST_TYPE_DICT = {
  STAFF: '人事',
  PACKAGE: '包裝',
  SHIPPING: '運送費用'
}
</script>
