<template>
  <span :class="classes" :style="{ backgroundColor: backgroundColor || null }">
    <i v-if="icon" :class="iconClasses" />
    <img v-if="src" :src="src" :alt="alt">
    <span v-if="!!slotType" class="k-avatar-slot"><slot></slot></span>
  </span>
</template>

<script>
export default {
  props: {
    shape: String,
    size: [String, Number],
    icon: String,
    src: {
      tupe: String,
      default: null
    },
    alt: {
      type: String,
      default: ''
    },
    backgroundColor: String
  },
  computed: {
    classes() {
      return {
        'k-avatar': true,
        [`k-avatar-${this.size}`]: typeof this.size === 'string',
        [`k-avatar-${this.shape}`]: !!this.shape,
        'k-avatar-string': this.slotType && this.slotType.type === 'string',
        'k-avatar-badge': this.slotType && this.slotType.type === 'badge',
      }
    },
    styles() {
      return {
        width: typeof this.size === 'number' ? this.size+'px' : null,
        height: typeof this.size === 'number' ? this.size+'px' : null,
        fontSize: typeof this.size === 'number' ? parseInt(this.size*0.8)+'px' : null,
        ...this.styles
      }
    },
    iconClasses() {
      return {
        'bi': true,
        [this.icon]: !!this.icon
      }
    },
    slotType() {
      if (this.$slots.default && this.$slots.default[0].text) {
        const strLength = this.$slots.default[0].text.length;
        return {type: 'string', slotStyle: {transform: `scale(${1/(1+(strLength-4)*0.15)}) translateX(-50%)`}};
      }
      return null
    }
  }
}
</script>
