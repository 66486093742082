import database from '@/utils/database'
import { genBasicModel } from './utils/basicAction'
import firebase from './firebase'

const MODEL_NAME = 'admin'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME, query)

async function query() {
  return await database.table(MODEL_NAME).orderBy('auth_id').get()
}

const initSecondaryApp = (state, commit) => {
  var config = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
  };

  let secondaryApp = state.secondaryApp || firebase.initializeApp(config, "Secondary");
  if (!state.secondaryApp) commit("saveSecondaryApp", secondaryApp);
  return secondaryApp
} 

export default {
  namespaced: true,
  state: {
    adminList: [],
    authList: []
  },
  mutations: {
    ...basicMutation,
    addAdmin(state, payload) {
      state.adminList.push({ ...payload })
    },
    saveSecondaryApp(state, payload) {
      state.secondaryApp = payload
    },
  },
  actions: {
    ...basicAction,
    async queryAuth({ commit }) {
      const { state, data, exists } = await database.table('auth').get()
      if (state === 200 && exists) commit('save', { name: 'authList' , data})
    },
    async createNewAdmin({ commit, state }, payload) {
      try {
        const { admin_email } = payload
        const password = '000000'
        const secondaryApp = initSecondaryApp(state, commit)
        const userCredential = await secondaryApp.auth().createUserWithEmailAndPassword(admin_email, password)
        const uid = userCredential.user.uid
        const response = await database.table('admin').set({ admin_uid: uid , admin_is_confirm: false, ...payload })
        if (response.status === 200) {
          commit('addAdmin', { admin_uid: uid, admin_is_confirm: false , ...payload })
          return response
        }
      } catch (error) {
        console.log(error);
        return { error }
      }
    },
    async activeAdmin({ dispatch }, payload) {
      try {
        const { uid, admin_email, admin_name, password } = payload
        const userCredential = await firebase.auth().signInWithEmailAndPassword(admin_email, '000000')
        const user = userCredential.user
        await user.updatePassword(password)
        await dispatch('login/updateUser', { uid, admin_name, admin_is_confirm: false }, { root: true })
        dispatch('queryAdmin')
        return true 
      } catch (error) {
        console.log(error);
      }
    },
    async getUserEmail(_, payload) {
      const response = await database.table('admin').where('admin_uid', '=', payload).get();
      console.log(response);
      return response
    }
  },
  getters: {
    authDict(state) {
      console.log(state.authList);
      return state.authList.reduce((acc, a) => ({ ...acc, [a.auth_id]: { ...a } }), {})
    }
  }
}
