import database from '@/utils/database'
import { genBasicModel } from './utils/basicAction'
import firebase from './firebase'

const MODEL_NAME = 'layout'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME, query)

async function query() {
  return await database.table(MODEL_NAME).get()
}

export default {
  namespaced: true,
  state: {
    layoutList: [],
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction,
  },
  getters: {
    layoutDict: state => {
      if (!state.layoutList.length) return {}
      return  state.layoutList.reduce((acc, item) => {
        acc[item.layout_name] = item
        return acc
      }, {})
    }
  }
}
