<template>
  <label :class="classes">
    <input style="display:none;" type="checkbox" :value="value" v-model="isChecked" @change="onChange">
    <k-icon :class="{ checked: isChecked }" :icon="checkedContainValue ? checkedIcon : uncheckIcon" ></k-icon>
    <span><slot></slot></span>
  </label>
</template>

<script>
import { arrayContainObject } from '@/utils'

export default {
  props: {
    theme: String,
    checked: [Array, Boolean],
    value: [String, Object],
    uncheckIcon: {
      default: 'square',
      type: String
    },
    checkedIcon: {
      default: 'check-square-fill',
      type: String
    }
  },
  model: {
    prop: 'checked',
    event: 'input'
  },
  data() {
    return {
      checkedProxy: false
    }
  },
  computed: {
    isChecked: {
      get() {
        return this.checked
      },
      set(val) {
        this.checkedProxy = val
      }
    },
    classes() {
      return {
        'k-checkbox': true,
        [`theme--${this.theme}`]: !!this.theme
      }
    },
    checkedContainValue() {
      if (typeof this.checked === 'boolean') {
        return !!this.checked 
      } else {
        switch (typeof this.value) {
          case 'string':
            return this.checked.includes(this.value)
          case 'object':
            return arrayContainObject(this.checked, this.value)
          default:
            break;
        }
      }
      
    },
  },
  methods: {
    toggleCheck() {
      this.isChecked = !this.isChecked
    },
    onChange: function(e) {
      this.$emit('input', this.checkedProxy)
      this.$emit('change', this.checkedProxy)
    }
  }
}
</script>
