<template>
  <k-container class="view-blog-category-container">
    <template #header>
      <h2>文章分類</h2>
      <k-button theme="primary" @click="showModal">新增分類</k-button>
    </template>
    
    <k-card>
      <h3 slot="header" style="margin: 0">分類列表</h3>
      <k-collapse v-if="postList.length && categoryList.length">
        <k-collapse-panel v-for="item in filterPostByCategory" :key="`cate_${item.key_id}`" :header="item.category_name">
          <k-menu>
            <k-menu-item v-for="post in item.posts" :key="`item_post_${post.post_id}`" @click="toEdit(post.post_id)">
              <k-icon icon="file-earmark-richtext-fill"></k-icon>
              {{ post.post_title }}
            </k-menu-item>
            <k-menu-item v-if="!item.posts.length">
              <k-empty></k-empty>
            </k-menu-item>
          </k-menu>
          <div slot="extra">
            <k-tag :style="{ marginRight: '16px' }" theme="primary">{{ item.posts.length }}</k-tag>
            <k-dropdown>
              <div><k-icon icon="three-dots"></k-icon></div>
              <k-menu slot="overlay">
                <k-menu-item key="1" @click.stop="changeFocuesCategory(item)">
                  <k-icon icon="pencil-square" theme="info" />編輯
                </k-menu-item>
                <k-menu-item key="2" @click.stop="handleDelete(item)">
                  <k-icon icon="eye" color="#fff" />刪除分類
                </k-menu-item>
              </k-menu>
            </k-dropdown>
          </div>
        </k-collapse-panel>
      </k-collapse>
    </k-card>

    <k-modal :show.sync="modalIsShow" :title="`${mode === 'CREATE' ? 'New': 'Edit'} Category`" hideFooter>
      <k-form :questionConfig.sync="questions" :defaultValues="focusCategory" @submit="handleSubmit" />
    </k-modal>
  </k-container>
</template>

<script>
import { KCollapse, KCollapsePanel } from '@/components/Collapse';
import KCard from '@/components/Card';
import KDropdown from '@/components/Dropdown';
import KButton from '@/components/Button'
import KTag from '@/components/Tag';
import KModal from '@/components/Modal'
import KEmpty from '@/components/Empty'
import { KForm } from '@/components/Form'
import { KMenu, KMenuItem, KMenuDivider } from '@/components/Menu';
import storage from '@/utils/storage'
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  mounted() {
    if (!this.categoryList.length) this.$store.dispatch('posts/queryCategories')
    if (!this.postList.length) this.$store.dispatch('posts/query')
  },
  data() {
    return {
      modalIsShow: false,
      questions: genQuestions(this.handleFileChange, this.mode),
      focusCategory: {},
    }
  },
  methods: {
    ...mapActions('media', ['insertFileInfo', 'registerFileUse', 'unlinkFileUse']),
    ...mapActions('posts', ['createCategory', 'updateCategory', 'deleteCategory']),

    toEdit(id) {
      this.$router.push(`/blog/${id}/edit`)
    },
    showModal() {
      this.focusCategory = {}
      this.mode = 'CREATE'
      this.modalIsShow = true;
    },
    changeFocuesCategory(item) {
      console.log(item);
      const { key_id, category_name, category_bannerPath, file_id } = item
      this.focusCategory = { key_id, category_name, category_banner: category_bannerPath, file_id }
      this.mode = 'UPDATE'
      this.modalIsShow = true
    },
    handleFileChange(info) {
      this.questions[1].imgSrc = info.src
    },
    async handleDelete({ category_id, file_id, posts }) {
      if (!posts.length) {
        console.log(category_id);
        const res = await this.deleteCategory({ id: category_id, file_id })
      } else {
        this.$message.error('此分類尚有文章，無法進行刪除！')
      }
    },
    async handleSubmit({ fileList, values }) {
      console.log({ fileList, values });
      if (this.mode === 'CREATE' || Object.keys(fileList).length) {
        var { file, file_path, upload_at } = await storage.path('img/category').put(fileList.category_banner[0])
        var file_id = await this.insertFileInfo({ file, file_path, upload_at })
        // var uploadRes = await this.uploadFileList({ fileList, path: 'img/category' })
      }

      if (this.mode === 'CREATE') {
        var { id:category_id } = await this.createCategory({
          category_name: values.category_name,
          category_banner: values.category_banner[0],
          category_bannerPath: file_path,
        })
      }
      if (this.mode === 'UPDATE') {
        var category_id = this.focusCategory.key_id
        await this.updateCategory({
          id: category_id,
          category_name: values.category_name,
          category_banner: values.category_banner && values.category_banner[0],
          category_bannerPath: file_path
        })
      }

      if (file_id) {
        await this.registerFileUse({ file_id, category_id })
        if (this.mode === 'UPDATE' ) await this.unlinkFileUse({ 
          useType: 'category',
          useTypeId: category_id,
          fileIds: this.focusCategory.file_id,
          keepFile: false
        })
      }
      this.modalIsShow = false
    },
    
  },
  computed: {
    ...mapState('posts', ['categoryList', 'postList']),
    ...mapGetters('posts', ['filterPostByCategory']),
    mode: {
      get() {
        return Object.keys(this.focusCategory).length ? 'UPDATE' : 'CREATE'
      },
      set(newVal) {
        this.questions = genQuestions(this.handleFileChange, newVal)
      }
    }
  },
  components: {
    KCollapse, KCollapsePanel, KCard, KDropdown, KMenu, KMenuItem, KMenuDivider, KTag, KButton, KModal, KForm, KEmpty
  }
}

const genQuestions = (uploadCallback, mode) => [
  {
    questionType: 'k-input',
    name: 'category_name',
    required:true,
    label: '分類名稱',
    placeholder: '輸入分類名稱'
  },
  {
    questionType: 'k-upload',
    name: 'category_banner',
    required: Boolean(mode === "CREATE"),
    block: true,
    label: '分類封面',
    onChange: (info) => uploadCallback(info)
  },
  {
    questionType: 'k-btn',
    theme: 'primary',
    label: '建立',
    type: "submit"
  }
]
</script>
