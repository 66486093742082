var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-card',{class:_vm.classes},[_c('div',{staticClass:"brand",style:({ backgroundColor: _vm.color }),attrs:{"slot":"header"},slot:"header"},[(_vm.data && _vm.label)?_c('k-chart-line',{attrs:{"datasets":[
          {
            data: _vm.data,
            label: _vm.label,
            backgroundColor: '#ffffff',
            borderColor: '#ffffff4f',
            borderWidth: 3,
            fill: true,
            tension: 0.4,
          }
        ],"options":{
          responsive:true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: 15,
              left: -10,
              bottom: -60,
            }
          },
          plugins: { legend: { display: false } },
          scales: {
            x: { grid: { display: false, drawBorder: false }, ticks: { display: false, padding: -10 } },
            y: { grid: { display: false, drawBorder: false }, ticks: { display: false, padding: -10 } }
          },
          elements: { point: { radius: 0 } }
        }}}):_vm._e(),_c('k-icon',{attrs:{"icon":_vm.icon}})],1),_c('div',{staticClass:"widget-content"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.leftHeader))]),_c('p',[_vm._v(_vm._s(_vm.leftFooter))])]),_c('div',[_c('h2',[_vm._v(_vm._s(_vm.rightHeader))]),_c('p',[_vm._v(_vm._s(_vm.rightFooter))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }