<template>
  <k-card :class="classes" @click="$emit('click')">
    <div class="widget-img"><img :src="src" :alt="alt"></div>
    <div class="widget-content-wrapper">
      <div class="widget-content">
        <slot v-bind="{ ...info }"></slot>
      </div>
    </div>
  </k-card>
</template>

<script>
import KCard from '../Card';
import KIcon from '../Icon';

export default {
  props: {
    theme: String,
    src: String,
    alt: String,
    info: Object,
    sizeType: {
      type: String,
      default: 'Kb'
    }
  },
  methods: {
    // fileSizeFormat(size, type) {
    //   switch (type) {
    //     case 'Kb':
    //       return Math.round(size/1024, 2)
    //     case 'Mb':
    //       return Math.round(size/1024/1024, 2)
    //     case 'Gb':
    //       return Math.round(size/1024/1024/1024, 2)
    //     default:
    //       return Math.round(size/1024, 2)
    //   }
    // }
  },
  computed: {
    classes() {
      return {
        'k-widget': true,
        'k-widget-image': true
      }
    }
  },
  components: {
    KCard, KIcon
  }
}
</script>
