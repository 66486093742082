<template>
  <div :class="classes">
    <div class="star-container">
      <div class="rate-wrapper" :style="{ width: `${computedValue*20}%` }">
        <div class="rate">
          <StarComponent />
          <StarComponent />
          <StarComponent />
          <StarComponent />
          <StarComponent />
        </div>
      </div>
      <div class="base">
        <StarComponent />
        <StarComponent />
        <StarComponent />
        <StarComponent />
        <StarComponent />
      </div>
    </div>
    <p v-if="showValue">{{ value }}</p>
  </div>
</template>

<script>
import StarComponent from './StarComponent.vue'

export default {
  props: {
    value: [Number, String],
    max: [Number, String],
    showValue: Boolean
  },
  computed: {
    classes() {
      return {
        'k-progress-star': true
      }
    },
    computedValue() {
      return this.value / this.computedMax * 5
    },
    computedMax() {
      return parseInt(this.max) || 5
    }
  },
  components: {
    StarComponent
  }
}
</script>
