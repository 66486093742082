export const KForm = require('./KForm.vue').default
export const KInput = require('./KInput.vue').default
export const KRadio = require('./KRadio.vue').default
export const KRadioButton = require('./KRadioButton.vue').default
export const KRadioGroup = require('./KRadioGroup.vue').default
export const KSelect = require('./KSelect.vue').default
export const KSwitch = require('./KSwitch.vue').default
export const KTextarea = require('./KTextarea.vue').default
export const KDatePicker = require('./KDatePicker.vue').default
export const KRangePicker = require('./KRangePicker.vue').default
export const KUpload = require('./KUpload.vue').default
export const KCoder = require('./KCoder.vue').default
export const KCheckbox = require('./KCheckbox.vue').default

