import ExcelJs from "exceljs";
import moment from 'moment'

async function exportFile(workbook, fileName) {
  const content = await workbook.xlsx.writeBuffer()
  const link = document.createElement("a");
  const blobData = new Blob([content], {
    type: "application/vnd.ms-excel;charset=utf-8;"
  });
  link.download = `${fileName}.xlsx`;
  link.href = URL.createObjectURL(blobData);
  link.click();
}

export async function genOrderReceiptExcel(ordersList, productDict) {
  const workbook = new ExcelJs.Workbook();
  const sheet = workbook.addWorksheet('訂單明細');
  //標題列
  const headerRow = sheet.addRow(['發票編號', '訂單編號', '貨號', '名稱', '數量', '單價', '金額', '訂單小計']);
  ordersList.forEach(order => {
    const receiptId = order.order_receipt_id;
    const orderId = order.orders_id;
    for (let i = 0; i < order.order_products.length; i++) {
      let product = order.order_products[i];
        let productInfo = productDict[order.order_products[i].product_id];
      if (i === 0) {
        const firstRow = sheet.addRow([receiptId, orderId, productInfo.product_uid, productInfo.product_name_zh, product.product_quantity, product.product_price, product.product_quantity * product.product_price, order.order_amount]);
      } else {
        const row = sheet.addRow(['', '', productInfo.product_uid, productInfo.product_name_zh, product.product_quantity, product.product_price, product.product_quantity * product.product_price, '']);
      }
      const element = array[i];
      
    }
  });
}

export async function genOrderReceiptCreateExcel(OrderList, productDict, productUidDict) {
  const workbook = new ExcelJs.Workbook();
  const sheet = workbook.addWorksheet('sheet1');
  //標題列
  const headerRow = sheet.addRow(["匯入格式","作業指令","發票號碼","訂單編號","發票日期","載具類型","載具號碼","捐贈碼","消費者編號","買受人名稱","收件人","地址","客戶手機","客戶信箱","保留欄位","通關方式","卡後四碼","備註","商品編號","商品名稱","數量","單價","小計","稅額","價格類別","商品課稅別","稅率代碼","幣別代碼","銷售通路 ","零稅率原因","商品備註","公司地址","發票類型","訂單備註","額外訂單編號 ","請款單號","出貨單號","採購單號"]);
  const orderListCopy = OrderList.filter(order => order.order_type === 'BUY')
  orderListCopy.sort((a, b) => a.order_create_at - b.order_create_at)
  orderListCopy.forEach(order => {
    const giveawayCoupons = order.order_coupon_used.filter(coupon => coupon.coupon_type === 'GIVEAWAY');
    const productAmount = order.order_products.reduce((acc, product) => acc + product.product_quantity * product.product_price, 0);
    const shippingFee = productAmount >= 1000 || order.order_products.filter(p => p.product_id == '38').length > 0 ? []: [{item_name: '運費', items_price: 70, items_quantity: 1, items_amount: 70}];
    const totalAmount = productAmount+shippingFee.reduce((acc, item) => acc + item.items_amount, 0);
    console.log(order.orders_id ,totalAmount, shippingFee);
    const discount = totalAmount > order.order_amount ? [{item_name: '折扣', items_price: order.order_amount-totalAmount, items_quantity: '1', items_amount: order.order_amount-totalAmount}] : []

    const itemsShow = [
      ...order.order_products.map(product => {
        const productInfo = productDict[product.product_id];
        return {
          item_uid: productInfo.product_uid,
          item_name: productInfo.product_name_zh,
          items_quantity: product.product_quantity,
          items_price: product.product_price,
          items_amount: product.product_quantity * product.product_price
        }
      }),
      ...giveawayCoupons.reduce((acc, coupon) => {
        return [...acc, ...coupon.coupon_items.map(item => {
          const giveawayInfo = productUidDict[item.product_uid];
          return {
            item_uid: item.product_uid,
            item_name: giveawayInfo?.product_name_zh || '',
            items_quantity: item.product_quantity,
            items_price: '0',
            items_amount: '0'
          }
        })]
      }, []),
      ...shippingFee,
      ...discount
    ]
    for (let i = 0; i < itemsShow.length; i++) {
      const itemColumnValues = [
        itemsShow[i].item_uid, //商品編號
        itemsShow[i].item_name, //商品名稱
        itemsShow[i].items_quantity, //數量
        itemsShow[i].items_price, //單價
        itemsShow[i].items_amount, //小計
      ]
      if (i === 0) {
        const firstRow = sheet.addRow([
          "3", //匯入格式 3:訂單明細
          "0", //作業指令 0:新增 1:修改 2:刪除
          "", //發票號碼
          order.orders_id, //訂單編號
          "", //發票日期
          "10", //載具類型
          order.order_receipt_unicode || "", //載具號碼
          "", //捐贈碼
          "", //消費者編號
          "", //買受人名稱
          "", //收件人
          "", //地址
          "", //客戶手機
          "", //客戶信箱
          "", //保留欄位
          "", //通關方式
          "", //卡後四碼
          "", //備註
          ...itemColumnValues, // 商品資訊
          "0", //稅額
          "2", //價格類別
          "1", //商品課稅別
          "0", //稅率代碼
          "NTD", //幣別代碼
        ])
      } else {
        sheet.addRow([
          ...Array(18).fill(''), //空白
          ...itemColumnValues, // 商品資訊
          "0", //稅額
          "2", //價格類別
          "1", //商品課稅別
          "0", //稅率代碼
          "NTD", //幣別代碼
        ])
      }
    }
    
  });

  //輸出檔案
  exportFile(workbook, `發票批次開立_${new moment().format('YYYYMMDD')}`)
}

export async function genOrderReceiptInfoExcel(OrderList, productDict, productUidDict) {
  const workbook = new ExcelJs.Workbook();
  const sheet = workbook.addWorksheet('sheet1');
  //標題列
  const headerRow = sheet.addRow(["發票號碼","訂單編號","發票日期","商品編號","商品名稱","數量","單價","小計","訂單備註"]);
  const orderListCopy = OrderList.filter(order => order.order_receipt_id)
  orderListCopy.sort((a, b) => a.order_create_at - b.order_create_at)
  orderListCopy.forEach(order => {
    const giveawayCoupons = order.order_coupon_used.filter(coupon => coupon.coupon_type === 'GIVEAWAY');
    const productAmount = order.order_products.reduce((acc, product) => acc + product.product_quantity * product.product_price, 0);
    const shippingFee = productAmount >= 1000 || order.order_products.filter(p => p.product_id == '38').length > 0 ? []: [{item_name: '運費', items_price: 70, items_quantity: 1, items_amount: 70}];
    const totalAmount = productAmount+shippingFee.reduce((acc, item) => acc + item.items_amount, 0);
    console.log(order.orders_id ,totalAmount, shippingFee);
    const discount = totalAmount > order.order_amount ? [{item_name: '折扣', items_price: order.order_amount-totalAmount, items_quantity: '1', items_amount: order.order_amount-totalAmount}] : []

    const itemsShow = [
      ...order.order_products.map(product => {
        const productInfo = productDict[product.product_id];
        return {
          item_uid: productInfo.product_uid,
          item_name: productInfo.product_name_zh,
          items_quantity: product.product_quantity,
          items_price: product.product_price,
          items_amount: product.product_quantity * product.product_price
        }
      }),
      ...giveawayCoupons.reduce((acc, coupon) => {
        return [...acc, ...coupon.coupon_items.map(item => {
          const giveawayInfo = productUidDict[item.product_uid];
          return {
            item_uid: item.product_uid,
            item_name: giveawayInfo?.product_name_zh || '',
            items_quantity: item.product_quantity,
            items_price: '0',
            items_amount: '0'
          }
        })]
      }, []),
      ...shippingFee,
      ...discount
    ]
    for (let i = 0; i < itemsShow.length; i++) {
      const itemColumnValues = [
        itemsShow[i].item_uid, //商品編號
        itemsShow[i].item_name, //商品名稱
        itemsShow[i].items_quantity, //數量
        itemsShow[i].items_price, //單價
        itemsShow[i].items_amount, //小計
      ]
      if (i === 0) {
        const firstRow = sheet.addRow([
          "", //發票號碼
          order.orders_id, //訂單編號
          "", //發票日期
          ...itemColumnValues, // 商品資訊
          "", //備註
        ])
      } else {
        sheet.addRow([
          ...Array(3).fill(''), //空白
          ...itemColumnValues, // 商品資訊
          "", //備註
        ])
      }
    }
    
  });

  //輸出檔案
  exportFile(workbook, `發票明細_${new moment().format('YYYYMMDD')}`)
}

export function genUOrderShortInfo(orderList) {
  const workbook = new ExcelJs.Workbook();
  const sheet = workbook.addWorksheet('sheet1');
  const orderListCopy = orderList.filter(Odr => Odr.user_id)

  //標題列
  sheet.addRow(["訂單編號", "聯絡電話", "訂單金額", "購買時間", "聯絡Email"]);
  //訂單內容列
  orderListCopy.forEach(order => {
    sheet.addRow([
      order.orders_id || '', //訂單編號
      order.order_logistics_info.ReceiverCellPhone || '',  //聯絡電話
      order.order_amount || '', //訂單金額
      moment(Number(order.order_create_at)*1000).format('YYYY-MM-DD HH:mm:ss') || '', //購買時間
      order.order_logistics_info.ReceiverEmail || '', //聯絡Email
    ])
  })
  //輸出檔案
  exportFile(workbook, `訂單資訊_${new moment().format('YYYYMMDD')}`)
}
