<template>
  <span v-click-away="hide" :class="classes" @click="toggleShow">
    <slot></slot>
    <transition name="popover">
      <div v-if="show" :class="{'popover-content': true, [placement]: placement }">
        <div :class="{'popover-arrow': true, [placement]: placement }"></div>
        <div class="popover-inner">
          <div class="popover-message">
            <k-icon v-if="icon" :icon="icon" :theme="iconTheme"></k-icon>
            <p>{{ message || 'no message...' }}</p>
          </div>
          <div class="popover-buttons">
            <k-button @click.capture="handelOK" theme="warning">{{ okText || '確認' }}</k-button>
            <k-button @click.capture="handelCancel" theme="secondary">{{ cancelText || '取消' }}</k-button>
          </div>
        </div>
      </div>
    </transition>
  </span>
</template>

<script>
import KButton from './Button'
import KIcon from './Icon'

export default {
  props: {
    'okText': String,
    'cancelText': String,
    'icon': String,
    'iconTheme': {
      type: String,
      default: 'secondary'
    },
    'placement': String,
    'message': String,
    'disabled': Boolean
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    hide() {
      this.show = false
    },
    toggleShow() {
      if (this.disabled) {
        this.show = false
        this.$emit('prohibit')
      } else {
        this.show = !this.show
      }
    },
    handelOK() {
      this.$emit('confirm')
    },
    handelCancel() {
      this.$emit('cancel')
    }
  },
  computed: {
    classes() {
      return {
        'k-popconfirm': true,
        'show': this.show
      }
    }
  },
  components: {
    KButton, KIcon
  }
}
</script>
