<template>
  <k-container class="view-product-edit-container ">
    <template #header>
      <h2>產品上架</h2>
      <k-button theme="primary" @click="()=>$refs.form.submit()">{{ mode === 'CREATE' ? '建立' : '修改'}}</k-button>
    </template>
    <k-form ref="form" :questionConfig.sync="questions" @submit="handleSubmit" />

    <section class="image-and-seo">
        <k-card class="image-selector" header="從媒體庫中選擇產品首圖">
            <k-menu v-if="productImages.length">
              <k-menu-item @click="handleClick(image)" v-for="image in productImages" :key="image.key_id">
                <img :src="image.file_path" :alt="image.file_name">
                <p>{{ image.file_name }}</p>
              </k-menu-item>
            </k-menu>

            <k-menu v-else>
              <k-menu-item v-for="e, index in Array(5).fill()" :key="`skeloton_${index}`">
                <k-skeleton :loading="true" :line="0" active dense/>
              </k-menu-item>
            </k-menu>
          </k-card>

      <Setting ref="seo" @mutate="() => seoMutated = true" />
    </section>

    <k-modal :show.sync="showEnsure" title="選擇產品圖片" @ok="handleEnsure" @cancel="resetCache">
      確定要使用此圖片作為產品首圖嗎？
      <img v-if="avatarChooseCache" :src="avatarChooseCache.file_path" :alt="avatarChooseCache.file_name">
    </k-modal>
  </k-container>
</template>

<script>
import { KForm } from '@/components/Form'
import { KMenu, KMenuItem } from '@/components/Menu'
import KButton from '@/components/Button'
import KCard from '@/components/Card'
import KSkeleton from '@/components/Skeleton'
import KModal from '@/components/Modal'
import Setting from '@/views/seo/components/Setting'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  mounted() {
    this.$store.dispatch('media/query')
  },
  data() {
    return {
      mode: 'CREATE',
      questions: genQuestions(this.handleFileChange),
      showEnsure: false,
      avatarChoose: undefined,
      avatarChooseCache: undefined,
      seoMutated: false
    }
  },
  computed: {
    ...mapState('media', ['mediaList']),
    ...mapGetters('media', ['filterByMediaType']),

    productImages() {
      if (this.filterByMediaType.image) {
        return [...this.filterByMediaType.image, ...this.filterByMediaType.unused].filter(f => {
          return f.file_path.split('/')[2] === 'product'
        })
      }
      return []
    },
  },
  methods: {
    ...mapActions('product', ['add', 'createStructureData']),
    ...mapActions('media', ['registerFileUse']),

    handleClick(f) {
      this.avatarChooseCache = f
      this.showEnsure = true
    },
    handleEnsure() {
      this.questions.splice(0, 1, { ...this.questions[0] ,imgSrc: this.avatarChooseCache.file_path })
      this.avatarChoose = { ...this.avatarChooseCache }
      this.resetCache()
      this.showEnsure = false
      this.$message.success('已選擇首圖')
    },
    resetCache() {
      this.avatarChooseCache = undefined
    },
    handleFileChange(src) {
      this.questions[0].imgSrc = src
    },
    async handleSubmit(formData) {
      const { values, fileList } = formData
      if (!values.product_avatar && !this.avatarChoose) {
        this.$message.error('請選擇或上傳產品首圖'); return
      }
      let product_avatar_id;
      if (this.avatarChoose) product_avatar_id = this.avatarChoose.key_id
      const { id: product_id } = await this.add({ ...values, product_avatar_id })
      this.registerFileUse({ file_id: product_avatar_id, product_id })

      if (this.seoMutated) {
        const { templateUse: seo_template_id, data } = this.$refs.seo.getData()
        await this.createStructureData({ 
          id: product_id,
          product_structured_data: JSON.stringify(data),
          seo_template_id,
        })
      }
    }
  },
  components: {
    KForm, KMenu, KMenuItem, KCard, KSkeleton, KModal, KButton, Setting
  }
}

const genQuestions = (handleChange) => [
  {
    questionType: 'k-upload',
    name: 'product_avatar',
    block: true,
    droppable: true,
    label: '產品首圖',
    onChange: info => handleChange(info.src)
  },
  {
    questionType: 'k-input',
    name: 'product_name',
    label: '產品名稱',
    required: true
  },
  {
    questionType: 'k-input',
    type: 'number',
    name: 'product_price',
    label: '產品價格',
    step: '100',
    required: true
  },
  {
    questionType: 'k-textarea',
    name: 'product_summary',
    label: '產品概述（SEO使用）',
    placeholder: '請以最多140字描述此產品',
    resize: false,
    required: true
  }
]
</script>
