import Vue from 'vue'
import App from './App.vue'
import KirstyMessage from './plugin/Message';
import router from './router'
import store from './store'
import './scss/index.scss';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import KIcon from '@/components/Icon';
import Clipboard from './plugin/Clipboard';
import Mock from './plugin/Mock'
import { isParentNode } from '@/utils';

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(KirstyMessage)
Vue.use(Clipboard)
Vue.use(Mock)
Vue.config.productionTip = false

Vue.directive('click-away', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || isParentNode(el, event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

Vue.component('k-icon', KIcon);

Vue.component('k-container', {
  name: 'k-container',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    fluid: Boolean,
    title: String
  },
  computed: {
    classes() {
      return {
        'k-container': true,
        'fluid': !!this.fluid
      }
    }
  },
  render(h) {
    const classes = Object.entries(this.classes).filter(e=>e[1]).map(e=>e[0]).join(' ')
    return h(
      this.tag,
      { staticClass: classes },
      [
        this.title || this.$slots.header ? h(
          'header',
          { staticClass: 'pageTitle' },
          [this.$slots.header || h('h2', {}, this.title)
        ]) : null,
        this.$slots.default
      ]
    )
  } 
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
