<template>
  <k-container title="信箱登入">
    <k-card>
      <k-input outline="light" label="信箱帳號" v-model="email_username"></k-input>
      <k-input outline="light" label="密碼" type="password" v-model="email_password" @enter="handleLogin"></k-input>
      <k-button theme="primary" :loading="loading" block @click="handleLogin">登入</k-button>
    </k-card>
  </k-container>
</template>

<script>
import KCard from '@/components/Card'
import KButton from '@/components/Button'
import { KInput } from '@/components/Form'
import { fetchApi } from '@/utils'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      email_username: '',
      email_password: '',
      loading: false
    }
  },
  methods: {
    ...mapActions('email', ['queryWebmailInbox']),
    async handleLogin() {
      const { email_username, email_password } = this
      this.loading = true
      if (email_username && email_password) {
        const res = await this.queryWebmailInbox({ email_username, email_password })
        switch (res.status) {
          case 200:
            await fetchApi('/api/session/', { set: { email_username, email_password } })
            this.$router.push('/email/inbox')
            break
          default:
            this.$message.error(res.message)
            this.loading = false
            break
        }
      } else {
        this.$message.error('請確實填入帳號與密碼！')
        this.loading = false
      }
    }
  },
  components: {
    KButton, KCard, KInput
  }
}
</script>
