<template>
  <div :class="classes">
    <div class="k-collapse-header" @click.stop="handleClick">
      <i v-if="showArrow" class="bi bi-caret-right-fill arrow"></i>
      <slot name="header">{{ header }}</slot>
      <div class="k-collapse-extra">
        <slot name="extra"></slot>
      </div>
    </div>
    <div class="k-collapse-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: String,
    showArrow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    mutationOpenState(bool) {
      this.isOpen = bool
    },
    handleClick() {
      this.isOpen = !this.isOpen
      this.$parent.mutateActiveKey(this.$vnode.key)
    }
  },
  computed: {
    classes() {
      return {
        'k-collapse-item': true,
        'open': this.isOpen
      }
    }
  },
}
</script>
