<template>
  <k-modal class="link-modal" :show="this.show" :closable="false" title="插入超連結" hideFooter @cancel="handleClick(false)">
    <k-form ref="form" :questionConfig.sync="QuestionConfig" :defaultValues="defaultValues" @submit="handleSubmit"/>
    <div class="button-group">
      <k-button label="取消" theme="light" @click="() => this.handleClick(false)" />
      <k-button label="插入超連結" theme="info" @click="() => this.handleClick(true)" />
    </div>
  </k-modal>
</template>

<script>
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import MediaSelector from './MediaSelector.vue'
import { KForm } from '@/components/Form'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      QuestionConfig: JSON.parse(JSON.stringify(QuestionConfig)),
      show: false,
      callback: void 8,
      defaultValues: {}
    }
  },
  methods: {
    execute({callback, entity}) {
      this.show = true
      this.callback = callback
      if (entity) this.defaultValues = { ...entity }
    },
    handleClick(bool) {
      console.log(bool);
      if (bool) this.$refs.form.handleSubmit()
      else this.show = false
      this.QuestionConfig = JSON.parse(JSON.stringify(QuestionConfig))
      console.log(this.QuestionConfig);
    },
    handleSubmit(formData) {
      const { values } = formData
      this.show = false
      this.callback(values)
    }
  },
  computed: {
    classes() {
      return {
        'show': this.show,
      }
    },
    spanClasses() {
      return {
        [`confirm--${this.type}`]: this.type
      }
    }
  },
  components: {
    KModal, KButton, KForm
  }
}

const QuestionConfig = [
  {
    questionType: 'k-input',
    name: 'link_url',
    label: "連結 URL",
    theme: "light",
    required: true,
    title: '請輸入連結 URL',
  },
  {
    questionType: 'k-input',
    name: 'link_context',
    label: "連結顯示文字",
    theme: "light",
    required: true,
    title: '請輸入連結顯示文字',
  },
  {
    questionType: 'k-select',
    name: 'link_target',
    label:"連結開啟方式",
    options: [
      { text: '直接開啟連結', value: '_self' },
      { text: '在新視窗開啟連結', value: '_blank' }
    ],
    required: true,
    theme: 'light',
    placeholder: '請選擇文章類別',
    block: true
  },
]
</script>
