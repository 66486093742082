<template>
  <div :class="classes">
    <slot>
      <div
        :class="{ 'progress-bar': true, [`theme--${theme}`]: !!this.theme }"
        :style="{ width: `${parseInt(value) * 100 / computedMax}%` }"
      >
        <span v-if="showValue && size !== 'thin'">{{ value }}</span>
      </div>
    </slot>
  </div>
</template>

<script>
import ProgressBar from './ProgressBar'

export default {
  extends: ProgressBar,
  data() {
    return {
      childrenValueSum: null
    }
  },
  mounted() {
    if (this.$slots.default && this.$slots.default.length && !this.max) {
      const childrenValueSum = this.$slots.default.reduce((acc, $s) => acc + parseInt($s.componentInstance.value), 0)
      this.childrenValueSum = childrenValueSum
    }
  },
  computed: {
    computedMax() {
      if (this.childrenValueSum && this.childrenValueSum > 100) return parseInt(this.max) || childrenValueSum
      return parseInt(this.max) || 100
    }
  },
}
</script>
