<template>
  <k-card :class="classes">
    <div slot="header" class="brand" :style="{ backgroundColor: color }">
        <k-chart-line
          v-if="data && label"
          :datasets="[
            {
              data,
              label,
              backgroundColor: '#ffffff',
              borderColor: '#ffffff4f',
              borderWidth: 3,
              fill: true,
              tension: 0.4,
            }
          ]"
          :options="{
            responsive:true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                top: 15,
                left: -10,
                bottom: -60,
              }
            },
            plugins: { legend: { display: false } },
            scales: {
              x: { grid: { display: false, drawBorder: false }, ticks: { display: false, padding: -10 } },
              y: { grid: { display: false, drawBorder: false }, ticks: { display: false, padding: -10 } }
            },
            elements: { point: { radius: 0 } }
          }"
        />
        <k-icon :icon="icon" />
      </div>

    <div class="widget-content">
      <div>
        <h2>{{ leftHeader }}</h2>
        <p>{{ leftFooter }}</p>
      </div>
      <div>
        <h2>{{ rightHeader }}</h2>
        <p>{{ rightFooter }}</p>
      </div>
    </div>
  </k-card>
</template>

<script>
import KCard from '../Card'
import { KChartLine } from '../Chart'

export default {
  props: {
    leftHeader: [Number, String],
    leftFooter: [Number, String],
    rightHeader: [Number, String],
    rightFooter: [Number, String],
    icon: String,
    color: String,
    data: Array,
    label: [String, Array]
  },
  computed: {
    classes() {
      return {
        'k-widget': true,
        'k-widget-brand': true
      }
    }
  },
  components: {
    KCard, KChartLine
  }
}
</script>
